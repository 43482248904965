"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorizationKeys = {
    ViewCustomer: 1,
    VehicleDetails: 2,
    SaleDetails: 3,
    ActivitiesLog: 4,
    ServiceHistory: 5,
    ChangeLog: 6,
    CustomerRecap: 7,
    Inventory: 8,
    Payments: 9,
    PaymentDetails: 10,
    PostActivity: 11,
    MarkAsSold: 12,
    MarkAsPaidOff: 13,
    MarkAsWarranty: 14,
    MarkAsWatch: 15,
    Print: 16,
    Reassign: 17,
    Access: 18,
    EditCustomer: 19,
    ConvertService: 20,
    Sale: 21,
    Payoff: 22,
    Odometer: 23,
    NewVehicle: 24,
    Alert: 25,
    Flex: 26,
    Conversion: 27,
    Appointment: 28,
    Service: 29,
    Mileage: 30,
    Warranty: 31,
    ContractEnd: 32,
    AllowExport: 33,
    AllowBatchReassigning: 34,
    AllowBatchAccess: 35,
    ViewAllCustomers: 36,
    EditLayout: 37,
    MarkAsNotOwn: 38,
    Script: 39,
    Scheduled: 40,
    Locked: 41,
    DoNotContact: 42,
    ViewAllConquests: 43,
    Price: 44,
    TradeValue: 45,
    ShareSearch: 46,
    PrintTemplate: 47,
    TemplateSettings: 48,
    TemplateOutput: 49,
    VehicleHistoryReports: 50,
    EmailTemplate: 51,
    SwitchUserDealer: 52,
    PcuVisit: 53,
    AdminCreditConvert: 54,
    EditCreditConvert: 55,
    GrossProfitDetails: 56,
    CurrentVehicleRecentRO: 57,
    CurrentVehicleMileage: 58,
    CurrentVehicleDelivery: 59,
    CurrentVehicleProjectedMileage: 60,
    CurrentVehicleBank: 61,
    CurrentVehicleSoldAs: 62,
    DestinationFeeAsEditable: 63,
    AdministerCreditConvert1Point5: 64,
    HaveNotServiced: 65,
    DueForService: 66,
    ViewSDOSettings: 67,
    DirectAlertSettings: 69,
    ChangeExpirationSetting: 70,
    SelfRegistrationAccess: 72,
    AutoAlertSupport: 73,
    ProductSettingAccess: 74,
    DirectAlert: 75,
    CreditConvertSoftPullManual: 76,
    ManageSearches: 102,
    SendTextSales: 103,
    TextMessageNewNumberSettings: 104,
    SendTextService: 105,
    CrossBrandMVO: 109
};
