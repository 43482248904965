import angular from 'angular';
import { OpportunitiesModule } from '../core/opportunities.module';

OpportunitiesModule.provider('selectedDealersInjector', selectedDealersInjectorProvider);

function selectedDealersInjectorProvider() {

    const URL_PREFIXES_TO_MODIFY = [];
    const CONFIG = {};

    this.configure = function ({ employeeDefaultDealer }) {
        if (employeeDefaultDealer != null) {
            CONFIG.employeeDefaultDealer = employeeDefaultDealer;
        }
    }

    this.addUrl = function (url) {
        if (url) {
            URL_PREFIXES_TO_MODIFY.push(url);
        }
    };

    this.$get = function ($q, crossDomainStorageService, storageService) {
        "ngInject";

        return {
            request(config) {
                // Only continue if the request URL matches a configured prefix
                if (!URL_PREFIXES_TO_MODIFY.some(prefix => config.url.startsWith(prefix)))
                    return config;

                var selectedDealers = getSelectedDealers();

                let selectedDealerGroupId = selectedDealers && selectedDealers.dealerGroupId ? selectedDealers.dealerGroupId : null;
                let selectedDealerIds = selectedDealers && selectedDealers.dealerIds ? selectedDealers.dealerIds : null;
                let overrideDealerIds = config.overrideDealerIds ? config.overrideDealerIds : null;

                // Employee users default to MBDEMO
                // This block should only be hit for employees.
                if (!selectedDealerGroupId) {
                    selectedDealerGroupId = CONFIG.employeeDefaultDealer.dealerGroupId;
                }
                if (!selectedDealerIds) {
                    selectedDealerIds = [CONFIG.employeeDefaultDealer.dealerId];
                }

                config.headers = config.headers || {};
                config.headers['dealers'] = selectedDealerIds.join(',');
                config.headers['dealergroupid'] = selectedDealerGroupId;

                if (overrideDealerIds != null)
                    config.headers['dealerIdOverride'] = overrideDealerIds.join(',');

                return config;
            }
        }

        function getSelectedDealers() {
            var selectedDealers = storageService.getItem('impersonatingSelectedDealers');

            if (selectedDealers != null) {
                var userProfile = storageService.getItem('userProfile');

                if (userProfile && userProfile.employeeUser) {
                    storageService.removeItem('impersonatingSelectedDealers');                   
                }
            }
            else {
                selectedDealers = crossDomainStorageService.getItem('selectedDealers');
            }

            if (selectedDealers && selectedDealers.dealers && selectedDealers.dealers.length > 0) {
                selectedDealers.dealerIds = selectedDealers.dealers.map(dealer => dealer.id);
            }

            return selectedDealers;
        }
    };
}
