"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var authorization_service_1 = require("../../../../services/authorization.service");
var OnDemandOffersComponent = /** @class */ (function () {
    function OnDemandOffersComponent(authorizationService, vehicleService, translate) {
        var _this = this;
        this.authorizationService = authorizationService;
        this.vehicleService = vehicleService;
        this.translate = translate;
        // define eventOUT signature
        this.select = new core_1.EventEmitter();
        this.vehicleImageNotAvailableUrl = require('../../../../images/vehicle-image-not-available.png');
        this.selectedVehicle = { selectedVehicleType: 'standardVehicle', alternateVehicleIndex: 0, onDemandVehicle: null };
        this.offBrandHasBeenCalledForEntity = false;
        this.showOffBrandDropSelect = false;
        this.noRecommendationsLabel = '';
        translate.get('loading').subscribe(function (translation) { _this.loadingText = translation; });
        translate.get('onDemandRecommendations').subscribe(function (translation) { _this.noRecommendationsText = translation; });
    }
    OnDemandOffersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authorizationService.getDealSheetPermissions()
            .then(function (dealSheetPermissions) {
            _this.hasPaymentsPermission = dealSheetPermissions.payments;
        });
    };
    OnDemandOffersComponent.prototype.ngOnChanges = function (changes) {
        if (changes.dealerGroupMakes) {
            this.selectedMakeId = "";
        }
    };
    OnDemandOffersComponent.prototype.toggleOnDemandDropSelect = function () {
        var onDemandDiv = document.getElementById('onDemandOffBrandListToggleWrapper');
        if (this.alternateVehicles) {
            if (onDemandDiv.classList.contains('open')) {
                //logic looks backward but the classList is updated after this event
                this.showOffBrandDropSelect = false;
            }
            else {
                this.showOffBrandDropSelect = true;
            }
        }
        else {
            onDemandDiv.classList.add('open'); //hack to keep the empty list from showing
            this.showOffBrandDropSelect = !this.showOffBrandDropSelect;
            if (!this.showOffBrandDropSelect) {
                this.selectedMakeId = "";
                this.noRecommendationsLabel = '';
            }
        }
    };
    OnDemandOffersComponent.prototype.onMakeSelect = function () {
        var _this = this;
        if (this.selectedMakeId && this.selectedMakeId > 0) {
            this.noRecommendationsLabel = this.loadingText;
            this.vehicleService.getOnDemandOffBrandVehicles(this.opportunityId, [this.selectedMakeId])
                .then(function (replacementVehicles) {
                if (replacementVehicles && replacementVehicles.offBrandAlternateVehicles) {
                    _this.noRecommendationsLabel = '';
                    _this.alternateVehicles = replacementVehicles.offBrandAlternateVehicles;
                    document.getElementById('onDemandOffBrandListToggleWrapper').classList.add('open');
                }
                else {
                    _this.noRecommendationsLabel = _this.noRecommendationsText;
                }
            });
        }
    };
    OnDemandOffersComponent.prototype.clickHandler = function (chosenVehicleType, alternateVehicleIndex, vehicle) {
        this.selectedVehicle = { selectedVehicleType: 'onDemandVehicle', alternateVehicleIndex: alternateVehicleIndex, onDemandVehicle: vehicle };
        this.select.emit(this.selectedVehicle);
        this.selectedVehicleObject = vehicle;
    };
    // on error of image src load default fallback image
    OnDemandOffersComponent.prototype.updateUrl = function (event, vehicle) {
        vehicle.imagePath = this.vehicleImageNotAvailableUrl;
    };
    OnDemandOffersComponent.prototype.getSelectedAttribute = function (vehicleType, index) {
        if (index === void 0) { index = 0; }
        if (this.selectedVehicle &&
            this.selectedVehicle.selectedVehicleType === vehicleType) {
            var selectedIndex = this.selectedVehicle.alternateVehicleIndex;
            if (selectedIndex !== undefined && selectedIndex !== null) {
                return (this.selectedVehicle.alternateVehicleIndex === index);
            }
            return true;
        }
        return false;
    };
    OnDemandOffersComponent.prototype.getContainerClass = function () {
        return 'row mvos-more-container1';
    };
    return OnDemandOffersComponent;
}());
exports.OnDemandOffersComponent = OnDemandOffersComponent;
