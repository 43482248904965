//
// THIS FILE WAS GENERATED BY 'generate-dmspushapi.ts'
//
// DO NOT EDIT THIS FILE MANUALLY.
//
// TO UPDATE THIS FILE, RUN:
//   yarn run generate:dmspushapi

export default {
    "swagger": "2.0",
    "info": {
        "version": "v1",
        "title": "AutoAlert DmsPush (Business Layer, User Context)",
        "description": "AutoAlert.AlertMiner.DmsPush.Api",
        "termsOfService": "AutoAlert, Inc."
    },
    "paths": {
        "/api/Activity/ManualPushToCrm": {
            "post": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "ManualPushToCrm_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "pushToCrm",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.Dto.Activity.ManualPushToCrmDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.Models.ActivityActionResponseModel"
                        }
                    }
                }
            }
        },
        "/api/Activity/SubmitAction": {
            "post": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "SubmitAction_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "submission",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.Models.ActivityActionSubmissionModel"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.Models.ActivityActionResponseModel"
                        }
                    }
                }
            }
        },
        "/api/Deskit/IsPushToDeskitValid/{dealerId}": {
            "get": {
                "tags": [
                    "DeskitClient"
                ],
                "operationId": "IsDeskitPushValid_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.Dto.DeskitDto.IsDeskitPushValidDto"
                        }
                    }
                }
            }
        },
        "/api/Deskit/PushToDeskit": {
            "post": {
                "tags": [
                    "DeskitClient"
                ],
                "operationId": "PushToDeskitAsync_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.Dto.DeskitDto.DeskitQuotingPushDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.Dto.DeskitDto.DeskitQuotingPushResponseDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/IsDmsPushValid/{dealerId}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "IsDmsPushValid_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "responseMethod",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "enum": [
                            0,
                            1,
                            2
                        ]
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.IsDmsPushValidDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushCustomer": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushCustomer_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "customer",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushPostCustomerDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushPostFAndIDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetDealerTrackLookupData/{dealerId}/{trackingKey}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetDealerTrackLookupData_ByDealerid_ByTrackingkey_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trackingKey",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int64"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DealerTrack.DmsPushDealerTrackLookupDataDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushDealerTrackFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushDealerTrackFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushPostDealerTrackFAndIDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetCDKLookupData/{dealerId}/{trackingKey}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetCDKLookupData_ByDealerid_ByTrackingkey_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "TrackingKey",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int64"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.CDK.DmsPushCDKLookupDataDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushCDKFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushCDKFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushPostCDKFAndIDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetRCILookupData/{dealerId}/{trackingKey}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetRCILookupData_ByDealerid_ByTrackingkey_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "TrackingKey",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int64"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.RCI.DmsPushRCILookupDataDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushRCIFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushRCIFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushPostRCIFAndIDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetPBSLookupData/{dealerId}/{trackingKey}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetPBSLookupData_ByDealerid_ByTrackingkey_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trackingKey",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int64"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.PBS.DmsPushPBSLookupDataDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushPBSFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushPBSFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushPostPBSFAndIDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushSertiFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushSertiFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushPostSertiFAndIDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushAdfFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushOecFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.Adf.DmsPushAdfDealDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    },
                    "409": {
                        "description": "Conflict",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushAutovanceFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushAutovanceFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.Autovance.Dto.DmsPushAutovanceDealDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    },
                    "409": {
                        "description": "Conflict",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetAutomateLookupData/{dealerId}/{trackingKey}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetAutomateLookupData_ByDealerid_ByTrackingkey_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trackingKey",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int64"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.Automate.DmsPushAutomateLookupDataDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushAutomateFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushAutomateFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushPostAutomateFAndIDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetDealerbuiltLookupData/{dealerId}/{trackingKey}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetDealerbuiltLookupData_ByDealerid_ByTrackingkey_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trackingKey",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int64"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.Communication.ExternalClients.DealerBuilt.DmsPushDealerbuiltLookupDataDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushDealerbuiltProspect": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushDealerbuiltProspect_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "prospect",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DealerBuilt.DMSPushDealerbuiltDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    },
                    "409": {
                        "description": "Conflict",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushDealerbuiltDeal": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushDealerbuiltDeal_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "deal",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DealerBuilt.DMSPushDealerbuiltDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    },
                    "409": {
                        "description": "Conflict",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetDmsPushSuccessHistory": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetDmsPushSuccessHistory_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushHistoryRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushHistoryDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetLatestDmsPushSuccess": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetLatestDmsPushSuccess_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushHistoryRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsPushHistoryExtDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetDmsEmployees/{dealerId}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetDmsEmployees_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsEmployeeRequestResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetDmsEmployeesForCleanup/{dealerId}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetDmsEmployeesForCleanup_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.DmsEmployeeRequestResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetNonRCIConfiguredFullDeskingDealers": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetNonRCIConfiguredFullDeskingDealers_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.vw_DmsPushDealerDmsTypeDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Finance/IsFinancePushValid/{dealerId}": {
            "get": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "IsFinancePushValid_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "responseMethod",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "enum": [
                            0,
                            1,
                            2
                        ]
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.IsFinancePushValidDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Finance/GetResultsForTrackingKey/{trackingKey}": {
            "get": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "GetResultsForTrackingKey_ByTrackingkey_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "trackingKey",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int64"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.FinancePushResultLogDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Finance/CreateSevenHundredCreditCreditReport": {
            "post": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "CreateSevenHundredCreditCreditReport_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.SevenHundredCredit.SevenHundredCreditCreditRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.FinancePushResultDto"
                        }
                    }
                }
            }
        },
        "/api/Finance/CreateSevenHundredCreditPrescreenReport": {
            "post": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "CreateSevenHundredCreditPrescreenReport_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.SevenHundredCredit.SevenHundredCreditPrescreenRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.FinancePushResultDto"
                        }
                    }
                }
            }
        },
        "/api/Finance/CreateSevenHundredCreditOFACReport": {
            "post": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "CreateSevenHundredCreditOFACReport_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.SevenHundredCredit.SevenHundredCreditOFACRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.FinancePushResultDto"
                        }
                    }
                }
            }
        },
        "/api/Finance/CreateRouteOneDealJacket": {
            "post": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "CreateRouteOneDealJacket_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealJacket",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.RouteOne.FinancePushDealJacketDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.FinancePushResultDto"
                        }
                    }
                }
            }
        },
        "/api/Finance/UpdateRouteOneDealJacket": {
            "post": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "UpdateRouteOneDealJacket_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealJacket",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.RouteOne.FinancePushDealJacketDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.FinancePushResultDto"
                        }
                    }
                }
            }
        },
        "/api/Finance/GetRouteOneDealJacket": {
            "post": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "GetRouteOneDealJacket_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealJacket",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.RouteOne.FinancePushDealJacketDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.RouteOne.FinancePushDealJacketDto"
                        }
                    }
                }
            }
        },
        "/api/Finance/CreateRouteOneCreditApp": {
            "post": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "CreateRouteOneCreditApp_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealJacket",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.RouteOne.FinancePushDealJacketDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.DmsPush.RouteOne.FinancePushRouteOneCreditAppResponse"
                        }
                    }
                }
            }
        },
        "/api/Finance/DealerTrackFinancePushLead": {
            "post": {
                "tags": [
                    "FinanceClient"
                ],
                "operationId": "DealerTrackFinancePushLead_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dtLead",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.DealerTrackFinance.DealerTrackFinanceDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.FinancePush.FinancePushResultDto"
                        }
                    }
                }
            }
        },
        "/api/Healthz": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/healthz": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/elbtest.html": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Healthz/Diagnostics": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "Diagnostics_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/MultipleApiCalls": {
            "post": {
                "tags": [
                    "MultipleApiCallsClient"
                ],
                "operationId": "DoWork_POST",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "apiRequests",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {},
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/AutoAlert.AlertMiner.DmsPush.Api.Controllers.ApiResponse"
                            }
                        }
                    }
                }
            }
        },
        "/api/vAuto/CreateAppraisal": {
            "post": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "CreateAppraisal_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.vAuto.vAutoCreateAppraisalRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.vAuto.vAutoCreateAppraisalResponseDto"
                        }
                    }
                }
            }
        },
        "/api/vAuto/CreateAppraisalV2": {
            "post": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "CreateAppraisalV2_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.vAuto.vAutoCreateAppraisalV2RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.vAuto.vAutoCreateAppraisalResponseDto"
                        }
                    }
                }
            }
        },
        "/api/vAuto/GetAppraisalById": {
            "post": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "GetAppraisalById_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.vAuto.vAutoGetAppraisalByIdRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.vAuto.vAutoGetAppraisalByIdResponseDto"
                        }
                    }
                }
            }
        },
        "/api/vAuto/GetLatestAppraisalByVin/{dealerId}/{vin}": {
            "get": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "GetLatestAppraisalByVin_ByDealerid_ByVin_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.vAuto.vAutoGetAppraisalByVinResponseDto"
                        }
                    }
                }
            }
        },
        "/api/vAuto/IsEnabled/{dealerId}": {
            "get": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "IsEnabled_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/vAuto/GetvAutoAccount/{dealerId}": {
            "get": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "GetvAutoAccount_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/vAuto/GetAppraisalHistoryBy/{dealerId}/{entityId}": {
            "get": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "GetAppraisalHistoryBy_ByDealerid_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/vAuto/UpdatevAutoAccount/{dealerId}": {
            "post": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "UpdatevAutoAccount_ByDealerid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vAutoEntityId",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VinCue/CreateAppraisal": {
            "post": {
                "tags": [
                    "VinCueClient"
                ],
                "operationId": "CreateAppraisal_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.VinCue.VinCueCreateAppraisalRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.VinCue.VinCueCreateAppraisalResponseDto"
                        }
                    }
                }
            }
        },
        "/api/VinCue/IsEnabled/{dealerId}": {
            "get": {
                "tags": [
                    "VinCueClient"
                ],
                "operationId": "IsEnabled_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/VinCue/GetLatestAppraisalByVin/{dealerId}/{vin}": {
            "get": {
                "tags": [
                    "VinCueClient"
                ],
                "operationId": "GetLatestAppraisalByVin_ByDealerid_ByVin_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AutoAlert.AlertMiner.ServiceContract.VinCue.VinCueGetAppraisalByVinResponseDto"
                        }
                    }
                }
            }
        }
    },
    "definitions": {},
    "securityDefinitions": {
        "Bearer": {
            "name": "Authorization",
            "in": "header",
            "type": "apiKey",
            "description": "access_token from SSO"
        }
    },
    "security": [
        {
            "Bearer": []
        }
    ]
}