"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var modal_1 = require("ngx-bootstrap/modal");
var toaster_logger_service_1 = require("../../../../core/toaster-logger.service");
var vincue_modal_component_1 = require("../vincue-modal/vincue-modal.component");
var ngx_modal_wrapper_service_1 = require("../../../../services/ngx-modal-wrapper.service");
var VinCueComponent = /** @class */ (function () {
    function VinCueComponent(modalService, tService, logger, vehicleService, dealSheetService) {
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
        this.vehicleService = vehicleService;
        this.dealSheetService = dealSheetService;
    }
    VinCueComponent.prototype.ngOnInit = function () {
    };
    VinCueComponent.prototype.ngOnChanges = function (changes) {
    };
    VinCueComponent.prototype.vincueModal = function () {
        var _this = this;
        var initialState = {
            dealerId: this.dealerId,
            customer: this.customer,
            vehicle: this.vehicle,
            assignedUserFullName: this.assignedUserFullName
        };
        this.bsModalRef = this.modalService.show(vincue_modal_component_1.VinCueModalComponent, { initialState: initialState, class: 'vincue-modal' });
        this.bsModalRef.content.onSaveChanges.subscribe(function (result) {
            var vehicle = {
                vin: _this.vehicle.vin,
                make: _this.vehicle.makeName,
                model: _this.vehicle.modelName,
                year: _this.vehicle.yearID,
                odometer: _this.vehicle.estimatedCurrentMileage,
                interiorColor: _this.vehicle.interiorColor > "" ? _this.vehicle.interiorColor : "",
                exteriorColor: _this.vehicle.exteriorColor > "" ? _this.vehicle.exteriorColor : ""
            };
            var customer = {
                firstName: _this.customer.firstName,
                lastName: _this.customer.lastName,
                email: _this.customer.emailAddress ? _this.customer.emailAddress.value : "",
                homePhone: _this.customer.homePhoneNumber ? _this.customer.homePhoneNumber.value : "",
                workPhone: _this.customer.workPhoneNumber ? _this.customer.workPhoneNumber.value : "",
                mobilePhone: _this.customer.cellPhoneNumber ? _this.customer.cellPhoneNumber.value : "",
                address: _this.customer.address ? _this.customer.address.streetAddress : "",
                postalCode: _this.customer.address ? _this.customer.address.zipCode : ""
            };
            var appraiser = {
                userName: "",
                firstName: "",
                lastName: "",
                email: ""
            };
            var salesPerson = {
                userName: "",
                firstName: _this.assignedUserFullName,
                lastName: "",
                email: ""
            };
            var request = {
                dealerId: _this.dealerId,
                vehicle: vehicle,
                customer: customer,
                appraiser: appraiser,
                salesPerson: salesPerson
            };
            _this.dealSheetService.vinCueCreateAppraisal(request)
                .then(function (data) {
                if (data && data.appraisalID) {
                    _this.logger.success("VinCue appraisal successfully created with appraisal id: " + data.appraisalID);
                    _this.showAppraisalPopup(data);
                }
                else {
                    _this.logger.error(_this.tService.instant("generalErrorMessageKey"));
                }
            });
        });
    };
    VinCueComponent.prototype.showAppraisalPopup = function (data) {
        console.log('Appraisial ID: ' + data.appraisalID + ' VinCue url: ' + data.deepLink);
        window.open(data.deepLink, '_blank');
    };
    return VinCueComponent;
}());
exports.VinCueComponent = VinCueComponent;
