import { Inject, Injectable } from "@angular/core";
import { $rootScope } from "../ajs-upgraded-providers";
import { Settings } from "../constants";
import { AssetTypes } from "../constants/asset-types";
import { DealerAlertToleranceExtDto, DealerAlertToleranceUpdateExtDto, DealerAssetDto, DealerDto, DealerFordInfoDto, DealerInfoDto, DealerSettingDto, PrintOfferDealerStatusDto, UserDto, DealerWishListExtDto, DealerWishListLogExtDto } from "../generated/models";
import { DealerAssetClientService, DealerClientService, DealerSearchClientService, DealerSettingClientService, PrintOfferBatchClientService, UserClientService } from "../generated/services";
import { DealSheetDealer } from "../interfaces/deal-sheet/deal-sheet-dealer.interface";
import { DealerInfoDtoExt } from "../interfaces/dealer-info-dto-ext.interface";
import { UserDtoExt } from "../interfaces/user-dto-ext.interface";
import { CookieStorage } from "../modules/storage/cookie-storage.service";
import { CrossDomainStorageService } from "../modules/storage/cross-domain-storage.service";
import { ObjectCache } from "../utilities/object-cache";
import { PandoCommunicationService } from '../services/pando-communication.service';

@Injectable({
    providedIn: 'root'
})
export class DealerService {
    constructor(private dealerSettingClient: DealerSettingClientService, 
        private dealerClient: DealerClientService, 
        private dealerSearchClient: DealerSearchClientService, 
        private userClient: UserClientService, 
        private printOfferBatchClient: PrintOfferBatchClientService,
        private dealerAssetClient: DealerAssetClientService, 
        private communicationService: PandoCommunicationService,
        @Inject($rootScope) private rootScope: any, 
        @Inject(CrossDomainStorageService) private crossDomainStorageService: CookieStorage) {}

    public dealersOneToOneStatus: PrintOfferDealerStatusDto[] = [];
    public dealersOneToOneStatusLoaded = false;

    // not sure why this was public before but keeping it that way just in case
    public getIsAssetEnabledForDealerPromise = null;

    private enabledUsersForDealerCache = new ObjectCache<UserDto[]>({cacheAgeLimitSeconds: Settings.environmentVariables.defaultInMemoryCacheTimeoutSeconds});
    private dealersCache = new ObjectCache<DealerInfoDtoExt[]>({cacheAgeLimitSeconds: Settings.environmentVariables.defaultInMemoryCacheTimeoutSeconds});
    private dealerCache = new ObjectCache<DealSheetDealer>({cacheAgeLimitSeconds: Settings.environmentVariables.defaultInMemoryCacheTimeoutSeconds});
        
    public getDealerSettings(dealerId: number) {
        return this.dealerSettingClient.ByDealeridGET(dealerId).toPromise()
            .then(mapDealerSettings)

        function mapDealerSettings(response: DealerSettingDto) {
            var dealerSettings = {
                allowUsedCalculation: response.allowUsedCalculation,
                allowCPOCalculation: response.allowCPOCalculation,
                enableCarFax: response.enableCarFax,
                enableCarProof: response.enableCarProof,
                enableAutoCheck: response.enableAutoCheck,
                useCreditSoftPull: response.useCreditSoftPull,
                maxScheduleFutureDays: response.activityFutureWindow,
                dncEnabled: response.dncEnabled,
                allowExpiration: response.allowExpiration,
                autoRewardAccountIdentifier: response.autoRewardAccountIdentifier,
                autoRewardPassword: response.autoRewardPassword,
                autoRewardUserName: response.autoRewardUserName,
                enableAutoRewards: response.enableAutoRewards,
                productID: response.productID,
                defaultUserID: response.defaultUserID,
                enableDirectAlertCampaigns: response.enableDirectAlertCampaigns,
                enableDealerUsedRates: response.enableDealerUsedRates,
                enableMultiVehReplTolerances: response.enableMultiVehReplTolerances,
                enableOffBrandMultiVehReplTolerances: response.enableOffBrandMultiVehReplTolerances,
                deskitDealerID: response.deskitDealerID,
                deskitPushEnabled: response.deskitPushEnabled,
                carFaxVersion: response.carFaxVersion,
                carFaxAutoPurchase: response.carFaxAutoPurchase,
                offBrandMakeIds: response.offBrandMakeIds
            };
            return dealerSettings;
        }
    }

    public getDealer(id: number): Promise<DealSheetDealer> {
        return this.dealerCache.getOrFetch(id, () => this.dealerClient.ByIdGET(id).toPromise()
            .then(function resolveDealer(response) {
                var data = response;

                var dealer: DealSheetDealer = {
                    name: data.dealerName,
                    displayText: data.dealerName + ' (' + data.dealerCode + ')',
                    dealerGroupId: data.dealerGroupID
                };

                return dealer;
            }));
    }

    public getDealers(): Promise<DealerInfoDtoExt[]> {
        return this.dealersCache.getOrFetch('dealers', () => this.dealerSearchClient.GET().toPromise()
            .then(function getDealersComplete(response: DealerInfoDto[]) {
                var dealers = response;

                var dealersExt: DealerInfoDtoExt[] = [];

                dealers.forEach(function (dealer) {
                    const displayText = dealer.dealerName + ' (' + dealer.dealerCode + ')';
                    const dealerInfoDtoExt = {...dealer, displayText: displayText};
                    dealersExt.push(dealerInfoDtoExt);
                });

                return dealersExt;
            }));
    }

    public getUsers(dealerId: number): Promise<UserDtoExt[]> {
        return this.getEnabledUsersForDealer(dealerId)
            .then(function resolveUsers(response) {
                var users = [];
                
                for (var i = 0; i < response.length; i++) {
                    var user = response[i];

                    users.push({
                        id: user.userID,
                        fullName: user.fullName,
                        userTypeId: user.userTypeID
                    });
                }

                return users;
            });
    }

    public getEnabledUsersForDealer(dealerId: number): Promise<UserDto[]> {
        return this.enabledUsersForDealerCache.getOrFetch(
            dealerId, 
            () => this.userClient.GetEnabledUsersForDealerByDealeridGET(dealerId).toPromise()
        );
    }

    public getSelectedDealerGroupId() {
        const data = this.getRawSelectedDealers();
        if (data) {
            return data.dealerGroupId;
        }
    }

    public getSelectedDealerIds(): number[] {
        const dealers = this.getSelectedDealers();
        if (dealers) {
            return dealers.map(d => d.id);
        }
    }

    public getDealerDisplayText() {
        const data = this.getRawSelectedDealers();
        if (data) {
            return data.dealerDisplayText;
        }
    }

    public getSelectedDealers(addAllDealersOption?: DealerDto): Array<any> {
        const data = this.getRawSelectedDealers();
        if (addAllDealersOption && data.dealers.length > 1) {
            data.dealers.unshift({ id: 0, name: "All Dealerships" });
        }
        if (data) {
            return data.dealers;
        }
    }

    public getCXMViewSelectedStores(): Promise<any> {
        return this.communicationService.getCXMViewSelectedStores();
    }

    private getRawSelectedDealers() {
        return this.crossDomainStorageService.getItem('selectedDealers');
    }

    public updateSelectedDealers(dealers, dealerGroupId, dealerDisplayText) {
        const data = { dealers, dealerGroupId, dealerDisplayText };
        this.crossDomainStorageService.setItem('selectedDealers', data);
        this.communicationService.notifyOnDealerChange(dealers);
    }

    public updateSelectedDealersToMatchCXM(dealers, dealerGroupId, dealerDisplayText) {
        const data = { dealers, dealerGroupId, dealerDisplayText };
        this.crossDomainStorageService.setItem('selectedDealers', data);
    }

    public updateCXMSelectedDealersToMatchAlertMiner(dealers) {
        this.communicationService.notifyOnDealerChange(dealers);
    }

    public onSelectedDealersChanged(callback) {
        return this.crossDomainStorageService.onchange('selectedDealers', callback);
    }

    public getDealersOneToOneStatus(): Promise<any> {
        return this.printOfferBatchClient.GetDealerStatusesGET().toPromise()
            .then((response) => {
                this.dealersOneToOneStatus = response || [];
                this.dealersOneToOneStatusLoaded = true;
                this.rootScope.$broadcast('RUN_USER_INIT.ONE_TO_ONE_READY');
            });
    }

    public getIsDealerNoLongerOwnsEnabled(dealerId: number): Promise<boolean> {
        return this.dealerAssetClient.GetByDealerByDealeridGET(dealerId).toPromise()
            .then(IsNoLongerOwnsActive);

        function IsNoLongerOwnsActive(response: DealerAssetDto[]) {
            var data = response;
            var noLongerOwns = data.find(function findNoLongerOwnsAsset(a) {
                return a.assetID === AssetTypes.noLongerOwns;
            });

            return typeof noLongerOwns != 'undefined' && noLongerOwns.isActive === true;
        }
    }

    public getIsDealerEmailAppendEnabled(dealerId: number): Promise<boolean> {
        return this.dealerAssetClient.GetByDealerByDealeridGET(dealerId).toPromise()
            .then(IsEmailAppendActive);

        function IsEmailAppendActive(response: DealerAssetDto[]) {
            var data = response;
            var emailAppend = data.find(function findEmailAppendAsset(a) {
                return a.assetID === AssetTypes.emailAppend;
            });

            return typeof emailAppend != 'undefined' && emailAppend.isActive === true;
        }
    }

    public getIsAssetEnabledForDealer(assetId, dealerId?: number): Promise<boolean> {
        //If this data changes, the user will need to refresh the screen.
        if (this.getIsAssetEnabledForDealerPromise == null) {
            this.getIsAssetEnabledForDealerPromise = this.dealerAssetClient.GetDealerAssetsGET().toPromise();
        }

        return this.getIsAssetEnabledForDealerPromise.then(processResponse);

        function processResponse(response: DealerAssetDto[]) {
            var data = response;
            var dealerAsset = data.find(function findDealerAsset(a) {
                var isSelectedDealer = dealerId == null || dealerId == 0 || a.dealerID == dealerId; 
                return isSelectedDealer && a.assetID === assetId && a.isActive === true;
            });

            return typeof dealerAsset != 'undefined';
        }
    }

    public getIsDealerPandoXEnabled(dealerId: number): Promise<boolean> {
        return this.dealerAssetClient.GetByDealerByDealeridGET(dealerId).toPromise()
            .then(IsPandoXActive);

        function IsPandoXActive(response: DealerAssetDto[]) {
            var data = response;
            var pandoX = data.find(function findPandoXAsset(a) {
                return a.assetID === AssetTypes.pandoX;
            });

            var result = typeof pandoX != 'undefined' && pandoX.isActive === true;

            return result;
        }
    }

    public getIsDealerOneToOneEnabled(dealerId: number): boolean {
        var dealer = this.dealersOneToOneStatus.find(function findDealerStatus(o) {
            return o.dealerId === dealerId;
        });

        if (!dealer)
            return false;

        return dealer.printOfferEnabled;
    }

    public getIsSomeDealerOneToOneEnabled(): boolean {
        var isSomeDealerOneToOneEnabled = false;

        this.dealersOneToOneStatus.forEach(function forEachDealerOneToOneStatus(o) {
            if (o.printOfferEnabled) {
                isSomeDealerOneToOneEnabled = true;
                return false;
            }
        });

        return isSomeDealerOneToOneEnabled;
    }

    public async getIsSomeDealerIntelligentMarketingEnabled(): Promise<boolean> {
     
      var promise = this.getIsAssetEnabledForDealer(AssetTypes.intelMarketing);
      var result = await promise;
      return result;
    }

    public async getIsSomeDealerAutoAssistantEnabled(): Promise<boolean> {
     
      var promise = this.getIsAssetEnabledForDealer(AssetTypes.autoAssistant);
      var result = await promise;
      return result;
    }

    public async getIsSomeDealerGeoAlertEnabled(): Promise<boolean> {
     
      var promise = this.getIsAssetEnabledForDealer(AssetTypes.geoAlert);
      var result = await promise;
      return result;
    }

    public getOneToOneEnabledDealerIds(): number[] {
        var dealerIds = [];

        this.dealersOneToOneStatus.forEach(function forEachDealerOneToOneStatus(o) {
            if (o.printOfferEnabled)
                dealerIds.push(o.dealerId);
        });

        return dealerIds;
    }

    public async getDealerAlertTolerances(dealerId: number, makeId: number, year: number, modelId: number, trimId: number): Promise<Array<DealerAlertToleranceExtDto>> {

        let params: DealerClientService.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETParams = {
            dealerId: dealerId,
            makeId: makeId,
            year: year,
            modelId: modelId,
            trimId: trimId
        };
        return this.dealerClient.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGET(params).toPromise();
    }

    public async UpdateDealerAlertTolerances(dealerId: number, alertTolerances: Array<DealerAlertToleranceUpdateExtDto>) {
        let params: DealerClientService.UpdateDealerAlertTolerancesByDealeridPOSTParams = {
            dealerId: dealerId,
            alertTolerances: alertTolerances
        };
        
        return this.dealerClient.UpdateDealerAlertTolerancesByDealeridPOST(params).toPromise();
    }

    public async getDealerFordInfo(dealerId: number): Promise<DealerFordInfoDto[]> {
        return this.dealerClient.GetDealerFordInfoGET(dealerId).toPromise();
    }

    public async getDealerWishLists(dealerId: number): Promise<DealerWishListExtDto[]> {
        return this.dealerClient.GetDealerWishListsByDealeridGET(dealerId).toPromise();
    }

    public async saveDealerWishList(dealerWishList: DealerWishListExtDto): Promise<DealerWishListExtDto> {
        return this.dealerClient.SaveDealerWishListPOST(dealerWishList).toPromise();
    }

    public async GetDealerWishListLogs(dealerId: number, dealerWishListID: number, take: number): Promise<DealerWishListLogExtDto[]> {
        let params: DealerClientService.GetDealerWishListLogsByDealeridByDealerwishlistidGETParams = {
            dealerWishListID: dealerWishListID,
            dealerId: dealerId,
            take: take
        };

        return this.dealerClient.GetDealerWishListLogsByDealeridByDealerwishlistidGET(params).toPromise();
    }


    

}
