import angular from 'angular';
import vehicleImageNotFoundPath from '../images/vehicle-image-not-available.png';

angular.module('AaApp.Opp').factory('vehicleService', vehicleService);

/* @ngInject */
function vehicleService($q, storageService, coreApi, opportunityTypes, vehicleTypes, saleTypes, legacy, mapUtils, contractService) {
    var service = {
        getVehicleImagePath: getVehicleImagePath,
        getCurrentVehicle: getCurrentVehicle,
        getReplacementVehicles: getReplacementVehicles,
        getOnDemandOffBrandVehicles: getOnDemandOffBrandVehicles,
        getAverageReplacementVehicle: getAverageReplacementVehicle,
        getAverageReplacementVehicleByTrim: getAverageReplacementVehicleByTrim,
        getInventoryReplacementVehicle: getInventoryReplacementVehicle,
        getInventoryReplacementVehicles: getInventoryReplacementVehicles,
        getInventoryVehicles: getInventoryVehicles,
        getInventoryVehicle: getInventoryVehicle,
        getAdjustedInventoryVehiclePrice: getAdjustedInventoryVehiclePrice,
        getMakes: getMakes,
        getMakesMany: getMakesMany,
        getModels: getModels,
        getModelId: getModelId,
        getModelsByMakeId: getModelsByMakeId,
        getYearIDs: getYearIDs,
        getTrims: getTrims,
        getTrimPrice: getTrimPrice,
        updateMileAge: updateMileAge,
        getFuelEconomyComparison: getFuelEconomyComparison,
        getFeatureComparison: getFeatureComparison,
        getAutoCheckReport: getAutoCheckReport,
        getCarFaxReport: getCarFaxReport,
        getCarFaxUrlFromAuthCode: getCarFaxUrlFromAuthCode,
        getCarFaxReportUrl: getCarFaxReportUrl,
        getCarProofReport: getCarProofReport,
        getVinRecall: getVinRecall,
        getVinRecallNos: getVinRecallNos,
        getVinRecallTypes: getVinRecallTypes,
        getModelsByMakesYears: getModelsByMakesYears,
        getTrimsByModels: getTrimsByModels,
        getTrimPlanPrice: getTrimPlanPrice,
        getInventoryBestMatchToTrim: getInventoryBestMatchToTrim,
        carFaxLogOut: carFaxLogOut,
        dealerMakeHasVciConfig: dealerMakeHasVciConfig,
        getVciMaturityData: getVciMaturityData,
        addVciEulaAccepted: addVciEulaAccepted
    };

    return service;

    function getVehicleImagePath(dealerId, trimId) {
        if (!dealerId || !trimId) {
            return $q.resolve(vehicleImageNotFoundPath);
        }
        else {
            return coreApi.api.VehicleImage.GetImage['{dealerId}']['{trimId}'].GET({ dealerId: dealerId, trimId: trimId }).then(function (response) {
                var imagePath = response.data ? response.data : vehicleImageNotFoundPath;

                return imagePath;
            }, function () {
                return $q.resolve(vehicleImageNotFoundPath);
            });
        }
    }

    function getFuelEconomyComparison(newTrimId, entityId) {
        return coreApi.api.Trim.GetFuelEconomyComparison['{newTrimId}']['{entityId}'].GET({ newTrimId: newTrimId, entityId: entityId })
            .then(resolveData);
    }

    function getFeatureComparison(newTrimId, entityId) {
        return coreApi.api.Trim.GetFeatureComparison['{newTrimId}']['{entityId}'].GET({ newTrimId: newTrimId, entityId: entityId })
            .then(resolveData);
    }

    function getCurrentVehicle(vehicleId) {
        return coreApi.api.Vehicle.GetDetails['{vehicleId}'].GET({ vehicleId: vehicleId })
            .then(resolveData);
    }

    function getReplacementVehicles(opportunityId, stockNo, pricingPlanId) {

        if (!stockNo) {
            stockNo = 'auto';
        }

        if (!pricingPlanId) pricingPlanId = null;

        return coreApi.api.VehicleReplacement.GetVehicleReplacements['{entityId}']['{stockNo}'].GET({ entityId: opportunityId, stockNo: stockNo, pricingPlanId: pricingPlanId })
            .then(resolveData);
    }

    function getOnDemandOffBrandVehicles(entityId, makeIds) {
        return coreApi.api.VehicleReplacement.OnDemandOffBrandVehicleReplacements.POST({ entityID: entityId, makeIds: makeIds }, {})
            .then(resolveData);
    }

    function getAverageReplacementVehicleByTrim(dealerId, vehicleId, pricingPlanId, trimId) {

        return coreApi.api.VehicleReplacement.GetExtByVehicleAndDealer['{vehicleId}']['{dealerId}'].GET({ vehicleId: vehicleId, dealerId: dealerId, pricingPlanId: pricingPlanId, trimId:trimId })
            .then(resolveData);
    }


    function getAverageReplacementVehicle(dealerId, vehicleId, pricingPlanId) {

        return coreApi.api.VehicleReplacement.GetExtByVehicleAndDealer['{vehicleId}']['{dealerId}'].GET({ vehicleId: vehicleId, dealerId: dealerId, pricingPlanId: pricingPlanId })
            .then(resolveData);
    }

    function getInventoryReplacementVehicle(dealerId, modelID, trimID, pricingPlanId) {

        return coreApi.api.Inventory.InventoryBestMatch.POST({ dealerId: dealerId, modelId: modelID, trimId: trimID, pricingPlanId: pricingPlanId }, {})
            .then(resolveData);
    }

    function getInventoryBestMatchToTrim(dealerId, trimID, replPrice) {
        return coreApi.api.Inventory.InventoryBestMatchToTrim.POST({ dealerId: dealerId, trimId: trimID, replPrice: replPrice }, {})
            .then(resolveData);
    }

    function getInventoryReplacementVehicles(opportunity) {

        return contractService.getCalculationParameters(opportunity)
            .then(getVehicles)
            .then(mapVehicles);

        function getVehicles(parameters) {
            return $q.all([
                coreApi.api.Inventory.InventoryRecommendation_UsedCar['{entityId}'].GET(
                    {
                        entityId: opportunity.id,
                        saleType: opportunity.currentContract.saleType,
                        currentTerm: opportunity.currentContract.term,
                        currentPayment: opportunity.currentContract.payment,
                        exteriorColor: opportunity.replacementVehicle.color,
                        daysInStock: opportunity.replacementVehicle.daysInStock
                    }),
                coreApi.api.Inventory.InventoryRecommendation_NewCar.POST(
                    {
                        CalculationParamsModel: parameters,
                        DealerId: parameters.dealerID,
                        SaleType: parameters.oldSaleTypeID,
                        CurrentTerm: parameters.oldTermID,
                        CurrentPayment: parameters.oldMonthlyPayment ? parameters.oldMonthlyPayment : 0,
                        TrimId: parameters.oldTrimID,
                        Year: parameters.newTrimYearID,
                    })
            ]);
        }

        function mapVehicles(response) {
            var usedCar = response[0].data;
            var newCar = response[1].data;

            var vehicles = [];
            var vehicleData = usedCar.concat(newCar);
            var resultsCount = vehicleData.length > 10 ? 10 : vehicleData.length;
            for (var i = 0; i < resultsCount; i++) {
                var data = vehicleData[i];
                vehicles.push(data);
            }

            return vehicles;
        }
    }

    function getInventoryVehicles(opportunity, params) {
        var isNew;

        if (params.getNewVehicles && !params.getUsedVehicles) {
            isNew = true;
        }
        else if (params.getUsedVehicles && !params.getNewVehicles) {
            isNew = false;
        }

        return contractService.getCalculationParameters(opportunity)
            .then(function (calculationParamsModel) {
                return coreApi.api.Inventory.Search.POST({
                    makeIds: params.makeIDs,
                    yearIds: params.yearIDs,
                    modelIds: params.modelIDs,
                    trimIds: params.trimIDs,
                    isNew: isNew,
                    dealerIds: params.dealerIds,
                    saleTypes: params.saleTypes,
                    minimumTermLength: params.minimumTermLength,
                    maximumTermLength: params.maximumTermLength,
                    minimumMonthlyPayment: params.minimumMonthlyPayment,
                    maximumMonthlyPayment: params.maximumMonthlyPayment,
                    pricingPlanId: params.pricingPlanId,
                    calculationParamsModel: calculationParamsModel,
                })
            })
            .then(resolveData);
    }

    function getInventoryVehicle(stockNo, opportunityId, pricingPlanId) {
        if (!pricingPlanId) { pricingPlanId = 0; }
        return coreApi.api.Inventory.GetExtByStockNo['{stockNo}']['{entityId}']['{pricingPlanID}'].GET({ stockNo: stockNo, entityId: opportunityId, pricingPlanID: pricingPlanId })
            .then(resolveData);
    }

    function getAdjustedInventoryVehiclePrice(dealerId, vehicleType, msrp) {
        return coreApi.api.DealerPrice.GetAdjustedInventoryVehiclePrice['{dealerId}']['{vehicleType}']['{msrp}']
            .GET({ dealerId: dealerId, vehicleType: vehicleType, msrp: msrp })
            .then(resolveData);
    }

    function getMakes(dealerIds, countryId) {
        return coreApi.api.Make.GetMakes.GET({ dealerids: dealerIds, countryId: countryId }).then(resolveData);
    }

    function getMakesMany(makeIds) {
        return coreApi.api.Make.GetMany.GET({ ids: makeIds }).then(resolveData);
    }

    function getModels(makeID, year) {
        return coreApi.api.Model.GetModelsByMakeYear['{makeId}']['{yearId}'].GET({ makeId: makeID, yearId: year }).then(resolveData);
    }

    function getModelId(trimID) {
        return coreApi.api.Trim['{id}'].GET({ id: trimID }).then(function resolveModelId(response) {
            return response.data.modelID;
        });
    }

    function getModelsByMakeId(makeID) {
        return coreApi.api.Model.GetModelsByMake['{makeId}'].GET({ makeId: makeID }).then(resolveData);
    }

    function getModelsByMakesYears(makeIDs, years) {
        return coreApi.api.Model.GetModelsByMakesYears.POST({ makeIDs: makeIDs, years: years })
            .then(resolveData);
    }

    function getTrimsByModels(modelIds) {
        return coreApi.api.Trim.GetTrimsByModels.POST(modelIds)
            .then(resolveData);
    }

    function getYearIDs(dealerId, showDefaultYears, yearLimit) {
        showDefaultYears = showDefaultYears ? showDefaultYears : false;

        return coreApi.api.Year.GetByDealerId['{dealerId}']['{showDefaultYears}'].GET({ dealerId: dealerId, showDefaultYears: showDefaultYears, limit: yearLimit })
            .then(resolveData);
    }

    function getTrims(modelIDs) {
        var promises = [];

        for (var i = 0; i < modelIDs.length; i++) {
            promises.push(coreApi.api.Trim.GetTrimsByModelId['{modelId}'].GET({ modelId: modelIDs[i] }));
        }

        return $q.all(promises).then(resolveTrims);

        function resolveTrims(response) {
            var trims = [];

            for (var i = 0; i < response.length; i++) {
                for (var j = 0; j < response[i].data.length; j++) {
                    var trim = response[i].data[j];

                    trims.push(trim);
                }
            }

            return trims;
        }
    }

    /**
     * Deprecated. Please use getTrimPlanPrice instead.
     * @param {*} dealerId 
     * @param {*} trimID 
     */
    function getTrimPrice(dealerId, trimID) {
        return coreApi.api.DealerPrice.GetTrimPrice['{dealerId}']['{trimId}'].GET({ dealerId: dealerId, trimId: trimID })
            .then(resolveData);            
    }

    function getTrimPlanPrice(dealerId, trimID, pricingPlanId = null) {
        return coreApi.api.DealerPrice.GetTrimPlanPrice.GET({ dealerId: dealerId, trimId: trimID, pricingPlanId: pricingPlanId})
            .then(resolveData);
    }

    function updateMileAge(opportunityId, mileage, milieageDate) {
        var milageData = {
            entityID: opportunityId,
            mileage: mileage,
            mileageDate: milieageDate
        }

        return coreApi.api.Entity.UpdateMileage.POST(milageData)
            .then(resolveData);
    }

    function getCarFaxReport(entityId, vin) {
        return coreApi.api.CarFax.GetCarFaxReportHtml['{entityId}']['{vin}'].GET({ entityId: entityId, vin: vin, disableErrorHandler: true})
            .then(resolveData);
    }

    function getCarFaxUrlFromAuthCode(code, state) {
        return coreApi.api.CarFax.GetCarFaxUrlFromAuthCode.GET({ code: code, state: state, disableErrorHandler: true })
            .then(resolveData);
    }

    function getCarFaxReportUrl(dealerId, entityId, vin) {
        return coreApi.api.CarFax.GetCarFaxReportUrl.GET({ dealerId: dealerId, entityId: entityId, vin: vin, disableErrorHandler: true })
            .then(resolveData);
    }

    function carFaxLogOut() {
        return coreApi.api.CarFax.CarFaxLogOut.POST()
            .then(resolveData);
    }

    function getAutoCheckReport(entityId, vin) {
        return coreApi.api.AutoCheck['{entityId}']['{vin}'].GET({ entityId: entityId, vin: vin })
            .then(resolveData);
    }

    function getCarProofReport(entityId, vin) {
        return coreApi.api.CarProof.GetCarProofReportHtml['{entityId}']['{vin}'].GET({ entityId: entityId, vin: vin })
            .then(resolveData);
    }

    function getVinRecall(dealerId, vehicleId) {
        return coreApi.api.VinRecall.GetByDealerVehicle['{dealerId}']['{vehicleId}'].GET({ dealerId, vehicleId })
            .then(resolveData);
    }

    function getVinRecallNos(dealerId) {
        return coreApi.api.VinRecall.GetVinRecallNos.GET({ dealerId: dealerId })
            .then(resolveData);
    }

    function getVinRecallTypes() {
        return coreApi.api.VinRecall.GetVinRecallTypes.GET()
            .then(resolveData);
    }

    function dealerMakeHasVciConfig(dealerId, makeId) {
        return coreApi.api.VciMaturity.DealerMakeHasVciConfig['{dealerId}']['{makeId}'].GET({ dealerId, makeId })
        .then(resolveData);
    }

    function getVciMaturityData(entityId, makeId, vin) {
        return coreApi.api.VciMaturity.GetVciMaturityData['{entityId}']['{makeId}']['{vin}'].GET({ entityId, makeId, vin })
        .then(resolveData);
    }

    function addVciEulaAccepted() {
        return coreApi.api.VciMaturity.AddVciEulaAccepted.POST().then(true);
    }

    function resolveData(response) {
        if (response && response.data) {
            return response.data;
        }
        else {
            return null;
        }
    }

}
