import { Inject, Injectable } from '@angular/core';
import { catchError, flatMap, map, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { PandoGroupInfo } from '../interfaces/pando-group-info.interface';
import { CommunicationApiService } from '../services/communication-api.service';
import { ToasterLoggerService } from '../core/toaster-logger.service';



@Injectable()
export class PandoCommunicationService {
    constructor(public communicationApi: CommunicationApiService, public toaster: ToasterLoggerService) {

    }

    public getPandoInboxConfig(): any {
        return this.communicationApi.getPandoInboxConfig();
    }

    public getMotofuzeCurrentUser(): any {
        return this.communicationApi.getCurrentUser();
    }

    public getGlobalFrameConfig(): any {
        return this.communicationApi.getGlobalFrameConfig();
    }

    public notifyOnDealerChange(dealers): any {
        const ids = dealers.map(function (dealer) { return dealer.id });
        let request = {
            data: { "SelectedAutoAlertDealerIDs": ids }
        };
        return this.communicationApi.post('core/users/setviewselectedstores', request);
    }

    public getCXMViewSelectedStores(): Promise<any> {
        return this.communicationApi.get('users/currentuser', null)
            .then(response => {
                if (response && response.status == 200 &&
                    response.body &&
                    response.body.Data &&
                    response.body.Data.hasOwnProperty('ViewSelectedStores')) {
                        return {
                            viewDealerGroupID: response.body.Data.ViewDealerGroupID,
                            viewAccount: response.body.Data.ViewAccount,
                            viewSelectedStores: response.body.Data.ViewSelectedStores
                        };
                }
                return null;
            }, function (error) {
                return null;
            });
    }

    public getUnreadMessageCount(isAutoAlertUser:boolean): Promise<any> {
        return this.communicationApi.get("users/appbundle", null)
            .then(response => {
                if (response && response.status == 200 && 
                    response.body && 
                    response.body.Data &&
                    response.body.Data.hasOwnProperty('TotalBadgeCount')) {
                    var unreadmsgCount = response.body.Data.TotalBadgeCount;
                    return { msgcount: unreadmsgCount };
                }
                return null;
            }, function (error) {
                return null;
            });
    }

    
    public getMessageVendorInfo(isAutoAlertUser:boolean): any {
        return this.communicationApi.get("message/vendor", null)
            .then(response => {
                if (response && response.status == 200) {
                    return response.body.Data;
                }
                return null;
            }
                , function (error) {
                    return null;
                });
    }

    public getChatStaff(isAutoAlertUser: boolean): any {
        return this.communicationApi.get("chat/staff", null)
            .then(response => {
                if (response && response.status == 200) {
                    return response.body.Data;
                }
                return null;
            }, function (error) {
                return null;
            });
    }

    public getAvailabilityReasons(isAutoAlertUser: boolean): any {
        return this.communicationApi.get("users/availabilityreasons", null)
            .then(response => {
                if (response && response.status == 200) {
                    return response.body.Data;
                }
                return null;
            }, function (error) {
                return null;
            });
    }

    public setAvailable(isAutoAlertUser:boolean): any {
        return this.communicationApi.put("users/clockin", null)
            .then(response => {
                if (response && response.status == 200) {
                    return response.body.Data;
                }
                return null;
            }, function (error) {
                return null;
            });
    }

    public setUnavailable(isAutoAlertUser, message, reasonId) {
        return this.communicationApi.put("users/clockout", {
            data: {
                Notes: message,
                AvailabilityReasonId: reasonId,
            }
        })
            .then(response => {
                if (response && response.status == 200) {
                    return response.body.Data;
                }
                return null;
            }, function (error) {
                return null;
            });
    }

    public setBusy(isAutoAlertUser, minutes, message, reasonId) {
        return this.communicationApi.put("users/busy/" + minutes, {
            data: {
                Notes: message,
                AvailabilityReasonId: reasonId,
            }
        })
            .then(response => {
                if (response && response.status == 200) {
                    return response.body.Data;
                }
                return null;
            }, function (error) {
                return null;
            });
    }
    
}