//
// THIS FILE WAS GENERATED BY 'generate-alertminerapi.ts'
//
// DO NOT EDIT THIS FILE MANUALLY.
//
// TO UPDATE THIS FILE, RUN:
//   yarn run generate:alertminerapi

export default {
    "swagger": "2.0",
    "info": {
        "version": "v1",
        "title": "AutoAlert (Business Layer, User Context)",
        "description": "AutoAlert.AlertMiner.Api",
        "termsOfService": "AutoAlert, Inc."
    },
    "paths": {
        "/api/Activity/RunActivityRule": {
            "post": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "RunActivityRule_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ActivityActionTargetEntityRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Activity/SubmitAction": {
            "post": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "SubmitAction_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "submission",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ActivityActionSubmissionModel"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ActivityActionResponseModel"
                        }
                    }
                }
            }
        },
        "/api/Activity/GetAgendaItems": {
            "get": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "GetAgendaItems_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "take",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 5
                    },
                    {
                        "name": "flushCache",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": true
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ActivityExtendedWithVwSearchDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Activity/GetAgendaItemsWithEntityData": {
            "get": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "GetAgendaItemsWithEntityData_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dateFrom",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "date-time"
                    },
                    {
                        "name": "dateTo",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "date-time"
                    },
                    {
                        "name": "activityTypes",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32",
                            "enum": [
                                1,
                                2,
                                3,
                                4,
                                5,
                                6
                            ]
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    },
                    {
                        "name": "viewAllUsers",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": false
                    },
                    {
                        "name": "take",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 5
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ActivityWithEntityDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Activity/GetActivitiesForEntity": {
            "post": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "GetActivitiesForEntity_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "activityStatuses",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    },
                    {
                        "name": "activityTypes",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Activity/GetNewActivityNotifications/{entityId}": {
            "get": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "GetNewActivityNotifications_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Activity/UpgradeProposal": {
            "post": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "AddUpgradeProposalActivityNote_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UpgradeProposalActivityRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Activity/{id}": {
            "get": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ActivityDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Activity/GetMany": {
            "get": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ActivityDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Activity": {
            "put": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ActivityDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "ActivityClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ActivityDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ActivityDto"
                        }
                    }
                }
            }
        },
        "/api/ActivityHistory/GetExtByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "ActivityHistoryClient"
                ],
                "operationId": "GetExtByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "count",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 0
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ActivityHistory/GetLastActivityByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "ActivityHistoryClient"
                ],
                "operationId": "GetLastActivityByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/LastActivityDetailsExtDto"
                        }
                    }
                }
            }
        },
        "/api/ActivityHistory/GetOneToOneMailHistoryBy/{entityId}": {
            "get": {
                "tags": [
                    "ActivityHistoryClient"
                ],
                "operationId": "GetOneToOneMailHistoryBy_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ActivityHistory/GetSLMEmailOfferHistoryBy/{entityId}": {
            "get": {
                "tags": [
                    "ActivityHistoryClient"
                ],
                "operationId": "GetSLMEmailOfferHistoryBy_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/EntityMailHistoryDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/ActivityHistory/GetExtActivityHistoryBy/{entityId}/{dealerId}": {
            "get": {
                "tags": [
                    "ActivityHistoryClient"
                ],
                "operationId": "GetExtActivityHistoryBy_ByEntityid_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ActivityHistory/GetEmailConversationHistoryBy/{entityId}": {
            "get": {
                "tags": [
                    "ActivityHistoryClient"
                ],
                "operationId": "GetEmailConversationHistoryBy_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ActivityHistory/GetCustomerEngagementHistoryBy/{entityId}": {
            "get": {
                "tags": [
                    "ActivityHistoryClient"
                ],
                "operationId": "GetCustomerEngagementHistoryBy_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ActivityHistory/GetCombinedActivityHistory": {
            "get": {
                "tags": [
                    "ActivityHistoryClient"
                ],
                "operationId": "GetCombinedActivityHistory_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "opportunityId",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "customerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ActivityHistoryCombinedDto"
                        }
                    }
                }
            }
        },
        "/api/Announcement/GetAnnouncementForUser": {
            "get": {
                "tags": [
                    "AnnouncementClient"
                ],
                "operationId": "GetAnnouncementForUser_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AnnouncementDto"
                        }
                    }
                }
            }
        },
        "/api/Announcement/UpdateAnnouncementUserDisplay/{announcementId}": {
            "put": {
                "tags": [
                    "AnnouncementClient"
                ],
                "operationId": "UpdateAnnouncementUserDisplay_ByAnnouncementid_PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "announcementId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "doNotDisplay",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "type": "boolean"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/AssetModule/{id}": {
            "get": {
                "tags": [
                    "AssetModuleClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AssetModuleDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "AssetModuleClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/AssetModule/GetMany": {
            "get": {
                "tags": [
                    "AssetModuleClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/AssetModuleDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/AssetModule": {
            "put": {
                "tags": [
                    "AssetModuleClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AssetModuleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "AssetModuleClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AssetModuleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/AssetModuleDto"
                        }
                    }
                }
            }
        },
        "/api/Authentication/CrmLogin/{entityId}/{vHash}/{accesCode}/{dealerId}": {
            "get": {
                "tags": [
                    "AuthenticationClient"
                ],
                "operationId": "CrmLogin_ByEntityid_ByVhash_ByAccescode_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "vHash",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "accesCode",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32",
                        "default": 0
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Authentication/CrmLogin/{entityId}/{vHash}/{accesCode}": {
            "get": {
                "tags": [
                    "AuthenticationClient"
                ],
                "operationId": "CrmLogin_ByEntityid_ByVhash_ByAccescode_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "vHash",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "accesCode",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 0
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Authentication/CrmLoginPage/{accesCode}/{dealerId}": {
            "get": {
                "tags": [
                    "AuthenticationClient"
                ],
                "operationId": "CrmLoginPage_ByAccescode_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "accesCode",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32",
                        "default": 0
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Authentication/CrmLoginPage/{accesCode}": {
            "get": {
                "tags": [
                    "AuthenticationClient"
                ],
                "operationId": "CrmLoginPage_ByAccescode_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "accesCode",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 0
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/AutoCheck/{entityId}/{vin}": {
            "get": {
                "tags": [
                    "AutoCheckClient"
                ],
                "operationId": "ByEntityid_ByVin_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/AutoCheck/GetAutoCheckReportHtml": {
            "post": {
                "tags": [
                    "AutoCheckClient"
                ],
                "operationId": "GetAutoCheck_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "autoCheckDto",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoCheckDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/BalanceToMaturity/{entityId}": {
            "get": {
                "tags": [
                    "BalanceToMaturityClient"
                ],
                "operationId": "ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "saleId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Bank/GetBankSelect/{dealerId}": {
            "get": {
                "tags": [
                    "BankClient"
                ],
                "operationId": "GetBankSelect_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/CacheManagement/FlushUserRelatedCache": {
            "get": {
                "tags": [
                    "CacheManagementClient"
                ],
                "operationId": "FlushUserRelatedCache_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/CarFax/GetCarFaxReportHtml/{entityId}/{vin}": {
            "get": {
                "tags": [
                    "CarFaxClient"
                ],
                "operationId": "GetCarFaxReportHtml_ByEntityid_ByVin_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/CarFax/GetCarFaxUrlFromAuthCode": {
            "get": {
                "tags": [
                    "CarFaxClient"
                ],
                "operationId": "GetCarFaxUrlFromAuthCode_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "code",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "state",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/CarFax/GetCarFaxUrlFromAuthCodeInv": {
            "post": {
                "tags": [
                    "CarFaxClient"
                ],
                "operationId": "GetCarFaxUrlFromAuthCodeInv_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "carFaxAuthDto",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CarFaxAuthDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CarFaxResultDto"
                        }
                    }
                }
            }
        },
        "/api/CarFax/GetCarFaxReportUrl": {
            "get": {
                "tags": [
                    "CarFaxClient"
                ],
                "operationId": "GetCarFaxReportUrl_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "entityId",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "vin",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/CarFax/GetCarFaxReportHtml": {
            "post": {
                "tags": [
                    "CarFaxClient"
                ],
                "operationId": "GetCarFaxReportHtml_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "carFaxDto",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CarFaxDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/CarFax/GetCarFaxReportUrlInv": {
            "post": {
                "tags": [
                    "CarFaxClient"
                ],
                "operationId": "GetCarFaxReportUrlInv_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "carFaxDto",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CarFaxDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CarFaxResultDto"
                        }
                    }
                }
            }
        },
        "/api/CarFax/CarFaxLogOut": {
            "post": {
                "tags": [
                    "CarFaxClient"
                ],
                "operationId": "CarFaxLogOut_POST",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/CarFax/CarFaxLogOutInv": {
            "post": {
                "tags": [
                    "CarFaxClient"
                ],
                "operationId": "CarFaxLogOutInv_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "userName",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/CarProof/GetCarProofReportHtml/{entityId}/{vin}": {
            "get": {
                "tags": [
                    "CarProofClient"
                ],
                "operationId": "GetCarProofReportUrl_ByEntityid_ByVin_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/ChangeLog/{entityId}": {
            "get": {
                "tags": [
                    "ChangeLogClient"
                ],
                "operationId": "ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ChangeLog/Term/{vehicleId}/{term}": {
            "post": {
                "tags": [
                    "ChangeLogClient"
                ],
                "operationId": "AddTermChangeLog_ByVehicleid_ByTerm_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "term",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ChangeLog/Price/{vehicleId}/{price}": {
            "post": {
                "tags": [
                    "ChangeLogClient"
                ],
                "operationId": "AddPriceChangeLog_ByVehicleid_ByPrice_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "price",
                        "in": "path",
                        "required": true,
                        "type": "number",
                        "format": "double"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Convert/GetConvertInfo/{entityId}": {
            "get": {
                "tags": [
                    "ConvertClient"
                ],
                "operationId": "GetConvertInfo_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Convert/PostConvertInfo": {
            "post": {
                "tags": [
                    "ConvertClient"
                ],
                "operationId": "PostConvertInfo_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "info",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ConvertDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Convert/GetConvertSaleTypes": {
            "get": {
                "tags": [
                    "ConvertClient"
                ],
                "operationId": "GetConvertSaleTypes_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/CreditSoftPullResultEntityDel/{entityId}": {
            "get": {
                "tags": [
                    "CreditSoftPullResultEntityDelClient"
                ],
                "operationId": "GetCreditSoftPullResultEntityDel_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/CreditSoftPullResultEntityKeep/GetSaleInfoByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "CreditSoftPullResultEntityKeepClient"
                ],
                "operationId": "GetSaleInfoByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Customer/ByEntity/{entityId}": {
            "get": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "ByEntity_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CustomerDto"
                        }
                    }
                }
            }
        },
        "/api/Customer/NameByEntity/{entityId}": {
            "get": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "GetNameOnly_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CustomerDto"
                        }
                    }
                }
            }
        },
        "/api/Customer/Customer_Mark_DoNotEmail": {
            "post": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "Customer_Mark_DoNotEmail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerMarkDoNotEmailDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/Customer/Customer_Get_DoNotEmail/{sessionID}": {
            "get": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "Customer_Get_DoNotEmail_BySessionid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "sessionID",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/Customer_Get_DoNotEmailDto"
                        }
                    }
                }
            }
        },
        "/api/Customer/Customer_Mark_DoNotCall": {
            "post": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "Customer_Mark_DoNotCall_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerMarkDoNotCallDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/Customer/Customer_Get_DoNotCall/{sessionID}": {
            "get": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "Customer_Get_DoNotCall_BySessionid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "sessionID",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/Customer_Get_DoNotCallDto"
                        }
                    }
                }
            }
        },
        "/api/Customer": {
            "put": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CustomerDto"
                        }
                    }
                }
            },
            "patch": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "Update_PATCH",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "customerProfile",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerPatch"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Customer/UpdateCustomer/{updatingUserId}": {
            "post": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "UpdateCustomer_ByUpdatinguserid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "updatingUserId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "customerProfile",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerPatch"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Customer/Insert_DoNotEmailStaging": {
            "post": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "Insert_DoNotEmailStaging_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "emailaddresslist",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DoNotEmailStagingResponseDto"
                        }
                    }
                }
            }
        },
        "/api/Customer/Insert_DoNotCallStaging": {
            "post": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "Insert_DoNotCallStaging_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "phoneNumberList",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DoNotCallStagingResponseDto"
                        }
                    }
                }
            }
        },
        "/api/Customer/UpdatePreferredLanguage": {
            "post": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "UpdatePreferredLanguage_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "customerPreferredLanguage",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerPreferredLanguageDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UpdatePreferredLanguageResultDto"
                        }
                    }
                }
            }
        },
        "/api/Customer/{id}": {
            "get": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CustomerDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Customer/GetMany": {
            "get": {
                "tags": [
                    "CustomerClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/CustomerDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/CustomerActivityHistory/ByEntity/{entityId}": {
            "get": {
                "tags": [
                    "CustomerActivityHistoryClient"
                ],
                "operationId": "GetCustomerActivityHistory_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/CustomerContactHistory/ByEntity/{entityId}": {
            "get": {
                "tags": [
                    "CustomerContactHistoryClient"
                ],
                "operationId": "GetCustomerAdditionalContacts_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/CustomerAdditionalContactDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/CustomerContactHistory/ByCustomer/{customerId}": {
            "get": {
                "tags": [
                    "CustomerContactHistoryClient"
                ],
                "operationId": "GetCustomerAdditionalContacts_ByCustomerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "customerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/CustomerAdditionalContactDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/CustomerContactHistory/{id}": {
            "get": {
                "tags": [
                    "CustomerContactHistoryClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CustomerContactHistoryDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "CustomerContactHistoryClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/CustomerContactHistory/GetMany": {
            "get": {
                "tags": [
                    "CustomerContactHistoryClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/CustomerContactHistoryDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/CustomerContactHistory": {
            "put": {
                "tags": [
                    "CustomerContactHistoryClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerContactHistoryDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "CustomerContactHistoryClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CustomerContactHistoryDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CustomerContactHistoryDto"
                        }
                    }
                }
            }
        },
        "/api/CustomerWorkflow/GetStartListCustomer/{entityId}": {
            "get": {
                "tags": [
                    "CustomerWorkflowClient"
                ],
                "operationId": "GetStartListCustomer_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/StartListCustomerDto"
                        }
                    }
                }
            }
        },
        "/api/CustomerWorkflow/StartCustomerWorkflow/{entityId}/{workflowTemplateId}": {
            "post": {
                "tags": [
                    "CustomerWorkflowClient"
                ],
                "operationId": "StartCustomerWorkflow_ByEntityid_ByWorkflowtemplateid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "workflowTemplateId",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "form",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/PandoFormRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ActiveWorkflowTemplateData"
                        }
                    }
                }
            }
        },
        "/api/CustomerWorkflow/GetStartForm/{startableFormTemplateId}": {
            "get": {
                "tags": [
                    "CustomerWorkflowClient"
                ],
                "operationId": "GetStartForm_ByStartableformtemplateid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "startableFormTemplateId",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/PandoFormDto"
                        }
                    }
                }
            }
        },
        "/api/Dealer/GetDealerVendorFlags/{dealerId}": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "GetDealerVendorFlags_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Dealer/GetDealerFordPilotDto/{dealerId}": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "GetDealerFordPilotDto_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Dealer/GetDealerFordInfo": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "GetDealerFordInfo_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerFordInfoDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Dealer/GetPandoErrorDealers/{lookbackDays}": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "GetPandoErrorDealers_ByLookbackdays_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "lookbackDays",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Dealer/GetDealerAlertTolerances/{dealerId}/{makeId}/{year}/{modelId}/{trimId}": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "GetDealerAlertTolerances_ByDealerid_ByMakeid_ByYear_ByModelid_ByTrimid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "year",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "modelId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerAlertToleranceExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Dealer/UpdateDealerAlertTolerances/{dealerId}": {
            "post": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "UpdateDealerAlertTolerances_ByDealerid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "alertTolerances",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerAlertToleranceUpdateExtDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Dealer/GetDealerWishLists/{dealerId}": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "GetDealerWishLists_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerWishListExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Dealer/FlushDealerWishListsCache/{dealerId}": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "FlushDealerWishListsCache_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Dealer/SaveDealerWishList": {
            "post": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "SaveDealerWishListAsync_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerWishList",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerWishListExtDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerWishListExtDto"
                        }
                    }
                }
            }
        },
        "/api/Dealer/GetDealerWishListLogs/{dealerId}/{dealerWishListID}/{take}": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "GetDealerWishListLogs_ByDealerid_ByDealerwishlistid_ByTake_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "dealerWishListID",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "take",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerWishListLogExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Dealer/{id}": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Dealer/GetMany": {
            "get": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Dealer": {
            "put": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "DealerClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerDto"
                        }
                    }
                }
            }
        },
        "/api/DealerAsset/GetDealerAssets": {
            "get": {
                "tags": [
                    "DealerAssetClient"
                ],
                "operationId": "GetDealerAssets_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerAssetDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerAsset/GetByDealer/{dealerId}": {
            "get": {
                "tags": [
                    "DealerAssetClient"
                ],
                "operationId": "GetByDealer_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerAssetDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerAsset/{id}": {
            "get": {
                "tags": [
                    "DealerAssetClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerAssetDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "DealerAssetClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerAsset/GetMany": {
            "get": {
                "tags": [
                    "DealerAssetClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerAssetDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerAsset": {
            "put": {
                "tags": [
                    "DealerAssetClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerAssetDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "DealerAssetClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerAssetDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerAssetDto"
                        }
                    }
                }
            }
        },
        "/info/{data}": {
            "get": {
                "tags": [
                    "DealerContactInfoExternalClient"
                ],
                "operationId": "GetDealerContactInfo_ByData_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerGroup/{dealerGroupId}": {
            "get": {
                "tags": [
                    "DealerGroupClient"
                ],
                "operationId": "ByDealergroupid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerGroupId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/img/DealerLogoImage/{dealerId}": {
            "get": {
                "tags": [
                    "DealerLogoImageClient"
                ],
                "operationId": "ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/img/DealerLogoImage/{dealerId}/{makeId}": {
            "get": {
                "tags": [
                    "DealerLogoImageClient"
                ],
                "operationId": "ByDealerid_ByMakeid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerModule/DealerModulesChanged": {
            "post": {
                "tags": [
                    "DealerModuleClient"
                ],
                "operationId": "DealerModulesChanged_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerPrice/GetTrimPrice/{dealerId}/{trimId}": {
            "get": {
                "tags": [
                    "DealerPriceClient"
                ],
                "operationId": "GetTrimPrice_ByDealerid_ByTrimid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "double",
                            "type": "number"
                        }
                    }
                }
            }
        },
        "/api/DealerPrice/GetTrimPlanPrice": {
            "get": {
                "tags": [
                    "DealerPriceClient"
                ],
                "operationId": "GetTrimPlanPrice_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "pricingPlanId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerTrimPlanPriceDto"
                        }
                    }
                }
            }
        },
        "/api/DealerPrice/GetAdjustedInventoryVehiclePrice/{dealerId}/{vehicleType}/{msrp}": {
            "get": {
                "tags": [
                    "DealerPriceClient"
                ],
                "operationId": "GetAdjustedInventoryVehiclePrice_ByDealerid_ByVehicletype_ByMsrp_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vehicleType",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32",
                        "enum": [
                            0,
                            1,
                            2
                        ]
                    },
                    {
                        "name": "msrp",
                        "in": "path",
                        "required": true,
                        "type": "number",
                        "format": "double"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "double",
                            "type": "number"
                        }
                    }
                }
            }
        },
        "/api/DealerPrice/GetDealerPrices/{dealerId}/{makeId}/{year}/{modelId}/{trimId}": {
            "get": {
                "tags": [
                    "DealerPriceClient"
                ],
                "operationId": "GetDealerPrices_ByDealerid_ByMakeid_ByYear_ByModelid_ByTrimid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "year",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "modelId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerPriceExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerPrice": {
            "patch": {
                "tags": [
                    "DealerPriceClient"
                ],
                "operationId": "UpdateDealerPrices_PATCH",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "prices",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerPriceDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerPriceDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerRates/GetDealerRateMarkUps/{dealerId}": {
            "get": {
                "tags": [
                    "DealerRatesClient"
                ],
                "operationId": "GetDealerRateMarkUps_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerRateMarkUpExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerRates/UpdateDealerUsedRates": {
            "post": {
                "tags": [
                    "DealerRatesClient"
                ],
                "operationId": "UpdateDealerUsedRates_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerRateUsedDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerRates/DeleteAllUsedRatesByDealerId": {
            "post": {
                "tags": [
                    "DealerRatesClient"
                ],
                "operationId": "DeleteAllUsedRatesByDealerId_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/DealerRates/UpdateDealerOverAllowance": {
            "post": {
                "tags": [
                    "DealerRatesClient"
                ],
                "operationId": "UpdateDealerOverAllowance_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerOverAllowanceUpdateDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerRates/UpdateDealerRateMarkUps": {
            "post": {
                "tags": [
                    "DealerRatesClient"
                ],
                "operationId": "UpdateDealerRateMarkUps_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "rates",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerRateMarkUpExtDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerRates/GetDealerOverAllowances/{dealerId}": {
            "get": {
                "tags": [
                    "DealerRatesClient"
                ],
                "operationId": "GetDealerOverAllowances_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerOverAllowanceExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerRates/GetDealerUsedRates/{dealerId}": {
            "get": {
                "tags": [
                    "DealerRatesClient"
                ],
                "operationId": "GetDealerUsedRates_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerRateUsedDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerRebate/GetDealerRebates/{dealerId}/{makeId}/{year}/{modelId}/{trimId}": {
            "get": {
                "tags": [
                    "DealerRebateClient"
                ],
                "operationId": "GetDealerRebates_ByDealerid_ByMakeid_ByYear_ByModelid_ByTrimid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "year",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "modelId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerRebateExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerRebate": {
            "patch": {
                "tags": [
                    "DealerRebateClient"
                ],
                "operationId": "UpdateDealerRebates_PATCH",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "rebates",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerRebateExtDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerSearch": {
            "get": {
                "tags": [
                    "DealerSearchClient"
                ],
                "operationId": "GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerInfoDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerSetting/{dealerId}": {
            "get": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerSettingDto"
                        }
                    }
                }
            }
        },
        "/api/DealerSetting/GetDealerSmtpSetting/{dealerId}": {
            "get": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "GetDealerSmtpSetting_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerSmtpSettingSelectionDto"
                        }
                    }
                }
            }
        },
        "/api/DealerSetting/DealerSettingChanged": {
            "post": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "DealerSettingChanged_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerSetting/GetDealerOutboundEmailProviderInfo/{dealerId}": {
            "get": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "GetDealerOutboundEmailProviderInfo_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/DealerSetting/GetDealerSettingAll/{dealerId}": {
            "get": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "GetDealerSettingAll_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DealerSettingResponseDto"
                        }
                    }
                }
            }
        },
        "/api/DealerSetting/UpdateDealerSettingAll": {
            "post": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "UpdateDealerSettingAll_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dsa",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerSettingRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/DealerSetting/SendTestAdfEmail": {
            "post": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "SendTestAdfEmail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerSettingSendTestAdfRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerSetting/GetTextNumbers/{dealerId}": {
            "get": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "GetTextNumbers_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "stateCode",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "areaCode",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerSetting/UseLegacyDealerSettings": {
            "get": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "UseLegacyDealerSettingsPage_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/DealerSetting/UpdateInventoryDealerSettingByDealerId": {
            "post": {
                "tags": [
                    "DealerSettingClient"
                ],
                "operationId": "UpdateInventoryDealerSettingByDealerIdAsync_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "inventoryDealerSettingDto",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerInventorySettingDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/DealerSmtpSetting/GetDealerSmtpSettings": {
            "get": {
                "tags": [
                    "DealerSmtpSettingClient"
                ],
                "operationId": "GetDealerSmtpSettings_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerSmtpSetting/SaveDealerSmtpSettings": {
            "post": {
                "tags": [
                    "DealerSmtpSettingClient"
                ],
                "operationId": "SaveDealerSmtpSettings_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerSmtpSettingCustomDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerTradein/GetDealerTradeins/{dealerId}/{makeId}/{yearId}/{modelId}/{trimId}": {
            "get": {
                "tags": [
                    "DealerTradeinClient"
                ],
                "operationId": "GetDealerTradeins_ByDealerid_ByMakeid_ByYearid_ByModelid_ByTrimid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "yearId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "modelId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerTradeinExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerTradein": {
            "patch": {
                "tags": [
                    "DealerTradeinClient"
                ],
                "operationId": "UpdateDealerTradeins_PATCH",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "tradeins",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerTradeinExtDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerTradeinExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealerUpgradeProposalSetting/GetDealerUpgradeProposalSetting/{dealerId}": {
            "get": {
                "tags": [
                    "DealerUpgradeProposalSettingClient"
                ],
                "operationId": "GetDealerUpgradeProposalSetting_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealerUpgradeProposalSetting/SaveDealerUpgradeProposalSetting": {
            "post": {
                "tags": [
                    "DealerUpgradeProposalSettingClient"
                ],
                "operationId": "SaveDealerUpgradeProposalSetting_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DealerUpgradeProposalSettingDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealSheet/History": {
            "get": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "UserDealSheetHistory_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealSheetHistoryDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealSheet/CalculatePayments": {
            "post": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "CalculatePayments_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "model",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CalculationParamModel"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealSheet/GetDealHistory/{entityId}": {
            "post": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "GetDealHistory_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealSheet/CustomerEngagementHistory": {
            "get": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "CustomerEngagementHistory_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dateFrom",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "date-time"
                    },
                    {
                        "name": "dateTo",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "date-time"
                    },
                    {
                        "name": "lastViewedDate",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "date-time"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/usp_GetCustomerEngagementData_ResponseDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealSheet/CustomerEngagementsByEntity": {
            "post": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "CustomerEngagementsByEntity_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_GetCustomerEngagementsByEntityData_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/usp_GetCustomerEngagementsByEntityData_ResponseDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealSheet/HasReviewProDealer": {
            "get": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "HasReviewProDealer_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/DealSheet/CustomerEngagementDashboard": {
            "post": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "CustomerEngagementDashboard_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_GetCustomerEngagementDashboard_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/Usp_GetCustomerEngagementDashboardDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DealSheet/CustomerEngagementLastViewed": {
            "get": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "GetCustomerEngagementLastViewed_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "DealSheetClient"
                ],
                "operationId": "UpdateCustomerEngagementLastViewed_POST",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Deskit/PushToDeskit": {
            "post": {
                "tags": [
                    "DeskitClient"
                ],
                "operationId": "PushToDeskit_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DeskitQuotingPushDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DeskitQuotingPushResponseDto"
                        }
                    }
                }
            }
        },
        "/api/DiscrepancyReport/ReportDiscrepancy": {
            "post": {
                "tags": [
                    "DiscrepancyReportClient"
                ],
                "operationId": "ReportDiscrepancy_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "model",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ReportDiscrepancyModel"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/EmailConversation/RecordEmailConversation": {
            "post": {
                "tags": [
                    "EmailConversationClient"
                ],
                "operationId": "RecordEmailConversation_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "emailConversation",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EmailConversationDetailDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/EmailConversation/RecordEmailRead": {
            "post": {
                "tags": [
                    "EmailConversationClient"
                ],
                "operationId": "RecordEmailRead_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "alertMinerMailOpened",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AlertMinerMailOpenedDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/EmailConversation/GetEmailBody/{id}": {
            "get": {
                "tags": [
                    "EmailConversationClient"
                ],
                "operationId": "GetEmailBody_ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/EmailConversation/{id}": {
            "get": {
                "tags": [
                    "EmailConversationClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EmailConversationDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "EmailConversationClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/EmailConversation/GetMany": {
            "get": {
                "tags": [
                    "EmailConversationClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/EmailConversationDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/EmailConversation": {
            "put": {
                "tags": [
                    "EmailConversationClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EmailConversationDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "EmailConversationClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EmailConversationDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EmailConversationDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/GetExt/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetExt_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EntityExternalDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/ReassignToUserId": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "ReassignToUserId_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EntityReassignRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/CreditPrescreenRequest/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "CreditPrescreenRequest_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/CreditPrescreenStatus/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "CreditPrescreenStatus_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CreditPrescreenStatusDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/MarkAsRead": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "MarkAsRead_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "format": "uuid",
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/CustomerDoesNotOwnVehicle/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "CustomerDoesNotOwnVehicle_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/VehiclePurchasedByDealer/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "VehiclePurchasedByDealer_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/VehiclePaidOff/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "VehiclePaidOff_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/VehicleNotPaidOff/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "VehicleNotPaidOff_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/HasWarranty/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "HasWarranty_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/SoldWarranty/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "SoldWarranty_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/UndoHasWarranty/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "UndoHasWarranty_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/UpdateMileage": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "UpdateMileage_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UpdateMileageRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/MarkAsSold/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "MarkAsSold_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/Watch/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "Watch_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/Unwatch/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "Unwatch_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/Reopen/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "Reopen_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/UnmarkSoldWarranty/{entityId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "UnmarkSoldWarranty_ByEntityid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/GetEntityReportByCustomerId/{customerId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetEntityReportByCustomerId_ByCustomerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "customerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EntityReportDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/GetEntityReportByDealerIdAndVin/{dealerId}/{vin}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetEntityReportByDealerIdAndVin_ByDealerid_ByVin_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EntityReport2Dto"
                        }
                    }
                }
            }
        },
        "/api/Entity/GetEntityStatus/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetEntityStatus_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "enum": [
                                100,
                                200,
                                300,
                                400
                            ],
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/Entity/HasReviewProDealer/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "HasReviewProDealer_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/HasGeoAlertDealer/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "HasGeoAlertDealer_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/GetVehicleTurnPredictor/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetVehicleTurnPredictor_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleTurnPredictorDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/GetActiveNLPNoLongerOwn/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetActiveNLPNoLongerOwn_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/NLPNoLongerOwnDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Entity/UpdateActiveNLPNoLongerOwn/{entityId}/{agreed}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "UpdateActiveNLPNoLongerOwn_ByEntityid_ByAgreed_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "agreed",
                        "in": "path",
                        "required": true,
                        "type": "boolean"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/Entity/GetEntityScoreFeature/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetEntityScoreFeature_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/EntityScoreFeatureDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Entity/ExternalGrantEntityAccess/{entityId}/{grantUserId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "ExternalGrantEntityAccess_ByEntityid_ByGrantuserid_POST",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "grantUserId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/PandoXEntityGrantAccessResultDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/ExternalRevokeEntityAccess/{entityId}/{userId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "ExternalRevokeEntityAccess_ByEntityid_ByUserid_POST",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "userId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/PandoXEntityGrantAccessResultDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/ExternalBulkAssignEntityOwners/{actingUserId}/{ownerUserId}": {
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "ExternalBulkAssignEntityOwners_ByActinguserid_ByOwneruserid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entites",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "uuid",
                                "type": "string"
                            }
                        }
                    },
                    {
                        "name": "actingUserId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "ownerUserId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EntityReassignResultDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/{id}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EntityDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Entity/GetMany": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "string",
                            "format": "uuid"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/EntityDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Entity": {
            "put": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EntityDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EntityDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EntityDto"
                        }
                    }
                }
            }
        },
        "/api/Entity/GetEntityEnterpriseCustomers/{entityId}": {
            "get": {
                "tags": [
                    "EntityClient"
                ],
                "operationId": "GetEntityEnterpriseCustomers_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/EntityReportDto"
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/EntityReportDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/EntityBalanceToMaturity/{entityId}": {
            "get": {
                "tags": [
                    "EntityBalanceToMaturityClient"
                ],
                "operationId": "GetEntityBalanceToMaturity_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/EntityOpportunity/GetOpportunitiesForEntity/{entityId}": {
            "get": {
                "tags": [
                    "EntityOpportunityClient"
                ],
                "operationId": "GetOpportunitiesForEntity_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Eula/{id}": {
            "get": {
                "tags": [
                    "EulaClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EulaDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "EulaClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Eula/GetMany": {
            "get": {
                "tags": [
                    "EulaClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/EulaDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Eula": {
            "put": {
                "tags": [
                    "EulaClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EulaDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "EulaClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EulaDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EulaDto"
                        }
                    }
                }
            }
        },
        "/api/EulaHistory/GetStatus": {
            "get": {
                "tags": [
                    "EulaHistoryClient"
                ],
                "operationId": "GetStatus_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "enum": [
                                0,
                                1,
                                2,
                                3
                            ],
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/EulaHistory/PostResponse/{eulaId}/{eulaResponseTypeId}": {
            "post": {
                "tags": [
                    "EulaHistoryClient"
                ],
                "operationId": "PostResponse_ByEulaid_ByEularesponsetypeid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "eulaId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "eulaResponseTypeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32",
                        "enum": [
                            1,
                            2,
                            3
                        ]
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/EulaHistory/{id}": {
            "get": {
                "tags": [
                    "EulaHistoryClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EulaHistoryDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "EulaHistoryClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/EulaHistory/GetMany": {
            "get": {
                "tags": [
                    "EulaHistoryClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/EulaHistoryDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/EulaHistory": {
            "put": {
                "tags": [
                    "EulaHistoryClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EulaHistoryDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "EulaHistoryClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EulaHistoryDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EulaHistoryDto"
                        }
                    }
                }
            }
        },
        "/api/ExtCustomerActivity/AddEndConversationEventRecord/{entityId}": {
            "get": {
                "tags": [
                    "ExtCustomerActivityClient"
                ],
                "operationId": "AddEndConversationEventRecord_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/ExtCustomerActivity/IsAutoAssistantConversationOpen/{entityId}": {
            "get": {
                "tags": [
                    "ExtCustomerActivityClient"
                ],
                "operationId": "IsAutoAssistantConversationOpen_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/FileUpload/UploadImage": {
            "post": {
                "tags": [
                    "FileUploadClient"
                ],
                "operationId": "UploadImage_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "ContentType",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "ContentDisposition",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "Headers",
                        "in": "query",
                        "required": false,
                        "type": "object"
                    },
                    {
                        "name": "Length",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int64"
                    },
                    {
                        "name": "Name",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "FileName",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/FordCreditApproval/FordCreditApprovalByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "FordCreditApprovalClient"
                ],
                "operationId": "FordCreditApprovalByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/FordPilotStore/GetFordPilotStoreStatus": {
            "get": {
                "tags": [
                    "FordPilotStoreClient"
                ],
                "operationId": "GetFordPilotStoreStatus_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/FordPilotStore/GetFordAlertModuleDealerStatus": {
            "get": {
                "tags": [
                    "FordPilotStoreClient"
                ],
                "operationId": "GetFordAlertModuleDealerStatus_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/FordProgram/{id}": {
            "get": {
                "tags": [
                    "FordProgramClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/FordProgramDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "FordProgramClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/FordProgram/GetMany": {
            "get": {
                "tags": [
                    "FordProgramClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/FordProgramDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/FordProgram": {
            "put": {
                "tags": [
                    "FordProgramClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/FordProgramDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "FordProgramClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/FordProgramDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/FordProgramDto"
                        }
                    }
                }
            }
        },
        "/api/Healthz": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/healthz": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/elbtest.html": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Healthz/Diagnostics": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "Diagnostics_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Inventory/InventoryBestMatchToTrim": {
            "post": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "InventoryBestMatchToTrim_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/InventoryBestMatchToTrimRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/InventoryBestRepTrimMatchDto"
                        }
                    }
                }
            }
        },
        "/api/Inventory/InventoryBestMatch": {
            "post": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "InventoryBestMatch_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/InventoryBestMatchRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/InventoryBestMatchResultDto"
                        }
                    }
                }
            }
        },
        "/api/Inventory/InventoryRecommendation_UsedCar/{entityId}": {
            "get": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "InventoryRecommendation_UsedCar_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "saleType",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "enum": [
                            1,
                            2,
                            3,
                            4,
                            6,
                            7,
                            8
                        ]
                    },
                    {
                        "name": "currentTerm",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "currentPayment",
                        "in": "query",
                        "required": false,
                        "type": "number",
                        "format": "double"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/InventoryExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Inventory/InventoryRecommendation_NewCar": {
            "post": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "InventoryRecommendation_NewCar_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/InventoryRecommendations_NewCarRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/InventoryExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Inventory/GetStockView/{dealerId}": {
            "get": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "GetStockView_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/InventoryStockMinViewDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Inventory/GetStockView": {
            "get": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "GetStockViews_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/InventoryStockMinViewDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Inventory/GetExtByStockNo/{stockNo}/{entityId}/{pricingPlanID}": {
            "get": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "GetExtByStockNo_ByStockno_ByEntityid_ByPricingplanid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "stockNo",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "pricingPlanID",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/InventoryExtDto"
                        }
                    }
                }
            }
        },
        "/api/Inventory/GetInventoryOpportunities/{dealerId}/{stockNumber}/{cashAdjustment}": {
            "get": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "GetInventoryOpportunities_ByDealerid_ByStocknumber_ByCashadjustment_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "stockNumber",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "cashAdjustment",
                        "in": "path",
                        "required": true,
                        "type": "number",
                        "format": "double"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/InventoryOpportunitiesExtractDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Inventory/Search": {
            "post": {
                "tags": [
                    "InventoryClient"
                ],
                "operationId": "Search_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/InventorySearchRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/InventoryExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/InventoryDetail/GetInventoryDetail/{dealerId}": {
            "get": {
                "tags": [
                    "InventoryDetailClient"
                ],
                "operationId": "GetInventoryDetail_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/InventoryDetailDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/InventoryDetail/UpdateInventoryDetail": {
            "post": {
                "tags": [
                    "InventoryDetailClient"
                ],
                "operationId": "UpdateInventoryDetail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UpdateInventoryDetailRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/KendoReportProxy": {
            "post": {
                "tags": [
                    "KendoReportProxyClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "multipart/form-data"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "Base64",
                        "in": "formData",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "ContentType",
                        "in": "formData",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "FileName",
                        "in": "formData",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Lms/GetTraining": {
            "get": {
                "tags": [
                    "LmsClient"
                ],
                "operationId": "GetTraining_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "days",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 10
                    },
                    {
                        "name": "top",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 10
                    },
                    {
                        "name": "certiticateId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": -1
                    },
                    {
                        "name": "includeCompleted",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": false
                    },
                    {
                        "name": "sort",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 1,
                        "enum": [
                            0,
                            1
                        ]
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Lms/Enroll/{id}": {
            "post": {
                "tags": [
                    "LmsClient"
                ],
                "operationId": "Enroll_ById_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Lms/Join/{id}": {
            "get": {
                "tags": [
                    "LmsClient"
                ],
                "operationId": "Join_ById_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Lms/GetTranscript/{userId}": {
            "get": {
                "tags": [
                    "LmsClient"
                ],
                "operationId": "GetTranscript_ByUserid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "userId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Log/Log": {
            "post": {
                "tags": [
                    "LogClient"
                ],
                "operationId": "LogWarning_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "message",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Make/GetMakes": {
            "get": {
                "tags": [
                    "MakeClient"
                ],
                "operationId": "GetMakes_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerIds",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Make/GetSearchableModelNames": {
            "get": {
                "tags": [
                    "MakeClient"
                ],
                "operationId": "GetSearchableModelNames_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "countryId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/SearchableModelNameDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Make/{id}": {
            "get": {
                "tags": [
                    "MakeClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/MakeDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "MakeClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Make/GetMany": {
            "get": {
                "tags": [
                    "MakeClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/MakeDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Make": {
            "put": {
                "tags": [
                    "MakeClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/MakeDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "MakeClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/MakeDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/MakeDto"
                        }
                    }
                }
            }
        },
        "/api/Messaging/GetPubNubConfig": {
            "get": {
                "tags": [
                    "MessagingClient"
                ],
                "operationId": "GetPubNubConfig_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/PubNubConfigDto"
                        }
                    }
                }
            }
        },
        "/api/Model/GetModelsByMakeYear/{makeId}/{yearId}": {
            "get": {
                "tags": [
                    "ModelClient"
                ],
                "operationId": "GetModelsByMakeYear_ByMakeid_ByYearid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "yearId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ModelDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Model/GetModelsByMake/{makeId}": {
            "get": {
                "tags": [
                    "ModelClient"
                ],
                "operationId": "GetModelsByMake_ByMakeid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ModelDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Model/GetModelsByMakesYears": {
            "post": {
                "tags": [
                    "ModelClient"
                ],
                "operationId": "GetModelsByMakesYears_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VehicleMakesYearsRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ModelDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Model/{id}": {
            "get": {
                "tags": [
                    "ModelClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ModelDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "ModelClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Model/GetMany": {
            "get": {
                "tags": [
                    "ModelClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ModelDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Model": {
            "put": {
                "tags": [
                    "ModelClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ModelDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "ModelClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ModelDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ModelDto"
                        }
                    }
                }
            }
        },
        "/api/MultipleApiCalls": {
            "post": {
                "tags": [
                    "MultipleApiCallsClient"
                ],
                "operationId": "DoWork_POST",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "apiRequests",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {},
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ApiResponse"
                            }
                        }
                    }
                }
            }
        },
        "/api/OpportunityCounts/GetOpportunityCounts": {
            "get": {
                "tags": [
                    "OpportunityCountsClient"
                ],
                "operationId": "GetOpportunityCounts_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "flushCache",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": true
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/OpportunityCountsDto"
                        }
                    }
                }
            }
        },
        "/api/PandoClaim/ByEntity/{entityId}": {
            "get": {
                "tags": [
                    "PandoClaimClient"
                ],
                "operationId": "ByEntity_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/PandoPassthrough/GetString": {
            "post": {
                "tags": [
                    "PandoPassthroughClient"
                ],
                "operationId": "GetString_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/GetStringAsyncRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/PandoPassthrough/GetWorkflowTemplates/{dealerId}": {
            "get": {
                "tags": [
                    "PandoPassthroughClient"
                ],
                "operationId": "GetWorkflowTemplates_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/KeyValuePair[String,String]"
                            }
                        }
                    }
                }
            }
        },
        "/api/PandoPassthrough/GetPandoCustomStoreGroups/{dealerId}": {
            "get": {
                "tags": [
                    "PandoPassthroughClient"
                ],
                "operationId": "GetPandoCustomStoreGroups_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/PandoGroupDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/PandoPassthrough/AutoAssistFullView/{dealerId}/{entityId}": {
            "get": {
                "tags": [
                    "PandoPassthroughClient"
                ],
                "operationId": "AutoAssistFullView_ByDealerid_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/GetFullConversicaConversationResponseDto"
                        }
                    }
                }
            }
        },
        "/api/PrintOffer/GetByEntity/{entityId}": {
            "get": {
                "tags": [
                    "PrintOfferClient"
                ],
                "operationId": "GetByEntity_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/PrintOffer/GetByEntity/{entityId}/{excludeEmployees}": {
            "get": {
                "tags": [
                    "PrintOfferClient"
                ],
                "operationId": "GetByEntity_ByEntityid_ByExcludeemployees_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "excludeEmployees",
                        "in": "path",
                        "required": true,
                        "type": "boolean"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/PrintOffer/GetByBatch/{batchId}": {
            "get": {
                "tags": [
                    "PrintOfferClient"
                ],
                "operationId": "GetByBatch_ByBatchid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "batchId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/PrintOfferBatch/OpenBatch": {
            "post": {
                "tags": [
                    "PrintOfferBatchClient"
                ],
                "operationId": "OpenBatch_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityIds",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "uuid",
                                "type": "string"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/PrintOfferBatch/GetDealerStatuses": {
            "get": {
                "tags": [
                    "PrintOfferBatchClient"
                ],
                "operationId": "GetDealerStatuses_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/PrintOfferDealerStatusDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/PrintOfferBatch/{id}": {
            "get": {
                "tags": [
                    "PrintOfferBatchClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/PrintOfferBatchDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "PrintOfferBatchClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/PrintOfferBatch/GetMany": {
            "get": {
                "tags": [
                    "PrintOfferBatchClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "string",
                            "format": "uuid"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/PrintOfferBatchDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/PrintOfferBatch": {
            "put": {
                "tags": [
                    "PrintOfferBatchClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/PrintOfferBatchDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "PrintOfferBatchClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/PrintOfferBatchDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/PrintOfferBatchDto"
                        }
                    }
                }
            }
        },
        "/api/Replacement/GetReplacementSpecifics": {
            "get": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "GetReplacementSpecifics_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "DealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "FilterByNew",
                        "in": "query",
                        "required": false,
                        "type": "boolean"
                    },
                    {
                        "name": "MakeId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "YearId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "ModelId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "TrimId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ReplacementSpecificDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Replacement/GetReplacementUpDown": {
            "get": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "GetReplacementUpDown_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "DealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "MakeId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "YearId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "ModelId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "TrimId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ReplacementUpDownDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Replacement/SaveBulkReplacements/{defaultDealerId}": {
            "post": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "SaveBulkReplacements_ByDefaultdealerid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ReplacementDto"
                            }
                        }
                    },
                    {
                        "name": "defaultDealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ReplacementDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Replacement/BulkDelete": {
            "post": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "BulkDelete_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimIDs",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "int32",
                                "type": "integer"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Replacement/{id}": {
            "get": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ReplacementDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Replacement/GetMany": {
            "get": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ReplacementDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Replacement": {
            "put": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ReplacementDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "ReplacementClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ReplacementDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/ReplacementDto"
                        }
                    }
                }
            }
        },
        "/api/Report_CreditConvert/Report_CreditConvert_Processed": {
            "post": {
                "tags": [
                    "Report_CreditConvertClient"
                ],
                "operationId": "Report_CreditConvert_Processed_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_CreditConvert_ProcessedRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_CreditConvert/Report_CreditConvert_ROI": {
            "post": {
                "tags": [
                    "Report_CreditConvertClient"
                ],
                "operationId": "Report_CreditConvert_ROI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_CreditConvert_ROIRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_CreditConvert/Report_CreditConvert_Detail": {
            "post": {
                "tags": [
                    "Report_CreditConvertClient"
                ],
                "operationId": "Report_CreditConvert_Detail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_CreditConvert_DetailRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_CreditConvert/Report_CreditConvert_Summary": {
            "post": {
                "tags": [
                    "Report_CreditConvertClient"
                ],
                "operationId": "usp_Report_CreditConvert_Summary_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_CreditConvert_SummaryRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_DataCleanse/Report_DataCleanse_Detail": {
            "post": {
                "tags": [
                    "Report_DataCleanseClient"
                ],
                "operationId": "ReportDataCleanse_Detail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_DataCleanse_DetailRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_DataCleanse/Report_DataCleanse_Summary": {
            "post": {
                "tags": [
                    "Report_DataCleanseClient"
                ],
                "operationId": "ReportDataCleanse_Summary_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_DataCleanse_Summary_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_Inventory/Report_Inventory": {
            "post": {
                "tags": [
                    "Report_InventoryClient"
                ],
                "operationId": "ReportInventory_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_InventoryRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_ServiceDrivePerformance/GetServiceDrivePerformanceSummary": {
            "post": {
                "tags": [
                    "Report_ServiceDrivePerformanceClient"
                ],
                "operationId": "GetServiceDrivePerformanceSummary_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_ServiceDrivePerformanceRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_ServiceDrivePerformance/GetServiceDrivePerformanceSummaryEnterprise": {
            "post": {
                "tags": [
                    "Report_ServiceDrivePerformanceClient"
                ],
                "operationId": "GetServiceDrivePerformanceSummaryEnterprise_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_ServiceDrivePerformance_Summary_ByDealerId_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_ServiceDrivePerformance/GetServiceDrivePerformanceDetail": {
            "post": {
                "tags": [
                    "Report_ServiceDrivePerformanceClient"
                ],
                "operationId": "GetServiceDrivePerformanceDetail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_ServiceDrivePerformanceRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_UserActivity/Report_UserActivity": {
            "post": {
                "tags": [
                    "Report_UserActivityClient"
                ],
                "operationId": "ReportUserActivity_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_UserActivity_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_UserActivity/Report_UserActivity_Entity": {
            "post": {
                "tags": [
                    "Report_UserActivityClient"
                ],
                "operationId": "ReportUserActivityEntity_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_UserActivity_Entity_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_UserActivity/Report_UserActivity_Main": {
            "post": {
                "tags": [
                    "Report_UserActivityClient"
                ],
                "operationId": "ReportUserActivityMain_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_Activity_Main_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Report_UserActivity/Report_DealerActivity": {
            "post": {
                "tags": [
                    "Report_UserActivityClient"
                ],
                "operationId": "ReportDealerActivity_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_DealerActivity_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_Dashboard": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_Dashboard_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_ROI_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_DashboardFromBenchmarks": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "GetDashboardReportFromBenchmarks_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_ROI_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_EnterpriseDashboard": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_EnterpriseDashboard_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_ROI_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/GetReportPeriods": {
            "get": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "GetReportPeriods_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_FooterInformation": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_FooterInformation_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerIds",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "int32",
                                "type": "integer"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/GenerateBenchmarks": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "GenerateBenchmarks_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/BenchmarkRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/GenerateBenchmarksBatch": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "GenerateBenchmarksBatch_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/BenchmarkRequestDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_Dashboard_Benchmarks": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_Dashboard_Benchmarks_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/BenchmarkAggregateRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_OpportunitySales": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_OpportunitySales_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_Sold_Master_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_AutoAssistant": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_AutoAssistant_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/OnetoOneSoldRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_AutoAssistantActivity": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_AutoAssistantActivity_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/AutoAssistantActivityKCRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_AutoAssistantEnterprise": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_AutoAssistantEnterprise_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/OnetoOneEnterpriseAPIRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_GeoAlert": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_GeoAlert_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/OnetoOneSoldRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_OnetoOne": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_OnetoOne_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/OnetoOneSoldRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_OnetoOneEnterprise": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_OnetoOneEnterprise_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/OnetoOneEnterpriseAPIRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_GeoAlertEnterprise": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_GeoAlertEnterprise_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/GeoAlertSoldUnitsEnterpriseKCRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_TradeSales": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_TradeSales_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_TradeSold_Master_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_Certification": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_Certification_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_CertificationRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_WebinarAttendance": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_WebinarAttendance_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/usp_Report_Webinar_AttendanceRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_ServiceLaneTechnology": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_ServiceLaneTechnology_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/rpt_ROI_Report_SDO_And_Full_RequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_Performance": {
            "post": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_Performance_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/PerformanceReportRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Reports/Report_Performance_GetAvailableMonths/{dealerId}": {
            "get": {
                "tags": [
                    "ReportsClient"
                ],
                "operationId": "Report_Performance_GetAvailableMonths_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/GetReportSelfSubscriptionsByUser": {
            "get": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "GetReportSelfSubscriptionsByUser_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/GetReportSelfSubscriptionsByUserReport/{reportId}": {
            "get": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "GetReportSelfSubscriptionsByUserReport_ByReportid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "reportId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/DisableReportSelfSubscription/{id}": {
            "post": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "DisableReportSelfSubscription_ById_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/EnableReportSelfSubscription/{id}": {
            "post": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "EnableReportSelfSubscription_ById_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/DeleteReportSelfSubscription/{id}": {
            "post": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "DeleteReportSelfSubscription_ById_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/AddReportSelfSubscription": {
            "post": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "AddReportSelfSubscription_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "subscription",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ReportSubscriptionDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/UpdateReportSelfSubscription": {
            "post": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "UpdateReportSelfSubscription_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "subscription",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ReportSubscriptionDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/GetReportSubscriptionRunLogs/{take}/{failOnly}": {
            "get": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "GetReportSubscriptionRunLogs_ByTake_ByFailonly_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "take",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "failOnly",
                        "in": "path",
                        "required": true,
                        "type": "boolean"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/GetReportDealerPandoGroupSubscriptions/{dealerId}": {
            "get": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "GetReportDealerPandoGroupSubscriptions_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/AddReportDealerPandoGroupSubscription": {
            "post": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "AddReportDealerPandoGroupSubscription_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "subscription",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ReportSubscriptionDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/UpdateReportDealerPandoGroupSubscription": {
            "post": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "UpdateReportDealerPandoGroupSubscription_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "subscription",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ReportSubscriptionDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/GetDealerAutoReportSubscription/{dealerId}": {
            "get": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "GetDealerAutoReportSubscription_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/RemoveDealerAutoReportSubscription/{dealerId}": {
            "get": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "RemoveDealerAutoReportSubscription_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/ReportSubscription/UpdateDealerAutoReportSubscription": {
            "post": {
                "tags": [
                    "ReportSubscriptionClient"
                ],
                "operationId": "UpdateDealerAutoReportSubscription_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "darsList",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DealerAutoReportSubscriptionDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Role/GetRoleOptions": {
            "get": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "GetRoleOptions_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "uniqueItems": false,
                                "type": "array",
                                "items": {
                                    "$ref": "#/definitions/RoleOptionDto"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/Role/GetRoleAccessRights/{roleId}": {
            "get": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "GetRoleAccessRights_ByRoleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "roleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/RoleAccessOptionDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Role/GetRoleModuleAccess/{roleId}": {
            "get": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "GetRoleModuleAccess_ByRoleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "roleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/RoleAccessOptionDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Role/GetRoleModuleCategories/{roleId}": {
            "get": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "GetRoleModuleCategories_ByRoleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "roleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/RoleAccessOptionDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Role/GetRoleAccessRightCategories": {
            "get": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "GetRoleAccessRightCategories_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/RoleAccessOptionDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Role/RoleAccessUpdate/{roleId}": {
            "get": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "RoleAccessUpdate_ByRoleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "roleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "moduleIdList",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    },
                    {
                        "name": "accessRightIdList",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/Role/AddEditRole": {
            "post": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "AddEditRole_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "role",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/RoleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/Role/{id}": {
            "get": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/RoleDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Role/GetMany": {
            "get": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/RoleDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Role": {
            "put": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/RoleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "RoleClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/RoleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/RoleDto"
                        }
                    }
                }
            }
        },
        "/api/Sale/GetSaleExtByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "SaleClient"
                ],
                "operationId": "GetSaleExtByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Sale/AddUpdateSalePayoff": {
            "post": {
                "tags": [
                    "SaleClient"
                ],
                "operationId": "AddUpdateSalePayoff_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/SalePayoffRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/SaleBalanceToMaturity/{saleId}": {
            "get": {
                "tags": [
                    "SaleBalanceToMaturityClient"
                ],
                "operationId": "GetSaleBalanceToMaturity_BySaleid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "saleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/SaleCalc/GetByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "SaleCalcClient"
                ],
                "operationId": "GetByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/SalePayoffEstimate/{saleId}": {
            "get": {
                "tags": [
                    "SalePayoffEstimateClient"
                ],
                "operationId": "GetSalePayoffEstimate_BySaleid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "saleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetSearchByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetSearchByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetOpenOpportunities": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetOpenOpportunities_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "saleTypes",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32",
                            "enum": [
                                1,
                                2,
                                3,
                                4,
                                6,
                                7,
                                8
                            ]
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    },
                    {
                        "name": "flushCache",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": true
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetOpenOpportunities/FlushCache": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetOpenOpportunitiesFlushCache_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "saleTypes",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32",
                            "enum": [
                                1,
                                2,
                                3,
                                4,
                                6,
                                7,
                                8
                            ]
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetEntitiesListCustom": {
            "post": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetEntitiesListCustom_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/SearchEntityListRequestExtDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/SearchDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Search/GetOneToOneEntities": {
            "post": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetOneToOneEntities_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/OneToOneRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetFreshOpportunities": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetFreshOpportunities_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "MaxResults",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "flushCache",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": true
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetFreshOpportunities/FlushCache": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetFreshOpportunitiesFlushCache_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "MaxResults",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetRecentlyAssignedOpportunities": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetRecentlyAssignedOpportunities_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "MaxResults",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "flushCache",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": true
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetRecentlyAssignedOpportunities/FlushCache": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetRecentlyAssignedOpportunities_FlushCache_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "MaxResults",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Search/GetSoldBySearchByDealers/{dealerId}": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetSoldBySearchByDealers_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/SoldBy_SearchDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Search/GetPrivateOfferInfo/{dealerId}/{entityId}": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetPrivateOfferInfo_ByDealerid_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/PrivateOfferDto"
                        }
                    }
                }
            }
        },
        "/api/Search/SaveSearch": {
            "post": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "SaveSearch_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/SaveSearchRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/SaveSearchResponse"
                        }
                    }
                }
            }
        },
        "/api/Search/CreateDealerCampaignExportAudience": {
            "post": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "CreateDealerCampaignExportAudience_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/O2ODealerCampaignExportAudienceRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/vw_DealerCampaignRequestDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Search/GetDealerCampaignRequestsInWindow/{days}": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetDealerCampaignRequestsInWindowAsync_ByDays_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "days",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/vw_DealerCampaignRequestDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Search/GetNoLongerOwnForDealer/{dealerId}": {
            "get": {
                "tags": [
                    "SearchClient"
                ],
                "operationId": "GetNoLongerOwnForDealer_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/NLPNoLongerOwnResultDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Service/GetServicesBy/{entityId}": {
            "get": {
                "tags": [
                    "ServiceClient"
                ],
                "operationId": "GetServicesBy_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ServiceExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Service/GetServiceDetail/{dealerId}/{roId}": {
            "get": {
                "tags": [
                    "ServiceClient"
                ],
                "operationId": "GetServiceDetail_ByDealerid_ByRoid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "roId",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ServiceLineDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Style/GetStyleImage/{styleId}": {
            "get": {
                "tags": [
                    "StyleClient"
                ],
                "operationId": "GetStyleImage_ByStyleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "styleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/Style/GetStyleImageCarousel/{styleId}": {
            "get": {
                "tags": [
                    "StyleClient"
                ],
                "operationId": "GetStyleImageCarousel_ByStyleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "styleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/StyleImageCarouselDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Tag/GetTagsForEntity/{entityId}": {
            "get": {
                "tags": [
                    "TagClient"
                ],
                "operationId": "GetTagsForEntity_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/TagExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Tag/GetTagsPivotForDealers": {
            "get": {
                "tags": [
                    "TagClient"
                ],
                "operationId": "GetTagsPivotForDealers_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "flushCache",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": true
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/TagPivotItemDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Tag/IsTagActivePast6Months/{dealerId}/{tagText}": {
            "get": {
                "tags": [
                    "TagClient"
                ],
                "operationId": "IsTagActivePast6Months_ByDealerid_ByTagtext_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "tagText",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/Tag/GetTagsForDealers/{dealerId}": {
            "get": {
                "tags": [
                    "TagClient"
                ],
                "operationId": "GetTagsForDealers_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/TagDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Tag/GetActiveTagsInDateRange": {
            "post": {
                "tags": [
                    "TagClient"
                ],
                "operationId": "GetActiveTagsInDateRange_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ActiveTagsRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Tag/GetActiveTagsInDateRangeForDealer/{dealerId}": {
            "post": {
                "tags": [
                    "TagClient"
                ],
                "operationId": "GetActiveTagsInDateRangeForDealer_ByDealerid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ActiveTagsRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/GetTemplateExtById/{templateId}": {
            "get": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "GetTemplateExtById_ByTemplateid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "cultureName",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "default": "en-US"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/SaveTemplateExt": {
            "post": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "SaveTemplateExt_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "template",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/TemplateExtDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/DeleteTemplate/{templateId}": {
            "delete": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "DeleteTemplate_ByTemplateid_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/TemplateEmailPreview/{templateId}/{dealerId}": {
            "get": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "TemplateEmailPreview_ByTemplateid_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/TemplatePdfPreview/{templateId}/{dealerId}": {
            "get": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "TemplatePdfPreview_ByTemplateid_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/GetTemplatesForDealerId": {
            "get": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "GetTemplatesForDealerId_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/GetNonEmptyTemplatesForDealerId": {
            "get": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "GetNonEmptyTemplatesForDealerId_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/GetTemplates/{dealerId}": {
            "get": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "GetTemplates_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "culture",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Template/MergeTemplate/{templateId}": {
            "post": {
                "tags": [
                    "TemplateClient"
                ],
                "operationId": "MergeTemplate_ByTemplateid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/MergeTemplateRequest"
                        }
                    },
                    {
                        "name": "templateId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/{id}": {
            "get": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/SendProcessEmail": {
            "post": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "SendProcessEmail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ProcessEmailRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/SendExternalProcessEmail": {
            "post": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "SendExternalProcessEmail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ProcessEmailRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/SendEmail": {
            "post": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "SendEmail_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EmailRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/SendEmailWithAttachment": {
            "post": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "SendEmailWithAttachment_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "sendEmailRequest",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/SendEmailRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/ValidateMaxEmailsPerDay/{entityId}": {
            "get": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "ValidateMaxEmailsPerDay_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/GenerateEmailPreview": {
            "post": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "GenerateEmailPreview_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/EmailRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/GetSentEmailContent/{templateOutboundEmailId}/{accessCode}": {
            "get": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "GetSentEmailContent_ByTemplateoutboundemailid_ByAccesscode_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateOutboundEmailId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "accessCode",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/GetEmailSubscription/{templateOutboundEmailId}/{accessCode}": {
            "get": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "GetEmailSubscription_ByTemplateoutboundemailid_ByAccesscode_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateOutboundEmailId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "accessCode",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutboundEmail/DisableEmailSubscription/{templateOutboundEmailId}/{accessCode}": {
            "post": {
                "tags": [
                    "TemplateOutboundEmailClient"
                ],
                "operationId": "DisableEmailSubscription_ByTemplateoutboundemailid_ByAccesscode_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateOutboundEmailId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "accessCode",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/t/o/{templateOutboundEmailId}/{accessCode}": {
            "get": {
                "tags": [
                    "TemplateOutboundEmailTrackingClient"
                ],
                "operationId": "TrackEmailOpen_ByTemplateoutboundemailid_ByAccesscode_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateOutboundEmailId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "accessCode",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/t/c/{templateOutboundEmailId}/{templateId}/{accessCode}/{url}": {
            "get": {
                "tags": [
                    "TemplateOutboundEmailTrackingClient"
                ],
                "operationId": "TrackLinkClick_ByTemplateoutboundemailid_ByTemplateid_ByAccesscode_ByUrl_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateOutboundEmailId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "templateId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "accessCode",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "url",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/t/cq/{templateOutboundEmailId}/{templateId}/{accessCode}": {
            "get": {
                "tags": [
                    "TemplateOutboundEmailTrackingClient"
                ],
                "operationId": "TrackLinkClickQuery_ByTemplateoutboundemailid_ByTemplateid_ByAccesscode_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateOutboundEmailId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "templateId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "accessCode",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "url",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutput/GetByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "TemplateOutputClient"
                ],
                "operationId": "GetByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "count",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 0
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutput/CreatePdf": {
            "post": {
                "tags": [
                    "TemplateOutputClient"
                ],
                "operationId": "CreatePdf_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/PdfCreateRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutput/DownloadPdf/{templateOutputId}": {
            "get": {
                "tags": [
                    "TemplateOutputClient"
                ],
                "operationId": "DownloadPdf_ByTemplateoutputid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "templateOutputId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TemplateOutput/ArchivePdf": {
            "post": {
                "tags": [
                    "TemplateOutputClient"
                ],
                "operationId": "ArchivePdf_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/PdfArchiveRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TextMessage/Send": {
            "post": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "Send_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "textMessage",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/TextMessageDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/TextMessageDto"
                        }
                    }
                }
            }
        },
        "/api/TextMessage/SendWithUpgradeProposal": {
            "post": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "SendWithUpgradeProposal_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/SendTextWithUpgradeProposalRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/SendTextWithUpgradeProposalResponse"
                        }
                    }
                }
            }
        },
        "/api/TextMessage/GetHistoryForEntity/{entityId}": {
            "get": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "GetHistoryForEntity_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TextMessage/GetMessagesByCustomerId/{customerId}": {
            "get": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "GetMessagesByCustomerId_ByCustomerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "customerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/TextMessageDto"
                        }
                    }
                }
            }
        },
        "/api/TextMessage/GetNewMessagesByCustomerId/{customerId}/{messageId}": {
            "get": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "GetMessagesByCustomerId_ByCustomerid_ByMessageid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "customerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "messageId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/TextMessageDto"
                        }
                    }
                }
            }
        },
        "/api/TextMessage/GetTextableStatus": {
            "get": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "GetTextableStatus_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "CustomerID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "DealerID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "CreatedByUserID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "InsertDate",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "date-time"
                    },
                    {
                        "name": "UpdateDate",
                        "in": "query",
                        "required": false,
                        "type": "string",
                        "format": "date-time"
                    },
                    {
                        "name": "FirstName",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "MiddleName",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "LastName",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "CompanyName",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "Address",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "Address2",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "City",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "State",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "ZipCode",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "NormalizedZipCode",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "HomePhone",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "HomePhone_DoNotCallStatusTypeId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "enum": [
                            0,
                            1,
                            2,
                            3,
                            4
                        ]
                    },
                    {
                        "name": "WorkPhone",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "WorkPhone_DoNotCallStatusTypeId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "enum": [
                            0,
                            1,
                            2,
                            3,
                            4
                        ]
                    },
                    {
                        "name": "CellPhone",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "CellPhone_DoNotCallStatusTypeId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "enum": [
                            0,
                            1,
                            2,
                            3,
                            4
                        ]
                    },
                    {
                        "name": "Email",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "DoNotCall",
                        "in": "query",
                        "required": false,
                        "type": "boolean"
                    },
                    {
                        "name": "DoNotEmail",
                        "in": "query",
                        "required": false,
                        "type": "boolean"
                    },
                    {
                        "name": "StateID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "FullName",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "ExternalImportID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "InvalidContacts",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "DoNotMail",
                        "in": "query",
                        "required": false,
                        "type": "boolean"
                    },
                    {
                        "name": "County",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "IsFullCustomer",
                        "in": "query",
                        "required": false,
                        "type": "boolean"
                    },
                    {
                        "name": "IsAddressNotValid",
                        "in": "query",
                        "required": false,
                        "type": "boolean"
                    },
                    {
                        "name": "PricingPlanID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "DoNotText",
                        "in": "query",
                        "required": false,
                        "type": "boolean"
                    },
                    {
                        "name": "PreferredCultureName",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/TextableStatusModel"
                        }
                    }
                }
            }
        },
        "/api/TextMessage/IsUserNumberTextable": {
            "get": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "IsUserNumberTextable_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/TextMessage/SearchForNewNumber": {
            "get": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "SearchForNewNumber_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "stateCode",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        },
        "/api/TextMessage/SearchForNewNumber/{dealerId}/{stateCode}": {
            "get": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "SearchForNewNumber_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "stateCode",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        },
        "/api/TextMessage/SearchForNewNumberByAreaCode": {
            "get": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "SearchForNewNumberByAreaCode_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "areaCode",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        },
        "/api/TextMessage/PurchaseNewNumber": {
            "post": {
                "tags": [
                    "TextMessageClient"
                ],
                "operationId": "PurchaseNewNumber_POST",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "newNumberToPurchase",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "accessRightId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "format": "int32",
                            "type": "integer"
                        }
                    }
                }
            }
        },
        "/api/Trim/GetTrimsByModelId/{modelId}": {
            "get": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "GetTrimsByModelId_ByModelid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "modelId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/LightTrimDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Trim/GetTrimExtId/{trimId}": {
            "get": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "GetTrimExtId_ByTrimid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "trimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/Trim/GetFuelEconomyComparison/{newTrimId}/{entityId}": {
            "get": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "GetFuelEconomyComparison_ByNewtrimid_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "newTrimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Trim/GetFeatureComparison/{newTrimId}/{entityId}": {
            "get": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "GetFeatureComparison_ByNewtrimid_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "newTrimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Trim/GetFeatureComparison": {
            "get": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "GetFeatureComparison_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "trimId",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    },
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Trim/GetTrimsByModels": {
            "post": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "GetTrimsByModels_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "modelIds",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "int32",
                                "type": "integer"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Trim/{id}": {
            "get": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/TrimDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Trim/GetMany": {
            "get": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/TrimDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Trim": {
            "put": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/TrimDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "TrimClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/TrimDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/TrimDto"
                        }
                    }
                }
            }
        },
        "/api/TrimAlternateOffer/{dealerID}/{baseTrimID}/{level}": {
            "delete": {
                "tags": [
                    "TrimAlternateOfferClient"
                ],
                "operationId": "ByDealerid_ByBasetrimid_ByLevel_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerID",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "baseTrimID",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "level",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TrimAlternateOffer": {
            "put": {
                "tags": [
                    "TrimAlternateOfferClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/TrimAlternateOfferDto"
                        }
                    }
                ],
                "responses": {
                    "204": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "TrimAlternateOfferClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/TrimAlternateOfferDto"
                        }
                    }
                ],
                "responses": {
                    "201": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/TrimAlternateOfferDto"
                        }
                    }
                }
            }
        },
        "/api/TrimAlternateOffer/BulkDelete": {
            "post": {
                "tags": [
                    "TrimAlternateOfferClient"
                ],
                "operationId": "BulkDelete_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "level",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimIDs",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "int32",
                                "type": "integer"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/TrimAlternateOffer/BulkRemoveMappings": {
            "post": {
                "tags": [
                    "TrimAlternateOfferClient"
                ],
                "operationId": "BulkRemoveMappings_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "level",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimIDs",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "int32",
                                "type": "integer"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UpgradeProposal": {
            "post": {
                "tags": [
                    "UpgradeProposalClient"
                ],
                "operationId": "UpgradeProposal_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/CalculationParamModel"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UpgradeProposal/GetByActivityId/{activityId}": {
            "get": {
                "tags": [
                    "UpgradeProposalClient"
                ],
                "operationId": "GetByActivityId_ByActivityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "activityId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/pub/up/{activityId}/{accessCode}": {
            "get": {
                "tags": [
                    "UpgradeProposalExternalClient"
                ],
                "operationId": "GetSentTextUpgradeProposal_ByActivityid_ByAccesscode_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "activityId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "accessCode",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/User/LoggedInUser": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "LoggedInUser_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CoreApiPrincipal"
                        }
                    }
                }
            }
        },
        "/api/User/CurrentUser": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "CurrentUser_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserDto"
                        }
                    }
                }
            }
        },
        "/api/User/GetUser/{username}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetUser_ByUsername_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "username",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserDto"
                        }
                    }
                }
            }
        },
        "/api/User/GetEnabledUsersForDealer/{dealerId}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetEnabledUsersForDealer_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/UserDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/User/GetEnabledClientUsersExtByDealer/{dealerId}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetEnabledClientUsersExtByDealer_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/UserExtDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/User/FullName/{userId}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetUserFullName_ByUserid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "userId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/User/FullNameByEntity/{entityId}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetUserFullNameByEntity_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/User/GetEntityUserInfo/{entityId}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetEntityUserInfo_ByEntityid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/EntityUserInfoDto"
                        }
                    }
                }
            }
        },
        "/api/User/LogUserLogin": {
            "post": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "LogUserLogin_POST",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/User/FacebookUserId/{emailAddress}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "FacebookUserId_ByEmailaddress_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "emailAddress",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "string"
                        }
                    }
                }
            }
        },
        "/api/User/GetUserDealers": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetUserDealers_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserDealerModel"
                        }
                    }
                }
            }
        },
        "/api/User/GetUserIsMetric/{countryId}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetUserIsMetric_ByCountryid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "countryId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/User/CheckUserIP": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "CheckUserIP_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "userName",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "dealerIds",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    },
                    {
                        "name": "ipAddress",
                        "in": "query",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "isMobile",
                        "in": "query",
                        "required": false,
                        "type": "boolean",
                        "default": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/CheckUserIPResultDto"
                        }
                    }
                }
            }
        },
        "/api/User/IsWhiteSalePartner": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "IsWhiteSalePartner_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/User/{id}": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/User/GetMany": {
            "get": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/UserDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/User": {
            "put": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "UserClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserDto"
                        }
                    }
                }
            }
        },
        "/api/UserModule/{id}": {
            "get": {
                "tags": [
                    "UserModuleClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserModuleDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "UserModuleClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserModule/GetMany": {
            "get": {
                "tags": [
                    "UserModuleClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/UserModuleDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/UserModule": {
            "put": {
                "tags": [
                    "UserModuleClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserModuleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "UserModuleClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserModuleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserModuleDto"
                        }
                    }
                }
            }
        },
        "/api/UserModuleAccess": {
            "get": {
                "tags": [
                    "UserModuleAccessClient"
                ],
                "operationId": "GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserModuleAccessDto"
                        }
                    }
                }
            }
        },
        "/api/UserModuleAccess/GetModuleAccess/{moduleId}": {
            "get": {
                "tags": [
                    "UserModuleAccessClient"
                ],
                "operationId": "GetModuleAccess_ByModuleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "moduleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserModuleDealerDto"
                        }
                    }
                }
            }
        },
        "/api/UserProfile": {
            "put": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserProfileDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserProfileDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserProfileDto"
                        }
                    }
                }
            },
            "patch": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "Update_PATCH",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "userProfile",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserProfilePatch"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserProfile/Extended": {
            "get": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "Extended_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserProfileExtendedDto"
                        }
                    }
                }
            }
        },
        "/api/UserProfile/GetCultures": {
            "get": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "GetCultures_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        },
        "/api/UserProfile/GetTimeZones": {
            "get": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "GetTimeZones_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/TimeZoneDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/UserProfile/{id}": {
            "get": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserProfileDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserProfile/GetMany": {
            "get": {
                "tags": [
                    "UserProfileClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/UserProfileDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/UserSearchCondition/GetSearches": {
            "get": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "GetSearches_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/GetSearchModel/{userSearchConditionId}": {
            "get": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "GetSearchModel_ByUsersearchconditionid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "userSearchConditionId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/GetSearchCounts": {
            "post": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "GetSearchCounts_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserSearchCountRequestOutDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/FlushSearchCountsCache/{luae}": {
            "get": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "FlushSearchCountsCache_ByLuae_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "luae",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/GetDealerLayOutExts": {
            "get": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "GetDealerLayOutExts_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/GetActiveDealerLayOutExts": {
            "get": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "GetActiveDealerLayOutExts_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/ShareUserSearchCondition/{userSearchConditionId}": {
            "post": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "ShareUserSearchCondition_ByUsersearchconditionid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "userSearchConditionId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/UnshareUserSearchCondition/{userSearchConditionId}": {
            "post": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "UnshareUserSearchCondition_ByUsersearchconditionid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "userSearchConditionId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/DeleteUserSearchCondition/{userSearchConditionId}": {
            "post": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "DeleteUserSearchCondition_ByUsersearchconditionid_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "userSearchConditionId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/UpdateDealerLayOuts/{dealerId}": {
            "post": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "UpdateDealerLayOuts_ByDealerid_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "layouts",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/UserSearchConditionDealerLayOutExtDto"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/ResetAutoAssignments": {
            "post": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "ResetAutoAssignments_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "resets",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/ResetAutoAssignmentsDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/ExecutePandoX": {
            "post": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "ExecutePandoX_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "userSearchConditionId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/{id}": {
            "get": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserSearchConditionDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/UserSearchCondition/GetMany": {
            "get": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/UserSearchConditionDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/UserSearchCondition": {
            "put": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserSearchConditionDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "UserSearchConditionClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/UserSearchConditionDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/UserSearchConditionDto"
                        }
                    }
                }
            }
        },
        "/api/VciMaturity/DealerMakeHasVciConfig/{dealerId}/{makeId}": {
            "get": {
                "tags": [
                    "VciMaturityClient"
                ],
                "operationId": "DealerMakeHasVciConfig_ByDealerid_ByMakeid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/VciMaturity/AddVciEulaAccepted": {
            "post": {
                "tags": [
                    "VciMaturityClient"
                ],
                "operationId": "AddVciEulaAccepted_POST",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VciMaturity/GetVciMaturityData/{entityId}/{makeId}/{vin}": {
            "get": {
                "tags": [
                    "VciMaturityClient"
                ],
                "operationId": "GetVciMaturityData_ByEntityid_ByMakeid_ByVin_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "makeId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VciResultDto"
                        }
                    }
                }
            }
        },
        "/api/VciMaturity/{id}": {
            "get": {
                "tags": [
                    "VciMaturityClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VciDealerByMakeDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "VciMaturityClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VciMaturity/GetMany": {
            "get": {
                "tags": [
                    "VciMaturityClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/VciDealerByMakeDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/VciMaturity": {
            "put": {
                "tags": [
                    "VciMaturityClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VciDealerByMakeDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "VciMaturityClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VciDealerByMakeDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VciDealerByMakeDto"
                        }
                    }
                }
            }
        },
        "/api/Vehicle/ByVin/{vin}": {
            "get": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "GetByVin_ByVin_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Vehicle/GetByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "GetByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Vehicle/GetDetailsByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "GetDetailsByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Vehicle/GetDetails/{vehicleId}": {
            "get": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "GetDetails_ByVehicleid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Vehicle/{id}": {
            "get": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Vehicle/GetMany": {
            "get": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/VehicleDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Vehicle": {
            "put": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VehicleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "VehicleClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VehicleDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleDto"
                        }
                    }
                }
            }
        },
        "/api/VehicleCalc/{id}": {
            "get": {
                "tags": [
                    "VehicleCalcClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleCalc/GetExtById/{id}": {
            "get": {
                "tags": [
                    "VehicleCalcClient"
                ],
                "operationId": "GetExtById_ById_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleExtendedWarranty/GetByVehicleId/{vehicleId}": {
            "get": {
                "tags": [
                    "VehicleExtendedWarrantyClient"
                ],
                "operationId": "GetByVehicleId_ByVehicleid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleExtendedWarranty/SaveExtendedWarrantySoldDate/{vehicleId}/{soldDate}": {
            "post": {
                "tags": [
                    "VehicleExtendedWarrantyClient"
                ],
                "operationId": "SaveExtendedWarrantySoldDate_ByVehicleid_BySolddate_POST",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "soldDate",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "date-time"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleFinalTradeValueTempChange/LogTradeValueChange": {
            "post": {
                "tags": [
                    "VehicleFinalTradeValueTempChangeClient"
                ],
                "operationId": "LogTradeValueChange_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/LogTradeValueChangeRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleFinalTradeValueTempChange/{id}": {
            "get": {
                "tags": [
                    "VehicleFinalTradeValueTempChangeClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleFinalTradeValueTempChangeDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "VehicleFinalTradeValueTempChangeClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleFinalTradeValueTempChange/GetMany": {
            "get": {
                "tags": [
                    "VehicleFinalTradeValueTempChangeClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/VehicleFinalTradeValueTempChangeDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/VehicleFinalTradeValueTempChange": {
            "put": {
                "tags": [
                    "VehicleFinalTradeValueTempChangeClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VehicleFinalTradeValueTempChangeDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "VehicleFinalTradeValueTempChangeClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VehicleFinalTradeValueTempChangeDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleFinalTradeValueTempChangeDto"
                        }
                    }
                }
            }
        },
        "/api/VehicleImage/GetImage/{dealerId}/{trimId}": {
            "get": {
                "tags": [
                    "VehicleImageClient"
                ],
                "operationId": "GetImage_ByDealerid_ByTrimid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleImage/GetEntityImage/{entityId}": {
            "get": {
                "tags": [
                    "VehicleImageClient"
                ],
                "operationId": "GetImage_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleImage/GetVehicleImage/{vehicleId}": {
            "get": {
                "tags": [
                    "VehicleImageClient"
                ],
                "operationId": "GetImage_ByVehicleid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleReplacement/GetExtByEntityId/{entityId}": {
            "get": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "GetExtByEntityId_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "pricingPlanID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleReplacement/GetExtByVehicleAndDealer/{vehicleId}/{dealerId}": {
            "get": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "GetExtByVehicleAndDealer_ByVehicleid_ByDealerid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "pricingPlanID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "trimId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleReplacement/GetVehicleReplacements/{entityId}/{stockNo}": {
            "get": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "GetVehicleReplacements_ByEntityid_ByStockno_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    },
                    {
                        "name": "stockNo",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    },
                    {
                        "name": "pricingPlanID",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleReplacementDto"
                        }
                    }
                }
            }
        },
        "/api/VehicleReplacement/OnDemandOffBrandVehicleReplacements": {
            "post": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "OnDemandOffBrandVehicleReplacements_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/OnDemandOffBrandVehicleReplacementsRequest"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleReplacementDto"
                        }
                    },
                    "403": {
                        "description": "Forbidden"
                    }
                }
            }
        },
        "/api/VehicleReplacement/{id}": {
            "get": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleReplacementDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VehicleReplacement/GetMany": {
            "get": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/VehicleReplacementDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/VehicleReplacement": {
            "put": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VehicleReplacementDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "VehicleReplacementClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VehicleReplacementDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VehicleReplacementDto"
                        }
                    }
                }
            }
        },
        "/api/VehicleUserTradeValue/StoreVehicleUserTradeValue": {
            "post": {
                "tags": [
                    "VehicleUserTradeValueClient"
                ],
                "operationId": "StoreVehicleUserTradeValue_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "model",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/StoreVehicleUserTradeValueDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VinRecall/GetByDealerVehicle/{dealerId}/{vehicleId}": {
            "get": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "GetByDealerVehicle_ByDealerid_ByVehicleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/VinRecallDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/VinRecall/GetInvRecallByDealerVehicle/{dealerId}/{vehicleId}": {
            "get": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "GetInvRecallByDealerVehicle_ByDealerid_ByVehicleid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vehicleId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/VinRecallDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/VinRecall/GetVinRecallNos": {
            "get": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "GetVinRecallNos_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VinRecallNumbersDto"
                        }
                    }
                }
            }
        },
        "/api/VinRecall/GetVinRecallTypes": {
            "get": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "GetVinRecallTypes_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/VinRecallTypeDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/VinRecall/GetVinRecallForInventory": {
            "post": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "GetVinRecallForInventory_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerIds",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "int32",
                                "type": "integer"
                            }
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VinRecall/{id}": {
            "get": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VinRecallDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/VinRecall/GetMany": {
            "get": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/VinRecallDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/VinRecall": {
            "put": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VinRecallDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "VinRecallClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/VinRecallDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/VinRecallDto"
                        }
                    }
                }
            }
        },
        "/api/Year/GetYears/{isAll}": {
            "get": {
                "tags": [
                    "YearClient"
                ],
                "operationId": "GetYears_ByIsall_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "isAll",
                        "in": "path",
                        "required": true,
                        "type": "boolean"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/YearDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Year/GetByDealerId/{dealerId}/{showDefaultYears}": {
            "get": {
                "tags": [
                    "YearClient"
                ],
                "operationId": "GetByDealerId_ByDealerid_ByShowdefaultyears_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "showDefaultYears",
                        "in": "path",
                        "required": true,
                        "type": "boolean",
                        "default": true
                    },
                    {
                        "name": "limit",
                        "in": "query",
                        "required": false,
                        "type": "integer",
                        "format": "int32",
                        "default": 10
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "format": "int32",
                                "type": "integer"
                            }
                        }
                    }
                }
            }
        },
        "/api/Year/{id}": {
            "get": {
                "tags": [
                    "YearClient"
                ],
                "operationId": "ById_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/YearDto"
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "YearClient"
                ],
                "operationId": "ById_DELETE",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Year/GetMany": {
            "get": {
                "tags": [
                    "YearClient"
                ],
                "operationId": "GetMany_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "ids",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/YearDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/Year": {
            "put": {
                "tags": [
                    "YearClient"
                ],
                "operationId": "PUT",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/YearDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            },
            "post": {
                "tags": [
                    "YearClient"
                ],
                "operationId": "POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "data",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/YearDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/YearDto"
                        }
                    }
                }
            }
        }
    },
    "definitions": {},
    "securityDefinitions": {
        "Bearer": {
            "name": "Authorization",
            "in": "header",
            "type": "apiKey",
            "description": "access_token from SSO"
        }
    },
    "security": [
        {
            "Bearer": []
        }
    ]
}