import { Injectable, Inject } from "@angular/core";
import { UserService } from "../services/user.service";
import { UserProfileDto } from "../generated/models";
import { DealerService } from "../services/dealer.service";
import { MenuService } from "../services/menu.service";
import { TmhDynamicLocale } from "../ajs-upgraded-providers";
import { StateService } from "@uirouter/angular";
import { ToasterLoggerService } from "./toaster-logger.service";
import { currentUserCulture$ } from './translation';

@Injectable({ providedIn: 'root' })
export class LoggedInDataInitializationService {
    constructor(private userService: UserService,
        private dealerService: DealerService,
        private menuService: MenuService,
        @Inject(TmhDynamicLocale) private tmhDynamicLocale,
        private stateService: StateService,
        private loggerService: ToasterLoggerService) { }

    public initializeData(): Promise<any> {
        return this.userService.getUserProfile()
            .then(
                (userProfile) => this.getUserProfileComplete(userProfile),
                () => this.getUserProfileFailed
            );
    }

    private getUserProfileComplete(userProfile: UserProfileDto): Promise<any> {

        const countryCode = userProfile.cultureName.substr(0, 2);
        this.tmhDynamicLocale.set(countryCode);

        return this.setSelectedDealers(userProfile)
            .then(() => {
                return this.dealerService
                    .getDealersOneToOneStatus()
                    .catch((e) => this.loggerService.debug(e))
                    .then(() => {
                        currentUserCulture$.next(userProfile.cultureName);

                        // Do not use userProfile.dealerIds here, because we might
                        // be an employee user who has changed their dealer
                        // context.
                        const dealerIds = this.dealerService.getSelectedDealerIds();
                        if (dealerIds) {
                            return this.menuService.getSiteMap(dealerIds);
                        }
                    })
            });
    }

    private setSelectedDealers(userProfile): Promise<any> {

        // Employee users should retain their selected dealers between login sessions.
        // (Assuming different employee users aren't using the same browser).
        // All other users must reset to their profile's list of dealers.
        let isDealerUser = !userProfile.employeeUser;

        if (isDealerUser || (!this.dealerService.getSelectedDealers() && !this.dealerService.getCXMViewSelectedStores())) {
            if (userProfile.dealerIds.length == 1) {
                this.dealerService.updateSelectedDealers([
                    {
                        id: userProfile.dealerIds[0],
                        name: userProfile.dealerDisplayText
                    }
                ], userProfile.dealerGroupId, userProfile.dealerDisplayText);
                return Promise.resolve();
            } else {
                return this.dealerService.getDealers()
                    .then((dealers) => {
                        let selectedDealers = dealers.map(function (o) {
                            return {
                                id: o.dealerID,
                                name: o.displayText
                            };
                        });
                        return this.dealerService.updateSelectedDealers(
                            selectedDealers,
                            userProfile.dealerGroupId,
                            userProfile.dealerDisplayText);
                    });
            }
        }

        return Promise.resolve();
    }

    private getUserProfileFailed() {
        this.stateService.go('errorUnauthorized', { errorState: 'noUserProfile' });
    }
}