
/* injects from baggage-loader */
require('./reports-menu.component.html');

import angular from 'angular';
import moment from 'moment-timezone';

angular.module('AaApp.Opp').component('reportsMenu', {
    templateUrl: '/app/components/reports/reports-menu.component.html',
    controller: ReportsMenuController,
    bindings: {
        kendo: '<',
        moduleIds: '<',
    }
});

/* @ngInject */
function ReportsMenuController(_, $state, $location, $transitions, storageService, reportService, userService, reportCategories, dealerService, environmentVariables) {
    var $ctrl = this;

    $ctrl.legacyExpirationDate;
    $ctrl.opportunitiesMenuVisible;
    $ctrl.communicationMenuVisible;
    $ctrl.oneToOneMenuVisible;
    $ctrl.autoAssistantMenuVisible;
    $ctrl.geoAlertMenuVisible;
    $ctrl.partnerExportMenuVisible;
    $ctrl.showBetaReports;
    $ctrl.oneToOneMenuDisabled;
    $ctrl.autoAssistantMenuDisabled;
    $ctrl.geoAlertMenuDisabled;
    $ctrl.partnerExportMenuDisabled;
    $ctrl.reportCategories;
    $ctrl.showLegacy;
    $ctrl.$state;
    $ctrl.isActiveMenuItem = isActiveMenuItem;
    $ctrl.$onInit = $onInit;
    $ctrl.$onChanges = $onChanges;
    $ctrl.showPerformanceReport = false;
    $ctrl.performanceReportMonthName;
    $ctrl.performanceReportYear;
    $ctrl.isAutoAlertEmployee = false;
    $ctrl.currentReportId;
    $ctrl.isActiveBetaMenuItem = isActiveBetaMenuItem;
    $ctrl.betaReportsMenu;
    $ctrl.betaReportCategories;
    $ctrl.performanceReportAvailable = false;
    $ctrl.monthlyPerformanceReportId;

    function $onInit() {
        $ctrl.legacyExpirationDate = new Date(2018, 0, 15);
        $ctrl.opportunitiesMenuVisible = true;
        $ctrl.communicationMenuVisible = false;
        $ctrl.oneToOneMenuDisabled = false;
        $ctrl.autoAssistantMenuDisabled = false;
        $ctrl.geoAlertMenuDisabled = false;
        $ctrl.partnerExportMenuDisabled = false;
        $ctrl.oneToOneMenuVisible = false;
        $ctrl.autoAssistantMenuVisible = false;
        $ctrl.geoAlertMenuVisible = false;
        $ctrl.partnerExportMenuVisible = false;
        $ctrl.showBetaReports = false;
        $ctrl.reportCategories = reportCategories;
        $ctrl.showLegacy = ($state.current.name.indexOf('legacy') > -1 && $state.current.name.indexOf('legacyoneToOneReport') < 0); // temp for legacy one-to-one
        $ctrl.$state = $state;
        $ctrl.reportsMenu = reportService.getAuthorizedReports($ctrl.moduleIds);
        $ctrl.betaReportsMenu = [];

        setPerformanceReportMonthYear();
        
        loadMenu();

        // Destroy all Kendo components so memory can be GC'd
        $transitions.onExit({}, function (transition, state) {
            if ($ctrl.kendo && (state.name == 'reportsContainer' || state.parent == 'reportsContainer')) {
                $ctrl.kendo.destroy('report-render')
            }
        });

        $transitions.onSuccess({}, function (transition) {
            $ctrl.showLegacy = (transition.to().name.indexOf('legacy') > -1 && transition.to().name.indexOf('legacyoneToOneReport') < 0); // temp for legacy one-to-one

            loadMenu();

            if (transition.from().parent && transition.from().parent == 'reportsContainer'
                && (!transition.to().parent || transition.to().parent != 'reportsContainer')) {
                storageService.removeItem('reportHeaderData');
            }

            $ctrl.currentReportId = $location.search().id;
        });
    }

    function setPerformanceReportMonthYear() {
        const PERFORMANCE_REPORT_START_DAY = environmentVariables.showPerformanceReportDayOfMonth || 1;
        const cultureName = storageService.getItem('cultureName') || 'en-US';
        var now = moment().locale(cultureName);
        var dayOfMonth = now.date();
        var month = now.month();
        var prevMonth = month === 0 ? 11 : month - 1;
        var year = now.year();
        var prevYear = month === 0 ? year - 1 : year;

        $ctrl.showPerformanceReport = (dayOfMonth >= PERFORMANCE_REPORT_START_DAY) && $ctrl.performanceReportAvailable;
        $ctrl.performanceReportMonthName = now.month(prevMonth).format("MMMM").toUpperCase();
        $ctrl.performanceReportYear = now.year(prevYear).format("YYYY");
    }

    function loadMenu() {
        var currentMenuItem = _.find($ctrl.reportsMenu, { id: $state.current.name });
        
        if (currentMenuItem) {
            switch (currentMenuItem.category) {
                case reportCategories.opportunities:
                    $ctrl.opportunitiesMenuVisible = true;
                    break;
                case reportCategories.communication:
                    $ctrl.communicationMenuVisible = true;
                    break;
                case reportCategories.onetoone:
                    $ctrl.oneToOneMenuVisible = true;
                    break;
                case reportCategories.autoassistant:
                      $ctrl.autoAssistantMenuVisible = true;
                      break;
                case reportCategories.geoalert:
                      $ctrl.geoAlertMenuVisible = true;
                      break;
                case reportCategories.partnerExport:
                    $ctrl.partnerExportMenuVisible = true;
                    break;
                default:
                    break;
            }
        }

    }

    function fixNullCategory(report, index, reports) {

        reports[index].CategoryName = "Additional Reports"; //set all Tableau reports to this category name
    }

    function $onChanges(changes) {

        if (changes) {
            userService.getUserProfile()
                .then(async (userProfile) => {
                    $ctrl.reportsMenu = reportService.getAuthorizedReports($ctrl.moduleIds, userProfile.isSltUser);
                    $ctrl.isAutoAlertEmployee = userProfile.employeeUser;
                    $ctrl.betaReportsMenu = [];
                    $ctrl.showBetaReports = false;

                    reportService.getBetaReportList()
                        .then((results) => {
                            if (results && results.Success == true && results.Data && results.Data.length > 0) {
                                results.Data.forEach(fixNullCategory);
                                const tableauReports = [];
                                results.Data.forEach((report) => {
                                    if (report.Name == "Monthly Performance Report") {
                                        //Monthly Performance Report does not go into the tableauReports array
                                        $ctrl.monthlyPerformanceReportId = report.Id;
                                        $ctrl.performanceReportAvailable = true;
                                    }
                                    else {
                                        tableauReports.push(report);
                                    }
                                });
                                if (tableauReports.length > 0) {
                                    $ctrl.showBetaReports = true;
                                }

                                $ctrl.betaReportsMenu = tableauReports.sort(byCategoryThenReportName);
                                $ctrl.currentReportId = $location.search().id;

                                var currentBetaCategory = "NO_CATEGORY";
                                if ($ctrl.currentReportId) {
                                    var currentReport = _.find($ctrl.betaReportsMenu, { Id: $ctrl.currentReportId });
                                    if (currentReport) {
                                        currentBetaCategory = currentReport.CategoryName;
                                    }
                                }

                                let categories = [...new Set($ctrl.betaReportsMenu.map(r => r.CategoryName))];
                                $ctrl.betaReportCategories = categories.map(r => {
                                    const container = {};
                                    container.categoryName = r;
                                    container.isVisible = (currentBetaCategory == r);
                                    return container;
                                });

                                setPerformanceReportMonthYear();
                            }
                        });

                    // If other code hasn't yet loaded the o2o status, we need to
                    // load it before checking if o2o is enabled
                    if (!dealerService.dealersOneToOneStatusLoaded) {
                        await dealerService.getDealersOneToOneStatus();
                    }

                    const isDealerOneTooneEnabled = dealerService.getIsSomeDealerOneToOneEnabled();
                    const isDealerAutoAssistantEnabled = await dealerService.getIsSomeDealerAutoAssistantEnabled();
                    const isDealerGeoAlertEnabled = await dealerService.getIsSomeDealerGeoAlertEnabled();

                    // show onetoone menu only for dealers who have onetoone enabled
                    $ctrl.oneToOneMenuDisabled = (!isDealerOneTooneEnabled);

                    $ctrl.autoAssistantMenuDisabled = !isDealerAutoAssistantEnabled;
                    $ctrl.geoAlertMenuDisabled = !isDealerGeoAlertEnabled;
                });
        }
    }

    function byCategoryThenReportName(report1, report2) {
        if (report1.CategoryName == report2.CategoryName) {
            if (report1.Name < report2.Name)
                return -1;
            if (report1.Name > report2.Name)
                return 1;
            return 0;

        } else {
            if (report1.CategoryName < report2.CategoryName)
                return -1;
            if (report1.CategoryName > report2.CategoryName)
                return 1;
            return 0;
        }
    }

    function isActiveMenuItem(menuItem) {
        return $state.current.url.indexOf(menuItem.url) > -1 || (menuItem.url == "/performance" && $location.$$search.id == $ctrl.monthlyPerformanceReportId); 
    }

    function isActiveBetaMenuItem(menuItem) {
        return $ctrl.currentReportId == menuItem.Id;
    }
}
