
/* injects from baggage-loader */
require('./dealer-dropdown.component.html');

import angular from 'angular';
import './dealer-dropdown.component.scss';


angular.module('AaApp.Opp').component('dealerDropdown', {
    templateUrl: '/app/components/dealer-dropdown.component.html',
    controller: DealerDropdownController,
    bindings: {
        selectedDealerId: '<',
        changeEvent: '&'
    }
});

/* @ngInject */
function DealerDropdownController(dealerService, storageService) {
    const $ctrl = this;
    $ctrl.$onInit = $onInit;

    $ctrl.selectedDealerId = null;
    $ctrl.dealers = [];
    $ctrl.changeEventWrapper = changeEventWrapper;

    function $onInit() {
        $ctrl.dealers = dealerService.getSelectedDealers();
        var selectedDealerId = storageService.getItem('selectedDealerId');
        $ctrl.selectedDealerId = selectedDealerId > 0 ? selectedDealerId : $ctrl.dealers[0].id;
    }

    function changeEventWrapper() {
        storageService.setItem('selectedDealerId', $ctrl.selectedDealerId);
        $ctrl.changeEvent({ dealerId: $ctrl.selectedDealerId });
    }
}
