
/* injects from baggage-loader */
require('./search-menu.component.html');

import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import './search-menu.component.scss';

angular.module('AaApp.Opp').component('searchMenu', {
    templateUrl: '/app/components/search/search-menu.component.html',
    controller: SearchMenuController,
    bindings: {
        presetId: '<',
        //permissions: '<',
        selectedDealerId: '=',
        searchMenuVisible: '=',
        sectionStates: '<',
        presetSections: '=',
        currentView: '<'
    }
});

/* @ngInject */
function SearchMenuController($state, _, $q, $scope, searchPresetTypes, searchPresetService, userService,
    dealerService, authorizationService, modules, authorizationKeys, $uibModal, $document, $translate, assetTypes) {
    var $ctrl = this;

    $ctrl.selectedDealerId;
    $ctrl.searchPresets;
    $ctrl.userId;
    $ctrl.isAutoAlertEmployee;
    $ctrl.hasShareSearchAccess;
    $ctrl.leadAssignOwnerAccessEnabled;
    $ctrl.searchPresetTypes = searchPresetTypes;
    
    //Events
    $ctrl.$onInit = $onInit;
    $ctrl.openSearchPreset = openSearchPreset;
    $ctrl.deleteSearchPreset = deleteSearchPreset;
    $ctrl.deleteSharedSearchPreset = deleteSharedSearchPreset;
    $ctrl.shareSearchPreset = shareSearchPreset;
    $ctrl.unshareSearchPreset = unshareSearchPreset;
    $ctrl.setView = setView;

    function $onInit() {
        if ($ctrl.presetSections == null)
            $ctrl.presetSections = {
                mySearchesMenuVisible: true,
                sharedSearchesMenuVisible: true,
                tagBasedSearchesMenuVisible: false,
                predefinedSearchesMenuVisible: false,
            };

        $ctrl.leadAssignOwnerAccessEnabled = false;
        $ctrl.pandoXEnabled = false;

        $ctrl.dealers = dealerService.getSelectedDealers();
        // if there is only one dealer 
        //if ($ctrl.dealers.length == 1) 
        //    $ctrl.selectedDealerId = $ctrl.dealers[0].id;  

        userService.getUserProfile()
            .then(function (userProfile) {
                $ctrl.userId = userProfile.userId;
                $ctrl.isAutoAlertEmployee = userProfile.employeeUser;
                $ctrl.hasShareSearchAccess = (authorizationService.isAuthorized(authorizationKeys.ManageSearches) || userProfile.employeeUser);
                if ($ctrl.isAutoAlertEmployee && $ctrl.selectedDealerId) {
                    dealerService.getDealerSettings($ctrl.selectedDealerId)
                        .then(function (settings) {
                            //Use dealer admin ID for AutoAlert Employee
                            $ctrl.userId = settings.defaultUserID;
                            searchPresetService.getSearchPresets().then(loadMenu);
                        })
                }
                else {
                    searchPresetService.getSearchPresets().then(loadMenu);
                }

                //this if moved from end of function to here, userProfile must be available before calling isDealerEnabledForModule
                if ($ctrl.dealers.length === 1) {
                    $ctrl.leadAssignOwnerAccessEnabled = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.leadAssignOwnerAccess);
                }
                else {
                    $ctrl.leadAssignOwnerAccessEnabled = userService.isModuleEnabledForAnyDealer(modules.leadAssignOwnerAccess);
                }
            })

        // Calling this without dealer id looks for the asset in any of the users dealers
        dealerService.getIsAssetEnabledForDealer(assetTypes.pandoX, ($ctrl.dealers.length === 1) ? $ctrl.selectedDealerId : null)
            .then(isEnabled => { $ctrl.pandoXEnabled = isEnabled; })
    }

    function setView() {
        $ctrl.searchMenuVisible = false;
        $ctrl.presetId = null;

        switch ($ctrl.currentView) {
            case "search":
                $ctrl.currentView = "manage";
                $state.go("search.manage", { selectedDealerId: $ctrl.selectedDealerId }, { reload: false });
                break;
            case "manage":
            default:
                $ctrl.currentView = "search";
                $state.go("search.form", { dealerId: $ctrl.selectedDealerId, selectedDealerId: $ctrl.selectedDealerId, sectionStates: $ctrl.sectionStates }, { reload: "search.form" });
        }
    }

    $scope.$watch('$ctrl.presetId', function (newVal, oldVal, scope) {
        if (Number.isInteger(newVal) && newVal !== oldVal) {
            $ctrl.presetId = newVal;
            //reload menu from database as a new preset may be added to database
            searchPresetService.getSearchPresets()
                .then(loadMenu);
        }

        if (newVal === undefined || newVal === null) {
            $ctrl.presetId = null;
        }
    });

    function deleteSearchPreset(searchPreset) {

        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            component: 'confirmationModal',
            resolve: {
                title: function () {
                    return "confirmDelete";
                },
                message: function () {
                    return $translate("deleteSearchWarningMessage");
                },
                submitButtonVisible: function () {
                    return true;
                },
                submitButtonDisabled: function () {
                    return false;
                },
                submitButtonText: function () {
                    return "continue"; // translation key
                }
            }
        });

        modal.result.then(function () {
            searchPresetService.deleteSearchPreset(searchPreset.userSearchConditionID)
                .then(function (response) {
                    _.remove($ctrl.searchPresets, { userSearchConditionID: searchPreset.userSearchConditionID });
                })
        });
    }

    function deleteSharedSearchPreset(searchPreset) {

        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            component: 'confirmationModal',
            resolve: {
                title: function () {
                    return "confirmDelete";
                },
                message: function () {
                    return $translate("deleteSharedSearchWarningMessage");
                },
                submitButtonVisible: function () {
                    return true;
                },
                submitButtonDisabled: function () {
                    return false;
                },
                submitButtonText: function () {
                    return "continue"; // translation key
                }
            }
        });

        modal.result.then(function () {
            searchPresetService.deleteSearchPreset(searchPreset.userSearchConditionID)
                .then(function (response) {
                    _.remove($ctrl.searchPresets, { userSearchConditionID: searchPreset.userSearchConditionID });
                })
        });

    }

    function shareSearchPreset(searchPreset) {

        if (isSearchNameDuplicate(searchPreset)) {
            var modal1 = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "shareSearch";
                    },
                    message: function () {
                        return $translate("duplicateSearchWarningMessage");
                    },
                    submitButtonVisible: function () {
                        return false;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    closeButtonVisible: function () {
                        return true;
                    },
                    closeButtonDisabled: function () {
                        return true;
                    },
                    closeButtonText: function () {
                        return "ok"; // translation key
                    }
                }
            });

            return;
        }

        searchPresetService.shareSearchPreset(searchPreset.userSearchConditionID)
            .then(function (response) {
                searchPreset.userSearchConditionGroupType = searchPresetTypes.dealer;
                $ctrl.sharedSearchesMenuVisible = true;
            })

    }

    function isSearchNameDuplicate(searchPreset) {

        var currentMenuItem = _.find($ctrl.searchPresets, (o) => { return o.searchConditionName == searchPreset.searchConditionName && o.userSearchConditionGroupType == searchPresetTypes.dealer });
        if (currentMenuItem)
            return true;
        else
            return false;

    }

    function unshareSearchPreset(searchPreset) {
        searchPresetService.unshareSearchPreset(searchPreset.userSearchConditionID)
            .then(function (response) {
                if ($ctrl.userId == searchPreset.userID) {
                    searchPreset.userSearchConditionGroupType = searchPresetTypes.user;
                    $ctrl.mySearchesMenuVisible = true;
                }
                else {
                    _.remove($ctrl.searchPresets, { userSearchConditionID: searchPreset.userSearchConditionID });
                }

            })
    }

    function openSearchPreset(preset) {
        $ctrl.presetId = preset.userSearchConditionID;
        $state.go('search.form', { presetId: preset.userSearchConditionID, recentActivity: null }, { reload: 'search.form' });
    }

    function loadMenu(searchPresets) {

        //remove duplicate in multiple dealer mode
        $ctrl.searchPresets = _.uniqBy(searchPresets, 'userSearchConditionID');

        var currentMenuItem = _.find($ctrl.searchPresets, (o) => { return o.userSearchConditionID == $ctrl.presetId });

        if (currentMenuItem) {
            switch (currentMenuItem.userSearchConditionGroupType) {
                case searchPresetTypes.user:
                    $ctrl.mySearchesMenuVisible = true;
                    break;
                case searchPresetTypes.dealer:
                    $ctrl.sharedSearchesMenuVisible = true;
                    break;
                case searchPresetTypes.tag:
                    $ctrl.tagBasedSearchesMenuVisible = true;
                    break;
                case searchPresetTypes.common:
                    $ctrl.predefinedSearchesMenuVisible = true;
                    break;
                default:
                    break;
            }
        }
    }
}
