import { Component, EventEmitter, Input, Output, OnInit, OnChanges, Inject, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReplacementVehicle } from '../../../../interfaces/replacement-vehicle.interface';
import { AuthorizationService } from '../../../../services/authorization.service';
import { VehicleService } from '../../../../ajs-upgraded-providers';

@Component({
    selector: 'aa-on-demand-offers',
    templateUrl: './on-demand-offers.component-ng.html',
    styleUrls: ['./more-vehicle-offers.component.scss']
})
export class OnDemandOffersComponent implements OnInit, OnChanges {

    // define input bindings
    @Input() selectedVehicle: { selectedVehicleType: string, alternateVehicleIndex: number, onDemandVehicle: ReplacementVehicle };
    @Input() dealerGroupMakes: Array<any>;
    @Input() opportunityId: string;

    // define eventOUT signature
    @Output() select = new EventEmitter<{}>();
    vehicleImageNotAvailableUrl = require('../../../../images/vehicle-image-not-available.png');
    selectedVehicleObject: ReplacementVehicle;
    hasPaymentsPermission: boolean;
    alternateVehicles: Array<ReplacementVehicle>;
    offBrandHasBeenCalledForEntity: boolean;
    selectedMakeId: any;
    noRecommendationsLabel: string;
    showOffBrandDropSelect: boolean;
    loadingText: string;
    noRecommendationsText: string;

    constructor(private authorizationService: AuthorizationService, @Inject(VehicleService) private vehicleService, private translate: TranslateService) {
        this.selectedVehicle = { selectedVehicleType: 'standardVehicle', alternateVehicleIndex: 0, onDemandVehicle: null };
        this.offBrandHasBeenCalledForEntity = false;
        this.showOffBrandDropSelect = false;
        this.noRecommendationsLabel = '';
        translate.get('loading').subscribe(translation => { this.loadingText = translation });
        translate.get('onDemandRecommendations').subscribe(translation => { this.noRecommendationsText = translation });
    }

    ngOnInit(): void {
        this.authorizationService.getDealSheetPermissions()
            .then(dealSheetPermissions => {
                this.hasPaymentsPermission = dealSheetPermissions.payments;
            });
    }

    ngOnChanges(changes:SimpleChanges): void {
        if (changes.dealerGroupMakes) {
            this.selectedMakeId = "";
        }
    }

    public toggleOnDemandDropSelect() {
        var onDemandDiv = document.getElementById('onDemandOffBrandListToggleWrapper');
        if (this.alternateVehicles) {
            if (onDemandDiv.classList.contains('open')) {
                //logic looks backward but the classList is updated after this event
                this.showOffBrandDropSelect = false;
            }
            else {
                this.showOffBrandDropSelect = true;
            }
        }
        else {
            onDemandDiv.classList.add('open'); //hack to keep the empty list from showing
            this.showOffBrandDropSelect = !this.showOffBrandDropSelect;
            if (!this.showOffBrandDropSelect) {
                this.selectedMakeId = "";
                this.noRecommendationsLabel = '';
            }
        }
    }

    public onMakeSelect() {
        if (this.selectedMakeId && this.selectedMakeId > 0) {
            this.noRecommendationsLabel = this.loadingText;
            this.vehicleService.getOnDemandOffBrandVehicles(this.opportunityId, [this.selectedMakeId])
                .then((replacementVehicles) => {
                    if (replacementVehicles && replacementVehicles.offBrandAlternateVehicles) {
                        this.noRecommendationsLabel = '';
                        this.alternateVehicles = replacementVehicles.offBrandAlternateVehicles;
                        document.getElementById('onDemandOffBrandListToggleWrapper').classList.add('open');
                    }
                    else {
                        this.noRecommendationsLabel = this.noRecommendationsText;
                    }
                })
        }
    }

    clickHandler(chosenVehicleType: string, alternateVehicleIndex: number, vehicle: ReplacementVehicle): void {
        this.selectedVehicle = { selectedVehicleType: 'onDemandVehicle', alternateVehicleIndex: alternateVehicleIndex, onDemandVehicle: vehicle };
        this.select.emit(this.selectedVehicle);
        this.selectedVehicleObject = vehicle;
    }

    // on error of image src load default fallback image
    updateUrl(event, vehicle: ReplacementVehicle) {
        vehicle.imagePath = this.vehicleImageNotAvailableUrl;
    }

    getSelectedAttribute(vehicleType: string, index: number = 0): boolean {

        if (this.selectedVehicle &&
            this.selectedVehicle.selectedVehicleType === vehicleType) {
            const selectedIndex = this.selectedVehicle.alternateVehicleIndex;
            if (selectedIndex !== undefined && selectedIndex !== null) {
                return (this.selectedVehicle.alternateVehicleIndex === index);
            }
            return true;
        }

        return false;
    }

    getContainerClass(): string {
        return 'row mvos-more-container1';
    }
}
