import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemplateService } from '../../../ajs-upgraded-providers';
import { Subscription, from } from 'rxjs';
import { AdminTemplatesEditModalComponent } from './admin-templates-edit-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DealerUpgradeProposalSettingClientService, DealerSmtpSettingClientService } from '../../../generated/services'
import { DealerUpgradeProposalSettingDto, DealerSmtpSettingCustomDto } from '../../../generated/models';
import { ToasterLoggerService } from '../../../core/toaster-logger.service';
import { SMTPSettingEnum } from '../../../interfaces/settings.interface';
import { DisplayModalComponent } from '../../modals/display-modal/display-modal.component';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { DealerService } from '../../../services/dealer.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ConfirmModalComponent } from '../../modals/confirm-modal.component';
import { StorageService } from '../../../modules/storage/storage.service';

@Component({
    selector: 'aa-admin-templates',
    templateUrl: './admin-templates.component-ng.html',
    styleUrls: ['./admin-templates.component.scss']
})

export class AdminTemplatesComponent implements OnChanges, OnInit, OnDestroy {
    public marketingMessageTextMaxLength = 255;
    public marketingMessageTextValue = '';
    public readOnly = false;
    public addConfidentialCoverPage: boolean = false;
    public type: SMTPSettingEnum;
    
    public SMTPForm: FormGroup;
    public submitted: boolean = false;
    public success: boolean = false;
    public dealers: Array<any>;
    public selectedDealerId: number;
    public marketingMessageId: number;
    private subscriptionAggregator = new Subscription();
    public busy: Subscription;
    public busy2: Subscription;
    public busy3: Subscription;
    public dealerTemplates: any;
    private bsModalRef: BsModalRef;
    private defaultMarketingMessage: string;
    private systemUserName: string;
    public pageSize = 50;
    public skip = 0;
    public tab1: boolean = true;
    public tab2: boolean = false;
    public tab3: boolean = false;
    public gridView: GridDataResult;    
    private tempDealerSmtpSettingCustomDto = {} as DealerSmtpSettingCustomDto;

    get getSMTPSettingEnum() { return SMTPSettingEnum; }


    constructor(private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private dealerUpgradeProposalSettingClientService: DealerUpgradeProposalSettingClientService,
        private dealerSmtpSettingClientService: DealerSmtpSettingClientService,
        public logger: ToasterLoggerService,
        private dealerService: DealerService,
        private storageService: StorageService,
        @Inject(TemplateService) private templateService: any,
    ) {
        // Adding validators
        this.SMTPForm = this.formBuilder.group({
            SMTPHost: ['', Validators.required],
            port: ['', [Validators.required, Validators.maxLength(5)]],
            useSSL: [''],
            username: ['', Validators.required],
            password: [''],
        })
    }

    ngOnInit(): void {
        this.dealers = this.dealerService.getSelectedDealers();
        var dealerId = this.storageService.getItem('selectedDealerId');
        this.selectedDealerId = dealerId > 0 ? dealerId : this.dealers[0].id;
        this.defaultMarketingMessage = this.translateService.instant("goalMarketingMessage");
        this.systemUserName = this.translateService.instant("globalAdmin");
        this.loadData();
    }

    ngOnDestroy(): void {
        this.subscriptionAggregator.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    public pageChange({ skip, take }: PageChangeEvent): void {        
        this.skip = skip;
        this.pageSize = take;
        this.dealerTemplates = orderBy(this.dealerTemplates, this.sort);
        this.gridView = {
            data: this.dealerTemplates.slice(this.skip, this.skip + this.pageSize),
            total: this.dealerTemplates.length
        };
    }

    loadData(): void {
        this.busy = this.getTemplates();
        this.getMarketingMessage();
        this.getDealerSmtpSettings();
        this.subscriptionAggregator.add(this.busy);
    }

    calculateLength() {
        return this.marketingMessageTextMaxLength - ((this.marketingMessageTextValue) ? this.marketingMessageTextValue.length : 0)
    }

    getDealerSmtpSettings(): Subscription {
        return this.dealerSmtpSettingClientService.GetDealerSmtpSettingsGET(this.selectedDealerId)
            .subscribe((data: DealerSmtpSettingCustomDto | null) => {                
                if (data != null) {
                    this.type = data.smtpMethod;
                    this.tempDealerSmtpSettingCustomDto.customHost = data.customHost;
                    this.tempDealerSmtpSettingCustomDto.customPort = data.customPort;
                    this.tempDealerSmtpSettingCustomDto.customUsername = data.customUsername;
                    this.tempDealerSmtpSettingCustomDto.customUseSsl = data.customUseSsl;
                    this.tempDealerSmtpSettingCustomDto.dealerID = data.dealerID;
                    this.tempDealerSmtpSettingCustomDto.smtpMethod = data.smtpMethod;

                    if (data.smtpMethod == SMTPSettingEnum.custom) {
                        this.SMTPForm.patchValue({
                            SMTPHost: this.tempDealerSmtpSettingCustomDto.customHost,
                            port: this.tempDealerSmtpSettingCustomDto.customPort,
                            username: this.tempDealerSmtpSettingCustomDto.customUsername,
                            useSSL: this.tempDealerSmtpSettingCustomDto.customUseSsl
                        });
                    }

                }
            });

    }


    getMarketingMessage(): Subscription {
        return this.dealerUpgradeProposalSettingClientService.GetDealerUpgradeProposalSettingByDealeridGET(this.selectedDealerId)
            .subscribe((data: DealerUpgradeProposalSettingDto | null)=> {                
                if (data != null) {
                    this.marketingMessageId = data.id;
                    this.marketingMessageTextValue = data.marketingMessage;
                    this.addConfidentialCoverPage = data.addConfidentialCoverPage;
                }
                else {
                    this.marketingMessageId = undefined;
                    this.marketingMessageTextValue = "";
                    this.addConfidentialCoverPage = false;
                }
            });
    }

    getTemplates(): Subscription {
        return from(this.templateService.getNonEmptyTemplatesForDealerId(this.selectedDealerId))
            .subscribe((templateData: Array<any>) => {
                this.dealerTemplates = templateData;

                this.dealerTemplates.forEach(t => {
                        if (t.isSystemTemplate) {
                            t.createdByFullName = this.systemUserName;
                        }
                    });

                this.dealerTemplates = orderBy(this.dealerTemplates, this.sort);
                this.gridView = {
                    data: this.dealerTemplates.slice(this.skip, this.skip + this.pageSize),
                    total: this.dealerTemplates.length
                };
                this.tab1 = true;
                this.tab2 = false;
                this.tab3 = false;
            });
    }

    public sortChange(sort: SortDescriptor[]): void {        
        if (sort[0].field == this.sort[0].field) {
            sort[0].dir = (this.sort[0].dir == "asc") ? "desc" : "asc";
        }        
        this.sort = sort;
        this.dealerTemplates = orderBy(this.dealerTemplates, this.sort);
        this.gridView = {
            data: this.dealerTemplates.slice(this.skip, this.skip + this.pageSize),
            total: this.dealerTemplates.length
        };
    }

    public sort: SortDescriptor[] = [{
        field: 'templateName',
        dir: 'asc'
    }];


    onSuccess(): void {
        this.logger.success(this.translateService.instant("changesSaved"));
    }

    refreshTemplates(showSuccess: boolean): void {
        this.busy = this.getTemplates();
        this.subscriptionAggregator.add(this.busy);
        if (showSuccess) this.onSuccess();
    }


    onDealerSelect(): void {     
        this.storageService.setItem("selectedDealerId", this.selectedDealerId);
        this.loadData();
    }

    onDeleteSuccess(): void {
        this.refreshTemplates(true);
    }

    deleteTemplate(id: number): void {
        const initialState = {
            saveWarningMessage: false,
            messageKey: "templateDeleteConfirmMessage"
        };

        this.bsModalRef = this.modalService.show(ConfirmModalComponent, { initialState });

        this.bsModalRef.content.onClosed.subscribe((result: any) => {
            if (result.confirmed) {
                from(this.templateService.deleteTemplate(id))
                    .subscribe(() => this.onDeleteSuccess());
            }
        });
    }

    
    pdfPreviewTemplate(id: number): void {        
        this.busy = from(this.templateService.getTemplatePdfPreview(id, this.selectedDealerId))
                .subscribe();

        this.subscriptionAggregator.add(this.busy);
    }

    emailPreviewTemplate(id: number): void {
        this.busy = from(this.templateService.getTemplateEmailPreview(id, this.selectedDealerId))
            .subscribe(templateData => {
                
                const initialState = { saveWarningMessage: false, htmlContent: templateData };
                this.bsModalRef = this.modalService.show(DisplayModalComponent, { initialState: initialState, class: 'modal-lg' });
                this.bsModalRef.content.onClosed.subscribe((result: any) => {

                });

            });

        this.subscriptionAggregator.add(this.busy);
    }


    onCreateNewTemplate(): void {
        const initialState = { id: 0, dealerId: this.selectedDealerId };

        this.bsModalRef = this.modalService.show(AdminTemplatesEditModalComponent, { initialState: initialState, class: 'modal-lg' });

        this.bsModalRef.content.saveChanges.subscribe((result: any) => {
            this.refreshTemplates(false);
        });
    }


    editTemplate(id: number): void {
        const initialState = { id: id, dealerId: this.selectedDealerId };        

        this.bsModalRef = this.modalService.show(AdminTemplatesEditModalComponent, { initialState: initialState, class: 'modal-lg' });

        this.bsModalRef.content.saveChanges.subscribe((result: any) => {
            this.refreshTemplates(false);
         });
    }

    saveMarketingMessage(marketingMessage: string) {
        if (marketingMessage != null) {
            if (marketingMessage.replace(/\s/g, '').length === 0) marketingMessage = this.defaultMarketingMessage;            

            this.busy3 = this.dealerUpgradeProposalSettingClientService.SaveDealerUpgradeProposalSettingPOST({
                id: this.marketingMessageId,
                dealerId: this.selectedDealerId,
                marketingMessage: marketingMessage,
                addConfidentialCoverPage: this.addConfidentialCoverPage
            })
                .subscribe(() => this.onSuccess());

            this.subscriptionAggregator.add(this.busy3);
        }
    }

    onSaveMarketingMessage(marketingMessage: string) {        
        this.saveMarketingMessage(marketingMessage);
    }

    onSaveSMTPSettings(): void {
        this.submitted = true;

        if (this.type == SMTPSettingEnum.custom && this.SMTPForm.invalid) {            
            return;
        }
        
        if (this.type == SMTPSettingEnum.none || this.type == SMTPSettingEnum.autoAlert) {                                                            
            this.tempDealerSmtpSettingCustomDto.smtpMethod = this.type;
            this.busy2 = this.dealerSmtpSettingClientService.SaveDealerSmtpSettingsPOST(this.tempDealerSmtpSettingCustomDto).subscribe(() => this.onSuccess());
            this.subscriptionAggregator.add(this.busy2);
        }
        else {

            this.tempDealerSmtpSettingCustomDto.smtpMethod = this.type;
            this.tempDealerSmtpSettingCustomDto.customHost = this.SMTPForm.get('SMTPHost').value;
            this.tempDealerSmtpSettingCustomDto.customPort = this.SMTPForm.get('port').value;
            this.tempDealerSmtpSettingCustomDto.customUsername = this.SMTPForm.get('username').value;
            this.tempDealerSmtpSettingCustomDto.customUseSsl = this.SMTPForm.get('useSSL').value;              
            this.tempDealerSmtpSettingCustomDto.customPassword = this.SMTPForm.get('password').value;                                  

            this.busy2 = this.dealerSmtpSettingClientService.SaveDealerSmtpSettingsPOST(this.tempDealerSmtpSettingCustomDto).subscribe(() => this.onSuccess());
            this.subscriptionAggregator.add(this.busy2);
        }
                                
        this.success = true;
    }

    selectType(type: SMTPSettingEnum.none | SMTPSettingEnum.autoAlert | SMTPSettingEnum.custom): void {
        this.type = type;
    }

}