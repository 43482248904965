
/* injects from baggage-loader */
require('./wish-list.component.html');

import angular from 'angular';
import './wish-list.component.scss';
import BusyIndicator from '../../utilities/BusyIndicator';
import eyeIconPath from '../../images/icon-eye-lake.svg';
import $ from 'jquery';

angular.module('AaApp.Opp').component('wishList', {
    templateUrl: '/app/components/wish-list/wish-list.component.html',
    controller: WishListController,
    bindings: {
        kendo: '<',
    }
});

function WishListController(
    $translate,
    $q,
    vehicleService,
    dealerService,
    userService,
    dataEntryService,
    storageService,
    $uibModal,
    $document,
    cultures,
    environmentVariables,
    logger,
    $timeout,
    modules,
    $filter,
    _
) {
    "ngInject";
    const $ctrl = this;

    $ctrl.$onInit = init;
    $ctrl.onDealerSelect = onDealerSelect;
    $ctrl.busyIndicator = new BusyIndicator();
    $ctrl.setKendoCulture = setKendoCulture;
    $ctrl.loadWishLists = loadWishLists;
    $ctrl.loadDetails = loadDetails;
    $ctrl.getTranslations = getTranslations;
    $ctrl.dealerService = dealerService;
    $ctrl.vehicleService = vehicleService;
    $ctrl.environmentVariables = environmentVariables;
    $ctrl.displayTrimList = displayTrimList;
    $ctrl.displayDistributionList = displayDistributionList;
    $ctrl.saveModalData = saveModalData;
    $ctrl.wishListCriteria;
    $ctrl.cultureName;
    $ctrl.translations = {};
    $ctrl.trimList = [];
    $ctrl.editWishList = editWishList;
    $ctrl.users;
    $ctrl.distributionList;
    $ctrl.makes;

    function init() {
        $ctrl.dealers = dealerService.getSelectedDealers();
        var selectedDealerId = storageService.getItem('selectedDealerId');
        $ctrl.selectedDealerId = selectedDealerId > 0 ? selectedDealerId : $ctrl.dealers[0].id;

        $q.all([
            userService.getUserProfile(),
            $ctrl.getTranslations(),
        ]).then((data) => {
            var userProfile = data[0];
            $ctrl.translations = data[1];

            $ctrl.cultureName = userProfile.cultureName;
            setKendoCulture();
            initGridOptions();
            onDealerSelect();
        });
    }
    
    function busy(translationKey, promise) {  //global
        $ctrl.busyMessage = null;
        $translate(translationKey)
            .then(translation => {
                $ctrl.busyMessage = translation;
            }, err => {
                $ctrl.busyMessage = translationKey;
            });

        const done = $ctrl.busyIndicator.start();
        return promise.finally(done);
    }

    function onDealerSelect(dealerId) {
        if (dealerId > 0) {
            $ctrl.selectedDealerId = dealerId;
            storageService.setItem("selectedDealerId", dealerId);
        } 

        return busy(
            $ctrl.translations.loadingMakeModelTrim,
            $q.all([
                $ctrl.vehicleService.getMakes($ctrl.selectedDealerId, null),
                userService.getUsersExt($ctrl.selectedDealerId),
            ]).then((data) => {
                $ctrl.makes = data[0];
                $ctrl.users = data[1].sort(byFullName);

                return loadWishLists();
            })
        );
    }

    function loadDetails(dealerId, dealerWishListID) {
        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            windowClass: 'wish-list-matches-modal',
            component: 'wishListMatchesModal',
            resolve: {
                title: function () {
                    return $ctrl.translations.wishlistMatchesTitle;
                },
                message: function () {
                    return {
                        dealerWishListID: dealerWishListID,
                        selectedDealerId: dealerId,
                        users: $ctrl.users,
                        kendo: $ctrl.kendo,
                        translations: $ctrl.translations
                    };
                },
                closeButtonVisible: function () {
                    return true;
                },
                submitButtonVisible: function () {
                    return false;
                },
                submitButtonDisabled: function () {
                    return false;
                },
                closeButtonText: function () {
                    return "close"; // translation key
                }
            }
        });
    }

    function loadWishLists() {
        return busy(
            $ctrl.translations.loadingWishlistData,
            dealerService.getDealerWishLists($ctrl.selectedDealerId)
                .then((data) => {
                    $ctrl.wishListData = data;

                    $ctrl.ds = new $ctrl.kendo.data.DataSource({
                        data: $ctrl.wishListData,
                        schema: {
                            model: {
                                id: 'dealerWishListID',
                                fields: {
                                    dealerWishListID: { type: "number", editable: false },
                                    dealerID: { type: "number", editable: false },
                                    expirationDateUtc: { type: "date", editable: false },
                                    active: { type: "boolean", editable: false },
                                    distributionList: { type: "array", editable: false },
                                    createUserID: { type: "number", editable: false },
                                    createUserFullName: { type: "string", editable: false },
                                    modifyUserID: { type: "number", editable: false },
                                    modifyUserFullName: { type: "string", editable: false },
                                    createDateUtc: { type: "date", editable: false },
                                    modifyDateUtc: { type: "date", editable: false },
                                    searchObject: { type: "array", editable: false },
                                }
                            }
                        },
                        sort: { field: "createDateUtc", dir: "desc" },
                        batch: true,
                        pageSize: 20
                    });
                })
        );
    }

    function saveModalData(modalState) {
        dealerService.saveDealerWishList(modalState)
            .then(function () {
                logger.success("changesSaved");
                onDealerSelect($ctrl.selectedDealerId);
            });
    }

    function editWishList(dealerWishlistID) {
        var row = $ctrl.ds.get(dealerWishlistID);

        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            component: 'editWishListModal',
            size: 'lg',
            resolve: {
                title: function () {
                    return dealerWishlistID != null ? $ctrl.translations.editWishlist : $ctrl.translations.addVehicleToWishlist;
                },
                message: function () {
                    return {
                        row: row,
                        selectedDealerId: $ctrl.selectedDealerId,
                        users: $ctrl.users,
                        save: $ctrl.saveModalData,
                        translations: $ctrl.translations
                    };
                },
                submitButtonVisible: function () {
                    return true;
                },
                submitButtonDisabled: function () {
                    return true;
                },
                submitButtonText: function () {
                    return dealerWishlistID != null ? $ctrl.translations.saveChanges : $ctrl.translations.addToWishlist;
                }
            }
        });
    }

    function initGridOptions() {
        $ctrl.gridOptions = {
            dataSource: $ctrl.ds,
            pageable: true,
            height: 500,
            columns: [
                {
                    field: "makeName",
                    title: $ctrl.translations.make,
                    template: '{{dataItem.searchObject.makeName}}',
                    width: "10%",
                    sortable: {
                        compare: function (a, b) {
                            var first = a.searchObject.makeName;
                            var second = b.searchObject.makeName;
                            first = first == null ? first = "ZZZZ" : first;
                            second = second == null ? second = "ZZZZ" : second;
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    },
                },
                {
                    field: "modelName",
                    title: $ctrl.translations.model,
                    template: '{{dataItem.searchObject.modelName}}',
                    width: "10%",
                    sortable: {
                        compare: function (a, b) {
                            var first = a.searchObject.modelName;
                            var second = b.searchObject.modelName;
                            first = first == null ? first = "ZZZZ" : first;
                            second = second == null ? second = "ZZZZ" : second;
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    },
                },
                {
                    field: "yearRange",
                    title: $ctrl.translations.year,
                    template: yearRangeTemplate,
                    width: "8%",
                    attributes: { style: "text-align: center" },
                    headerAttributes: { "class": "align-center" },
                    sortable: {
                        compare: function (a, b) {
                            var first = a.searchObject.yearMin + "-" + a.searchObject.yearMax;
                            var second = b.searchObject.yearMin + "-" + b.searchObject.yearMax;
                            first = first == null ? first = "ZZZZ" : first;
                            second = second == null ? second = "ZZZZ" : second;
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    },
                },
                {
                    field: "trimList",
                    title: $ctrl.translations.trims,
                    template: trimListTemplate,
                    width: "13%",
                    attributes: { style: "font-size: 11pt" },
                    sortable: false,
                    sortable: {
                        compare: function (a, b) {
                            var trims_a = "";
                            var trims_b = "";
                            if (a.searchObject.trimList && a.searchObject.trimList.length > 0) {
                                var uniqueTrimsByNamea = $filter('unique')(a.searchObject.trimList, 'trimName');
                                var trims_a = uniqueTrimsByNamea.map(t => t.trimName).sort();
                            }
                            if (b.searchObject.trimList && b.searchObject.trimList.length > 0) {
                                var uniqueTrimsByNameb = $filter('unique')(b.searchObject.trimList, 'trimName');
                                var trims_b = uniqueTrimsByNameb.map(t => t.trimName).sort();
                            }

                            var first = trims_a;
                            var second = trims_b;
                            first = first == null ? first = "ZZZZ" : first;
                            second = second == null ? second = "ZZZZ" : second;
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    },
                },
                {
                    field: "odometerRange",
                    title: $ctrl.translations.odometer,
                    template: odometorRangeTemplate,
                    width: "8%",
                    sortable: {
                        compare: byOdometerRange,
                    },
                },
                { field: 
                    "createUserFullName",
                    title: $ctrl.translations.createdBy,
                    width: "12%",
                    sortable: true,
                },
                {
                    field: "createDateUtc",
                    title: $ctrl.translations.lblDateCreated,
                    format: "{0:d}",
                    width: "8%",
                    sortable: true,
                },
                {
                    field: "expirationDate",
                    title: $ctrl.translations.expireOn,
                    template: expirationDateTemplate,
                    format: "{0:d}",
                    width: "7%",
                    sortable: {
                        compare: function (a, b) {
                            var first = a.expirationDateUtc ? a.expirationDateUtc : '';
                            var second = b.expirationDateUtc ? b.expirationDateUtc : '';
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    },
                },
                {
                    field: "active",
                    title: $ctrl.translations.wishlistActiveLabel,
                    template: displayYesNo,
                    width: "4%",
                    sortable: {
                        compare: function (a, b) {
                            var first = displayYesNo(a);
                            var second = displayYesNo(b);
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    },
                },
                {
                    field: "notifyList",
                    title: $ctrl.translations.notify,
                    template: notifyTemplate,
                    width: "6%",
                    sortable: false,
                    attributes: { style: "text-align: center" },
                    headerAttributes: { "class": "align-center" }
                },
                {
                    field: "editWishList",
                    title: $ctrl.translations.edit,
                    template: editWishListTemplate,
                    width: "4%",
                    sortable: false,
                    headerAttributes: { "class": "align-center" }
                },
                {
                    field: "wishListResults",
                    title: $ctrl.translations.matches,
                    template: detailLinkTemplate,
                    width: "6%",
                    sortable: false,
                    attributes: { style: "text-align: center" },
                    headerAttributes: { "class": "align-center" }
                },
            ],
            //detailTemplate: kendo.template($("#detail-template").html()),
            //detailInit: renderDetailsInnerGrid,
            editable: false,
            sortable: {
                mode: "single",
                allowUnsort: false
            },
            resizable: true,
            reorderable: true,
            filterable: false
        }

    }

    //function renderDetailsInnerGrid(e) {
    //    dealerService.GetDealerWishListLogs(e.data.dealerID, e.data.dealerWishListID, 50)
    //        .then((data) => {
    //            $ctrl.wishListMatches = data;
    //            var config = {
    //                dataSource: {
    //                    data: $ctrl.wishListMatches,
    //                    schema: {
    //                        model: {
    //                            id: 'dealerWishlistLogID',
    //                            fields: {
    //                                dealerWishlistLogID: { type: "number", editable: false },
    //                                dealerWishlistID: { type: "number", editable: false },
    //                                dealerID: { type: "number", editable: false },
    //                                serviceID: { type: "number", editable: false },
    //                                alerts: { type: "array", editable: false },
    //                                opportunityRank: { type: "number", editable: false },
    //                                resultDateUtc: { type: "date", editable: false },
    //                                distributionSent: { type: "array", editable: false },
    //                                notes: { type: "string", editable: false },
    //                                customerFullName: { type: "string", editable: false },
    //                                vin: { type: "string", editable: false },
    //                                yearID: { type: "number", editable: false },
    //                                odometer: { type: "number", editable: false },
    //                                trimName: { type: "string", editable: false },
    //                                rO_ID: { type: "string", editable: false },
    //                                distributionSent: { type: "array", editable: false },
    //                            }
    //                        }
    //                    },
    //                    batch: true,
    //                    pageSize: 20,
    //                    sort: {
    //                        field: "resultDateUtc",
    //                        dir: "desc"
    //                    }
    //                },
    //                pageable: true,
    //                //height: 400,
    //                columns: [
    //                    {
    //                        field: "opportunityRank",
    //                        title: "Score",
    //                        template: '<span class="bold">#: opportunityRank #</span>',
    //                        width: 12,
    //                        sortable: true
    //                    },
    //                    {
    //                        field: "customerFullName",
    //                        title: "Customer",
    //                        width: 30,
    //                        sortable: true,
    //                    },
    //                    {
    //                        field: "alerts",
    //                        title: "Alerts",
    //                        width: 30,
    //                        sortable: true,
    //                    },
    //                    {
    //                        field: "vin",
    //                        title: "VIN",
    //                        width: 40,
    //                        sortable: true,
    //                    },
    //                    {
    //                        field: "yearID",
    //                        title: "Year",
    //                        width: 15,
    //                        sortable: true,
    //                    },
    //                    {
    //                        field: "odometer",
    //                        title: "Odometer",
    //                        width: 18,
    //                        sortable: true,
    //                    },
    //                    {
    //                        field: "trimName",
    //                        title: "Trim",
    //                        width: 30,
    //                        sortable: true,
    //                    },
    //                    {
    //                        field: "rO_ID",
    //                        title: "Service RO",
    //                        width: 20,
    //                        sortable: true,
    //                    },
    //                    {
    //                        field: "resultDateUtc",
    //                        title: "Date/Time",
    //                        format: "{0:MM-dd-yyyy hh:mm tt}",
    //                        width: 35,
    //                        sortable: true,
    //                    },
    //                    //{
    //                    //    field: "notes",
    //                    //    title: "Notes",
    //                    //    width: 40,
    //                    //    sortable: true,
    //                    //},
    //                    {
    //                        field: "distributionSent",
    //                        title: "Sent To",
    //                        template: notifyTemplate,
    //                        width: 12,
    //                        sortable: false,
    //                    },
    //                ],
    //                editable: false,
    //                sortable: {
    //                    mode: "single",
    //                    allowUnsort: false
    //                },
    //                resizable: true,
    //                reorderable: true,
    //                filterable: false
    //            };
    //            $("<div class='inner-grid'/>").appendTo(e.detailCell).kendoGrid(config);

    //        });

    //}

    function byOdometerRange(o1, o2) {
        if (o1.searchObject.odometerMin == o2.searchObject.odometerMin) {
            if (o1.searchObject.odometerMax < o2.searchObject.odometerMax)
                return -1;
            if (o1.searchObject.odometerMax > o2.searchObject.odometerMax)
                return 1;
            return 0;
        }
        else {
            if (o1.searchObject.odometerMin < o2.searchObject.odometerMin)
                return -1;
            if (o1.searchObject.odometerMin > o2.searchObject.odometerMin)
                return 1;
            return 0;
        }
    }

    function byFullName(object1, object2) {
        var fullName1 = object1.fullName.toLowerCase();
        var fullName2 = object2.fullName.toLowerCase();
        if (fullName1 < fullName2)
            return -1;
        if (fullName1 > fullName2)
            return 1;
        return 0;
    }

    function displayTrimList(dealerWishListID) {
        var row = $ctrl.ds.get(dealerWishListID);
        $ctrl.trimList = row.searchObject.trimList.map(t => t.trimName).sort();
    }

    function displayDistributionList(dealerWishListID) {
        var row = $ctrl.ds.get(dealerWishListID);

        $ctrl.distributionList = row.distributionList.map(d => ({
            userID: d,
            fullName: "",
            emailAddress: ""
        }));
        
        for (let user of $ctrl.users) {
            for (let distUser of $ctrl.distributionList) {
                if (user.userID === distUser.userID) {
                    distUser.fullName = user.fullName;
                    distUser.emailAddress = user.email;
                }
            }
        }

        $ctrl.distributionList.sort(byFullName);
    }

    function detailLinkTemplate(e) {
        var eyeball = "<a ng-click='$ctrl.loadDetails(" + e.dealerID + ", " + e.dealerWishListID + ")' style=\"cursor: pointer; height: 25px;\">{{$ctrl.translations.view}}</a>";
        return eyeball;
    }

    function yearRangeTemplate(e) {
        return "<span>" + e.searchObject.yearMin + " - " + e.searchObject.yearMax + "</span>";
    }

    function odometorRangeTemplate(e) {
        return "<span>" + kendo.toString(e.searchObject.odometerMin, "n0") + " - " + kendo.toString(e.searchObject.odometerMax, "n0") + "</span>";
    }

    function trimListTemplate(e) {
        if (!e.searchObject.trimList || e.searchObject.trimList.length == 0)
            return "";

        var uniqueTrimsByName = $filter('unique')(e.searchObject.trimList, 'trimName');
        var trims = uniqueTrimsByName.map(t => t.trimName).sort();

        if (uniqueTrimsByName.length > 2) {
            var trimString = trims.slice(0, 2).join(', ');
            var trimLink = "<div ng-click='$ctrl.displayTrimList(" + e.dealerWishListID + ")' style=\"cursor: pointer; width: 25px;display:inline;\" title=\"{{$ctrl.translations.wishlistFullListTooltip}}\" uib-popover-template=\"'/app/components/wish-list/wish-list-trim-list.component.html'\" popover-append-to-body='true' popover-placement=\"auto right-center\" popover-trigger=\"'outsideClick'\" popover-popup-close-delay=\"1\" popover-class=\"wishlist-popover\"><span>" + trimString + " ...</span></div>";
            return trimLink;
        }
        else {
            var trimString = trims.join(', ');
            return trimString;
        }

    }

    function notifyTemplate(e) {
        var eyeball = "<div align='center' ng-click='$ctrl.displayDistributionList(" + e.dealerWishListID + ")' style=\"cursor: pointer; width: 25px;display:inline;\" title=\"{{$ctrl.translations.wishlistFullListTooltip}}\" uib-popover-template=\"'/app/components/wish-list/wish-list-distr-list.component.html'\" popover-append-to-body='true' popover-placement=\"auto left-center\" popover-trigger=\"'outsideClick'\" popover-popup-close-delay=\"1\" popover-class=\"wishlist-popover\"><img class='svg-icon xsm' src='" + eyeIconPath + "' /></div>";
        return eyeball;
    }

    function expirationDateTemplate(e) {
        var expirationDate = e.expirationDateUtc ? e.expirationDateUtc : '';
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        if (expirationDate) {
            var localeDateFilter = $filter('localeDate');
            var localeExpirationDate = localeDateFilter(expirationDate);
            if (expirationDate <= today)
                return "<span class='expire-date'>" + localeExpirationDate + "</span>";
            else
                return "<span>" + localeExpirationDate + "</span>";
        }
        else {
            return "";
        }
    }

    function displayYesNo(e) {
        return e.active ? $ctrl.translations.yes : $ctrl.translations.searchNo;
    }

    function editWishListTemplate(e) {
        var template = "<div align='center'><icon style='cursor: pointer'  ng-click='$ctrl.editWishList(";
        template += e.dealerWishListID;
        template += ")'class='icon aa-icon-core-edit-icon-000000'></icon></div>";
        return template;
    }

    function setKendoCulture() {
        switch ($ctrl.cultureName) {
            case cultures.enUS:
            case cultures.enGB:
            case cultures.enCA:
            case cultures.frCA:
            case cultures.frFR:
            case cultures.esUS:
                $ctrl.kendo.culture($ctrl.cultureName);
                break;
            default:
                $ctrl.kendo.culture(cultures.enUS);
                break;
        }
    }

    function getTranslations() {
        return $q.all({
            'loadingMakeModelTrim': $translate('loadingMakeModelTrim'),
            'edit': $translate('edit'),
            'saveChanges': $translate('saveChanges'),
            'inventoryWishlist': $translate('inventoryWishlist'),
            'dealerships': $translate('dealerships'),
            'make': $translate('make'),
            'model': $translate('model'),
            'year': $translate('year'),
            'trims': $translate('trims'),
            'createdBy': $translate('createdBy'),
            'lblDateCreated': $translate('lblDateCreated'),
            'expireOn': $translate('expireOn'),
            'notify': $translate('notify'),
            'matches': $translate('matches'),
            'view': $translate('view'),
            'yes': $translate('yes'),
            'searchNo': $translate('searchNo'),
            'addVehicle': $translate('addVehicle'),
            'addToWishlist': $translate('addToWishlist'),
            'addVehicleToWishlist': $translate('addVehicleToWishlist'),
            'chooseOne': $translate('chooseOne'),
            'dateMustBeGreaterToday': $translate('dateMustBeGreaterToday'),
            'editWishlist': $translate('editWishlist'),
            'loadingWishlistData': $translate('loadingWishlistData'),
            'loadingWishlistMatches': $translate('loadingWishlistMatches'),
            'odometer': $translate('odometer'),
            'odometerMinMaxRequired': $translate('odometerMinMaxRequired'),
            'odometerRangeError': $translate('odometerRangeError'),
            'toLabel': $translate('toLabel'),
            'wishlistActiveLabel': $translate('wishlistActiveLabel'),
            'wishlistFullListTooltip': $translate('wishlistFullListTooltip'),
            'wishlistIsActiveLabel': $translate('wishlistIsActiveLabel'),
            'wishlistMatchesTitle': $translate('wishlistMatchesTitle'),
            'wishlistMatchingVehicles': $translate('wishlistMatchingVehicles'),
            'wishlistNotificationExceeds': $translate('wishlistNotificationExceeds'),
            'wishlistNotificationLabel': $translate('wishlistNotificationLabel'),
            'wishlistNotificationReq': $translate('wishlistNotificationReq'),
            'wishlistNotificationTooltip': $translate('wishlistNotificationTooltip'),
            'wishlistRequirements': $translate('wishlistRequirements'),
            'wishlistSelectedTrims': $translate('wishlistSelectedTrims'),
            'wishlistTrimsLabel': $translate('wishlistTrimsLabel'),
            'wishlistTrimExceeds': $translate('wishlistTrimExceeds'),
            'yearsLabel': $translate('yearsLabel'),
            'expirationDate': $translate('expirationDate'),
            'validationRequired': $translate('validationRequired'),
            'faalertletter': $translate('faalertletter'),
            'flexAlertLetter': $translate('flexAlertLetter'),
            'inMarketLetter': $translate('inMarketLetter'),
            'inMarketEngagedLetter': $translate('inMarketEngagedLetter'),
            'contractEndLetter': $translate('contractEndLetter'),
            'serviceLetter': $translate('serviceLetter'),
            'serviceAppointmentLetter': $translate('serviceAppointmentLetter'),
            'mileageLetter': $translate('mileageLetter'),
            'warrantyLetter': $translate('warrantyLetter'),
            'repairOrderNumberColumnLabel': $translate('repairOrderNumberColumnLabel'),
            'dateTime': $translate('dateTime'),
            'sentTo': $translate('sentTo'),
            'alertsLabel': $translate('alertsLabel'),
            'score': $translate('score'),
            'customerLabel': $translate('customerLabel'),
            'vinLabel': $translate('vinLabel'),
            'trim': $translate('trim'),
            'yearRangeError': $translate('yearRangeError'),
        });
    }
}
