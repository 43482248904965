<div class="offer-dropdown">
    <div id="onDemandOffBrandListToggleWrapper" class="dropdown mvos-selector">
        <a class="dropdown-toggle mvos-dropdown" data-toggle="dropdown" href="#" (click)="toggleOnDemandDropSelect()">
            <div class="row mvos1">
                <div [class]="getContainerClass()">
                    <span class="mvos-more">{{'searchOffBrandOffers' | translate}}</span>
                    <div class="mvos-bottom"></div>
                </div>
            </div>
        </a>
        <ul ng-if="alternateVehicles" class="dropdown-menu offercontainer">
            <li *ngFor="let alternateVehicle of alternateVehicles; let idx = index;">
                <a (click)="clickHandler('alternateVehicle', idx, alternateVehicle)"
                   [attr.selected]="getSelectedAttribute('alternateVehicle', idx)">
                    <div class="row mvos">
                        <div class="row">
                            <div class="row mvos-details-container">
                                <div class="col-xs-4 mvos-image">
                                    <img [src]="alternateVehicle.imagePath"
                                         (error)="updateUrl($event,alternateVehicle)"
                                         alt="Alternate Vehicle">
                                </div>
                                <div class="col-xs-8 end">
                                    <div class="row">
                                        <div class="col-xs-7 no-padding">
                                            <div class="mvos-type">
                                                {{'alternate' | translate | uppercase}}
                                                {{(alternateVehicles.length > 1) ? idx + 1 : null}}
                                            </div>
                                        </div>
                                        <payment-difference [vehicle]="alternateVehicle" [hasPaymentsPermission]="hasPaymentsPermission"></payment-difference>
                                    </div>
                                    <span class="mvos-yearmake">{{alternateVehicle.yearID}}</span><span class="mvos-yearmake">{{alternateVehicle.trimFullName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</div>
<div *ngIf="showOffBrandDropSelect === true">
    <label style="font-size:12px;">{{ noRecommendationsLabel }}</label>
    <select id="select-on-demand-off-brand-make" class="form-control offBrandDropSelect" 
            [(ngModel)]="selectedMakeId"
            (change)="onMakeSelect()">
        <option value="" translate="selectReplacementBrand"></option>
        <option *ngFor="let make of dealerGroupMakes" [ngValue]="make.makeId">
            {{ make.makeName }}
        </option>
        </select>
</div>
