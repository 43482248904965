import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.array.find";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.promise.finally";
DataEntryTradeInsController.$inject = ["$filter", "$translate", "$q", "vehicleService", "dealerService", "dataEntryService", "$uibModal", "$document", "logger", "$element", "$window", "storageService", "cultures", "$logFactory"];

/* injects from baggage-loader */
require('./data-entry-trade-ins.component.html');

import angular from 'angular';
import $ from 'jquery';
import './data-entry-trade-ins.component.scss';
import BusyIndicator from '../../utilities/BusyIndicator';
angular.module('AaApp.Opp').component('dataEntryTradeIns', {
  templateUrl: '/app/components/data-entry/data-entry-trade-ins.component.html',
  controller: DataEntryTradeInsController,
  bindings: {
    kendo: '<'
  }
});

function DataEntryTradeInsController($filter, $translate, $q, vehicleService, dealerService, dataEntryService, $uibModal, $document, logger, $element, $window, storageService, cultures, $logFactory) {
  "ngInject";

  var $ctrl = this;
  var localeCurrency = $filter('localeCurrency');
  var log = $logFactory.get();
  var tradeInsSetToOptions = {
    empty: "N",
    weekOne: "1",
    tradeValue: "T",
    overwrite: "O"
  };
  var cultureName = storageService.getItem('cultureName') || 'en-US';
  $ctrl.$onInit = init;
  $ctrl.onDealerSelect = onDealerSelect;
  $ctrl.trimSelections;
  $ctrl.setTrimSelections = setTrimSelections;
  $ctrl.showQuickAction = false;
  $ctrl.onShowQuickAction = onShowQuickAction;
  $ctrl.onHideQuickAction = onHideQuickAction;
  $ctrl.onPerformTradeIn = onPerformTradeIn;
  $ctrl.onClearTrade = onClearTrade;
  $ctrl.onPerformExpireDate = onPerformExpireDate;
  $ctrl.onClearExpireDate = onClearExpireDate;
  $ctrl.onSave = onSave;
  $ctrl.onCancel = onCancel;
  $ctrl.kendoGrid = null;
  $ctrl.busyIndicator = new BusyIndicator();
  $ctrl.extTradeValueSourceID = 0;
  $ctrl.weekColumns = {};
  $ctrl.screenWidth = 0;
  $ctrl.enableQuickAction = true;
  $ctrl.minPrice = 1000;
  $ctrl.maxPrice = 500000;
  $ctrl.tradeInsSetTo = tradeInsSetToOptions.empty;
  $ctrl.tradeInsPerformError = false;

  function init() {
    $ctrl.dealers = dealerService.getSelectedDealers();
    var selectedDealerId = storageService.getItem('selectedDealerId');
    $ctrl.selectedDealerId = selectedDealerId > 0 ? selectedDealerId : $ctrl.dealers[0].id;
    $ctrl.dateOptions = {
      minDate: new Date(),
      startingDay: 0,
      showWeeks: false
    };
    getTranslations();
    initGridOptions();
    onDealerSelect(); // $window can be injected into the controller

    $($window).on('resize', resizeHandler); // The $onDestroy method is called automatically by AngularJS when the component is being unloaded

    $ctrl.$onDestroy = function () {
      $($window).off('resize', resizeHandler);
    };
  }

  function resizeHandler() {
    $ctrl.screenWidth = null;
    setColumnWidths();
  }

  function getTranslations() {
    $q.all({
      'currencySymbol': $translate('currencySymbol'),
      'validationErrorMessage': $translate('validationErrorMessage')
    }).then(function (stuff) {
      $ctrl.currencySymbol = stuff.currencySymbol;
      $ctrl.validationErrorMessage = stuff.validationErrorMessage;
    });
  }

  function busy(translationKey, promise) {
    $ctrl.busyMessage = null;
    $translate(translationKey).then(function (translation) {
      $ctrl.busyMessage = translation;
    }, function (err) {
      $ctrl.busyMessage = translationKey;
    });
    var done = $ctrl.busyIndicator.start();
    return promise.finally(done);
  }

  function onDealerSelect(previousValue) {
    storageService.setItem("selectedDealerId", $ctrl.selectedDealerId);

    if (isDataDirty()) {
      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "pleaseConfirm";
          },
          message: function message() {
            return $translate('saveWarningMessage');
          },
          submitButtonVisible: function submitButtonVisible() {
            return true;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          submitButtonText: function submitButtonText() {
            return "continue"; // translation key
          }
        }
      });
      modal.result.then(function () {
        return busy("Loading Trade-ins", $q.all([vehicleService.getMakes($ctrl.selectedDealerId, null), vehicleService.getYearIDs($ctrl.selectedDealerId, false, 255)]).then(function (data) {
          $ctrl.selectedMakeId = data[0][0].makeID;
          $ctrl.selectedYearId = data[1][0];
          $ctrl.defaults = {
            vehicleTypes: 0,
            makeID: data[0][0].makeID,
            makeName: data[0][0].makeName,
            yearID: data[1][0],
            modelID: null,
            modelName: null,
            trimID: null,
            trimName: null
          };
          $ctrl.trimSelections = $ctrl.defaults;
          return loadTradeIns();
        }));
      }).catch(function () {
        $ctrl.selectedDealerId = previousValue;
        return false;
      });
    } else return busy("Loading Trade-ins", $q.all([vehicleService.getMakes($ctrl.selectedDealerId, null), vehicleService.getYearIDs($ctrl.selectedDealerId, false, 255)]).then(function (data) {
      $ctrl.selectedMakeId = data[0][0].makeID;
      $ctrl.selectedYearId = data[1][0];
      $ctrl.defaults = {
        vehicleTypes: 0,
        makeID: data[0][0].makeID,
        makeName: data[0][0].makeName,
        yearID: data[1][0],
        modelID: null,
        modelName: null,
        trimID: null,
        trimName: null
      };
      $ctrl.trimSelections = $ctrl.defaults;
      return loadTradeIns();
    }));
  }

  function onShowQuickAction() {
    if (!$ctrl.enableQuickAction) $ctrl.enableQuickAction = true;
    $ctrl.showQuickAction = true;
    if (!$ctrl.tradeInsSetTo) $ctrl.tradeInsSetTo = tradeInsSetToOptions.empty;
    if (!$ctrl.tradeInsAdjMode) $ctrl.tradeInsAdjMode = "%";
  }

  function onHideQuickAction() {
    $ctrl.showQuickAction = false;
  }

  function clearQuickAction() {
    $ctrl.tradeInSetTo = tradeInsSetToOptions.empty;
    $ctrl.priceAdjMode = "%";
    $ctrl.priceAdjBy = null;
    $ctrl.expireSetTo = null;
    $ctrl.expireAdjBy = null; //remove from the DOM so yhe date field will be recreated as there is no easy way set the date to empty

    $ctrl.enableQuickAction = false;
  }

  function onPerformTradeIn() {
    if ($ctrl.tradeInsSetTo && $ctrl.tradeInsSetTo !== tradeInsSetToOptions.empty && ($ctrl.tradeInsAdjBy || $ctrl.tradeInsAdjBy == 0)) {
      var rows = $ctrl.kendoGrid.dataSource.data();
      rows.forEach(function (row) {
        if ($ctrl.tradeInsSetTo && $ctrl.tradeInsSetTo !== tradeInsSetToOptions.empty) {
          if ($ctrl.tradeInsSetTo === tradeInsSetToOptions.weekOne && $ctrl.extTradeValueSourceID === 0) row.dealerTradeValue = row.auctionWeek1;

          if ($ctrl.tradeInsSetTo === tradeInsSetToOptions.tradeValue) {
            if (row.tradeValue) {
              row.dealerTradeValue = row.tradeValue;
            } else {
              row.dealerTradeValue = row.estimated;
            }
          }

          if ($ctrl.tradeInsSetTo === tradeInsSetToOptions.overwrite) row.dealerTradeValue = row.dealerTradeValue;

          if ($ctrl.tradeInsAdjBy) {
            if ($ctrl.tradeInsAdjMode === "%") row.dealerTradeValue = Math.round(row.dealerTradeValue + row.dealerTradeValue * ($ctrl.tradeInsAdjBy / 100));
            if ($ctrl.tradeInsAdjMode === $ctrl.currencySymbol) row.dealerTradeValue = row.dealerTradeValue + $ctrl.tradeInsAdjBy;
          }

          row.dirty = true;
          if (row.dirtyFields) row.dirtyFields.tradeIn = true;else row.dirtyFields = {
            "tradein": true
          };
        } else {
          if (row.dealerTradeValue) {
            if ($ctrl.tradeInsAdjMode === "%") {
              row.dealerTradeValue = row.dealerTradeValue + row.dealerTradeValue * $ctrl.tradeInsAdjBy / 100;
            }

            if ($ctrl.tradeInsAdjMode === $ctrl.currencySymbol) {
              row.dealerTradeValue = row.dealerTradeValue + $ctrl.tradeInsAdjBy;
            }

            row.dirty = true;
            if (row.dirtyFields) row.dirtyFields.dealerTradeValue = true;else row.dirtyFields = {
              "dealerTradeValue": true
            };
          }
        }
      });
      $ctrl.kendoGrid.refresh();
    } else {
      $ctrl.tradeInsPerformError = true;
      var modal1 = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "validationError";
          },
          message: function message() {
            return $ctrl.validationErrorMessage;
          },
          submitButtonVisible: function submitButtonVisible() {
            return false;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          closeButtonVisible: function closeButtonVisible() {
            return true;
          },
          closeButtonDisabled: function closeButtonDisabled() {
            return true;
          },
          closeButtonText: function closeButtonText() {
            return "ok"; // translation key
          }
        }
      });
      return;
    }
  }

  function onClearTrade() {
    var rows = $ctrl.kendoGrid.dataSource.data();
    rows.forEach(function (row) {
      if (row.dealerTradeValue) {
        row.dealerTradeValue = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.dealerTradeValue = true;else row.dirtyFields = {
          "dealerTradeValue": true
        };
      }
    });
    $ctrl.kendoGrid.refresh();
  }

  function onPerformExpireDate() {
    if ($ctrl.expireSetTo || $ctrl.expireAdjBy) {
      var rows = $ctrl.kendoGrid.dataSource.data();
      rows.forEach(function (row) {
        if ($ctrl.expireSetTo) {
          row.dealerTradeExpirationDate = new Date($ctrl.expireSetTo);
          if ($ctrl.expireAdjBy) row.dealerTradeExpirationDate.setDate(row.dealerTradeExpirationDate.getDate() + $ctrl.expireAdjBy);
          row.dirty = true;
          if (row.dirtyFields) row.dirtyFields.dealerTradeExpirationDate = true;else row.dirtyFields = {
            "dealerTradeExpirationDate": true
          };
        } else {
          if ($ctrl.expireAdjBy && row.dealerTradeExpirationDate) {
            row.dealerTradeExpirationDate.setDate(row.dealerTradeExpirationDate.getDate() + $ctrl.expireAdjBy);
            row.dirty = true;
            if (row.dirtyFields) row.dirtyFields.dealerTradeExpirationDate = true;else row.dirtyFields = {
              "dealerValueExpirationDate": true
            };
          }
        }
      });
      $ctrl.kendoGrid.refresh();
    }
  }

  function onClearExpireDate() {
    var rows = $ctrl.kendoGrid.dataSource.data();
    rows.forEach(function (row) {
      if (row.dealerTradeExpirationDate) {
        row.dealerTradeExpirationDate = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.dealerTradeExpirationDate = true;else row.dirtyFields = {
          "dealerTradeExpirationDate": true
        };
      }
    });
    $ctrl.kendoGrid.refresh();
  }

  function onSave() {
    if (!isDataValid()) {
      var modal1 = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "validationError";
          },
          message: function message() {
            return $translate('validationErrorMessage');
          },
          submitButtonVisible: function submitButtonVisible() {
            return false;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          closeButtonVisible: function closeButtonVisible() {
            return true;
          },
          closeButtonDisabled: function closeButtonDisabled() {
            return true;
          },
          closeButtonText: function closeButtonText() {
            return "ok"; // translation key
          }
        }
      });
      return;
    }

    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return "readyToProcess";
        },
        message: function message() {
          return $translate('saveConfirmMessage');
        },
        submitButtonVisible: function submitButtonVisible() {
          return true;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        submitButtonText: function submitButtonText() {
          return "continue"; // translation key
        }
      }
    });
    modal.result.then(function () {
      var rows = $ctrl.kendoGrid.dataSource.data();
      var dirtyRows = [];
      rows.forEach(function (row) {
        if (row.dirty) {
          dirtyRows.push({
            trimID: row.trimID,
            insertDate: row.insertDate,
            dealerID: row.dealerID,
            dealerTradeValue: row.dealerTradeValue,
            dealerTradeExpirationDate: row.dealerTradeExpirationDate
          });
          row.dirty = false;
        }
      });

      if (dirtyRows.length > 0) {
        return updateDealerTradeins(dirtyRows);
      } else {
        logger.success("changesSaved");
        return $q.resolve();
      }
    }).catch(function () {
      return false;
    });
  }

  function onCancel() {
    $ctrl.kendoGrid.cancelChanges();
  }

  function updateDealerTradeins(tradeIns) {
    return busy("Saving Trade-ins", dataEntryService.updateDealerTradeins(tradeIns).then(function (success) {
      logger.success("changesSaved");
      return loadTradeIns();
    }).then(function () {}, function (error) {
      log.log(error);
    }));
  }

  function setTrimSelections(selections) {
    if (isDataDirty()) {
      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "pleaseConfirm";
          },
          message: function message() {
            return $translate('saveWarningMessage');
          },
          submitButtonVisible: function submitButtonVisible() {
            return true;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          submitButtonText: function submitButtonText() {
            return "continue"; // translation key
          }
        }
      });
      modal.result.then(function () {
        $ctrl.trimSelections = selections;
        return loadTradeIns();
      }).catch(function () {
        if (selections.makeID != $ctrl.trimSelections.makeID || selections.yearID != $ctrl.trimSelections.yearID || selections.modelID != $ctrl.trimSelections.modelID || selections.trimID != $ctrl.trimSelections.trimID) {
          $ctrl.defaults = {
            vehicleTypes: 0,
            makeID: $ctrl.trimSelections.makeID,
            makeName: $ctrl.trimSelections.makeName,
            yearID: $ctrl.trimSelections.yearID,
            modelID: $ctrl.trimSelections.modelID,
            modelName: $ctrl.trimSelections.modelName,
            trimID: $ctrl.trimSelections.trimID,
            trimName: $ctrl.trimSelections.trimName
          };
        }

        return;
      });
    } else {
      $ctrl.trimSelections = selections;
      return loadTradeIns();
    }
  }

  function isDataValid() {
    if (!$ctrl.kendoGrid) return true;
    var valid = true;
    var rows = $ctrl.kendoGrid.dataSource.data();
    rows.forEach(function (row) {
      if (row.dealerTradeValue && !row.dealerTradeExpirationDate || !row.dealerTradeValue && row.dealerTradeExpirationDate) {
        log.log(row.dealerTradeValue + "  " + row.dealerTradeExpirationDate);
        valid = false;
      }

      if (row.dealerTradeValue && row.dealerTradeValue < $ctrl.minPrice || row.dealerTradeValue && row.dealerTradeValue > $ctrl.maxPrice) {
        log.log(row.dealerTradeValue + "  " + row.dealerTradeExpirationDate);
        valid = false;
      }
    });
    return valid;
  }

  function isDataDirty() {
    if (!$ctrl.kendoGrid) return false;
    var dirty = false;
    var rows = $ctrl.kendoGrid.dataSource.data();
    rows.forEach(function (row) {
      if (row.dirty) dirty = true;
    });
    return dirty;
  }

  function initGridOptions() {
    $ctrl.gridOptions = {
      dataSource: $ctrl.ds,
      navigatable: true,
      pageable: true,
      height: 583,
      columns: [{
        field: "trimFullName",
        title: "{{ 'trim' | translate }}",
        width: "23%",
        sortable: {
          allowUnsort: false
        }
      }, {
        field: "auctionWeek1",
        title: "{{ 'Week5OrGreater' | translate }}",
        format: "{0:c0}",
        width: "11%",
        sortable: {
          allowUnsort: false
        }
      }, {
        field: "auctionWeek2",
        title: "{{ 'Week4to5' | translate }}",
        format: "{0:c0}",
        width: "11%",
        sortable: {
          allowUnsort: false
        }
      }, {
        field: "auctionWeek3",
        title: "{{ 'Week2to3' | translate }}",
        format: "{0:c0}",
        width: "11%",
        sortable: {
          allowUnsort: false
        }
      }, {
        field: "auctionWeek4",
        title: "{{ 'Week1' | translate }}",
        format: "{0:c0}",
        width: "11%",
        sortable: {
          allowUnsort: false
        }
      }, {
        field: "tradeValue",
        title: "{{ 'TradeValueHeading' | translate}}",
        template: tradeValueTemplate,
        width: "11%",
        sortable: {
          allowUnsort: false
        }
      }, {
        field: "dealerTradeValue",
        title: "{{ 'OverwriteValue' | translate }}",
        template: dealerTradeValueTemplate,
        format: "{0:c0}",
        width: "11%",
        sortable: {
          allowUnsort: false
        }
      }, {
        field: "dealerTradeExpirationDate",
        title: "{{ 'expirationDate' | translate}}",
        template: expirationDateTemplate,
        format: "{0:d}",
        width: "11%",
        sortable: {
          allowUnsort: false
        }
      }],
      editable: true,
      sortable: true,
      resizable: true,
      reorderable: true,
      filterable: false
    };
  }

  function tradeValueTemplate(row) {
    if (row.tradeValue) {
      return "".concat(localeCurrency(row.tradeValue));
    } else {
      var title = $translate.instant('ValueEstimated');
      return "<span id=\"trade-value-estimated\" title=\"".concat(title, "\">\n                ").concat(localeCurrency(row.estimated), "*\n            </span>");
    }
  }

  function loadTradeIns() {
    var makeID = $ctrl.trimSelections.makeID;
    var yearID = $ctrl.trimSelections.yearID;
    var modelID = $ctrl.trimSelections.modelID ? $ctrl.trimSelections.modelID : 0;
    var trimID = $ctrl.trimSelections.trimID ? $ctrl.trimSelections.trimID : 0;
    return busy("Loading Trade-ins", dataEntryService.getDealerTradeIns($ctrl.selectedDealerId, makeID, yearID, modelID, trimID).then(function (dataTradeIns) {
      $ctrl.ds = new $ctrl.kendo.data.DataSource({
        data: dataTradeIns,
        schema: {
          model: {
            fields: {
              trimFullName: {
                type: "string",
                editable: false
              },
              auctionWeek1: {
                type: "number",
                editable: false
              },
              auctionWeek2: {
                type: "number",
                editable: false
              },
              auctionWeek3: {
                type: "number",
                editable: false
              },
              auctionWeek4: {
                type: "number",
                editable: false
              },
              tradeValue: {
                type: "number",
                editable: false
              },
              estimated: {
                type: "number",
                editable: false
              },
              dealerTradeValue: {
                type: "number",
                editable: true
              },
              dealerTradeExpirationDate: {
                type: "date",
                editable: true
              }
            }
          }
        },
        batch: true,
        pageSize: 50
      }); // set the input settings for culture

      switch (cultureName) {
        case cultures.enUS:
          $ctrl.kendo.culture(cultures.enUS);
          break;

        case cultures.enGB:
          $ctrl.kendo.culture(cultures.enGB);
          break;

        case cultures.enCA:
          $ctrl.kendo.culture(cultures.enCA);
          break;

        case cultures.frCA:
          $ctrl.kendo.culture(cultures.frCA);
          break;

        case cultures.frFR:
          $ctrl.kendo.culture(cultures.frFR);
          break;

        case cultures.esUS:
          $ctrl.kendo.culture(cultureName);
          break;

        default:
          $ctrl.kendo.culture(cultures.enUS);
          break;
      }

      if (dataTradeIns.length > 0) {
        $ctrl.extTradeValueSourceID = dataTradeIns[0].extTradeValueSourceID;
      } else {
        $ctrl.extTradeValueSourceID = 0; // AutoAlert
      }

      $("#k-datepicker").kendoDatePicker.widget.fn.options.min = new Date();
      $ctrl.showQuickAction = false;
      $ctrl.kendoGrid = $element.find("#gridDataEntryTradeIns").data("kendoGrid");
      setColumnWidths(); // show/hide the week 1 option in trade set to values

      var weekOne = $element.find("#AuctionWeek1Option");

      if ($ctrl.extTradeValueSourceID === 0) {
        weekOne.show();
      } else {
        weekOne.hide();
      }
    }));
  }

  function setColumnWidths() {
    // toggle visibility based on extTradeValueSourceID
    var grid = $ctrl.kendoGrid;
    $ctrl.weekColumns = grid.columns.filter(function (item) {
      return item.field.match(/Week/);
    });

    if (!$ctrl.screenWidth) {
      if (!$ctrl.screenWidth || $ctrl.screenWidth < grid.element[0].scrollWidth) {
        $ctrl.screenWidth = grid.element[0].scrollWidth;
      }
    } // hide if the extTradeValueSourceID is not 0 (not AutoAlert)


    $ctrl.weekColumns.forEach(function (col, index) {
      if ($ctrl.extTradeValueSourceID !== 0) {
        $ctrl.kendoGrid.hideColumn(col);
      } else {
        $ctrl.kendoGrid.showColumn(col);
      }
    });
    grid.columns.forEach(function (col, index) {
      var bt = $ctrl.extTradeValueSourceID;
      var isTrim = col.field === "trimFullName";
      var totalColumns = $ctrl.extTradeValueSourceID === 0 ? 8 : 4;
      var columnWidth = $ctrl.screenWidth / totalColumns - 10; // the trim name column is longer than the others.

      col.width = bt ? "25%" : isTrim ? "23%" : "11%";
    });
  }

  function dealerTradeValueTemplate(e) {
    updateExpirationValidationFlag(e);

    if (e.dealerTradeValue) {
      if (e.dealerTradeValue >= $ctrl.minPrice && e.dealerTradeValue <= $ctrl.maxPrice) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.dealerTradeValue, 'n0') + "</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.dealerTradeValue, 'n0') + "</div>";
    } else if (e.dealerTradeExpirationDate) {
      return "<div class='edit-box-error'></div>";
    } else {
      return "<div class='edit-box'></div>";
    }
  }

  function updateExpirationValidationFlag(e) {
    if (!e.dealerTradeExpirationDate) {
      var grid = $element.find("#gridDataEntryTradeins").data("kendoGrid");
      var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
      var rowChildren = $(row.children('td[role="gridcell"]'));
      var cell = rowChildren.eq(10);

      if (e.dealerTradeValue) {
        cell.html("<div class='edit-box-error'></div>");
      } else {
        cell.html("<div class='edit-box'></span></div>");
      }
    }
  }

  function expirationDateTemplate(e) {
    updateDealerValueValidationFlag(e);
    var expirationDate = e.dealerTradeExpirationDate ? e.dealerTradeExpirationDate : '';
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    if (expirationDate) {
      if (expirationDate < today) return "<div class= 'expire-date edit-box'>" + $ctrl.kendo.toString(expirationDate, 'd') + "</div>";else return "<div class= 'edit-box'>" + $ctrl.kendo.toString(expirationDate, 'd') + "</div>";
    } else if (e.dealerTradeValue) {
      return "<div class='edit-box-error'></div>";
    } else {
      return "<div class= 'edit-box'>" + "</div>";
    }
  }

  function updateDealerValueValidationFlag(e) {
    if (!e.dealerTradeValue) {
      var grid = $("#gridDataEntryTradeins").data("kendoGrid");
      var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
      var rowChildren = $(row.children('td[role="gridcell"]'));
      var cell = rowChildren.eq(9);

      if (e.dealerTradeExpirationDate) {
        cell.html("<div class='edit-box-error'></div>");
      } else {
        cell.html("<div class='edit-box'></span></div>");
      }
    }
  }
}