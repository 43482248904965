"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var MakeClientService = /** @class */ (function (_super) {
    __extends(MakeClientService, _super);
    function MakeClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param dealerIds undefined
     */
    MakeClientService.prototype.GetMakesGETResponse = function (dealerIds, countryId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (dealerIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('dealerIds', val.toString()); });
        if (countryId != null)
            __params = __params.set('countryId', countryId.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Make/GetMakes", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerIds undefined
     */
    MakeClientService.prototype.GetMakesGET = function (dealerIds, countryId) {
        return this.GetMakesGETResponse(dealerIds, countryId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param countryId undefined
     * @return Success
     */
    MakeClientService.prototype.GetSearchableModelNamesGETResponse = function (countryId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (countryId != null)
            __params = __params.set('countryId', countryId.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Make/GetSearchableModelNames", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param countryId undefined
     * @return Success
     */
    MakeClientService.prototype.GetSearchableModelNamesGET = function (countryId) {
        return this.GetSearchableModelNamesGETResponse(countryId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    MakeClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Make/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    MakeClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    MakeClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/Make/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    MakeClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    MakeClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Make/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    MakeClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    MakeClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/Make", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    MakeClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    MakeClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Make", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    MakeClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    MakeClientService.GetMakesGETPath = '/api/Make/GetMakes';
    MakeClientService.GetSearchableModelNamesGETPath = '/api/Make/GetSearchableModelNames';
    MakeClientService.ByIdGETPath = '/api/Make/{id}';
    MakeClientService.ByIdDELETEPath = '/api/Make/{id}';
    MakeClientService.GetManyGETPath = '/api/Make/GetMany';
    MakeClientService.PUTPath = '/api/Make';
    MakeClientService.POSTPath = '/api/Make';
    MakeClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MakeClientService_Factory() { return new MakeClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: MakeClientService, providedIn: "root" });
    return MakeClientService;
}(base_service_1.BaseService));
exports.MakeClientService = MakeClientService;
