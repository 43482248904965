import { Component, OnInit, Input, Output, EventEmitter, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../../../core/toaster-logger.service';
import { DealSheetService } from '../../../../ajs-upgraded-providers';

@Component({
    selector: 'vincue-modal',
    templateUrl: './vincue-modal.component-ng.html',
    styleUrls: ['./vincue-modal.component.scss']
})

export class VinCueModalComponent implements OnInit, AfterViewInit {
    public dealerId: number;
    public estimatedMileage: number;
    public primaryPhone: string;
    public customer: any;
    public vehicle: any;
    public fullAddress: string;
    public fullTrim: string;
    public appraisalId: string;
    public createDate: any;
    public deepLink: string;
    public hasLatestAppraisal: boolean = false;
    public acv: number;
    @Output() onSaveChanges = new EventEmitter();

    constructor(
        public _bsModalRef: BsModalRef,
        public tService: TranslateService,
        private logger: ToasterLoggerService,
        @Inject(DealSheetService) private dealSheetService) {
    }

    public ngOnInit(): void {
        this._bsModalRef.hide();

        this.getLatestAppraisal(this.dealerId, this.vehicle.vin);

        if (this.vehicle.estimatedCurrentMileage) {
            this.estimatedMileage = this.vehicle.estimatedCurrentMileage;
        }
        else {
            this.estimatedMileage = null;
        }
        
        if (this.customer.cellPhoneNumber) {
            this.primaryPhone = this.customer.cellPhoneNumber.value;
        }
        else if (this.customer.homePhoneNumber) {
            this.primaryPhone = this.customer.homePhoneNumber.value;
        }
        else {
            this.primaryPhone = null;
        }

        this.getCustomerAddress();
        this.getFullTrim();
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public cancelModal(): void {
        this._bsModalRef.hide();
    }

    public sendAppraisal(): void {
        this.onSaveChanges.emit();
        this._bsModalRef.hide();
    }

    private getLatestAppraisal(dealerId:number, vin:string) {
        return this.dealSheetService.getLatestAppraisalVinCue(dealerId, vin)
            .then(data => {
                if (data && data.appraisal) {
                    this.hasLatestAppraisal = true;
                    this.appraisalId = data.appraisal.id;
                    this.deepLink = data.deepLink;
                    this.createDate = data.appraisal.created;
                    this.acv = data.appraisal.acv
                }
                else {
                    this.hasLatestAppraisal = false;
                }
            });
    }

    private getCustomerAddress() {
        var address1 = '';
        var address2 = '';
        if (this.customer) {

            if (this.customer.address.streetAddress)
                address1 = this.customer.address.streetAddress
            else
                address1 = '';

            if (this.customer.address.streetAddress2)
                address2 = ' ' + this.customer.address.streetAddress2;
            else
                address2 = ' ';

            this.fullAddress = address1 + address2 + ', ' + this.customer.address.city + ', ' + this.customer.address.stateName + ', ' + this.customer.address.normalizedZipCode;
        }
    }

    private getFullTrim() {
        this.fullTrim = this.vehicle.yearID + " " + this.vehicle.makeName + " " + this.vehicle.trimFullName;
    }

}



