import "core-js/modules/es6.function.name";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
DashboardController.$inject = ["$rootScope", "dealerService", "authorizationService", "$state", "globalIframeService", "storageService", "userService"];

/* injects from baggage-loader */
require('./dashboard.component.html');

import angular from 'angular';
import { Modules } from '../../constants/modules';
import './dashboard.scss';
angular.module('AaApp.Opp').component('dashboard', {
  templateUrl: '/app/components/dashboard/dashboard.component.html',
  controller: DashboardController,
  bindings: {
    moduleIds: '<'
  }
});

function DashboardController($rootScope, dealerService, authorizationService, $state, globalIframeService, storageService, userService) {
  "ngInject";

  var $ctrl = this;
  $ctrl.$onInit = $onInit;
  $ctrl.$onChanges = $onChanges;
  $ctrl.dealers;
  $ctrl.selectedDealerId = null;
  $ctrl.showQuickLinkPanel = true;
  $ctrl.onDealerSelect = onDealerSelect;
  $ctrl.tabs = [];
  var isEuroLite = authorizationService.isEuroLite();
  var tabDefinitions = [{
    requiredModules: [],
    sref: 'dashboard.opportunities',
    translationKey: 'opportunitiesDashboardHeader'
  }, {
    requiredModules: [Modules.managerDashboard],
    sref: 'dashboard.manager',
    translationKey: 'priorityOppDashboard'
  }];

  function $onInit() {
    $ctrl.dealers = dealerService.getSelectedDealers();
    $ctrl.selectedDealerId = storageService.getItem('selectedDealerId'); // if no dealer has been selected yet and there is only one dealer or the selected dealer is no longer on the list of available dealers

    if ($ctrl.selectedDealerId == null && $ctrl.dealers.length == 1 || $ctrl.selectedDealerId != null && !$ctrl.dealers.some(function (d) {
      return d.id == $ctrl.selectedDealerId;
    })) $ctrl.selectedDealerId = $ctrl.dealers[0].id;
    var subscription = globalIframeService.subscribe(function (_ref) {
      var data = _ref.data;

      if (data && data['AUTOALERT_IFRAME_LOADED']) {
        // AA is ready show iframe
        $ctrl.IsVisible = true;
      }
    });

    $ctrl.$onDestroy = function () {
      return subscription.unsubscribe();
    };

    userService.getUserProfile().then(function (userProfile) {
      $ctrl.userId = userProfile.userId;
    });
  }

  function $onChanges(changes) {
    if (changes.moduleIds) {
      filterAvailableTabs();
    }
  }

  function filterAvailableTabs() {
    $ctrl.showQuickLinkPanel = !isEuroLite;
    if (!isEuroLite) $ctrl.tabs = tabDefinitions.filter(function (def) {
      // the user must have every required module
      return def.requiredModules.every(function (req) {
        return $ctrl.moduleIds.includes(req);
      });
    });else $ctrl.tabs = tabDefinitions.filter(function (def) {
      return def.sref == 'dashboard.opportunities';
    });
  }

  function onDealerSelect() {
    storageService.setItem('selectedDealerId', $ctrl.selectedDealerId);
    $state.go($state.current.name, {
      selectedDealerId: $ctrl.selectedDealerId
    });
  }
}