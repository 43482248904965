"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var core_2 = require("@angular/core");
var forms_1 = require("@angular/forms");
var rxjs_1 = require("rxjs");
var admin_templates_edit_modal_component_1 = require("./admin-templates-edit-modal.component");
var modal_1 = require("ngx-bootstrap/modal");
var services_1 = require("../../../generated/services");
var toaster_logger_service_1 = require("../../../core/toaster-logger.service");
var settings_interface_1 = require("../../../interfaces/settings.interface");
var display_modal_component_1 = require("../../modals/display-modal/display-modal.component");
var kendo_data_query_1 = require("@progress/kendo-data-query");
var dealer_service_1 = require("../../../services/dealer.service");
var confirm_modal_component_1 = require("../../modals/confirm-modal.component");
var storage_service_1 = require("../../../modules/storage/storage.service");
var AdminTemplatesComponent = /** @class */ (function () {
    function AdminTemplatesComponent(modalService, formBuilder, translateService, dealerUpgradeProposalSettingClientService, dealerSmtpSettingClientService, logger, dealerService, storageService, templateService) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.translateService = translateService;
        this.dealerUpgradeProposalSettingClientService = dealerUpgradeProposalSettingClientService;
        this.dealerSmtpSettingClientService = dealerSmtpSettingClientService;
        this.logger = logger;
        this.dealerService = dealerService;
        this.storageService = storageService;
        this.templateService = templateService;
        this.marketingMessageTextMaxLength = 255;
        this.marketingMessageTextValue = '';
        this.readOnly = false;
        this.addConfidentialCoverPage = false;
        this.submitted = false;
        this.success = false;
        this.subscriptionAggregator = new rxjs_1.Subscription();
        this.pageSize = 50;
        this.skip = 0;
        this.tab1 = true;
        this.tab2 = false;
        this.tab3 = false;
        this.tempDealerSmtpSettingCustomDto = {};
        this.sort = [{
                field: 'templateName',
                dir: 'asc'
            }];
        // Adding validators
        this.SMTPForm = this.formBuilder.group({
            SMTPHost: ['', forms_1.Validators.required],
            port: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(5)]],
            useSSL: [''],
            username: ['', forms_1.Validators.required],
            password: [''],
        });
    }
    Object.defineProperty(AdminTemplatesComponent.prototype, "getSMTPSettingEnum", {
        get: function () { return settings_interface_1.SMTPSettingEnum; },
        enumerable: true,
        configurable: true
    });
    AdminTemplatesComponent.prototype.ngOnInit = function () {
        this.dealers = this.dealerService.getSelectedDealers();
        var dealerId = this.storageService.getItem('selectedDealerId');
        this.selectedDealerId = dealerId > 0 ? dealerId : this.dealers[0].id;
        this.defaultMarketingMessage = this.translateService.instant("goalMarketingMessage");
        this.systemUserName = this.translateService.instant("globalAdmin");
        this.loadData();
    };
    AdminTemplatesComponent.prototype.ngOnDestroy = function () {
        this.subscriptionAggregator.unsubscribe();
    };
    AdminTemplatesComponent.prototype.ngOnChanges = function (changes) {
    };
    AdminTemplatesComponent.prototype.pageChange = function (_a) {
        var skip = _a.skip, take = _a.take;
        this.skip = skip;
        this.pageSize = take;
        this.dealerTemplates = kendo_data_query_1.orderBy(this.dealerTemplates, this.sort);
        this.gridView = {
            data: this.dealerTemplates.slice(this.skip, this.skip + this.pageSize),
            total: this.dealerTemplates.length
        };
    };
    AdminTemplatesComponent.prototype.loadData = function () {
        this.busy = this.getTemplates();
        this.getMarketingMessage();
        this.getDealerSmtpSettings();
        this.subscriptionAggregator.add(this.busy);
    };
    AdminTemplatesComponent.prototype.calculateLength = function () {
        return this.marketingMessageTextMaxLength - ((this.marketingMessageTextValue) ? this.marketingMessageTextValue.length : 0);
    };
    AdminTemplatesComponent.prototype.getDealerSmtpSettings = function () {
        var _this = this;
        return this.dealerSmtpSettingClientService.GetDealerSmtpSettingsGET(this.selectedDealerId)
            .subscribe(function (data) {
            if (data != null) {
                _this.type = data.smtpMethod;
                _this.tempDealerSmtpSettingCustomDto.customHost = data.customHost;
                _this.tempDealerSmtpSettingCustomDto.customPort = data.customPort;
                _this.tempDealerSmtpSettingCustomDto.customUsername = data.customUsername;
                _this.tempDealerSmtpSettingCustomDto.customUseSsl = data.customUseSsl;
                _this.tempDealerSmtpSettingCustomDto.dealerID = data.dealerID;
                _this.tempDealerSmtpSettingCustomDto.smtpMethod = data.smtpMethod;
                if (data.smtpMethod == settings_interface_1.SMTPSettingEnum.custom) {
                    _this.SMTPForm.patchValue({
                        SMTPHost: _this.tempDealerSmtpSettingCustomDto.customHost,
                        port: _this.tempDealerSmtpSettingCustomDto.customPort,
                        username: _this.tempDealerSmtpSettingCustomDto.customUsername,
                        useSSL: _this.tempDealerSmtpSettingCustomDto.customUseSsl
                    });
                }
            }
        });
    };
    AdminTemplatesComponent.prototype.getMarketingMessage = function () {
        var _this = this;
        return this.dealerUpgradeProposalSettingClientService.GetDealerUpgradeProposalSettingByDealeridGET(this.selectedDealerId)
            .subscribe(function (data) {
            if (data != null) {
                _this.marketingMessageId = data.id;
                _this.marketingMessageTextValue = data.marketingMessage;
                _this.addConfidentialCoverPage = data.addConfidentialCoverPage;
            }
            else {
                _this.marketingMessageId = undefined;
                _this.marketingMessageTextValue = "";
                _this.addConfidentialCoverPage = false;
            }
        });
    };
    AdminTemplatesComponent.prototype.getTemplates = function () {
        var _this = this;
        return rxjs_1.from(this.templateService.getNonEmptyTemplatesForDealerId(this.selectedDealerId))
            .subscribe(function (templateData) {
            _this.dealerTemplates = templateData;
            _this.dealerTemplates.forEach(function (t) {
                if (t.isSystemTemplate) {
                    t.createdByFullName = _this.systemUserName;
                }
            });
            _this.dealerTemplates = kendo_data_query_1.orderBy(_this.dealerTemplates, _this.sort);
            _this.gridView = {
                data: _this.dealerTemplates.slice(_this.skip, _this.skip + _this.pageSize),
                total: _this.dealerTemplates.length
            };
            _this.tab1 = true;
            _this.tab2 = false;
            _this.tab3 = false;
        });
    };
    AdminTemplatesComponent.prototype.sortChange = function (sort) {
        if (sort[0].field == this.sort[0].field) {
            sort[0].dir = (this.sort[0].dir == "asc") ? "desc" : "asc";
        }
        this.sort = sort;
        this.dealerTemplates = kendo_data_query_1.orderBy(this.dealerTemplates, this.sort);
        this.gridView = {
            data: this.dealerTemplates.slice(this.skip, this.skip + this.pageSize),
            total: this.dealerTemplates.length
        };
    };
    AdminTemplatesComponent.prototype.onSuccess = function () {
        this.logger.success(this.translateService.instant("changesSaved"));
    };
    AdminTemplatesComponent.prototype.refreshTemplates = function (showSuccess) {
        this.busy = this.getTemplates();
        this.subscriptionAggregator.add(this.busy);
        if (showSuccess)
            this.onSuccess();
    };
    AdminTemplatesComponent.prototype.onDealerSelect = function () {
        this.storageService.setItem("selectedDealerId", this.selectedDealerId);
        this.loadData();
    };
    AdminTemplatesComponent.prototype.onDeleteSuccess = function () {
        this.refreshTemplates(true);
    };
    AdminTemplatesComponent.prototype.deleteTemplate = function (id) {
        var _this = this;
        var initialState = {
            saveWarningMessage: false,
            messageKey: "templateDeleteConfirmMessage"
        };
        this.bsModalRef = this.modalService.show(confirm_modal_component_1.ConfirmModalComponent, { initialState: initialState });
        this.bsModalRef.content.onClosed.subscribe(function (result) {
            if (result.confirmed) {
                rxjs_1.from(_this.templateService.deleteTemplate(id))
                    .subscribe(function () { return _this.onDeleteSuccess(); });
            }
        });
    };
    AdminTemplatesComponent.prototype.pdfPreviewTemplate = function (id) {
        this.busy = rxjs_1.from(this.templateService.getTemplatePdfPreview(id, this.selectedDealerId))
            .subscribe();
        this.subscriptionAggregator.add(this.busy);
    };
    AdminTemplatesComponent.prototype.emailPreviewTemplate = function (id) {
        var _this = this;
        this.busy = rxjs_1.from(this.templateService.getTemplateEmailPreview(id, this.selectedDealerId))
            .subscribe(function (templateData) {
            var initialState = { saveWarningMessage: false, htmlContent: templateData };
            _this.bsModalRef = _this.modalService.show(display_modal_component_1.DisplayModalComponent, { initialState: initialState, class: 'modal-lg' });
            _this.bsModalRef.content.onClosed.subscribe(function (result) {
            });
        });
        this.subscriptionAggregator.add(this.busy);
    };
    AdminTemplatesComponent.prototype.onCreateNewTemplate = function () {
        var _this = this;
        var initialState = { id: 0, dealerId: this.selectedDealerId };
        this.bsModalRef = this.modalService.show(admin_templates_edit_modal_component_1.AdminTemplatesEditModalComponent, { initialState: initialState, class: 'modal-lg' });
        this.bsModalRef.content.saveChanges.subscribe(function (result) {
            _this.refreshTemplates(false);
        });
    };
    AdminTemplatesComponent.prototype.editTemplate = function (id) {
        var _this = this;
        var initialState = { id: id, dealerId: this.selectedDealerId };
        this.bsModalRef = this.modalService.show(admin_templates_edit_modal_component_1.AdminTemplatesEditModalComponent, { initialState: initialState, class: 'modal-lg' });
        this.bsModalRef.content.saveChanges.subscribe(function (result) {
            _this.refreshTemplates(false);
        });
    };
    AdminTemplatesComponent.prototype.saveMarketingMessage = function (marketingMessage) {
        var _this = this;
        if (marketingMessage != null) {
            if (marketingMessage.replace(/\s/g, '').length === 0)
                marketingMessage = this.defaultMarketingMessage;
            this.busy3 = this.dealerUpgradeProposalSettingClientService.SaveDealerUpgradeProposalSettingPOST({
                id: this.marketingMessageId,
                dealerId: this.selectedDealerId,
                marketingMessage: marketingMessage,
                addConfidentialCoverPage: this.addConfidentialCoverPage
            })
                .subscribe(function () { return _this.onSuccess(); });
            this.subscriptionAggregator.add(this.busy3);
        }
    };
    AdminTemplatesComponent.prototype.onSaveMarketingMessage = function (marketingMessage) {
        this.saveMarketingMessage(marketingMessage);
    };
    AdminTemplatesComponent.prototype.onSaveSMTPSettings = function () {
        var _this = this;
        this.submitted = true;
        if (this.type == settings_interface_1.SMTPSettingEnum.custom && this.SMTPForm.invalid) {
            return;
        }
        if (this.type == settings_interface_1.SMTPSettingEnum.none || this.type == settings_interface_1.SMTPSettingEnum.autoAlert) {
            this.tempDealerSmtpSettingCustomDto.smtpMethod = this.type;
            this.busy2 = this.dealerSmtpSettingClientService.SaveDealerSmtpSettingsPOST(this.tempDealerSmtpSettingCustomDto).subscribe(function () { return _this.onSuccess(); });
            this.subscriptionAggregator.add(this.busy2);
        }
        else {
            this.tempDealerSmtpSettingCustomDto.smtpMethod = this.type;
            this.tempDealerSmtpSettingCustomDto.customHost = this.SMTPForm.get('SMTPHost').value;
            this.tempDealerSmtpSettingCustomDto.customPort = this.SMTPForm.get('port').value;
            this.tempDealerSmtpSettingCustomDto.customUsername = this.SMTPForm.get('username').value;
            this.tempDealerSmtpSettingCustomDto.customUseSsl = this.SMTPForm.get('useSSL').value;
            this.tempDealerSmtpSettingCustomDto.customPassword = this.SMTPForm.get('password').value;
            this.busy2 = this.dealerSmtpSettingClientService.SaveDealerSmtpSettingsPOST(this.tempDealerSmtpSettingCustomDto).subscribe(function () { return _this.onSuccess(); });
            this.subscriptionAggregator.add(this.busy2);
        }
        this.success = true;
    };
    AdminTemplatesComponent.prototype.selectType = function (type) {
        this.type = type;
    };
    return AdminTemplatesComponent;
}());
exports.AdminTemplatesComponent = AdminTemplatesComponent;
