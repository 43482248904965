<div class="modal-wrapper">

        <div class="alert-box">
            <div class="modal-header">
                <div style="float:left">
                    <h5 class="modal-title" translate="vinCueDialogTitle"></h5>
                </div>
                <div style="float:right">
                    <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
                        <icon class="icon aa-icon-close-icon"></icon>
                    </button>
                </div>
            </div>
            <div class="modal-body form-horizontal">

                <div>
                    <div class="row">
                        <label class="col-sm-12 sectionHeader">{{customer.fullName}}</label>
                    </div>
                    <div class="row">
                        <label class="col-sm-3" translate="vAutoAddressLabel"></label>
                        <div class="col-sm-9 bold">
                            <span>{{fullAddress}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-sm-3" translate="vAutoEmailLabel"></label>
                        <div class="col-sm-9 bold">
                            <span>{{customer.emailAddress?.value}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-sm-3" translate="vAutoPhoneLabel"></label>
                        <div class="col-sm-9 bold">
                            <span>{{primaryPhone | localePhone}}</span>
                        </div>
                    </div>

                    <table class="table" style="margin-top: 8px;">
                        <thead>
                            <tr>
                                <th translate="vAutoVinLabel"></th>
                                <th translate="vAutoTrimLabel"></th>
                                <th translate="vAutoTradeOdometerLabel" class="right"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{vehicle.vin}}</td>
                                <td>{{fullTrim}}</td>
                                <td class="right">
                                    <span *ngIf="estimatedMileage">{{estimatedMileage | localeNumber}} {{ 'vAutoMileageLabel' | translate }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <ng-container *ngIf="hasLatestAppraisal">
                        <div class="row">
                            <label class="col-sm-12 sectionHeader" translate="vAutoLatestAppraisalLabel"></label>
                        </div>

                        <table class="table" style="margin-top: 4px;">
                            <thead>
                                <tr>
                                    <th translate="vAutoDateCreatedLabel"></th>
                                    <th translate="vAutoAppraisalIdLabel"></th>
                                    <th translate="vAutoViewAppraisalLabel"></th>
                                    <th>ACV</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{createDate | localeDate}}</td>
                                    <td>{{appraisalId}}</td>
                                    <td><a href="{{deepLink}}" target="_blank" class="qa-appraisal-link" translate="vinCueAppraisalLink"></a></td>
                                    <td>{{acv | localeCurrency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="cancelModal()" translate="cancel"></button>
                <button type="submit" class="btn btn-primary" (click)="sendAppraisal()" translate="vAutoSendAppraisalButtonText"></button>
            </div>
        </div>
</div>