import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.sort";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
DealSheetController.$inject = ["$timeout", "$q", "$translate", "$translatePartialLoader", "$sce", "$scope", "$window", "$document", "$analytics", "$state", "logger", "$auth", "$uibModal", "$rootScope", "toaster", "dealSheetService", "opportunity2Service", "opportunityService", "vehicleService", "contractService", "authorizationService", "customerService", "globalIframeService", "userService", "dealerService", "activityService", "servicesService", "storageService", "eulaModalService", "searchService", "localePaths", "switchToNewDealSheetKey", "switchToClassicDealSheetKey", "dontHaveAccessWarningKey", "legacy", "dealSheetTabs", "saleTypes", "conquestTypes", "msrpTypes", "vehicleTypes", "alertScriptTypes", "activityRule", "analytics", "userProfileRoles", "modules", "submitDiscrepancySuccessMessageKey", "paymentTypes", "entityActionAccessEnum", "ActivityActionEnum", "activityResults", "tradeValueTypes", "vehicleActions", "opportunityStatuses", "environmentVariables", "pandoActionTypes", "assetTypes", "territories", "cultures", "fordFBATypes", "_", "mapUtils"];

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* injects from baggage-loader */
require('./deal-sheet.component.html');

import angular from 'angular';
import { tokenMissing$ } from '../../modules/oidc-auth/events';
import pandoOnIconPath from '../../images/icon-pando-profile-on.svg';
import pandoOffIconPath from '../../images/icon-pando-profile-off.svg';
import './deal-sheet.scss';
import { ActivityTypes as activityTypes, ConquestTypes as conquestTypes } from '../../constants';
import { DealerCountries as dealerCountries } from '../../constants/dealer-countries';
import { forEach } from '@uirouter/core';
angular.module('AaApp.Opp').component('dealSheet', {
  templateUrl: '/app/components/deal-sheet/deal-sheet.component.html',
  controller: DealSheetController,
  bindings: {
    opportunityId: '<',
    calculationType: '<',
    stockNo: '<',
    showLegacy: '<',
    closeButtonVisible: '<',
    newWindowButtonVisible: '<',
    close: '<',
    dismiss: '<'
  }
});
/* @ngInject */

function DealSheetController( //angular services
$timeout, $q, $translate, $translatePartialLoader, $sce, $scope, $window, $document, $analytics, $state, logger, $auth, $uibModal, $rootScope, toaster, //shared services
dealSheetService, //other services
opportunity2Service, opportunityService, vehicleService, contractService, authorizationService, customerService, globalIframeService, userService, dealerService, activityService, servicesService, storageService, eulaModalService, searchService, //$translate related
localePaths, switchToNewDealSheetKey, switchToClassicDealSheetKey, dontHaveAccessWarningKey, //constants
legacy, dealSheetTabs, saleTypes, conquestTypes, msrpTypes, vehicleTypes, alertScriptTypes, activityRule, analytics, userProfileRoles, modules, submitDiscrepancySuccessMessageKey, paymentTypes, entityActionAccessEnum, ActivityActionEnum, activityResults, tradeValueTypes, vehicleActions, opportunityStatuses, environmentVariables, pandoActionTypes, assetTypes, territories, cultures, fordFBATypes, //Tools
_, mapUtils) {
  // props
  var $ctrl = this;
  var opportunityService = environmentVariables.opportunityServiceAng8Enabled ? opportunity2Service : opportunityService;
  $ctrl.isSltUser;
  $ctrl.showExpirationDate;
  $ctrl.opportunityId;
  $ctrl.showVehicleNotPaidoff;
  $ctrl.isOfferEnabled;
  $ctrl.moduleIds;
  $ctrl.replacementStockNo;
  $ctrl.currentVehicle;
  $ctrl.currentContract;
  $ctrl.replacementVehicle;
  $ctrl.replacementContract;
  $ctrl.opportunity;
  $ctrl.activities;
  $ctrl.msrpTypes = msrpTypes;
  $ctrl.paymentTypes = paymentTypes;
  $ctrl.conquestTypes = conquestTypes;
  $ctrl.tabs = dealSheetTabs;
  $ctrl.permissions = {
    featureComparison: true,
    otherVehiclesOwned: true
  };
  $ctrl.newDealSheetReady = false;
  $ctrl.newDealSheetVisible = dealSheetService.modalDisplayState === 'new';
  $ctrl.serviceHistory = null;
  $ctrl.fuelEconomyComparison;
  $ctrl.featureComparison;
  $ctrl.relatedOpportunities;
  $ctrl.relatedOpportunitiesByVin;
  $ctrl.activityNotification = false;
  $ctrl.userProfileRoles = userProfileRoles;
  $ctrl.replacementColor;
  $ctrl.replacementInStockDays;
  $ctrl.pricingPlanEnabled;
  $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Log_Call;
  $ctrl.tradeValueTypes = tradeValueTypes;
  $ctrl.opportunityStatuses = opportunityStatuses;
  $ctrl.eulaModalService = eulaModalService;
  $ctrl.showPullForward = false;
  $ctrl.hasReviewPro = false;
  $ctrl.hasGeoAlert = false;
  $ctrl.showPrivateOfferScript;
  $ctrl.showPrivateOfferPreApprovalScript;
  $ctrl.states;
  $ctrl.cultureName;
  $ctrl.isFordAlert = false;
  $ctrl.isFordLincoln = false;
  $ctrl.printingFeatures = false;
  $ctrl.isVautoEnabled = false;
  $ctrl.isVinCueEnabled = false;
  $ctrl.isDmsPushValid = false;
  $ctrl.isEnterpriseCustomer = false;
  $ctrl.dealerGroupMakes;
  $ctrl.crossBrandConditionsMet = false;
  $ctrl.alternatesWithOvernightOffBrand = false; // VCI

  $ctrl.isVciAvailable = false; // methods

  $ctrl.$onInit = init;
  $ctrl.closeModal = closeModal;
  $ctrl.showNoRecommendedVehiclesMessage = showNoRecommendedVehiclesMessage;
  $ctrl.changeDealSheetView = changeDealSheetView;
  $ctrl.changeTab = changeTab;
  $ctrl.isDealSheetDirty = false;
  $ctrl.isDealsheetVersionChangeEnabled = legacy.isDealsheetVersionChangeEnabled;
  $ctrl.updateAssignedUser = updateAssignedUser;
  $ctrl.onActivityUpdate = onActivityUpdate;
  $ctrl.onTradeValueChange = onTradeValueChange;
  $ctrl.onPriceChange = onPriceChange;
  $ctrl.onMileageValueChange = onMileageValueChange;
  $ctrl.onWatchChange = onWatchChange;
  $ctrl.refreshOpportunity = refreshOpportunity;
  $ctrl.startWorkingState = startWorkingState;
  $ctrl.stopWorkingState = stopWorkingState;
  $ctrl.updateReplacementVehicleByMsrpType = updateReplacementVehicleByMsrpType;
  $ctrl.setReplacementVehicle = setReplacementVehicle;
  $ctrl.updateReplacementVehicleByTrim = updateReplacementVehicleByTrim;
  $ctrl.onScheduleSubmit = onScheduleSubmit;
  $ctrl.updateCustomer = updateCustomer;
  $ctrl.updateCustomerCancel = updateCustomerCancel;
  $ctrl.printVisible = printVisible;
  $ctrl.refreshTabs = refreshTabs;
  $ctrl.showPrintDialog = showPrintDialog;
  $ctrl.showFeaturePrintDialog = showFeaturePrintDialog;
  $ctrl.onMarkAsNLO = onMarkAsNLO;
  $ctrl.onFinancialUpdate = onFinancialUpdate;
  $ctrl.onOpenComparisonTab = onOpenComparisonTab;
  $ctrl.changeOpportunity = changeOpportunity;
  $ctrl.setReplacementVehicleType = setReplacementVehicleType;
  $ctrl.vehicleNotPaidOff = vehicleNotPaidOff;
  $ctrl.updatePayoff = updatePayoff;
  $ctrl.$onDestroy = onDestroy;
  $ctrl.reopen = reopen;
  $ctrl.onMarkAsSold = onMarkAsSold;
  $ctrl.markAsOnOrder = markAsOnOrder;
  $ctrl.unMarkAsOnOrder = unMarkAsOnOrder;
  $ctrl.getCreditConvertData = getCreditConvertData;
  $ctrl.otherVehicleHasAlerts;
  $ctrl.otherVehicleStatusClosed;
  $ctrl.submitDiscrepancy = submitDiscrepancy;
  $ctrl.setInventoryOrAverageReplacementVehicle = setInventoryOrAverageReplacementVehicle;
  $ctrl.setCreditPrescreenStatus = setCreditPrescreenStatus;
  $ctrl.onLogScheduleSubmit = onLogScheduleSubmit;
  $ctrl.setDefaultTab = setDefaultTab;
  $ctrl.onNoLongerOwns = onNoLongerOwns;
  $ctrl.onVehicleActionUpdate = onVehicleActionUpdate;
  $ctrl.isVehicleStatusVisible = isVehicleStatusVisible;
  $ctrl.setReplacementContractOverride = setReplacementContractOverride;
  $ctrl.funcKeydown = funcKeydown;
  $ctrl.setMVOVehicle = setMVOVehicle;
  $ctrl.logShortcut = logShortcut;
  $ctrl.openSmartVincentLink = openSmartVincentLink; // translations

  $translatePartialLoader.addPart(localePaths.dealSheet);
  $translatePartialLoader.addPart(localePaths.general); // spinner

  $ctrl.dealsheetWorking;
  $ctrl.loadingDealSheetText;
  $ctrl.hideWhenBusy = false; //customer-connect tab status

  $ctrl.isCustomerConnectOpen = false;
  $ctrl.OriginalTradeValue = null;
  $ctrl.isUserEditedTradeValue = false; // term override

  $ctrl.hasTermOverride = false;
  $ctrl.overrideTermInfo;
  $ctrl.mKey = 77; // pando connect

  $ctrl.isPandoOpen = false;
  $ctrl.pandoOnIcon = pandoOnIconPath;
  $ctrl.pandoOffIcon = pandoOffIconPath;
  $ctrl.onPandoActionClick = onPandoActionClick;
  $ctrl.isPandoConnectEnabled = environmentVariables.pandoDealSheetEnabled; //$ctrl.isPandoDealSheetProcessEnabled = environmentVariables.pandoDealSheetProcessEnabled;

  $ctrl.isPandoDealSheetProcessEnabled = false;
  $ctrl.pandoActionTypes = pandoActionTypes;
  $ctrl.toggleClick = toggleClick;
  $ctrl.LogActivityClick = LogActivityClick;
  $ctrl.autoRewardsUrl = null;
  $ctrl.vehicleActions = vehicleActions; // init defaults for auto assignment of owner and access infos

  $ctrl.isAutoAssignedOwner = false;
  $ctrl.autoAssignData = {
    fullName: null,
    icon: "clipboard-assigned",
    toolTip: "",
    date: null,
    labelText: "accessLogs",
    isAutoAssigned: false,
    autoAssignAccessSearch: null,
    autoAssignAccessDate: null
  };
  $ctrl.selectedReplacementVehicle = {
    selectedVehicleType: 'standardVehicle',
    alternateVehicleIndex: 0
  }; // hot trade

  $ctrl.displayHotTrade;
  $ctrl.hotTradeData;
  $ctrl.openHotTradeModal = openHotTradeModal;
  $ctrl.displayNlo = false;
  $ctrl.nloData = null;

  function getAutoRewardsUrl() {
    var url = null;

    if ($ctrl.dealerSettings.autoRewardAccountIdentifier && $ctrl.dealerSettings.autoRewardUserName && $ctrl.dealerSettings.autoRewardPassword && ($ctrl.opportunity.customer.lastName && $ctrl.opportunity.customer.firstName || $ctrl.opportunity.customer.lastName)) {
      url = environmentVariables.autoRewardsUrl;
      url = url.replace("{0}", encodeURIComponent($ctrl.dealerSettings.autoRewardAccountIdentifier));
      url = url.replace("{1}", encodeURIComponent($ctrl.dealerSettings.autoRewardUserName));
      url = url.replace("{2}", encodeURIComponent($ctrl.dealerSettings.autoRewardPassword));
      url = url.replace("{3}", encodeURIComponent($ctrl.opportunity.customer.lastName));
      url = url.replace("{4}", encodeURIComponent($ctrl.opportunity.customer.firstName));
    }

    return url;
  }

  function LogActivityClick(value) {
    $ctrl.isPandoOpen = false;
    $ctrl.isCustomerConnectOpen = value;
  }

  function toggleClick(value) {
    $ctrl.onPandoActionClick($ctrl.pandoActionTypes.tab);
  }

  function onPandoActionClick(actionTypeSelected) {
    $ctrl.pandoActionTypeSelected = actionTypeSelected; // used in pando-customer-connect component.

    $ctrl.isCustomerConnectOpen = false;
    if (actionTypeSelected == $ctrl.pandoActionTypes.tab) $ctrl.isPandoOpen = !$ctrl.isPandoOpen;else $ctrl.isPandoOpen = true; // if Process or Message btn click just open.
  }

  function closeModal() {
    globalIframeService.postMessage({
      'task': 'opportunity_refresh'
    });
    $ctrl.close();
  }

  function vehicleNotPaidOff() {
    $ctrl.startWorkingState();
    contractService.undoVehiclePaidOff($ctrl.opportunityId).then(function () {
      $ctrl.refreshOpportunity();
    }, function () {
      $ctrl.stopWorkingState();
      throw {
        message: "We encountered an error processing your request."
      };
    }).catch(errorExit(dontHaveAccessWarningKey));
  }

  function resetDealSheet() {
    $ctrl.opportunityId = null;
    $ctrl.replacementStockNo = null;
    $ctrl.currentVehicle = null;
    $ctrl.currentContract = null;
    $ctrl.replacementVehicle = null;
    $ctrl.replacementContract = null;
    $ctrl.opportunity = null;
    $ctrl.activities = null;
    $ctrl.tabs = dealSheetTabs;
    $ctrl.permissions = {
      featureComparison: true,
      otherVehiclesOwned: true
    };
    $ctrl.newDealSheetReady = true;
    $ctrl.newDealSheetVisible = dealSheetService.modalDisplayState === 'new';
    $ctrl.serviceHistory = null;
    $ctrl.fuelEconomyComparison = null;
    $ctrl.relatedOpportunities = null;
    $ctrl.featureComparison = null;
    $ctrl.OriginalTradeValue = null;
    $ctrl.currentTab = null;
  }

  function changeOpportunity(opportunityId) {
    if ($ctrl.newWindowButtonVisible) {
      resetDealSheet();
      $ctrl.opportunityId = opportunityId;
      init();
    } else {
      $state.go('dealSheet', {
        opportunityId: opportunityId
      });
    }
  }

  function printVisible(x) {
    $ctrl.printShow = x;
  }

  function onOpenComparisonTab() {
    changeTab($ctrl.tabs.featureComparison);
  }

  function loadDealerSettings() {
    var dealerId = $ctrl.opportunity.dealerId;
    $ctrl.pricingPlanEnabled = userService.isDealerEnabledForModule(dealerId, modules.fordAXZPlanPricing);
    $ctrl.dealerSettings = $ctrl.opportunity.dealerSettings;
    return $q.resolve();
  }

  function errorExitWithCode(key) {
    $translate([key]).then(showWarningMessage);

    function showWarningMessage(translations) {
      logger.warning(translations[key]);
    }

    if ($ctrl.dismiss) {
      $ctrl.dismiss({
        $value: 'cancel'
      });
    } else {
      $state.go('home');
    }
  }

  function errorExit(key) {
    return function (response) {
      errorExitWithCode(key);
    };
  }

  function init() {
    // If the user isn't logged in, the tokenMissing$ is fired due to API calls
    // returning 401. We respond to that by (1) saving the current URL, (2)
    // redirecting to SSO, and (3) redirecting to the 'home' state.
    //
    // When the user loads the deal sheet from a URL, there's a race condition where
    // (1) happens before (3). This means we store the home URL ("/") instead of
    // the deal sheet URL ("/alert-desk/deal-sheet/..."), so the user doesn't get
    // redirected to the right place after they sign in.
    //
    // Firing tokenMissing$ here doesn't make sense, but it seems to fix the
    // timing issue.
    if (!$auth.isAuthenticated()) {
      tokenMissing$.next();
      return;
    }

    dealSheetService.modalDisplayState = 'new';
    $ctrl.conquestTypes = conquestTypes;
    $ctrl.vehicleTypes = vehicleTypes;
    $ctrl.vehicleTypeKeys = _.invert(vehicleTypes);
    $ctrl.saleTypeKeys = _.invert(saleTypes);
    $ctrl.alertScriptTypes = alertScriptTypes;
    $ctrl.alertScriptTypeKeys = _.invert(alertScriptTypes);
    $ctrl.states = territories;
    $ctrl.fordFBATypes = fordFBATypes;

    if ($ctrl.opportunityId) {
      $ctrl.dealsheetWorking = $q.defer().promise;

      if (dealSheetService.modalDisplayState === 'new') {
        $ctrl.isDealsheetVersionChangeEnabled = false;
        $ctrl.changeDealSheetLabel = switchToClassicDealSheetKey;
        $ctrl.newDealSheetVisible = true;
        $ctrl.hideWhenBusy = true;
        initNewDealSheet();
      } else {
        $ctrl.newDealSheetVisible = false;
        $ctrl.changeDealSheetLabel = switchToNewDealSheetKey;
        $ctrl.src = getLegacyDealSheet();
        $ctrl.dealsheetWorking = null;
      }
    }

    $translate('loadingDealSheet').then(function (translation) {
      return $ctrl.loadingDealSheetText = translation;
    });
    eulaModalService.isEmailEnabled().then(function (isEnabled) {
      return $ctrl.isEmailEnabled = isEnabled;
    });
  }

  function initNewDealSheet() {
    $ctrl.newDealSheetReady = false;
    $ctrl.contractReady = false;
    $ctrl.opportunityDetailsReady = false;
    return userService.getUserProfile().then(function (userProfile) {
      return $q.all({
        'dealSheetPermissions': authorizationService.getDealSheetPermissions(),
        'userProfile': $q.resolve(userProfile)
      });
    }).then(function (data) {
      $ctrl.permissions = data.dealSheetPermissions;
      $ctrl.permissions.featureComparison = true;
      $ctrl.permissions.otherVehiclesOwned = true;
      $ctrl.isSltUser = data.userProfile.isSltUser;
      var userProfile = $ctrl.userProfile = data.userProfile;
      $ctrl.cultureName = userProfile.cultureName;
      angular.element($window).on("keydown", $ctrl.funcKeydown); // TODO: Pass this in via a binding

      $ctrl.moduleIds = storageService.getItem('moduleIds');
      return opportunityService.getOpportunity($ctrl.opportunityId, $ctrl.stockNo, $ctrl.calculationId, $ctrl.permissions, $ctrl.moduleIds).then(function (opportunity) {
        return setOpportunity(opportunity, userProfile);
      });
    }).catch(errorExit(dontHaveAccessWarningKey));
  }

  function setOpportunity(opportunity, userProfile) {
    $ctrl.opportunity = opportunity;
    SetCrossBrandConditionsMet();
    $ctrl.isVciAvailable = $ctrl.opportunity.currentVehicle.makeID == 84 || $ctrl.opportunity.currentVehicle.makeID == 85; // hot trade

    if ($ctrl.userProfile.countryId == dealerCountries.usa) {
      opportunityService.getVehicleTurnPredictor($ctrl.opportunityId).then(function (result) {
        if (result.hasData) {
          $ctrl.displayHotTrade = true;
          $ctrl.hotTradeData = result.data;
        } else {
          $ctrl.displayHotTrade = false;
          $ctrl.hotTradeData = null;
        }
      });
    } else {
      $ctrl.displayHotTrade = false;
      $ctrl.hotTradeData = null;
    } // use same module check as for wishlist:


    if ($ctrl.moduleIds.indexOf(modules.wishlist) > -1) {
      opportunityService.getActiveNLPNoLongerOwn($ctrl.opportunityId).then(function (result) {
        if (result && result.length > 0) {
          $ctrl.displayNlo = true;
          $ctrl.nloData = result;
        } else {
          $ctrl.displayNlo = false;
          $ctrl.nloData = null;
        }
      });
    } else {
      $ctrl.displayNlo = false;
      $ctrl.nloData = null;
    }

    $q.all({
      'leadAssignment': $translate('leadAssignment'),
      'date': $translate('date')
    }).then(function (stuff) {
      $ctrl.leadAssignmentLabel = stuff.leadAssignment;
      $ctrl.dateLabel = stuff.date; //load autoassigned data from returned service results

      $ctrl.isAutoAssignedOwner = $ctrl.opportunity.autoAssignOwner;
      if ($ctrl.opportunity.autoAssignOwnerSearch) $ctrl.autoOwnerLeadAssignmentToolTip = $ctrl.leadAssignmentLabel + ": " + $ctrl.opportunity.autoAssignOwnerSearch;
      $ctrl.dateSeparator = ": ";
      $ctrl.newLineSeparator = "\n";
      $ctrl.autoAssignData.fullName = $ctrl.opportunity.autoAssignAccessUserName;
      $ctrl.autoAssignData.isAutoAssigned = $ctrl.autoAssignData.fullName != null;
      $ctrl.autoAssignData.autoAssignAccessSearch = $ctrl.opportunity.autoAssignAccessSearch;
      $ctrl.autoAssignData.autoAssignAccessDate = $ctrl.opportunity.autoAssignAccessDate;
      if ($ctrl.autoAssignData.autoAssignAccessSearch) $ctrl.autoAssignData.toolTip = $ctrl.leadAssignmentLabel + ": " + $ctrl.autoAssignData.autoAssignAccessSearch;
      var hasTextingModule = userService.isDealerEnabledForModule(opportunity.dealerId, modules.textMessaging);
      $ctrl.isTextingEnabled = hasTextingModule && ($ctrl.permissions.sendTextSales || $ctrl.permissions.sendTextService); ////TEST pando claim and Auto Assignment with mock data
      ////test auto owner
      //$ctrl.isAutoAssignedOwner = true;
      //$ctrl.opportunity.autoAssignOwnerSearch = "OWNER TEST DATA 1234";
      //$ctrl.opportunity.autoAssignOwnerDate = "2018-09-14T00:00:00Z";
      //if($ctrl.opportunity.autoAssignOwnerSearch)
      //    $ctrl.autoOwnerLeadAssignmentToolTip =  $ctrl.leadAssignmentLabel + ": " + $ctrl.opportunity.autoAssignOwnerSearch;
      //// test auto access
      //$ctrl.autoAssignData.isAutoAssigned =  true ;
      //$ctrl.autoAssignData.fullName = $ctrl.autoAssignData.fullName == null ? "Test User" : $ctrl.autoAssignData.fullName;
      //$ctrl.autoAssignData.autoAssignAccessSearch = "ACCESS TEST DATA 1234";
      //$ctrl.autoAssignData.autoAssignAccessDate = "2018-09-14T00:00:00Z";
      //if($ctrl.autoAssignData.autoAssignAccessSearch)
      //    $ctrl.autoAssignData.toolTip = $ctrl.leadAssignmentLabel + ": " + $ctrl.autoAssignData.autoAssignAccessSearch;
      //// test pando claim
      //$ctrl.opportunity.pandoClaim = {fullName:"James Larson", statusDateUTC:"08/17/2018 9:18 AM",statusID:"100"}
    });
    $ctrl.hasAutoAssist = false;
    dealerService.getIsAssetEnabledForDealer(assetTypes.autoAssistant, $ctrl.opportunity.dealerId).then(function (data) {
      $ctrl.hasAutoAssist = data;
    });
    $ctrl.hasReviewPro = false;
    dealerService.getIsAssetEnabledForDealer(assetTypes.reviewBuilderPro, $ctrl.opportunity.dealerId).then(function (data) {
      $ctrl.hasReviewPro = data;
    });
    $ctrl.hasGeoAlert = false;
    dealerService.getIsAssetEnabledForDealer(assetTypes.geoAlert, $ctrl.opportunity.dealerId).then(function (data) {
      $ctrl.hasGeoAlert = data;
    });
    dealSheetService.isVAutoEnabled($ctrl.opportunity.dealerId).then(function (data) {
      $ctrl.isVautoEnabled = data;
    });
    dealSheetService.isDmsPushValid($ctrl.opportunity.dealerId).then(function (data) {
      $ctrl.isDmsPushValid = data.status == 0;
    });
    dealSheetService.isVinCueEnabled($ctrl.opportunity.dealerId).then(function (data) {
      $ctrl.isVinCueEnabled = data;
    });
    if ($ctrl.OriginalTradeValue == null) $ctrl.OriginalTradeValue = $ctrl.opportunity.currentVehicle.tradeValue;

    if ($ctrl.isSltUser) {
      var expirationDateLimit = new Date();
      expirationDateLimit.setYear(expirationDateLimit.getFullYear() + 1);
      $ctrl.showExpirationDate = new Date($ctrl.opportunity.expirationDate) < expirationDateLimit;
    }

    $ctrl.showVehicleNotPaidoff = mapUtils.hasActionAccess($ctrl.opportunity.actionAccess, entityActionAccessEnum.showVehicleNotPaidoff);
    opportunityService.addAlertsAsProperties($ctrl, $ctrl.opportunity.alerts);
    setDocumentTitle();
    isOfferEnabled();
    checkPullForward();
    opportunityService.getOpportunitiesByVin($ctrl.opportunity.dealerId, $ctrl.opportunity.currentVehicle.vin).then(setRelatedOpportunitiesByVin);
    $ctrl.isDealSheetDirty = false;
    $ctrl.replacementStockNo = opportunity.replacementVehicle.stockNo;
    $ctrl.activityNotification = opportunityService.activityNotification.notify && opportunityService.activityNotification.triggered;
    var programDetails = null;
    if (opportunity.replacementContract) programDetails = opportunity.replacementContract.programDetails;
    var isPrivateOffer = programDetails != null && programDetails.some(function (p) {
      return p.isPrivateOffer;
    });
    var creditPreApproval = opportunity.creditPreApproval;
    $ctrl.isFordAlert = userService.isDealerEnabledForModule(opportunity.dealerId, modules.fordAlert);
    $ctrl.showPrivateOfferScript = $ctrl.isFordAlert && isPrivateOffer && !creditPreApproval;
    $ctrl.showPrivateOfferPreApprovalScript = $ctrl.isFordAlert && isPrivateOffer && creditPreApproval;

    if ($ctrl.showPrivateOfferScript) {
      $ctrl.selectedAlertScriptType = alertScriptTypes.privateoffer;
      searchService.getPrivateOfferInfo(opportunity.dealerId, opportunity.id).then(function (po) {
        opportunity.privateOfferInfo = po;
      });
    }

    if ($ctrl.showPrivateOfferPreApprovalScript) {
      $ctrl.selectedAlertScriptType = alertScriptTypes.privateOfferWithPreApproval;
      searchService.getPrivateOfferInfo(opportunity.dealerId, opportunity.id).then(function (po) {
        opportunity.privateOfferInfo = po;
      });
    }

    updateReplacementVehicleFuelCost(opportunity.replacementVehicle);
    loadDealerSettings().then(function (data) {
      $ctrl.autoRewardsUrl = getAutoRewardsUrl();

      if ($ctrl.dealerSettings.enableOffBrandMultiVehReplTolerances === false && $ctrl.opportunity.replacementVehicles && $ctrl.opportunity.replacementVehicles.offBrandAlternateVehicles) {
        $ctrl.opportunity.replacementVehicles.offBrandAlternateVehicles = $ctrl.opportunity.replacementVehicles.offBrandAlternateVehicles.filter(function (v) {
          return v.level >= 901 && v.level <= 905;
        });

        if ($ctrl.opportunity.replacementVehicles.offBrandAlternateVehicles.length > 0) {
          $ctrl.alternatesWithOvernightOffBrand = true;
        }
      }
    });
    opportunityService.getOpportunitiesByCustomerId($ctrl.opportunity.customerId).then(function (opportunities) {
      dealSheetService.getEntityEnterpriseCustomers($ctrl.opportunity.id).then(function (entityReport) {
        if (entityReport) {
          var enterpriseOpportunities = opportunityService.buildOpportunitiesFromEntityReport(entityReport);

          if (enterpriseOpportunities != null && enterpriseOpportunities.length > 0) {
            enterpriseOpportunities.forEach(function (enterpriseOpportunity) {
              var entityAlreadyInList = opportunities.some(function (entity) {
                return entity.id === enterpriseOpportunity.id;
              });

              if (!entityAlreadyInList) {
                $ctrl.isEnterpriseCustomer = true;
                opportunities.push(enterpriseOpportunity);
              }
            });
          }
        }

        $ctrl.relatedOpportunities = opportunities.filter(function (op) {
          return op.id != $ctrl.opportunity.id;
        }).map(function (op) {
          return _objectSpread({}, op, {
            alerts: op.alerts.map(function (type) {
              return {
                type: type
              };
            }) // see: interface Alert

          });
        });
        $ctrl.otherVehicleStatusClosed = $ctrl.relatedOpportunities.some(function (op) {
          return !op.status;
        });
        $ctrl.otherVehicleHasAlerts = $ctrl.relatedOpportunities.some(function (op) {
          return op.alerts.length > 0;
        });
        $ctrl.relatedOpportunities.sort( //serviceDate
        function (a, b) {
          //opp rank here
          if (a.opportunityRank > b.opportunityRank) {
            return -1;
          }

          if (a.opportunityRank < b.opportunityRank) {
            return 1;
          }

          if (a.currentVehicle.lastServiceDate > b.currentVehicle.lastServiceDate) {
            return -1;
          }

          if (a.currentVehicle.lastServiceDate < b.currentVehicle.lastServiceDate) {
            return 1;
          } //startDate


          if (a.currentContract.startDate > b.currentContract.startDate) {
            return -1;
          }

          if (a.currentContract.startDate < b.currentContract.startDate) {
            return 1;
          }

          return 0;
        });
      });
    });
    return $q.all({
      'users': dealerService.getUsers(opportunity.dealerId),
      'dealer': dealerService.getDealer(opportunity.dealerId)
    }).then(function (results) {
      return setUserDetails(userProfile, results.users, results.dealer);
    }).catch(errorExit(dontHaveAccessWarningKey));
  }

  function SetCrossBrandConditionsMet() {
    $ctrl.crossBrandConditionsMet = false;

    if (!$ctrl.opportunity.currentVehicle) {
      return;
      /*no current vehicle*/
    }

    if (!$ctrl.opportunity.replacementVehicle || !$ctrl.opportunity.replacementVehicle.makeName) {
      return;
      /*no replacement vehicle*/
    }

    if (!$ctrl.opportunity.currentContract) {
      return;
      /*no current Contract*/
    }

    if ($ctrl.opportunity.currentVehicle.yearID < new Date().getFullYear() - 7) {
      return;
      /*current vehicle year over 7 years old*/
    }

    if ($ctrl.opportunity.paidOff === true) {
      return;
      /*vehicle is paid off*/
    }

    if ($ctrl.opportunity.stillOwned === false) {
      return;
      /*vehicle no longer owned*/
    }

    if ($ctrl.opportunity.currentContract.paymentsMade < 7) {
      return;
      /*payments made not > 6*/
    }

    if (!$ctrl.opportunity.currentContract.saleType && ($ctrl.opportunity.currentContract.conquestType == conquestTypes.notConverted || $ctrl.opportunity.currentContract.conquestType == conquestTypes.creditConvert)) {
      return;
      /*conquest, no sale type and conquestType one of the two*/
    }

    $ctrl.crossBrandConditionsMet = true;
  }

  function isOfferEnabled() {
    var isUsedToUsed = $ctrl.opportunity.currentVehicle.type != vehicleTypes.new && $ctrl.opportunity.replacementVehicle.type != vehicleTypes.new;

    if (isUsedToUsed) {
      return false;
    }

    var isDealerEnabled = dealerService.getIsDealerOneToOneEnabled($ctrl.opportunity.dealerId);
    $ctrl.isOfferEnabled = isDealerEnabled ? $ctrl.opportunity.currentVehicle.replacementTrimID : false;
  }

  function funcKeydown($event) {
    if ($ctrl.userProfile.employeeUser) {
      if ($event.ctrlKey && $event.keyCode == $ctrl.mKey) {
        $ctrl.isDealsheetVersionChangeEnabled = true;
        $ctrl.changeDealSheetView();
      }
    }
  }

  function setDocumentTitle() {
    if (!$ctrl.newWindowButtonVisible && $ctrl.opportunity != null && $ctrl.opportunity.customer != null) {
      $document[0].title = ($ctrl.opportunity.customer.firstName || '') + ' ' + ($ctrl.opportunity.customer.middleName || '') + ' ' + ($ctrl.opportunity.customer.lastName || '');
    }
  }

  function checkPullForward() {
    $ctrl.showPullForward = false;

    if ($ctrl.opportunity != null && $ctrl.opportunity.replacementContract != null && $ctrl.opportunity.replacementContract.pullForwardAmount > 0) {
      var tradeEquity;
      if ($ctrl.opportunity.currentVehicle.tradeValue != null && $ctrl.opportunity.currentContract.payoffAmount != null) tradeEquity = $ctrl.opportunity.currentVehicle.tradeValue - $ctrl.opportunity.currentContract.payoffAmount;else tradeEquity = $ctrl.opportunity.currentVehicle.tradeValue;
      var btmEquity = -$ctrl.opportunity.currentContract.balanceToMaturity + $ctrl.opportunity.currentContract.dispositionFee + $ctrl.opportunity.replacementContract.pullForwardAmount;
      $ctrl.showPullForward = btmEquity > tradeEquity;
    }
  }

  function setRelatedOpportunitiesByVin(opportunities) {
    $ctrl.relatedOpportunitiesByVin = opportunities.filter(function (op) {
      return op.id != $ctrl.opportunityId.toLowerCase();
    }).map(function (op) {
      return _objectSpread({}, op, {
        alerts: op.alerts.map(function (type) {
          return {
            type: type
          };
        }) // see: interface Alert

      });
    });
  }

  function setUserDetails(userProfile, users, dealer) {
    //todo: just use the $ctrl.opportunity object for setting everything
    var makeDealerIds = [];
    var dealerGroupMakes = [];
    vehicleService.getMakes($ctrl.opportunity.dealerId, null).then(function (makes) {
      makeDealerIds = [$ctrl.opportunity.dealerId];
      dealerGroupMakes = makes.map(function (make) {
        return {
          makeId: make.makeID,
          makeName: make.makeName
        };
      });
      $ctrl.isFordLincoln = makes.some(function (m) {
        return environmentVariables.fordMakeIds.indexOf(m.makeID) > -1;
      }) || makes.some(function (m) {
        return environmentVariables.lincolnMakeIds.indexOf(m.makeID) > -1;
      });
      userProfile.dealerIds.forEach(function (dealerId) {
        if (makeDealerIds.includes(dealerId) == false) {
          makeDealerIds.push(dealerId);
        }
      });

      if ($ctrl.opportunity.dealerSettings.offBrandMakeIds) {
        vehicleService.getMakesMany($ctrl.opportunity.dealerSettings.offBrandMakeIds.split(",")).then(function (makes) {
          $ctrl.dealerGroupMakes = makes.map(function (make) {
            return {
              makeId: make.makeID,
              makeName: make.makeName
            };
          });
        });
      } else {
        $ctrl.dealerGroupMakes = dealerGroupMakes;
      }

      dealerService.getIsAssetEnabledForDealer(assetTypes.pandoDsButtonsOverride, $ctrl.opportunity.dealerId).then(function (hasPandoDsButtonsOverride) {
        $ctrl.isPandoConnectEnabled = environmentVariables.pandoDealSheetEnabled && (hasPandoDsButtonsOverride || !$ctrl.isFordLincoln || userProfile.countryId != dealerCountries.canada);
      });
    });
    $ctrl.opportunity.userProfile = userProfile;
    $ctrl.users = users;
    $ctrl.dealer = $ctrl.opportunity.dealer = dealer;
    $ctrl.newDealSheetReady = true;
    $ctrl.dealsheetWorking = null; //verifiy the user has signed EULA.if not return to Accept/decline EULA screen.

    if ($ctrl.userProfile == undefined || $ctrl.userProfile.eulaAcceptedDate == null || $ctrl.userProfile.eulaAcceptedDate == undefined) $state.go('login');

    if (!$ctrl.currentTab) {
      $ctrl.defaultTab = getDefaultTab();
      $ctrl.currentTab = $ctrl.defaultTab;
      $ctrl.changeTab($ctrl.currentTab);
    }
  }

  function getLegacyDealSheet() {
    var legacyUrl = '/AlertDesk/DealSheet/Show?e=' + $ctrl.opportunityId;

    if ($ctrl.calculationType) {
      legacyUrl += '&calc=' + $ctrl.calculationType;
    }

    if ($ctrl.stockNo) {
      legacyUrl += '&sn=' + $ctrl.stockNo;
    }

    if ($ctrl.newWindowButtonVisible) {
      legacyUrl += '&display=popup';
    }

    return $sce.trustAsResourceUrl(legacy.iframeUrl + legacyUrl);
  }

  function changeDealSheetView() {
    $ctrl.dealsheetWorking = $q.defer().promise;

    if (dealSheetService.modalDisplayState === 'new') {
      $ctrl.hideWhenBusy = false;
      dealSheetService.modalDisplayState = 'old';
      $ctrl.newDealSheetVisible = false;
      $ctrl.changeDealSheetLabel = switchToNewDealSheetKey;
      $ctrl.src = getLegacyDealSheet();
      $ctrl.dealsheetWorking = null;
    } else {
      $ctrl.src = "";
      dealSheetService.modalDisplayState = 'new';
      $ctrl.newDealSheetVisible = true;
      $ctrl.changeDealSheetLabel = switchToClassicDealSheetKey;
      initNewDealSheet();
      $ctrl.isDealsheetVersionChangeEnabled = false;
    }
  }

  function updateCustomerCancel() {
    // revert customer back to original settings since user selected cancel
    customerService.getCustomer($ctrl.opportunity.id).then(function (customer) {
      $ctrl.opportunity.customer = customer;
    });
  }

  function updateCustomer(customer) {
    $ctrl.startWorkingState();
    customerService.updateCustomer(customer).then(function (success) {
      if (success) {
        $ctrl.opportunity.customer = customer;
        reLoadOpportunity(1000);
      } else {
        $ctrl.stopWorkingState();
      }
    }, function () {
      $ctrl.stopWorkingState();
    });
  }

  function updateAssignedUser(user) {
    $ctrl.startWorkingState();
    opportunityService.updateAssignedUser($ctrl.opportunity.id, user.id).then(function (success) {
      if (success) {
        $ctrl.opportunity.assignedUserId = user.id;
        $ctrl.opportunity.assignedUserFullName = user.fullName;
      }

      reloadOpportunityOnSuccess(true);
    }).catch(function () {
      $ctrl.stopWorkingState();
    });
  }

  function changeTab(tab) {
    if (tab === $ctrl.tabs.featureComparison) {
      updateReplacementVehicleFeatures($ctrl.opportunity.replacementVehicle);
    }

    $ctrl.currentTab = tab;

    var tabLabel = _.invert($ctrl.tabs)[tab];

    $analytics.eventTrack(analytics.actions.navigate, {
      category: analytics.categories.dealSheet,
      label: analytics.labels[tabLabel]
    });
    refreshTabs();
  }

  function refreshTabs() {
    $ctrl.tabLoadingPromise = null;

    switch ($ctrl.currentTab) {
      case $ctrl.tabs.activityAndNotes:
        {
          if ($ctrl.activityNotification) {
            $ctrl.activityNotification = false;
            opportunityService.resetActivityNotification();
          }

          $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(setActivities);
          break;
        }

      case $ctrl.tabs.featureComparison:
        {
          if ($ctrl.defaultTab == $ctrl.tabs.featureComparison) {
            $timeout(function () {
              $scope.$broadcast('rzSliderForceRender');
            }, 1000);
          } else {
            $timeout(function () {
              $scope.$broadcast('rzSliderForceRender');
            }, 200);
          }

          break;
        }

      case $ctrl.tabs.serviceHistory:
        {
          if (!$ctrl.serviceHistory) {
            $ctrl.tabLoadingPromise = servicesService.getServicesBy($ctrl.opportunity.id).then(function (data) {
              return $ctrl.serviceHistory = data;
            });
          }

          break;
        }
    }
  }

  function setActivities(activities) {
    $ctrl.activities = activities;
  }

  function onActivityUpdate(data) {
    if ($ctrl.activities && $ctrl.userProfile && $ctrl.userProfile.userFullName && data.collectionUrl && !$ctrl.userProfile.employeeUser) {
      var activity = {
        type: activityTypes.printOfferLetter,
        submittedByUserFullName: $ctrl.userProfile.userFullName,
        submittedDateTime: new Date(),
        fileUrl: data.collectionUrl
      };
      $ctrl.activities.splice(0, 0, activity);
    }
  }

  function onTradeValueChange(value, logged) {
    startWorkingState();
    $ctrl.isDealSheetDirty = true;
    if (value == null || value == "") value = 0;
    if ($ctrl.opportunity.currentVehicle.tradeValue != value) $ctrl.isUserEditedTradeValue = true;
    $ctrl.opportunity.currentVehicle.tradeValue = value;
    reCalculateVehicleEquity();
    reCalculateReplacementContract();

    if (logged) {
      opportunityService.logTradeValueChange($ctrl.opportunity.vehicleId, value);
    }
  }

  function reCalculateVehicleEquity() {
    var tradeValue = $ctrl.opportunity.currentVehicle.tradeValue;
    var payoffValue = $ctrl.opportunity.currentContract.payoffAmount ? $ctrl.opportunity.currentContract.payoffAmount : 0;
    $ctrl.opportunity.currentContract.equity = {
      tradeValue: tradeValue,
      equityValue: tradeValue - payoffValue
    };
  }

  function onPriceChange(value) {
    $ctrl.isDealSheetDirty = true;
    $ctrl.opportunity.replacementVehicle.price = value;
    startWorkingState(); // log price change

    opportunityService.addPriceOverrideChangeLog($ctrl.opportunity.vehicleId, value);
    reCalculateReplacementContract();
  }

  function onMileageValueChange(value) {
    $ctrl.isUserEditedTradeValue = false;
    reLoadOpportunity(1000);
  }

  function onFinancialUpdate() {
    startWorkingState();
    reLoadOpportunity(1000);
    changeTab($ctrl.tabs.alertsAndScripts);
  }

  function onMarkAsNLO(markAsNLO) {
    // call api to mark as NLO or decline NLO then refresh deal sheet:
    opportunityService.updateActiveNLPNoLongerOwn($ctrl.opportunityId, markAsNLO).then(function () {
      $ctrl.refreshOpportunity();
      $ctrl.stopWorkingState();
    });
  }

  function reCalculateReplacementContract() {
    if ($ctrl.opportunity.replacementContract && $ctrl.opportunity.replacementContract.useOverrideTerm) {
      // setup override term
      $ctrl.hasTermOverride = true;
      $ctrl.overrideTermInfo = {
        term: $ctrl.opportunity.replacementContract.term,
        saleType: $ctrl.opportunity.replacementContract.saleType,
        saleTypeName: $ctrl.opportunity.replacementContract.saleTypeName
      };
    }

    contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions).then(setReplacementContracts);
  }

  function startWorkingState() {
    $ctrl.hideWhenBusy = true;
    $ctrl.dealsheetWorking = $q.defer().promise;
  }

  function stopWorkingState() {
    $ctrl.hideWhenBusy = false;
    $ctrl.dealsheetWorking = null;
  }

  function refreshOpportunity() {
    startWorkingState();
    reLoadOpportunity(10);
  }

  function reLoadOpportunity(waitTime) {
    $timeout(function () {
      opportunityService.getOpportunity($ctrl.opportunityId, $ctrl.stockNo, $ctrl.calculationId, $ctrl.permissions, $ctrl.moduleIds).then(function (opportunity) {
        setOpportunity(opportunity, $ctrl.userProfile);
        stopWorkingState();
      }, function () {
        stopWorkingState();
        throw {
          message: "We encountered an error processing your request."
        };
      }).catch(errorExit(dontHaveAccessWarningKey));
    }, waitTime);
  }

  function onWatchChange(value) {
    if (value) {
      return opportunityService.markOpportunityWatched($ctrl.opportunityId).then(function () {
        $ctrl.opportunity.watched = true;
      });
    } else {
      return opportunityService.markOpportunityUnwatched($ctrl.opportunityId).then(function () {
        $ctrl.opportunity.watched = false;
      });
    }
  }

  function updateReplacementVehicleByMsrpType(msrpType) {
    if (msrpType == msrpTypes.inventory) {
      $analytics.eventTrack(analytics.actions.changeReplacementVehicle, {
        category: analytics.categories.dealSheet,
        label: analytics.labels.inventoryBestMatch
      });
    }

    if (msrpType == msrpTypes.average) {
      vehicleService.getAverageReplacementVehicle($ctrl.opportunity.dealerId, $ctrl.opportunity.vehicleId, $ctrl.opportunity.customer.pricingPlanId).then(setInventoryOrAverageReplacementVehicle);
    } else {
      if ($ctrl.replacementStockNo) {
        vehicleService.getReplacementVehicles($ctrl.opportunity.id, $ctrl.stockNo, $ctrl.opportunity.customer.pricingPlanId).then(function (replacementVehicles) {
          $ctrl.opportunity.replacementVehicles = replacementVehicles;
          setReplacementVehicle(replacementVehicles.standardVehicle);
        });
      } else {
        vehicleService.getInventoryReplacementVehicle($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.modelID, $ctrl.opportunity.replacementVehicle.trimID, $ctrl.opportunity.customer.pricingPlanId).then(setReplacementVehicle);
      }
    }
  }

  function setMVOVehicle(selectedMVOEventObject) {
    $ctrl.selectedReplacementVehicle = selectedMVOEventObject;

    switch (selectedMVOEventObject.selectedVehicleType) {
      case "upgradeVehicle":
        setReplacementVehicle($ctrl.opportunity.replacementVehicles.upgradeVehicle);
        break;

      case "downgradeVehicle":
        setReplacementVehicle($ctrl.opportunity.replacementVehicles.downgradeVehicle);
        break;

      case "standardVehicle":
        setReplacementVehicle($ctrl.opportunity.replacementVehicles.standardVehicle);
        break;

      case "alternateVehicle":
        setReplacementVehicle($ctrl.opportunity.replacementVehicles.alternateVehicles[selectedMVOEventObject.alternateVehicleIndex]);
        break;

      case "offBrandVehicle":
        setReplacementVehicle($ctrl.opportunity.replacementVehicles.offBrandAlternateVehicles[selectedMVOEventObject.alternateVehicleIndex]);
        break;

      case "onDemandVehicle":
        setReplacementVehicle(selectedMVOEventObject.onDemandVehicle);
        break;
    }
  }

  function setReplacementVehicle(vehicle) {
    if (!vehicle) {
      $ctrl.showNoRecommendedVehiclesMessage();
      return;
    }

    $ctrl.startWorkingState();
    updateReplacementVehicleFuelCost(vehicle);
    $ctrl.featureComparison = null;

    if ($ctrl.pricingPlanEnabled && vehicle.pricingPlanID && vehicle.pricingPlanID > 0) {
      vehicle.price = vehicle.pricingPlanMsrp;
    }

    if (!vehicle.modelID) {
      vehicleService.getModelId(vehicle.trimID).then(function (modelID) {
        $ctrl.isDealSheetDirty = true;
        $ctrl.opportunity.replacementVehicle = vehicle;
        $ctrl.opportunity.replacementVehicle.modelID = modelID;
        $ctrl.opportunity.replacementVehicle.pricingPlanMsrp = vehicle.pricingPlanMsrp;
        updateReplacementVehicleImagePath();
        contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions).then(setReplacementContracts);
      });
    } else {
      $ctrl.isDealSheetDirty = true;
      $ctrl.opportunity.replacementVehicle = vehicle;
      $ctrl.opportunity.replacementVehicle.pricingPlanMsrp = vehicle.pricingPlanMsrp;
      updateReplacementVehicleImagePath();
      contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions).then(setReplacementContracts);
    }
  }

  function updateReplacementVehicleImagePath() {
    if (!$ctrl.opportunity.replacementVehicle.imagePath) {
      vehicleService.getVehicleImagePath($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.trimID).then(function (imagePath) {
        $ctrl.opportunity.replacementVehicle.imagePath = imagePath;
      });
    }
  }

  function updateReplacementVehicleFuelCost(vehicle) {
    if (vehicle && vehicle.trimID && vehicle.trimID > 0) {
      vehicleService.getFuelEconomyComparison(vehicle.trimID, $ctrl.opportunityId).then(function (data) {
        $ctrl.fuelEconomyComparison = data;
      }).catch(function () {
        $ctrl.fuelEconomyComparison = null;
      });
    } else {
      $ctrl.fuelEconomyComparison = null;
    }
  }

  function updateReplacementVehicleFeatures(vehicle) {
    if (vehicle && vehicle.trimID && vehicle.trimID > 0) {
      vehicleService.getFeatureComparison(vehicle.trimID, $ctrl.opportunityId).then(function (data) {
        $ctrl.featureComparison = data;
      }).catch(function () {
        $ctrl.featureComparison = null;
      });
    } else {
      $ctrl.featureComparison = null;
    }
  }

  function updateReplacementVehicleByTrim(trim) {
    $analytics.eventTrack(analytics.actions.changeReplacementVehicle, {
      category: analytics.categories.dealSheet,
      label: analytics.labels.trimChange
    });
    $ctrl.startWorkingState();
    var trimIsValid = !(trim.yearID == "" || trim.makeID == "" || trim.modelID == "");

    if (trimIsValid) {
      var vehicle = {
        type: vehicleTypes.new,
        makeID: trim.makeID,
        makeName: trim.makeName,
        yearID: trim.yearID,
        modelID: trim.modelID,
        modelName: trim.modelName,
        trimID: trim.trimID,
        trimName: trim.trimName,
        trimFullName: trim.trimFullName,
        extID: trim.extID
      };
      updateReplacementVehicleFuelCost(vehicle);
      $ctrl.opportunity.replacementVehicle = vehicle;
      $ctrl.opportunity.isDealSheetDirty = true;
      vehicleService.getVehicleImagePath($ctrl.opportunity.dealerId, vehicle.trimID).then(function (imagePath) {
        $ctrl.opportunity.replacementVehicle.imagePath = imagePath;
        vehicleService.getTrimPlanPrice($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.trimID, $ctrl.opportunity.customer.pricingPlanId).then(function (trimPriceInfo) {
          if ($ctrl.opportunity.customer.pricingPlanId > 0) {
            vehicle.pricingPlanMsrp = trimPriceInfo.newVehiclePrice;
          }

          setTrimPriceInfo(trimPriceInfo.newVehiclePrice, trimPriceInfo.sourceID, trimPriceInfo.sourceName);
        });
        vehicleService.getTrimPrice($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.trimID).then(setReplacementVehicleMsrp);
      });
    }
  }

  function setTrimPriceInfo(price, trimPlanSourceId, trimPlanSourceName) {
    $ctrl.opportunity.replacementVehicle.trimPlanPriceSourceId = trimPlanSourceId;
    $ctrl.opportunity.replacementVehicle.trimPlanPriceSourceName = trimPlanSourceName;
    $ctrl.opportunity.replacementVehicle.price = price;
  }

  function setReplacementVehicleMsrp(msrp) {
    $ctrl.opportunity.replacementVehicle.msrp = msrp;
    contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions).then(setReplacementContracts);

    if ($ctrl.opportunity.replacementVehicle.type == $ctrl.vehicleTypes.new && $ctrl.isFordAlert && $ctrl.isFordLincoln) {
      vehicleService.getInventoryBestMatchToTrim($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.trimID, $ctrl.opportunity.replacementVehicle.msrp).then(function (avgVehicle) {
        if (avgVehicle != null) {
          setAvgVehicleVIN(avgVehicle.vin);
        }
      });
    }
  }

  function setAvgVehicleVIN(vin) {
    $ctrl.opportunity.replacementVehicle.bestTrimMatch_VIN = vin;
  }

  function setReplacementContracts(data) {
    if (data && data.contracts) {
      $ctrl.opportunity.replacementContracts = data.contracts;

      if ($ctrl.hasTermOverride && $ctrl.overrideTermInfo && $ctrl.overrideTermInfo.term) {
        // get override contract from term and set to replacement...
        var overrideContract = _.find(data.contracts, function (o) {
          return o.term === $ctrl.overrideTermInfo.term && o.saleType === $ctrl.overrideTermInfo.saleType;
        });

        if (overrideContract) {
          overrideContract.useOverrideTerm = true;
          $ctrl.opportunity.replacementContract = overrideContract;
        } else {
          $ctrl.opportunity.replacementContract = data.defaultContract;
        }

        $ctrl.hasTermOverride = false;
      } else {
        $ctrl.opportunity.replacementContract = data.defaultContract;
      }

      if ($ctrl.opportunity.replacementContract) {
        $ctrl.opportunity.replacementContract.vehicleType = $ctrl.opportunity.replacementVehicle.type;
      }
    } else {
      $ctrl.opportunity.replacementContracts = [];
      $ctrl.opportunity.replacementContract = null;
    }

    checkPullForward();
    stopWorkingState();
  }

  function setReplacementContractOverride(contract) {
    $ctrl.startWorkingState(); // persist vehile type from current replacement contract since contract does not persist it

    if ($ctrl.opportunity.replacementContract) {
      contract.vehicleType = $ctrl.opportunity.replacementContract.vehicleType;
    } else {
      contract.vehicleType = vehicleTypes.new;
    } // set replacement contract


    $ctrl.opportunity.replacementContract = contract;
    checkPullForward(); // log into change log

    opportunityService.addTermOverrideChangeLog($ctrl.opportunity.vehicleId, contract.term).then($ctrl.stopWorkingState, $ctrl.stopWorkingState);
  }

  function onLogScheduleSubmit(result) {
    if (!result) {
      return;
    } // user data entry error


    if (result == 'cancel') {
      // user canceled operation close accordian panel and reset Customer Connect to default
      $ctrl.isCustomerConnectOpen = !$ctrl.isCustomerConnectOpen;
      $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Log_Call;
      return;
    } // submit user's activity changes


    $ctrl.isCustomerConnectOpen = !$ctrl.isCustomerConnectOpen;
    startWorkingState();
    activityService.postActivitySubmitAction(result).then(function (data) {
      if (result.isFollowUpScheduleAppt) {
        logger.success("postActivityFollowupMsg");
        $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Schedule_Appt;
        $ctrl.isCustomerConnectOpen = !$ctrl.isCustomerConnectOpen;
      } else {
        logger.success("postActivitySuccessMsg");
        $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Log_Call;
      } // if vehicle no longer owns


      if (result.resultID == activityResults.phoneCall.noLongerOwns || result.resultID == activityResults.note.noLongerOwns) {
        $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(setActivities);
        activityService.updateCustomerDoesNotOwnVehicle($ctrl.opportunityId).then(function () {
          $ctrl.refreshOpportunity();
          $ctrl.stopWorkingState();
        });
      } else onScheduleSubmit(); // if vehicle paid off


      if (result.resultID == activityResults.phoneCall.vehiclePaidOff) {
        activityService.updateVehiclePaidOff($ctrl.opportunityId).then(function () {
          $ctrl.refreshOpportunity();
          $ctrl.stopWorkingState();
        });
      }
    });
  }

  function onNoLongerOwns() {
    startWorkingState();
    $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(setActivities);
    activityService.updateCustomerDoesNotOwnVehicle($ctrl.opportunityId).then($ctrl.refreshOpportunity, $ctrl.stopWorkingState);
  }

  function onScheduleSubmit() {
    startWorkingState();
    $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(setActivities);
    $ctrl.refreshOpportunity();
  }

  function showPrintDialog() {
    activityService.runActivityRule($ctrl.opportunity.id, [activityRule.Print_Deal_Sheet]);

    if (!$ctrl.activities) {
      $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(function (activities) {
        $ctrl.activities = activities;
        $timeout($window.print, 250);
      });
    } else {
      $window.print();
    }
  }

  function showFeaturePrintDialog() {
    $ctrl.printingFeatures = true;
    $window.setTimeout(function () {
      $window.print();
      $ctrl.printingFeatures = false;
    }, 500);
  }

  function setReplacementVehicleType(vehicleType) {
    $ctrl.startWorkingState();
    $ctrl.isDealSheetDirty = true;
    $ctrl.opportunity.replacementVehicle.type = vehicleType;
    vehicleService.getAdjustedInventoryVehiclePrice($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.type, $ctrl.opportunity.replacementVehicle.msrp).then(function (price) {
      $ctrl.opportunity.replacementVehicle.price = price;
      contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions).then(function (data) {
        return setReplacementContracts.call($ctrl, data);
      });
    });
  }

  function updatePayoff(payoffResult) {
    startWorkingState();
    contractService.updatePayoff($ctrl.opportunityId, payoffResult.payoffAmount, payoffResult.goodThroughDate).then(reloadOpportunityOnSuccess).catch(stopWorkingState);
  }

  function onDestroy() {
    opportunityService.resetActivityNotification();
    angular.element($window).off("keydown", $ctrl.funcKeydown);
  }

  function reopen() {
    startWorkingState();
    opportunityService.reopenOpportunity($ctrl.opportunityId).then(reloadOpportunityOnSuccess);
  }

  function reloadOpportunityOnSuccess(success) {
    if (success) {
      reLoadOpportunity(0);
    } else {
      stopWorkingState();
    }
  }

  function onMarkAsSold() {
    startWorkingState();
    activityService.runActivityRule($ctrl.opportunityId, [activityRule.Mark_As_Sold, activityRule.Close]).then(function (response) {
      reloadOpportunityOnSuccess(true);
    }, function () {
      $ctrl.stopWorkingState();
    });
  }

  function markAsOnOrder() {
    startWorkingState();
    activityService.runActivityRule($ctrl.opportunityId, [activityRule.Mark_As_On_Order]).then(function (response) {
      reloadOpportunityOnSuccess(true);
    }, function () {
      $ctrl.stopWorkingState();
    });
  }

  function unMarkAsOnOrder() {
    startWorkingState();
    activityService.runActivityRule($ctrl.opportunityId, [activityRule.Unmark_As_On_Order]).then(function (response) {
      reloadOpportunityOnSuccess(true);
    }, function () {
      $ctrl.stopWorkingState();
    });
  }

  function getCreditConvertData() {
    opportunityService.getCreditSoftPullResultAsync($ctrl.permissions, $ctrl.opportunityId).then(function (creditPrescreenResult) {
      $ctrl.opportunity.creditSoftPull = creditPrescreenResult;
    });
    opportunityService.getTags($ctrl.opportunityId).then(function (tags) {
      $ctrl.opportunity.tags = tags;
    });
  }

  function submitDiscrepancy(discrepancy) {
    $ctrl.dealsheetWorking = opportunityService.submitDiscrepancy($ctrl.opportunity, discrepancy).then(showSuccessMessage);

    function showSuccessMessage() {
      logger.success(submitDiscrepancySuccessMessageKey);
    }
  }

  function setInventoryOrAverageReplacementVehicle(vehicle) {
    $ctrl.selectedReplacementVehicle = null;
    setReplacementVehicle(vehicle);
  }

  function setCreditPrescreenStatus(status) {
    $ctrl.opportunity.creditPrescreenStatus = status;
  }

  function showNoRecommendedVehiclesMessage() {
    $ctrl.noRecommendedVehiclesMessageVisible = true;
    $timeout(function () {
      $ctrl.noRecommendedVehiclesMessageVisible = false;
    }, 5000);
  }

  function getDefaultTab() {
    var defaultTabs = storageService.getItem('defaultDealSheetTabs');
    var defaultTab;

    if (defaultTabs && defaultTabs[$ctrl.userProfile.userId]) {
      defaultTab = defaultTabs[$ctrl.userProfile.userId];
    } else {
      defaultTab = $ctrl.tabs.alertsAndScripts;
    }

    if (!isTabAccessible(defaultTab)) {
      defaultTab = getAccessibleTabs()[0];
    }

    return defaultTab;
  }

  function setDefaultTab() {
    var defaultTabs = storageService.getItem('defaultDealSheetTabs') || {};
    defaultTabs[$ctrl.userProfile.userId] = $ctrl.currentTab;
    storageService.setItem('defaultDealSheetTabs', defaultTabs);
    $ctrl.defaultTab = $ctrl.currentTab;
  }

  function getAccessibleTabs() {
    var accessibleTabs = [];
    var tabs = [$ctrl.tabs.alertsAndScripts, $ctrl.tabs.currentVehicleDetails, $ctrl.tabs.replacementVehicleDetails, $ctrl.tabs.featureComparison, $ctrl.tabs.activityAndNotes, $ctrl.tabs.serviceHistory, $ctrl.tabs.otherVehiclesOwned, $ctrl.tabs.changeLog];

    for (var i = 0; i < tabs.length; i++) {
      var tab = tabs[i];

      if (isTabAccessible(tab)) {
        accessibleTabs.push(tab);
      }
    }

    return accessibleTabs;
  }

  function isTabAccessible(tab) {
    switch (tab) {
      case $ctrl.tabs.alertsAndScripts:
        return $ctrl.permissions.viewAlertScripts;

      case $ctrl.tabs.currentVehicleDetails:
        return $ctrl.permissions.viewVehicle || $ctrl.permissions.viewContract;

      case $ctrl.tabs.replacementVehicleDetails:
        return $ctrl.permissions.payments;

      case $ctrl.tabs.dealHistory:
        return $ctrl.permissions.payments;

      case $ctrl.tabs.activityAndNotes:
        return $ctrl.permissions.activitiesLog;

      case $ctrl.tabs.serviceHistory:
        return $ctrl.permissions.serviceHistory;

      case $ctrl.tabs.otherVehiclesOwned:
        return $ctrl.userProfile.roleName != $ctrl.userProfileRoles.Sales_Associate && $ctrl.userProfile.roleName != $ctrl.userProfileRoles.Base_Sales_Associate;

      case $ctrl.tabs.changeLog:
        return $ctrl.permissions.viewChangeLog;

      default:
        return true;
    }
  }

  function onVehicleActionUpdate(vehicleActionUpdated) {
    $ctrl.startWorkingState();

    switch (vehicleActionUpdated) {
      case vehicleActions.doesNotOwnVehicle:
        activityService.updateCustomerDoesNotOwnVehicle($ctrl.opportunityId).then($ctrl.refreshOpportunity, $ctrl.stopWorkingState);
        break;

      case vehicleActions.vehiclePaidOff:
        activityService.updateVehiclePaidOff($ctrl.opportunityId).then($ctrl.refreshOpportunity, $ctrl.stopWorkingState);
        break;

      case vehicleActions.vehicleNotPaidOff:
        vehicleNotPaidOff();
        break;

      case vehicleActions.purchasedByDealer:
        activityService.updateVehiclePurchasedByDealer($ctrl.opportunityId).then($ctrl.refreshOpportunity, $ctrl.stopWorkingState);
        break;

      default:
        $ctrl.stopWorkingState();
        break;
    }
  }

  function isVehicleStatusVisible() {
    //if ($ctrl.opportunity){
    //    if ($ctrl.opportunity.status == $ctrl.opportunityStatuses.closed)
    //        return false;
    //    if ($ctrl.opportunity.status == $ctrl.opportunityStatuses.sold)
    //        return false;
    //}
    var availableChoices = 0;
    if ($ctrl.permissions.editMarkAsNotOwn) availableChoices++;
    if ($ctrl.permissions.editMarkAsPaidOff) availableChoices++;
    if ($ctrl.permissions.editMarkAsSold) availableChoices++;
    return availableChoices > 0 ? true : false;
  }

  function logShortcut() {
    $ctrl.isCustomerConnectOpen = true;
  }

  function openHotTradeModal() {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'hotTradeDetailsModal',
      appendTo: parentElem,
      resolve: {
        renderedPromise: function renderedPromise() {
          return {
            vehicle: $ctrl.opportunity.currentVehicle,
            hotTrade: $ctrl.hotTradeData
          };
        }
      }
    });
    return modalInstance;
  }

  function openSmartVincentLink() {
    if ($ctrl.opportunity.replacementVehicle.vin != null || $ctrl.opportunity.replacementVehicle.bestTrimMatch_VIN) {
      var market = "USA";
      var language = "EN";

      switch ($ctrl.cultureName) {
        case cultures.enUS:
        case cultures.enCA:
          language = "EN";
          break;

        case cultures.frCA:
        case cultures.frFR:
          language = "FR";
          break;

        default:
          language = "EN";
          break;
      }

      switch ($ctrl.cultureName) {
        case cultures.enUS:
          market = "USA";
          break;

        case cultures.enCA:
        case cultures.frCA:
          market = "CAN";
          break;

        default:
          market = "USA";
          break;
      }

      var smartVincentUrl = environmentVariables.smartVincentUrl;
      smartVincentUrl = smartVincentUrl.replace("{0}", $ctrl.opportunity.replacementVehicle.vin != null ? $ctrl.opportunity.replacementVehicle.vin : $ctrl.opportunity.replacementVehicle.bestTrimMatch_VIN);
      smartVincentUrl = smartVincentUrl.replace("{1}", market);
      smartVincentUrl = smartVincentUrl.replace("{2}", language);

      if ($ctrl.opportunity.customer.lastName) {
        smartVincentUrl += "&lastName=" + encodeURIComponent($ctrl.opportunity.customer.lastName);
      }

      if ($ctrl.opportunity.customer.firstName) {
        smartVincentUrl += "&firstName=" + encodeURIComponent($ctrl.opportunity.customer.firstName);
      }

      if ($ctrl.opportunity.customer.address.streetAddress && $ctrl.opportunity.customer.address.streetAddress2) {
        smartVincentUrl += "&street=" + encodeURIComponent($ctrl.opportunity.customer.address.streetAddress + ", " + $ctrl.opportunity.customer.address.streetAddress);
      } else if ($ctrl.opportunity.customer.address.streetAddress) {
        smartVincentUrl += "&street=" + encodeURIComponent($ctrl.opportunity.customer.address.streetAddress);
      }

      if ($ctrl.opportunity.customer.address.city) {
        smartVincentUrl += "&city=" + encodeURIComponent($ctrl.opportunity.customer.address.city);
      }

      if ($ctrl.opportunity.customer.address.stateName) {
        smartVincentUrl += "&state=" + $ctrl.opportunity.customer.address.stateName;
      }

      if ($ctrl.opportunity.customer.address.zipCode) {
        var zipCode = "";

        if ($ctrl.userProfile.countryId == dealerCountries.usa) {
          zipCode = $ctrl.opportunity.customer.address.normalizedZipCode;
        } else if ($ctrl.userProfile.countryId == dealerCountries.canada) {
          zipCode = $ctrl.opportunity.customer.address.zipCode.replace(/\s+/g, '').substring(0, 6);
        } else {
          zipCode = $ctrl.opportunity.customer.address.zipCode;
        }

        smartVincentUrl += "&zipCode=" + encodeURIComponent(zipCode);
      }

      if ($ctrl.opportunity.customer.emailAddress && $ctrl.opportunity.customer.emailAddress.value) {
        smartVincentUrl += "&email=" + encodeURIComponent($ctrl.opportunity.customer.emailAddress.value);
      }

      if ($ctrl.opportunity.customer.companyName && $ctrl.opportunity.customer.companyName.length > 1) {
        // This is a business
        // if both a home and work phone put them in respective homePhone and businessPhone parameters:
        if ($ctrl.opportunity.customer.homePhoneNumber && $ctrl.opportunity.customer.homePhoneNumber.value && $ctrl.opportunity.customer.workPhoneNumber && $ctrl.opportunity.customer.workPhoneNumber.value) {
          smartVincentUrl += "&homePhone=" + encodeURIComponent($ctrl.opportunity.customer.homePhoneNumber.value);
          smartVincentUrl += "&businessPhone=" + encodeURIComponent($ctrl.opportunity.customer.workPhoneNumber.value);
        } else {
          // if only a home phone or work phone put it in both homePhone and businessPhone parameters:
          if ($ctrl.opportunity.customer.homePhoneNumber && $ctrl.opportunity.customer.homePhoneNumber.value) {
            smartVincentUrl += "&homePhone=" + encodeURIComponent($ctrl.opportunity.customer.homePhoneNumber.value);
            smartVincentUrl += "&businessPhone=" + encodeURIComponent($ctrl.opportunity.customer.homePhoneNumber.value);
          } else if ($ctrl.opportunity.customer.workPhoneNumber && $ctrl.opportunity.customer.workPhoneNumber.value) {
            smartVincentUrl += "&homePhone=" + encodeURIComponent($ctrl.opportunity.customer.workPhoneNumber.value);
            smartVincentUrl += "&businessPhone=" + encodeURIComponent($ctrl.opportunity.customer.workPhoneNumber.value);
          }
        }
      } else {
        // This is a regular customer, not a business. Put home and work numbers in respective homePhone and businessPhone parameters:
        if ($ctrl.opportunity.customer.homePhoneNumber && $ctrl.opportunity.customer.homePhoneNumber.value) {
          smartVincentUrl += "&homePhone=" + encodeURIComponent($ctrl.opportunity.customer.homePhoneNumber.value);
        }

        if ($ctrl.opportunity.customer.workPhoneNumber && $ctrl.opportunity.customer.workPhoneNumber.value) {
          smartVincentUrl += "&businessPhone=" + encodeURIComponent($ctrl.opportunity.customer.workPhoneNumber.value);
        }
      }

      console.log('smartVincent url: ' + smartVincentUrl);
      $window.open(smartVincentUrl, '_blank');
    }
  }
}