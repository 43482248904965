"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var services_1 = require("../generated/services");
var storage_service_1 = require("../modules/storage/storage.service");
var window_ref_service_1 = require("./window-ref.service");
var app_settings_1 = require("../constants/app-settings");
var dealer_service_1 = require("./dealer.service");
var object_cache_1 = require("../utilities/object-cache");
var pando_communication_service_1 = require("../services/pando-communication.service");
var UserService = /** @class */ (function () {
    function UserService(window, announcementClientService, cacheManagementClientService, dealerService, eulaClientService, eulaHistoryClientService, storageService, userClientService, userProfileService, pandoCommunicationService) {
        this.window = window;
        this.announcementClientService = announcementClientService;
        this.cacheManagementClientService = cacheManagementClientService;
        this.dealerService = dealerService;
        this.eulaClientService = eulaClientService;
        this.eulaHistoryClientService = eulaHistoryClientService;
        this.storageService = storageService;
        this.userClientService = userClientService;
        this.userProfileService = userProfileService;
        this.pandoCommunicationService = pandoCommunicationService;
        this.facebookUserIdCache = new object_cache_1.ObjectCache({ cacheAgeLimitSeconds: app_settings_1.Settings.environmentVariables.defaultInMemoryCacheTimeoutSeconds });
    }
    UserService.prototype.getAnnouncementForUser = function () {
        return this.announcementClientService
            .GetAnnouncementForUserGET()
            .toPromise();
    };
    UserService.prototype.getCellPhoneNumberDetails = function () {
        return this.getUserProfile().then(function (userProfile) {
            return {
                areaCode: userProfile.cellPhoneArea,
                part1: userProfile.cellPhonePart1,
                part2: userProfile.cellPhonePart2,
                wirelessCarrier: userProfile.cellPhoneCarrier
            };
        });
    };
    UserService.prototype.getCultureNameSync = function () {
        return this.storageService.getItem("cultureName");
    };
    UserService.prototype.getCultures = function () {
        return this.userProfileService.GetCulturesGET().toPromise();
    };
    UserService.prototype.getEula = function (eulaId) {
        return this.eulaClientService.ByIdGET(eulaId).toPromise();
    };
    UserService.prototype.getEulaStatus = function () {
        return this.eulaHistoryClientService.GetStatusGET().toPromise();
    };
    UserService.prototype.getFacebookUserId = function (emailAddress) {
        var _this = this;
        if (isValidEmailAddress(emailAddress)) {
            return this.facebookUserIdCache.getOrFetch(emailAddress, function () { return _this.userClientService
                .FacebookUserIdByEmailaddressGET(emailAddress)
                .toPromise(); });
        }
        else {
            return Promise.resolve(null);
        }
        function isValidEmailAddress(email) {
            var emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailExpression.test(email);
        }
    };
    UserService.prototype.getTimeZones = function () {
        return this.userProfileService.GetTimeZonesGET().toPromise();
    };
    UserService.prototype.getUser = function (username) {
        return this.userClientService
            .GetUserByUsernameGET(username)
            .toPromise();
    };
    UserService.prototype.getUserIsMetric = function (countryId) {
        return this.userClientService
            .GetUserIsMetricByCountryidGET(countryId)
            .toPromise();
    };
    /**
     * Gets the user profile. A cached value may be returned unless forceReload
     * is specified.
     * @param forceReload - Disregard any cached value and retrieve from the server.
     */
    UserService.prototype.getUserProfile = function (forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(forceReload ||
                            // If these values are missing, assume this is the first load after
                            // login and invalidate any server-side cache
                            !this.storageService.getItem("accessRightIds") ||
                            !this.storageService.getItem("moduleIds") ||
                            !this.storageService.getItem("userProfile"))) return [3 /*break*/, 2];
                        this.userPromise = null;
                        this.cachedUserProfile = null;
                        return [4 /*yield*/, this.cacheManagementClientService
                                .FlushUserRelatedCacheGET()
                                .toPromise()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        if (this.cachedUserProfile) {
                            return [2 /*return*/, this.cachedUserProfile];
                        }
                        if (this.userPromise != null) {
                            return [2 /*return*/, this.userPromise];
                        }
                        this.userPromise = this.getUserProfileImpl();
                        _a = this;
                        return [4 /*yield*/, this.userPromise];
                    case 3:
                        _a.cachedUserProfile = _b.sent();
                        return [2 /*return*/, this.cachedUserProfile];
                }
            });
        });
    };
    /**
     *  Gets the user's profile from the server. Never returns a cached value.
     */
    UserService.prototype.getUserProfileImpl = function () {
        return __awaiter(this, void 0, void 0, function () {
            function setGtmData(window, employeeUser, role, dealerCodes) {
                if (!window.dataLayer) {
                    var dealerCode;
                    if (dealerCodes && dealerCodes.length > 0) {
                        dealerCode = dealerCodes[0].dealerCode;
                    }
                    window.dataLayer = [
                        {
                            product: "Opportunities",
                            isAutoAlertEmployee: employeeUser ? "Yes" : "No",
                            dealerCode: dealerCode,
                            role: role
                        }
                    ];
                    (function (w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({
                            "gtm.start": new Date().getTime(),
                            event: "gtm.js"
                        });
                        var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != "dataLayer" ? "&l=" + l : "";
                        j.async = true;
                        j.src =
                            "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                        f.parentNode.insertBefore(j, f);
                    })(window, window.document, "script", "dataLayer", app_settings_1.Settings.tagManager.containerId);
                }
            }
            var _a, loggedInUser, userInfo, profile, motofuzeUser, gfConfig, selectedDealerIds, cxmViewSelectedDealers, cxmDealers, dealers, dealerListsMatch, dealerGroupId, dealerDisplayText, dealerCodes, dealerModules, countryId, i, dealer, dealerFordInfo, refreshedUser, _loop_1, i, dealerIds, accountPlatformIds, userProfile;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.userClientService.LoggedInUserGET().toPromise(),
                            this.userClientService.CurrentUserGET().toPromise(),
                            this.userProfileService.ExtendedGET().toPromise(),
                            this.pandoCommunicationService.getMotofuzeCurrentUser(),
                            this.pandoCommunicationService.getGlobalFrameConfig()
                        ])];
                    case 1:
                        _a = _b.sent(), loggedInUser = _a[0], userInfo = _a[1], profile = _a[2], motofuzeUser = _a[3], gfConfig = _a[4];
                        setGtmData(this.window, profile.isAutoAlertEmployee, loggedInUser.roleName, loggedInUser.dealerCodes);
                        return [4 /*yield*/, this.dealerService.getSelectedDealerIds()];
                    case 2:
                        selectedDealerIds = _b.sent();
                        return [4 /*yield*/, this.dealerService.getCXMViewSelectedStores()];
                    case 3:
                        cxmViewSelectedDealers = _b.sent();
                        cxmDealers = cxmViewSelectedDealers && cxmViewSelectedDealers.viewSelectedStores ? cxmViewSelectedDealers.viewSelectedStores : [];
                        dealers = [];
                        dealerListsMatch = true;
                        dealerGroupId = loggedInUser.dealerGroupId;
                        dealerDisplayText = loggedInUser.dealerDisplayText;
                        dealerCodes = loggedInUser.dealerCodes;
                        dealerModules = loggedInUser.dealerModules;
                        countryId = loggedInUser.countryId;
                        if (!profile.isAutoAlertEmployee) return [3 /*break*/, 7];
                        if (!(cxmViewSelectedDealers && cxmDealers)) return [3 /*break*/, 6];
                        for (i = 0; i < cxmDealers.length; i++) {
                            dealer = { id: cxmDealers[i].AutoAlertDealerID, name: cxmDealers[i].Name };
                            dealers.push(dealer);
                            if (selectedDealerIds && dealerListsMatch) {
                                dealerListsMatch = selectedDealerIds.includes(cxmDealers[i].AutoAlertDealerID);
                            }
                            else {
                                dealerListsMatch = false;
                            }
                        }
                        return [4 /*yield*/, this.dealerService.getDealerFordInfo(dealers[0].id)];
                    case 4:
                        dealerFordInfo = _b.sent();
                        if (dealerFordInfo && dealerFordInfo.length > 0) {
                            countryId = dealerFordInfo[0].countryID;
                        }
                        if ((cxmViewSelectedDealers.viewDealerGroupID && loggedInUser.dealerGroupId && cxmViewSelectedDealers.viewDealerGroupID != loggedInUser.dealerGroupId) ||
                            (cxmViewSelectedDealers.viewAccount && loggedInUser.dealerDisplayText && cxmViewSelectedDealers.viewAccount != loggedInUser.dealerDisplayText)) {
                            //lists may match but user defaults are not current
                            dealerGroupId = cxmViewSelectedDealers.viewDealerGroupID ? cxmViewSelectedDealers.viewDealerGroupID : loggedInUser.dealerGroupId;
                            if (cxmViewSelectedDealers.viewSelectedStores.length == 1) {
                                dealerDisplayText = cxmViewSelectedDealers.viewSelectedStores[0].Name;
                            }
                            else {
                                dealerDisplayText = cxmViewSelectedDealers.viewAccount;
                            }
                        }
                        if (!(dealerListsMatch == false || (selectedDealerIds && cxmDealers.length != selectedDealerIds.length))) return [3 /*break*/, 6];
                        dealerGroupId = cxmViewSelectedDealers.viewDealerGroupID ? cxmViewSelectedDealers.viewDealerGroupID : loggedInUser.dealerGroupId;
                        if (cxmViewSelectedDealers.viewSelectedStores.length == 1) {
                            dealerDisplayText = cxmViewSelectedDealers.viewSelectedStores[0].Name;
                        }
                        else {
                            dealerDisplayText = cxmDealers.length == 1 ? cxmDealers[0].Name : cxmViewSelectedDealers.viewAccount ? cxmViewSelectedDealers.viewAccount : loggedInUser.dealerDisplayText;
                        }
                        this.dealerService.updateSelectedDealersToMatchCXM(dealers, dealerGroupId, dealerDisplayText);
                        return [4 /*yield*/, this.userClientService.LoggedInUserGET().toPromise()];
                    case 5:
                        refreshedUser = _b.sent();
                        if (refreshedUser) {
                            dealerCodes = refreshedUser.dealerCodes;
                            dealerModules = refreshedUser.dealerModules;
                        }
                        _b.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        if (cxmDealers && loggedInUser.dealerCodes) {
                            _loop_1 = function (i) {
                                var dealer = { id: loggedInUser.dealerCodes[i].dealerID, name: loggedInUser.dealerCodes[i].dealerCode };
                                dealers.push(dealer);
                                if (dealerListsMatch) {
                                    dealerListsMatch = cxmDealers.some(function (dealer) { return dealer.AutoAlertDealerID == loggedInUser.dealerCodes[i].dealerID; });
                                }
                            };
                            for (i = 0; i < loggedInUser.dealerCodes.length; i++) {
                                _loop_1(i);
                            }
                            if (cxmDealers.length != loggedInUser.dealerCodes.length || dealerListsMatch == false) {
                                this.dealerService.updateCXMSelectedDealersToMatchAlertMiner(dealers);
                            }
                        }
                        _b.label = 8;
                    case 8:
                        dealerIds = profile.isAutoAlertEmployee
                            ? this.dealerService.getSelectedDealerIds() ||
                                loggedInUser.dealerIds
                            : loggedInUser.dealerIds;
                        this.storageService.setItem("cultureName", profile.cultureName);
                        this.storageService.setItem("timezoneId", profile.timeZoneID);
                        accountPlatformIds = [];
                        if (dealerIds && dealerIds.length > 0
                            && motofuzeUser && motofuzeUser.ViewAllowedStores && motofuzeUser.ViewAllowedStores.length > 0) {
                            dealerIds.forEach(function (id) {
                                var store = motofuzeUser.ViewAllowedStores.find(function (x) { return x.AutoAlertDealerID == id; });
                                if (store) {
                                    accountPlatformIds.push(store.AccountPlatformID);
                                }
                            });
                        }
                        userProfile = {
                            userTypeId: userInfo.userTypeID,
                            firstName: userInfo.firstName,
                            middleName: userInfo.middleName,
                            lastName: userInfo.lastName,
                            userFullName: loggedInUser.name,
                            dealerGroupId: dealerGroupId,
                            dealerDisplayText: dealerDisplayText,
                            dealerIds: dealerIds ? dealerIds : loggedInUser.dealerIds,
                            roleId: loggedInUser.roleId,
                            employeeUser: profile.isAutoAlertEmployee,
                            cultureName: profile.cultureName,
                            countryId: countryId,
                            userId: profile.userID,
                            roleName: loggedInUser.roleName,
                            dealerCodes: dealerCodes,
                            eulaAcceptedDate: profile.eulaAcceptDate,
                            email: profile.email,
                            phone: profile.phone,
                            position: profile.position,
                            timeZoneId: loggedInUser.timeZoneId
                                ? loggedInUser.timeZoneId
                                : profile.timeZoneID,
                            lastPasswordResetDate: profile.lastPwdResetDate,
                            isSltUser: loggedInUser.isSltUser,
                            isLiteUser: loggedInUser.isLiteUser,
                            cellPhoneArea: profile.cellPhoneArea,
                            cellPhonePart1: profile.cellPhonePart1,
                            cellPhonePart2: profile.cellPhonePart2,
                            cellPhoneCarrier: profile.cellPhoneCarrier,
                            //Per architect, we are keeping the logic to get dealerModules separate from other modules to replicate legacy logic
                            dealerModules: dealerModules,
                            permissions: motofuzeUser.Permissions,
                            accountPlatformIds: accountPlatformIds,
                            walkmeSegments: (gfConfig && gfConfig.WalkMeSegments) ? gfConfig.WalkMeSegments : null,
                        };
                        this.storageService.setItem("userProfile", userProfile);
                        return [2 /*return*/, userProfile];
                }
            });
        });
    };
    UserService.prototype.getUsers = function (dealerId) {
        return __awaiter(this, void 0, void 0, function () {
            var enabledUsers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dealerService.getEnabledUsersForDealer(dealerId)];
                    case 1:
                        enabledUsers = _a.sent();
                        return [2 /*return*/, enabledUsers.map(function (u) { return ({
                                id: u.userID,
                                fullName: u.fullName
                            }); })];
                }
            });
        });
    };
    //async getUsersExt(dealerId: number): Promise<{ id: number; fullName: string }[]> {
    //    const enabledUsers = await this.dealerService.getEnabledUsersForDealer(dealerId);
    //    return enabledUsers.map(u => ({
    //        id: u.userID,
    //        fullName: u.fullName
    //    }));
    //}
    UserService.prototype.getUsersExt = function (dealerId) {
        return this.userClientService
            .GetEnabledClientUsersExtByDealerByDealeridGET(dealerId)
            .toPromise();
    };
    UserService.prototype.isAutoAlertEmployee = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userProfile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserProfile()];
                    case 1:
                        userProfile = _a.sent();
                        return [2 /*return*/, userProfile.employeeUser];
                }
            });
        });
    };
    UserService.prototype.isDealerEnabledForModule = function (dealerId, moduleId) {
        // This really should be calling this.getUserProfile(), but the previous
        // non-TypeScript implementation only ever checked the cached value, so
        // all the usages of this are expecting a boolean return.
        var cachedUserProfile = this.cachedUserProfile;
        if (!cachedUserProfile) {
            return false;
        }
        var dealerModules = cachedUserProfile.dealerModules;
        return dealerModules.some(function (mod) {
            return mod.moduleID === moduleId &&
                (mod.dealerID === dealerId || mod.dealerID === 0);
        });
    };
    UserService.prototype.isModuleEnabledForAnyDealer = function (moduleId) {
        var _this = this;
        var dealerIds = this.dealerService.getSelectedDealerIds();
        return dealerIds.some(function (dealerId) {
            return _this.isDealerEnabledForModule(dealerId, moduleId);
        });
    };
    UserService.prototype.logUserLogin = function () {
        return this.userClientService.LogUserLoginPOST().toPromise();
    };
    UserService.prototype.postResponse = function (eulaId, eulaResponseTypeId) {
        var eulaResponse = { eulaId: eulaId, eulaResponseTypeId: eulaResponseTypeId };
        return this.eulaHistoryClientService
            .PostResponseByEulaidByEularesponsetypeidPOST(eulaResponse)
            .toPromise();
    };
    UserService.prototype.updateAnnouncementUserDisplay = function (announcementId, doNotDisplay) {
        var dto = { announcementId: announcementId, doNotDisplay: doNotDisplay };
        return this.announcementClientService
            .UpdateAnnouncementUserDisplayByAnnouncementidPUT(dto)
            .toPromise();
    };
    UserService.prototype.updateUserProfile = function (userProfile) {
        return this.userProfileService.UpdatePATCH(userProfile).toPromise();
    };
    return UserService;
}());
exports.UserService = UserService;
