import { Injectable } from '@angular/core';
import { StorageService } from '../modules/storage/storage.service';
import { UserService } from './user.service';
import { AuthorizationKeys, Modules, StorageKeys } from '../constants';
import _ from 'lodash';
import { UserModuleAccessClientService } from '../generated/services';
import { UserModuleAccessDto, UserAccessRightDealerDto, ModuleAccessDto, UserModuleDealerDto } from '../generated/models';
import { DealSheetPermissions } from '../interfaces/deal-sheet/deal-sheet-permissions.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    constructor(private storageService: StorageService,
        private userService: UserService,
        private userModuleAccessClient: UserModuleAccessClientService) { }

    // Storing this promise causes subsequent calls to share a single
    // request, rather than making separate requests for no reason.. Once
    // the request completes, we set it back to null so the next call will
    // fetch data from the server again.
    private userModuleAccessPromise: Promise<UserModuleAccessDto>;

    /**
     * Gets an array of access right IDs for the current user.
     * from the server.
    */
    public getModuleIds(dealerIds: number[]): Promise<number[]> {
        return this.getAccessRightsAndModules()
            .then((result) => {
                const moduleIds = this.convertToModuleIds(result.modules, dealerIds);
                this.storageService.setItem('moduleIds', moduleIds);
                return moduleIds;
            });
    }

    public getModuleIdsFromCache(dealerIds: number[]): Promise<number[]> {
        const moduleIds = this.storageService.getItem(StorageKeys.ModuleIds);

        if (moduleIds && moduleIds.length > 0) {
            return Promise.resolve(moduleIds);
        }

        return this.getModuleIds(dealerIds);
    }

    public getAccessRightIdsFromCache(dealerIds: number[]): Promise<number[]> {
        const accessRightIds = this.storageService.getItem(StorageKeys.AccessRightIds);

        if (accessRightIds && accessRightIds.length > 0) {
            return Promise.resolve(accessRightIds);
        }

        return this.getAccessRightIds(dealerIds);
    }

    /**
     * Gets an array of access right IDs for the current user.
     * from the server.
    */
    public getAccessRightIds(dealerIds: number[]): Promise<number[]> {
        return this.getAccessRightsAndModules()
            .then((result) => {
                const accessRightIds = this.convertToAccessRightIds(result.accessRights, dealerIds);
                this.storageService.setItem('accessRightIds', accessRightIds);
                return accessRightIds;
            });
    }
    private getAccessRightsAndModules(): Promise<UserModuleAccessDto> {
        if (!this.userModuleAccessPromise) {
            this.userModuleAccessPromise = this.userModuleAccessClient.GET().toPromise()
                .then((response) => {
                    this.userModuleAccessPromise = null;
                    return response;
                });
        }

        return this.userModuleAccessPromise;
    }

    public async getDealerModules(): Promise<ModuleAccessDto[]> {
        const userProfile = await this.userService.getUserProfile();
        return userProfile.dealerModules;
    }

    public isAuthorized(permissionValue): boolean {
        const accessRightIds = this.storageService.getItem('accessRightIds');
        if (accessRightIds) {
            return accessRightIds.indexOf(permissionValue) > -1;
        } else {
            return false;
        }
    }

    public getUserCountryID(): number {
        const savedCultureName = this.storageService.getItem('cultureName');
        let cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();

        switch (cultureName) {
            case 'en-gb':
                return 3;
            case 'en-ca':
                return 2;
            case 'fr-ca':
                return 2;
            case 'en-us':
                return 1;
            default:
                return 0;
        }
    }

    public getUserCultureName(): string {
        const savedCultureName = this.storageService.getItem('cultureName');
        let cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();

        return cultureName;
    }

    public getDealSheetPermissions(accessRightIds?: number[]): Promise<DealSheetPermissions> {
        if (accessRightIds && accessRightIds.length > 0) {
            this.storageService.setItem(StorageKeys.AccessRightIds, accessRightIds);
        }

        const permissions = {
            serviceHistory: this.isAuthorized(AuthorizationKeys.ServiceHistory),
            viewCustomer: this.isAuthorized(AuthorizationKeys.ViewCustomer),
            viewVehicle: this.isAuthorized(AuthorizationKeys.VehicleDetails),
            viewContract: this.isAuthorized(AuthorizationKeys.SaleDetails),
            viewChangeLog: this.isAuthorized(AuthorizationKeys.ChangeLog),
            viewInventory: this.isAuthorized(AuthorizationKeys.Inventory),
            viewTradeValue: this.isAuthorized(AuthorizationKeys.TradeValue),
            editMileage: this.isAuthorized(AuthorizationKeys.Odometer),
            editContract: this.isAuthorized(AuthorizationKeys.Sale),
            editConquestContract: this.isAuthorized(AuthorizationKeys.ConvertService),
            editPrice: this.isAuthorized(AuthorizationKeys.Price),
            editCustomer: this.isAuthorized(AuthorizationKeys.EditCustomer),
            editAssignedUser: this.isAuthorized(AuthorizationKeys.Reassign),
            editActivity: this.isAuthorized(AuthorizationKeys.PostActivity),
            editPayoffAmount: this.isAuthorized(AuthorizationKeys.Payoff),
            editMarkAsNotOwn: this.isAuthorized(AuthorizationKeys.MarkAsNotOwn),
            editMarkAsSold: this.isAuthorized(AuthorizationKeys.MarkAsSold),
            editMarkAsPaidOff: this.isAuthorized(AuthorizationKeys.MarkAsPaidOff),
            editMarkAsWarranty: this.isAuthorized(AuthorizationKeys.MarkAsWarranty),
            editMarkAsWatch: this.isAuthorized(AuthorizationKeys.MarkAsWatch),
            warranty: this.isAuthorized(AuthorizationKeys.Warranty),
            activitiesLog: this.isAuthorized(AuthorizationKeys.ActivitiesLog),
            postActivity: this.isAuthorized(AuthorizationKeys.PostActivity),
            print: this.isAuthorized(AuthorizationKeys.Print),
            printTemplate: this.isAuthorized(AuthorizationKeys.PrintTemplate),
            requestCreditPrescreen: this.isAuthorized(AuthorizationKeys.CreditConvertSoftPullManual),
            emailTemplate: this.isAuthorized(AuthorizationKeys.EmailTemplate),
            vehicleHistoryReports: this.isAuthorized(AuthorizationKeys.VehicleHistoryReports),
            payments: this.isAuthorized(AuthorizationKeys.Payments),
            paymentDetails: this.isAuthorized(AuthorizationKeys.PaymentDetails),
            editDoNotContact: this.isAuthorized(AuthorizationKeys.DoNotContact),
            viewAlertScripts: this.isAuthorized(AuthorizationKeys.Script),
            manageSearches: this.isAuthorized(AuthorizationKeys.ManageSearches),
            sendTextSales: this.isAuthorized(AuthorizationKeys.SendTextSales),
            sendTextService: this.isAuthorized(AuthorizationKeys.SendTextService),
            crossBrandMVO: this.isAuthorized(AuthorizationKeys.CrossBrandMVO)
        } as DealSheetPermissions;

        return Promise.resolve(permissions);
    }

    private convertToModuleIds(modules: UserModuleDealerDto[], dealerIds: number[]) {
        return modules
            // Only include modules linked to our current dealers
            .filter((module) => {
                return _.some(_.intersection(module.moduleDealerIds, dealerIds));
            })
            .map((module) => module.moduleId);
    }

    private convertToAccessRightIds(accessRights: UserAccessRightDealerDto[], dealerIds: number[]) {
        return _.uniq(
            accessRights
                // Only include access rights for our current dealers
                .filter(function (accessRight) {
                    return _.some(_.intersection(accessRight.accessRightDealerIds, dealerIds));
                })
                .map(function (accessRight) { return accessRight.accessRightId; }));
    }

    public isEuroLite(): boolean {
        const userProfile = this.storageService.getItem('userProfile');
        if (userProfile) {
            return userProfile.isLiteUser;
        }
        return false;
    }

}
