import { Component, OnInit, Input, OnChanges, Inject, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VehicleService } from '../../../../ajs-upgraded-providers';
import { ToasterLoggerService } from '../../../../core/toaster-logger.service';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { VinCueModalComponent } from '../vincue-modal/vincue-modal.component';
import { NgxModalWrapperService } from '../../../../services/ngx-modal-wrapper.service';
import { DealSheetService } from '../../../../ajs-upgraded-providers';

@Component({
    selector: 'vincue',
    templateUrl: './vincue.component-ng.html',
    styleUrls: ['./vincue.component.scss']
})
export class VinCueComponent {
    @Input() dealerId: number;
    @Input() customer: any;
    @Input() vehicle: any;
    @Input() assignedUserFullName: string;
    bsModalRef: BsModalRef;

    constructor(
        public modalService: NgxModalWrapperService,
        public tService: TranslateService,
        public logger: ToasterLoggerService,
        @Inject(VehicleService) private vehicleService,
        @Inject(DealSheetService) private dealSheetService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    public vincueModal() {

        const initialState = {
            dealerId: this.dealerId,
            customer: this.customer,
            vehicle: this.vehicle,
            assignedUserFullName: this.assignedUserFullName
        };

        this.bsModalRef = this.modalService.show(VinCueModalComponent, { initialState, class: 'vincue-modal' });

        this.bsModalRef.content.onSaveChanges.subscribe((result: any) => {

            var vehicle = {
                vin: this.vehicle.vin,
                make: this.vehicle.makeName,
                model: this.vehicle.modelName,
                year: this.vehicle.yearID,
                odometer: this.vehicle.estimatedCurrentMileage,
                interiorColor: this.vehicle.interiorColor > "" ? this.vehicle.interiorColor : "",
                exteriorColor: this.vehicle.exteriorColor > "" ? this.vehicle.exteriorColor : ""
            }
            var customer = {
                firstName: this.customer.firstName,
                lastName: this.customer.lastName,
                email: this.customer.emailAddress ? this.customer.emailAddress.value : "",
                homePhone: this.customer.homePhoneNumber ? this.customer.homePhoneNumber.value : "",
                workPhone: this.customer.workPhoneNumber ? this.customer.workPhoneNumber.value : "",
                mobilePhone: this.customer.cellPhoneNumber ? this.customer.cellPhoneNumber.value : "",
                address: this.customer.address ? this.customer.address.streetAddress : "",
                postalCode: this.customer.address ? this.customer.address.zipCode : ""
            }
            var appraiser = {
                userName: "",
                firstName: "",
                lastName: "",
                email: ""
            }
            var salesPerson = {
                userName: "",
                firstName: this.assignedUserFullName,
                lastName: "",
                email: ""
            }

            var request = {
                dealerId: this.dealerId,
                vehicle: vehicle,
                customer: customer,
                appraiser: appraiser,
                salesPerson: salesPerson
            }

            this.dealSheetService.vinCueCreateAppraisal(request)
                .then(data => {
                    if (data && data.appraisalID) {
                        this.logger.success("VinCue appraisal successfully created with appraisal id: " + data.appraisalID);
                        this.showAppraisalPopup(data);
                    }
                    else {
                        this.logger.error(this.tService.instant("generalErrorMessageKey"));
                    }
                });
        });
    }

    public showAppraisalPopup(data) {
        console.log('Appraisial ID: ' + data.appraisalID + ' VinCue url: ' + data.deepLink);
        window.open(data.deepLink, '_blank');
    }
}
