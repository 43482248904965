dealSheetService.$inject = ["$document", "$state", "$uibModal", "coreApi", "dmsPushApi", "dealSheetUrl", "globalIframeService", "logger", "urlHelper", "ActivityActionEnum"];
import angular from 'angular';
angular.module('AaApp.Opp').factory('dealSheetService', dealSheetService);

function dealSheetService($document, $state, $uibModal, coreApi, dmsPushApi, dealSheetUrl, globalIframeService, logger, urlHelper, ActivityActionEnum) {
  "ngInject";

  var service = {
    getDealSheetUrl: getDealSheetUrl,
    getDealSheetLiteUrl: getDealSheetLiteUrl,
    openDealSheetModal: openDealSheetModal,
    openDealSheetLiteModal: openDealSheetLiteModal,
    modalDisplayState: 'new',
    activeDealSheet: '',
    openScheduleModal: openModalDialog,
    getModalParamObject: getModalParamObject,
    getDealHistory: getDealHistory,
    getEntityEnterpriseCustomers: getEntityEnterpriseCustomers,
    pushToDeskit: pushToDeskit,
    isVAutoEnabled: isVAutoEnabled,
    isVinCueEnabled: isVinCueEnabled,
    vAutoCreateAppraisal: vAutoCreateAppraisal,
    vinCueCreateAppraisal: vinCueCreateAppraisal,
    getLatestAppraisal: getLatestAppraisal,
    getLatestAppraisalVinCue: getLatestAppraisalVinCue,
    dmsPushCustomer: dmsPushCustomer,
    dmsPushPushFAndI: dmsPushPushFAndI,
    dmsPushGetLatest: dmsPushGetLatest,
    isDmsPushValid: isDmsPushValid
  };
  return service;

  function isVAutoEnabled(dealerId) {
    return dmsPushApi.api.vAuto.IsEnabled['{dealerId}'].GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data;
    });
  }

  function isVinCueEnabled(dealerId) {
    return dmsPushApi.api.VinCue.IsEnabled['{dealerId}'].GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data;
    });
  }

  function isDmsPushValid(dealerId) {
    return dmsPushApi.api.DmsPush.IsDmsPushValid['{dealerId}'].GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data;
    });
  }

  function dmsPushCustomer(request) {
    return dmsPushApi.api.DmsPush.PushCustomer.POST(request).then(function (response) {
      return response.data;
    });
  }

  function dmsPushPushFAndI(request) {
    return dmsPushApi.api.DmsPush.PushFAndI.POST(request).then(function (response) {
      return response.data;
    });
  }

  function dmsPushGetLatest(request) {
    return dmsPushApi.api.DmsPush.GetLatestDmsPushSuccess.POST(request).then(function (response) {
      return response.data;
    });
  }

  function vAutoCreateAppraisal(request) {
    return dmsPushApi.api.vAuto.CreateAppraisal.POST(request).then(function (response) {
      return response.data;
    });
  }

  function vinCueCreateAppraisal(request) {
    return dmsPushApi.api.VinCue.CreateAppraisal.POST(request).then(function (response) {
      return response.data;
    });
  }

  function getLatestAppraisal(dealerId, vin) {
    return dmsPushApi.api.vAuto.GetLatestAppraisalByVin['{dealerId}']['{vin}'].GET({
      dealerId: dealerId,
      vin: vin
    }).then(function (response) {
      return response.data;
    });
  }

  function getLatestAppraisalVinCue(dealerId, vin) {
    return dmsPushApi.api.VinCue.GetLatestAppraisalByVin['{dealerId}']['{vin}'].GET({
      dealerId: dealerId,
      vin: vin
    }).then(function (response) {
      return response.data;
    });
  }

  function getDealHistory(opportunityId) {
    return coreApi.api.DealSheet.GetDealHistory['{entityId}'].POST({}, {
      entityId: opportunityId
    }).then(function (response) {
      return response.data;
    });
  }

  function getEntityEnterpriseCustomers(opportunityId) {
    return coreApi.api.Entity.GetEntityEnterpriseCustomers['{entityId}'].GET({
      entityId: opportunityId
    }).then(function (response) {
      return response.data;
    });
  }

  function pushToDeskit(pushRequest) {
    return coreApi.api.Deskit.PushToDeskit.POST(pushRequest).then(resolveData);
  }

  function resolveData(response) {
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  }

  function getDealSheetUrl(opportunityId) {
    return $state.href('dealSheet', {
      opportunityId: opportunityId
    }, {
      absolute: true
    });
  }

  function getDealSheetLiteUrl(entityId) {
    return $state.href('dealSheetLite', {
      entityId: entityId
    }, {
      absolute: true
    });
  }

  function openDealSheetLiteModal(vw_search, entityID, callback) {
    var parentElem = angular.element($document[0].querySelector('#aa-app')); // Need to hide the scrollbar of the current page before displaying the modal
    // to eliminate the double-scroll bars.

    parentElem.css('overflowY', 'hidden');
    var modalInstance = $uibModal.open({
      animation: true,
      //$ctrl.animationsEnabled,
      component: 'dealSheetLiteModal',
      appendTo: parentElem,
      windowClass: 'modal-aa-lg modal-aa-lg-dealsheet',
      backdropClass: 'modal-aa-lg-backdrop',
      resolve: {
        vwsearch: function vwsearch() {
          return vw_search;
        },
        opportunityId: function opportunityId() {
          return vw_search != null ? vw_search._EntityID : entityID;
        },
        calculationType: function calculationType() {
          return null;
        },
        stockNo: function stockNo() {
          return null;
        }
      }
    });
    modalInstance.result.then(function (selectedItem) {
      parentElem.css('overflowY', 'visible');

      if (callback) {
        callback(entityID);
      }
    });
  }

  function openDealSheetModal(dealsheetId, url, callback) {
    if (!dealsheetId) {
      logger.genericError('DealsheetId must be passed in.');
    }

    var autoalertIframe = angular.element($document[0].querySelector('#autoalertiframe'));
    if (autoalertIframe.length > 0) autoalertIframe.blur();
    var parentElem = angular.element($document[0].querySelector('#aa-app')); // Need to hide the scrollbar of the current page before displaying the modal
    // to eliminate the double-scroll bars.

    parentElem.css('overflowY', 'hidden');

    var _calculationType;

    var _stockNo;

    if (url) {
      _calculationType = urlHelper.queryString(url).calc || 'null';
      _stockNo = urlHelper.queryString(url).sn;
    } else {
      service.activeDealSheet = dealSheetUrl + dealsheetId + "&snav=true";
    }

    var modalInstance = $uibModal.open({
      animation: true,
      //$ctrl.animationsEnabled,
      component: 'dealSheetModal',
      appendTo: parentElem,
      windowClass: 'modal-aa-lg modal-aa-lg-dealsheet',
      backdropClass: 'modal-aa-lg-backdrop',
      resolve: {
        opportunityId: function opportunityId() {
          return dealsheetId;
        },
        calculationType: function calculationType() {
          return _calculationType;
        },
        stockNo: function stockNo() {
          return _stockNo;
        }
      }
    });
    modalInstance.result.then(function () {
      _verifyCallback(callback, dealsheetId);
    }, function () {
      _verifyCallback(callback, dealsheetId);

      globalIframeService.postMessage({
        'task': 'opportunity_refresh'
      });
    }).catch(function () {
      _verifyCallback(callback, dealsheetId);
    });

    function _verifyCallback(callback, dealsheetId) {
      // Need to undo what we did above before we opened the modal
      parentElem.css('overflowY', 'visible');

      if (callback && dealsheetId) {
        callback(dealsheetId);
      }
    }
  } // this is the common object to pass data to modal dialog box


  function getModalParamObject(modalType, opportunityId, dealerId, dealSheetOwnerId, maxScheduleFutureDays, usersObject, activityObject, ownerUserInfoObject, expirationDate) {
    return {
      modalType: modalType,
      componentName: 'schedule',
      opportunityId: opportunityId,
      dealerId: dealerId,
      dealSheetOwnerId: dealSheetOwnerId,
      maxScheduleFutureDays: maxScheduleFutureDays,
      usersObject: usersObject,
      activityObject: activityObject,
      ownerUserInfoObject: ownerUserInfoObject,
      expirationDate: expirationDate
    };
  }

  function openModalDialog(modalParamObject, callback) {
    switch (modalParamObject.modalType) {
      case ActivityActionEnum.Log_Call:
        modalParamObject.componentName = 'logCallModal';
        break;

      case ActivityActionEnum.Appointment_Completed:
      case ActivityActionEnum.Phone_Call_Completed:
      case ActivityActionEnum.Cancel_Call:
      case ActivityActionEnum.Cancel_Appt:
      case ActivityActionEnum.Post_Note:
      case ActivityActionEnum.Email_Sent:
        modalParamObject.componentName = 'postNotes';
        break;

      case ActivityActionEnum.Reschedule_Appt:
      case ActivityActionEnum.Reschedule_Call:
      case ActivityActionEnum.Schedule_Call:
      case ActivityActionEnum.Schedule_Appt:
        modalParamObject.componentName = 'schedule';
        break;
    }

    var IsFollowUp = false;
    var modalInstance = $uibModal.open({
      animation: true,
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      component: modalParamObject.componentName,
      size: modalParamObject.componentName == 'schedule' ? 'lg' : null,
      resolve: {
        items: function items() {
          return modalParamObject;
        }
      }
    }); // result from submit

    modalInstance.result.then(function (returnedData) {
      // schedule cancel on no followup action
      if (returnedData == 'followup-cancel' && !IsFollowUp) {
        return;
      } // schedule cancel on followup action


      if (returnedData == 'followup-cancel' && IsFollowUp) {
        callBackScheduleModal(callback, 'onScheduleRefresh');
        IsFollowUp = false;
        return;
      } //  on followup action


      if (returnedData != 'cancel' || returnedData != 'submit') {
        //activityResultID = 23 , 11, 7 maps to ResulyKeySold
        if (returnedData.followUpActions.length > 1) {
          if (returnedData.activityResult == 23 || returnedData.activityResult == 11 || returnedData.activityResult == 7) {
            callBackScheduleModal(callback, 'onVehicleSold');
          } else {
            IsFollowUp = true;
            modalParamObject.modalType = returnedData.followUpActions[0].activityActionId;
            openFollowUpActions(returnedData, modalParamObject, callback);
          }
        } else if (returnedData.followUpActions.length > 0) {
          IsFollowUp = true; // if only 1 follow-up launch it

          modalParamObject.modalType = returnedData.followUpActions[0].activityActionId; //modalParamObject.activityObject.activityId = null;

          openModalDialog(modalParamObject, callback);
        } else {
          // update status and activity grid
          if (returnedData.activityId == ActivityActionEnum.Post_Note || returnedData.activityId == ActivityActionEnum.Log_Call || returnedData.activityId == ActivityActionEnum.Schedule_Call || returnedData.activityId == ActivityActionEnum.Schedule_Appt || returnedData.activityId == ActivityActionEnum.Schedule_Call || returnedData.activityId == ActivityActionEnum.Schedule_Appt || returnedData.activityId == ActivityActionEnum.Reassign_Call || returnedData.activityId == ActivityActionEnum.Reschedule_Call || returnedData.activityId == ActivityActionEnum.Reassign_Appt || returnedData.activityId == ActivityActionEnum.Reschedule_Appt || returnedData.activityId == ActivityActionEnum.Cancel_Call || returnedData.activityId == ActivityActionEnum.Cancel_Appt || returnedData.activityId == ActivityActionEnum.Phone_Call_Completed || returnedData.activityId == ActivityActionEnum.Appointment_Completed) {
            callBackScheduleModal(callback, 'onScheduleRefresh');
          }
        }
      }
    });

    function openFollowUpActions(_followUpActions, modalParamObject, callback) {
      var modalInstance = $uibModal.open({
        animation: true,
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        component: "activityFollowUpModal",
        resolve: {
          followUpActions: function followUpActions() {
            return _followUpActions;
          }
        }
      }); // result from followup action could be: 'cancel', 3 or 4

      modalInstance.result.then(function (modalType) {
        if (modalType != 'followup-cancel') {
          modalParamObject.modalType = modalType;
          openModalDialog(modalParamObject, callback);
        } else {
          callBackScheduleModal(callback, 'onScheduleRefresh');
        }
      });
    }

    function callBackScheduleModal(callback, returnValue) {
      if (callback) {
        callback(returnValue);
      }
    }
  }
}