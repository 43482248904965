import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.array.find";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.is-integer";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
SearchFormController.$inject = ["$document", "$q", "$element", "$scope", "$state", "$sce", "$uibModal", "$translate", "searchService", "searchPresetService", "dealerService", "vehicleService", "userService", "storageService", "modules", "legacy", "urlHelper", "dealSheetService", "authorizationService", "authorizationKeys", "logger", "$logFactory", "environmentVariables"];

/* injects from baggage-loader */
require('./search-form.component.html');

import angular from 'angular';
import _ from 'lodash';
import './search-form.component.scss';
import { delay, TimeoutError } from "../../utilities/promises";
import { getUrlComponents } from "../../utilities/url";
import { ChildMessagePort } from '../../utilities/messaging';
import { searchFilters, filterTypes, filterNames, filterSources, saveResponseCodes } from './searchfilter-definitions';
import { AssetTypes } from '../../constants';
import { filter } from 'rxjs/operators';
import moment from 'moment';
angular.module('AaApp.Opp').component('searchForm', {
  templateUrl: '/app/components/search/search-form.component.html',
  controller: SearchFormController,
  bindings: {
    presetId: '<',
    tagId: '<',
    dealerId: '<',
    activity: '<',
    selectedDealerId: '=',
    sectionStates: '=',
    onMenuSettingsChanged: '&'
  }
});
/* @ngInject */

function SearchFormController($document, $q, $element, $scope, $state, $sce, $uibModal, $translate, searchService, searchPresetService, dealerService, vehicleService, userService, storageService, modules, legacy, urlHelper, dealSheetService, authorizationService, authorizationKeys, logger, $logFactory, environmentVariables) {
  var originalIframeUrl = legacy.iframeUrl + "/AlertDesk/Search?showgridonly=true";
  var bulkTemplateUrl = legacy.iframeUrl + "/AlertDesk/BulkOutput/BulkOutputWizard";
  var assetTypes = AssetTypes;
  var $ctrl = this;
  var log = $logFactory.get();
  $ctrl.searchFilters = searchFilters;
  $ctrl.searchFilterSections;
  $ctrl.filterTypes = filterTypes;
  $ctrl.filterSources = filterSources;
  $ctrl.filterNames = filterNames;
  $ctrl.$onInit = onInit;
  $ctrl.dealers;
  $ctrl.selectedDealer;
  $ctrl.openBulkTemplateWizard = openBulkTemplateWizard;
  $ctrl.performSearch = performSearch;
  $ctrl.performExport = performExport;
  $ctrl.performEpsilonExport = performEpsilonExport;
  $ctrl.performReassign = performReassign;
  $ctrl.performAddAccess = performAddAccess;
  $ctrl.showDirectAlertModal = showDirectAlertModal;
  $ctrl.performDirectAlert = performDirectAlert;
  $ctrl.printGrid = printGrid;
  $ctrl.saveSearch = saveSearch;
  $ctrl.saveLayout = saveLayout;
  $ctrl.onDealerSelect = onDealerSelect;
  $ctrl.searchGridMessagePort;
  $ctrl.savedSearchModel;
  $ctrl.searchConditionName = "";
  $ctrl.SearchCriteria = {};
  $ctrl.searchResultsForm = {};
  $ctrl.tagId;
  $ctrl.popOverText = {};
  $ctrl.values = {};
  $ctrl.fieldValidation = {};
  $ctrl.updateTooltip = updateTooltip;
  $ctrl.clearAll = clearAll;
  $ctrl.iframeUrl = $sce.trustAsResourceUrl(originalIframeUrl);
  $ctrl.exportLayout;
  $ctrl.dealsheetDomain;
  $ctrl.showExportLink = false;
  $ctrl.showPrintLink = false;
  $ctrl.AllowBatchReassigning = false;
  $ctrl.AllowBatchAccess = false;
  $ctrl.CanSwitchUserDealer = false;
  $ctrl.One2OneAccess = false;
  $ctrl.searchGridResultsReady = false;
  $ctrl.saveSearchBtnDisabled = false;
  $ctrl.moreBtnDisabled = false;
  $ctrl.oneToOneBtnDisabled = false;
  $ctrl.enableDirectAlertCampaigns = false;
  $ctrl.dealerSettings = [];
  $ctrl.resultsGridPromise;
  $ctrl.isMultipleDealers = false;
  $ctrl.isEmployeeUser = false;
  $ctrl.templateBtnDisabled = false;
  $ctrl.CanPrintTemplate = false;
  $ctrl.unitOfMeasureKey;
  $ctrl.isMetric = false;
  $ctrl.countryId;

  function getInvalidFields() {
    var invalidFields = '';

    for (var s in $ctrl.searchFilters) {
      var invalidField = null;
      var sf = $ctrl.searchFilters[s]; // special field validation

      if (sf.fieldName == filterNames.CustomerEngagementGroups) {
        if ($ctrl.values[filterNames.CustomerEngagementGroups].length > 0) {
          $ctrl.fieldValidation[filterNames.HasEngagementDays] = $ctrl.values[filterNames.HasEngagementDays] != null && $ctrl.values[filterNames.HasEngagementDays] > 0;
          $ctrl.fieldValidation[filterNames.HasEngagementID] = $ctrl.values[filterNames.HasEngagementID] != "A";
        } else {
          $ctrl.fieldValidation[filterNames.HasEngagementID] = true;
          $ctrl.fieldValidation[filterNames.HasEngagementDays] = true;
        }
      }

      if ($ctrl.fieldValidation[sf.fieldName] != null && !$ctrl.fieldValidation[sf.fieldName] || $ctrl.fieldValidation[sf.fieldNameMin] != null && !$ctrl.fieldValidation[sf.fieldNameMin] || $ctrl.fieldValidation[sf.fieldNameMax] != null && !$ctrl.fieldValidation[sf.fieldNameMax]) invalidField = sf.displayNameKey;
      if (invalidField) invalidFields += $translate.instant(invalidField) + ", ";
    }

    if (invalidFields) invalidFields = invalidFields.slice(0, -2);
    return invalidFields;
  }

  $scope.$watch('$ctrl.presetId', function (newVal, oldVal, scope) {
    if (Number.isInteger($ctrl.presetId)) {
      loadSavedSearch();
    }
  });
  $scope.$watch(function () {
    return $ctrl.values[$ctrl.filterNames.MakesList];
  }, function (newVal, oldVal, scope) {
    getModelsByMakesYears();
  }, true // compare arrays using angular.equals()
  );
  $scope.$watch(function () {
    return $ctrl.values[$ctrl.filterNames.YearsList];
  }, function (newVal, oldVal, scope) {
    getModelsByMakesYears();
  }, true // compare arrays using angular.equals()
  );
  $scope.$watch(function () {
    return $ctrl.values[$ctrl.filterNames.ModelsList];
  }, function (newVal, oldVal, scope) {
    getTrims();
  }, true // compare arrays using angular.equals()
  );
  $scope.$watch(function () {
    return $ctrl.values[$ctrl.filterNames.Categories];
  }, function (newVal, oldVal, scope) {
    var fieldDisabled = newVal == null || newVal[$ctrl.filterNames.Categories + "1"] == false;
    var searchFilter = $ctrl.searchFilters.filter(function (filter) {
      return filter.fieldName == $ctrl.filterNames.Priorities;
    })[0];
    searchFilter.fieldDisabled = fieldDisabled;
  }, true // compare arrays using angular.equals()
  );
  $scope.$watch(function () {
    return $ctrl.values[$ctrl.filterNames.CustomerEngagementGroups];
  }, function (newVal, oldVal, scope) {
    var result = newVal == null || newVal.length == 0;
    var searchFilter = $ctrl.searchFilters.filter(function (filter) {
      return filter.fieldNameMin == $ctrl.filterNames.HasEngagementID;
    })[0];
    searchFilter.fieldDisabled = result;
  }, true // compare arrays using angular.equals()
  );
  $scope.$watch(function () {
    return $ctrl.values;
  }, function (newVal, oldVal, scope) {
    setDisabledUntilPerformSearch(true);
  }, true // compare arrays using angular.equals()
  );

  function isFordAlert() {
    if ($ctrl.selectedDealerId == 0) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $ctrl.dealers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var d = _step.value;

          if (d.dealerId != 0) {
            if (userService.isDealerEnabledForModule(d.id, modules.fordAlert)) {
              return true;
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    } else return userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.fordAlert);
  }

  function enableMvo(selectedDealerId) {
    var showMVO = false;
    var mvoField = $ctrl.searchFilters.find(function (filter) {
      return filter.fieldName == $ctrl.filterNames.MVO;
    });

    if (mvoField) {
      // if selectedDealerId is 0, all available dealers for user will be used:
      dealerService.getDealerFordInfo(selectedDealerId).then(function (dealerFordInfo) {
        for (var i = 0; i < dealerFordInfo.length; i++) {
          var dealer = dealerFordInfo[i];
          var isUS = dealer.countryID == 1; // only show if ANY a) non-Ford/Lincoln dealer or b) Ford National Program US dealer

          if (!dealer.hasLincolnMake && !dealer.hasFordMake || dealer.isFordAlert) {
            showMVO = true;
            break;
          }
        }

        mvoField.isDisabled = !showMVO;
      });
    }
  }

  function onInit() {
    if ($ctrl.sectionStates === null || $ctrl.sectionStates === undefined) $ctrl.sectionStates = {
      0: true,
      1: true,
      2: false,
      3: false,
      4: false,
      5: false
    };
    $ctrl.searchConditionName = "";
    $ctrl.showExportLink = false;
    $ctrl.showPrintLink = false;
    $ctrl.searchGridResultsReady = false;
    $ctrl.SearchCriteria = {};
    $ctrl.searchResultsForm = {};
    $ctrl.EnableDirectAlertCampaigns = false;
    $ctrl.AllowBatchReassigning = authorizationService.isAuthorized(authorizationKeys.AllowBatchReassigning);
    $ctrl.AllowBatchAccess = authorizationService.isAuthorized(authorizationKeys.AllowBatchAccess);
    $ctrl.CanSwitchUserDealer = authorizationService.isAuthorized(authorizationKeys.SwitchUserDealer);
    $ctrl.CanPrintTemplate = authorizationService.isAuthorized(authorizationKeys.PrintTemplate);
    $ctrl.One2OneAccess = authorizationService.isAuthorized(authorizationKeys.DirectAlert);
    $ctrl.searchFilterSections = $ctrl.searchFilters.filter(function (filter) {
      return filter.filterType == $ctrl.filterTypes.Title || filter.filterType == $ctrl.filterTypes.CustomTitle;
    });
    $ctrl.dealers = dealerService.getSelectedDealers();
    $ctrl.isMultipleDealers = $ctrl.dealers.length > 1;

    if ($ctrl.dealers.length > 1) {
      $translate(['allDealerships']).then(function (translations) {
        $ctrl.dealers.unshift({
          id: 0,
          name: translations.allDealerships
        });
      });
    }

    userService.getUserProfile().then(function (userProfile) {
      $ctrl.isEmployeeUser = userProfile.employeeUser;
      $ctrl.countryId = userProfile.countryId;
      getMakes(userProfile.countryId);
      onInitOrLoad(); //moved from last line of this function to here, userProfile has to be available before calling this

      userService.getUserIsMetric(userProfile.countryId).then(function (isMetric) {
        $ctrl.unitOfMeasureKey = isMetric ? "KilometersLabelShort" : "MilesLabelShort";
        $ctrl.isMetric = isMetric;
      });
    });
    var selectedDealerId = storageService.getItem('selectedDealerId');

    if (selectedDealerId > 0) {
      $ctrl.selectedDealerId = selectedDealerId;
    } else if ($ctrl.dealerId) {
      $ctrl.selectedDealerId = $ctrl.dealerId;
    } // if no dealer has been selected yet and there is only one dealer or the selected dealer is no longer on the list of available dealers


    if ($ctrl.selectedDealerId === null && $ctrl.dealers.length === 1 || $ctrl.selectedDealerId > 0 && !$ctrl.dealers.some(function (d) {
      return d.id === $ctrl.selectedDealerId;
    })) $ctrl.selectedDealerId = $ctrl.dealers[0].id;
    var iframe = $element.find(".ifrmSearchResults")[0];
    var origin = getUrlComponents(originalIframeUrl).origin;
    $ctrl.searchGridMessagePort = new ChildMessagePort(iframe, origin, $scope);
    $ctrl.searchGridMessagePort.message$.pipe(filter(function (data) {
      return data['DEAL_SHEET_URL'];
    })).subscribe(function (data) {
      var url = data['DEAL_SHEET_URL'];
      dealSheetService.activeDealSheet = url + "&snav=true";
      var opportunityId = urlHelper.queryString(url).e;

      if (authorizationService.isEuroLite()) {
        $state.go('euroliteopportunities', {
          opportunityId: opportunityId
        });
        return;
      }

      dealSheetService.openDealSheetModal(opportunityId, url);
    });
    $ctrl.searchGridMessagePort.subscribe(function (_ref, replyPort) {
      var data = _ref.data;

      switch (data.type) {
        /*
        case "SAVE_SEARCH_COMPLETED":
            var result = data.data;
            if (result.status === 0 && result.searchID !== undefined) {
                $ctrl.presetId = result.searchID;
                logger.success("searchConditionsSaved");
            }
            else {
                logger.warning(result.errorMessage);
            }
            break;
        */
        case "EXPORT_LINK_VISIBILITY":
          $ctrl.showExportLink = data.data.visible;
          break;

        case "PRINT_LINK_VISIBILITY":
          $ctrl.showPrintLink = data.data.visible;
          break;

        case "SEARCH_RESULTS_READY":
          $ctrl.searchGridResultsReady = true;
          setDisabledUntilPerformSearch(false);
          break;

        case "SEARCH_REASSIGN_COMPLETED":
          var reassignResult = data.data.reassignResult;

          if (reassignResult.Status === 0) {
            logger.success("searchReassignSuccessful");
            performSearch();
          } else {
            logger.warning(reassignResult.Message);
          }

          break;

        case "SEARCH_ADD_ACCESS_COMPLETED":
          var addAccessResult = data.data.addAccessResult;

          if (addAccessResult.Status === 0) {
            logger.success("searchAddAccessSucessful"); //performSearch();
          } else {
            logger.warning(reassignResult.Message);
          }

          break;

        default:
      }
    });
    getYears();
    getBanks();
    getSoldBy();
    getOwners();
    getTags();
    getRecallNumbers();
    getRecallTypes();
    getDealerSettings($ctrl.selectedDealerId);

    for (var f in $ctrl.searchFilters) {
      var field = $ctrl.searchFilters[f];
      updateTooltip(field.displayNameKey, field.filterType, field.tooltipText, null, null);
    }

    if ($ctrl.tagId) {
      searchService.getTagsForDealers($ctrl.selectedDealerId).then(function (tags) {
        var tag = _.find(tags, function (o) {
          return o.tagID == $ctrl.tagId;
        });

        $ctrl.values[$ctrl.filterNames.Tags] = tag.tagText;
        $ctrl.values["Source"]["ShowConquest"] = true;
        performSearch();
      });
    }

    $ctrl.$onDestroy = function () {
      return $ctrl.searchGridMessagePort.close();
    };
  }

  function loadSavedSearch() {
    if ($ctrl.presetId != null) {
      initializeValues();
      searchPresetService.getSearchPreset($ctrl.presetId).then(function (savedSearchPreset) {
        $ctrl.searchConditionName = savedSearchPreset.searchConditionName;

        if (savedSearchPreset.searchConditionText) {
          var savedSearchModel = JSON.parse(savedSearchPreset.searchConditionText); //if (savedSearchModel.MfgrRecallNo != null && !Array.isArray(savedSearchModel.MfgrRecallNo)) {
          //    savedSearchModel.MfgrRecallNo = [savedSearchModel.MfgrRecallNo];
          //}
          //if (savedSearchModel.NatRecallNo != null && !Array.isArray(savedSearchModel.NatRecallNo)) {
          //    savedSearchModel.NatRecallNo = [savedSearchModel.NatRecallNo];
          //}
          // convert legacy object to portal object

          var portalSearchModel = convertLegacyObjectToPortalSearch(savedSearchModel); // set selected values

          for (var i in portalSearchModel) {
            $ctrl.values[i] = portalSearchModel[i];
          } //WEBUI-7422 no parameter manipulation for the following two egde cases


          if ($ctrl.values[$ctrl.filterNames.HasActivityID] == "Y" && $ctrl.values[$ctrl.filterNames.HasActivityDays] <= 30 || $ctrl.values[$ctrl.filterNames.HasActivityID] == "N") $ctrl.activity = null;

          if (Number.isInteger($ctrl.activity)) {
            $ctrl.values[$ctrl.filterNames.HasActivityDays] = 30;
            if ($ctrl.activity == 1) $ctrl.values[$ctrl.filterNames.HasActivityID] = "Y";else $ctrl.values[$ctrl.filterNames.HasActivityID] = "N"; //we set Activity only once when the form is loaded first time
            //$ctrl.activity = null;
          }

          setSectionsOpenCloseState(false);
          performSearch($ctrl.presetId);
        }
      }).catch(function (err) {
        logger.error("searchLoadSearchFailed");
        $logFactory.log(err);
      });
    }

    onInitOrLoad();
  }

  function setSectionsOpenCloseState(state) {
    // open up all sections 
    for (var section in $ctrl.sectionStates) {
      if (section > 0) $ctrl.sectionStates[section] = state; // change to false for prod
    }
  }

  function onInitOrLoad() {
    $ctrl.isFordAlert = isFordAlert();
    var privateOfferField = $ctrl.searchFilters.filter(function (filter) {
      return filter.fieldNameMin == $ctrl.filterNames.PrivateOfferMin;
    });
    var privateOfferFieldsPopulated = $ctrl.values[filterNames.PrivateOfferMin] && $ctrl.values[filterNames.PrivateOfferMax];
    var privateOfferSearchInvalid = !$ctrl.isFordAlert && privateOfferFieldsPopulated;
    privateOfferField[0].isDisabled = !$ctrl.isFordAlert && !privateOfferFieldsPopulated;

    if (privateOfferSearchInvalid) {
      logger.error("searchPrivateOfferErrorMessage");
    }

    if (!$ctrl.isFordAlert) {
      var mappedToField = $ctrl.searchFilters.filter(function (filter) {
        return filter.fieldName == $ctrl.filterNames.MappedTo;
      });
      var keys = ["searchCPOFordBlue", "searchCPOFordGold"];
      var filteredList = mappedToField[0].availableValues.filter(function (m) {
        return !keys.includes(m.nameKey);
      });
      mappedToField[0].availableValues = filteredList;
    }

    enableMvo($ctrl.selectedDealerId);
  }

  function openReassignModal(_data) {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'searchReassignModal',
      appendTo: parentElem,
      windowClass: 'search-reassign-modal',
      resolve: {
        data: function data() {
          return _data;
        }
      }
    });
    return modalInstance;
  }

  function openDirectAlertModal(_data2) {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'searchDirectAlertModal',
      appendTo: parentElem,
      resolve: {
        data: function data() {
          return _data2;
        }
      }
    });
    return modalInstance;
  }

  function openAddAccessModal(_data3) {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'searchAddAccessModal',
      appendTo: parentElem,
      windowClass: 'search-add-access-modal',
      resolve: {
        data: function data() {
          return _data3;
        }
      }
    });
    return modalInstance;
  }

  function openEpsilonExportModal(data) {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'SearchExportEpsilonModal',
      appendTo: parentElem,
      windowClass: 'search-export-epsilon-modal',
      resolve: {
        renderedPromise: function renderedPromise() {
          return data;
        }
      }
    });
    return modalInstance;
  }

  function openEpsilonExportResultModal(data) {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'searchExportEpsilonResultModal',
      appendTo: parentElem,
      windowClass: 'search-export-epsilon-result-modal',
      resolve: {
        renderedPromise: function renderedPromise() {
          return data;
        }
      }
    });
    return modalInstance;
  }

  function openExportModal(data) {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'searchExportModal',
      appendTo: parentElem,
      windowClass: 'search-export-modal',
      resolve: {
        renderedPromise: function renderedPromise() {
          return data;
        },
        isFordAlert: $ctrl.isFordAlert
      }
    });
    return modalInstance;
  }

  function openSaveLayoutModal(data) {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'searchSaveLayoutModal',
      appendTo: parentElem,
      windowClass: 'search-save-layout-modal',
      resolve: {
        renderedPromise: function renderedPromise() {
          return data;
        },
        isFordAlert: $ctrl.isFordAlert
      }
    });
    return modalInstance;
  }

  function openSaveSearchModal(_data4) {
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'searchSaveModal',
      appendTo: parentElem,
      resolve: {
        data: function data() {
          return _data4;
        }
      }
    });
    return modalInstance;
  }

  function saveSearch() {
    var rendered = $q.defer();
    $ctrl.saveSearchModalInstance = openSaveSearchModal({
      rendered: rendered.promise,
      selectedDealerId: $ctrl.selectedDealerId,
      searchConditionName: $ctrl.searchConditionName,
      dealers: $ctrl.dealers
    });
    $ctrl.saveSearchModalInstance.rendered.then(function () {
      rendered.resolve();
    });
    $ctrl.saveSearchModalInstance.result.then(function (result) {
      saveUsersSearch(result);
    });
  }

  function saveUsersSearch(result) {
    var searchModel = convertPortalSearchToLegacyObject($ctrl.values);
    searchService.saveSearch($ctrl.selectedDealerId, result.savedSearchName, result.autoShareSearch, result.autoShareSearchPosition, searchModel, result.assignPandoXRule, result.pandoXRuleQuantity, result.executePandoXImmediately, result.pandoXRuleCategoryId, result.searchActiveStartDate, result.searchActiveEndDate).then(function (response) {
      var modalPopupText = '';
      var displayModal = false;

      if (response.responseCode == saveResponseCodes.ShareSearchError) {
        logger.error($translate.instant("searchSaveError"));
        return;
      } else if (response.responseCode == saveResponseCodes.Success) {
        $ctrl.presetId = response.userSearchConditionID;

        if (response.dealerIdsAutoShared.length == 0 && response.dealerIdsShareSkipped.length == 0) {
          logger.success($translate.instant("changesSaved"));
          return;
        }
      }

      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'searchSaveResponseModal',
        resolve: {
          title: function title() {
            return "Message";
          },
          saveResponse: function saveResponse() {
            return response;
          },
          dealers: function dealers() {
            return $ctrl.dealers;
          }
        }
      });
    });
  }

  function saveLayout() {
    var rendered = $q.defer();
    $ctrl.saveLayoutModalInstance = openSaveLayoutModal({
      rendered: rendered.promise,
      selectedDealerId: $ctrl.selectedDealerId
    });
    $ctrl.saveLayoutModalInstance.rendered.then(function () {
      rendered.resolve();
    });
    $ctrl.saveLayoutModalInstance.result.then(function (result) {
      if (result.success === true) {
        $ctrl.exportLayout = result.exportLayout; // reload search results

        performSearch();
        logger.success("searchLayoutSaved");
      }
    });
  }

  function printGrid() {
    // we need a 1 second timeout here to allow any popups to close.  otherwise the print dialog will prevent any outstanding popups close events from triggering
    setTimeout(function () {
      var msg = {
        type: 'PERFORM_PRINT_GRID',
        data: {}
      };
      $ctrl.searchGridMessagePort.postMessage(msg);
    }, 1000);
  }

  function performReassign() {
    var rendered = $q.defer();
    var reassignModalInstance = openReassignModal({
      rendered: rendered.promise,
      selectedDealerId: $ctrl.selectedDealerId,
      dealsheetDomain: $ctrl.dealsheetDomain
    });
    reassignModalInstance.rendered.then(function () {
      rendered.resolve();
    });
    reassignModalInstance.result.then(function (result) {
      performBatchReassign(result.assignedUsers);
    });
  }

  function showDirectAlertModal() {
    var dealerId = $ctrl.selectedDealerId;

    if (typeof dealerId != 'number') {
      throw new Error("Must select a single dealer");
    }

    performDirectAlert(); //dealerService.getIsAssetEnabledForDealer(assetTypes.intelMarketing, dealerId)
    //    .then(function (isEnabled) {
    //        if (isEnabled) {
    //            showOneToOneWarning();
    //        } else {
    //            performDirectAlert();
    //        }
    //    });
  }

  function showOneToOneWarning() {
    $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return $translate.instant("oneToOneIntelligentMarketing");
        },
        message: function message() {
          return "intelMarketingConfirmationMessage";
        },
        submitButtonVisible: function submitButtonVisible() {
          return false;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        closeButtonVisible: function closeButtonVisible() {
          return true;
        },
        closeButtonDisabled: function closeButtonDisabled() {
          return false;
        },
        closeButtonText: function closeButtonText() {
          return "ok";
        }
      }
    });
  }

  function performDirectAlert() {
    var rendered = $q.defer();
    var modalInstance = openDirectAlertModal({
      rendered: rendered.promise,
      selectedDealerId: $ctrl.selectedDealerId,
      searchCriteria: $ctrl.SearchCriteria,
      searchResultsForm: $ctrl.searchResultsForm
    });
    modalInstance.rendered.then(function () {
      rendered.resolve();
    });
    modalInstance.result.then(function (result) {
      $state.go('searchDirectAlert', {
        url: result.url
      });
    });
  }

  function performAddAccess() {
    var rendered = $q.defer();
    var addAccessModalInstance = openAddAccessModal({
      rendered: rendered.promise,
      selectedDealerId: $ctrl.selectedDealerId,
      dealsheetDomain: $ctrl.dealsheetDomain
    });
    addAccessModalInstance.rendered.then(function () {
      rendered.resolve();
    });
    addAccessModalInstance.result.then(function (result) {
      performBatchAddAccess(result.assignedUsers);
    });
  }

  function performBatchAddAccess(assignedUsers) {
    var msg = {
      type: 'PERFORM_BATCH_ADD_ACCESS',
      data: {
        assignedUsers: assignedUsers
      }
    };
    $ctrl.searchGridMessagePort.postMessage(msg);
  }

  function performBatchReassign(assignedUsers) {
    var msg = {
      type: 'PERFORM_BATCH_REASSIGN',
      data: {
        assignedUsers: assignedUsers
      }
    };
    $ctrl.searchGridMessagePort.postMessage(msg);
  }

  function performEpsilonExport() {
    var modalInstance = openEpsilonExportModal();
    modalInstance.result.then(function (result) {
      var dealerIds = [];
      $ctrl.resultsGridPromise = new $q(function (resolve, reject) {
        if ($ctrl.isMultipleDealers) {
          if (!$ctrl.selectedDealerId) {
            // null, undefined & 0 are all not truthy in JS
            dealerIds = Array.from($ctrl.dealers.filter(function (item) {
              return item.id != 0;
            }), function (x) {
              return x.id;
            });
          } else {
            dealerIds.push($ctrl.selectedDealerId);
          }
        } else {
          dealerIds = Array.from($ctrl.dealers.filter(function (item) {
            return item.id != 0;
          }), function (x) {
            return x.id;
          });
          ;
        }

        var searchModel = convertPortalSearchToLegacyObject($ctrl.values);
        var campaignTypeName = result.campaignType == '5' ? result.campaignTypeName : "";
        searchService.createDealerCampaignExportAudience(searchModel, result.campaignType, dealerIds, campaignTypeName).then(function (data) {
          resolve();
          openEpsilonExportResultModal(data);
        }).catch(function (e) {
          reject(e);
        });
      });
    });
  }

  function performExport() {
    var rendered = $q.defer();
    var exportModalInstance = openExportModal({
      rendered: rendered.promise,
      selectedDealerId: $ctrl.selectedDealerId
    });
    exportModalInstance.rendered.then(function () {
      rendered.resolve();
    });
    exportModalInstance.result.then(function (result) {
      $ctrl.exportLayout = result.exportLayout;
      generateExport(result.exportLayout);
    });
  }

  function generateExport(exportLayout) {
    var msg = {
      type: 'EXPORT_GENERATE',
      data: {
        exportLayout: exportLayout
      }
    };
    $ctrl.searchGridMessagePort.postMessage(msg);
  }

  function updateMenuSettings(presetId, activity) {
    var settings = {
      selectedUserSearchConditionId: null
    };

    if (presetId && activity === null) {
      settings.selectedUserSearchConditionId = presetId;
    }

    $ctrl.onMenuSettingsChanged({
      settings: settings
    });
  }

  function performSearch(presetId) {
    presetId = typeof presetId !== 'undefined' ? presetId : null;
    updateMenuSettings(presetId, $ctrl.activity);
    $ctrl.activity = null;
    $ctrl.presetId = null;
    var invalidFields = getInvalidFields();

    if (!invalidFields) {
      $ctrl.values[$ctrl.filterNames.LayoutModuleName] = "Search";
      $ctrl.values[$ctrl.filterNames.LayoutModuleTitle] = "Manage Search";
      $ctrl.values[$ctrl.filterNames.ZipDistanceUnits] = $ctrl.isMetric ? "kilometers" : "miles";
      var json = convertPortalSearchToLegacyObject($ctrl.values);
      $ctrl.SearchCriteria = json;
      var msg = {
        'task': 'perform_search',
        'searchData': json
      };

      try {
        $ctrl.searchGridMessagePort.postMessage(msg);
      } catch (e) {// SEARCH_RESULTS_FAILED message will be triggered in this case.  Do nothing.
      }

      $ctrl.resultsGridPromise = new $q(function (resolve, reject) {
        var sub = $ctrl.searchGridMessagePort.message$.subscribe(function (data) {
          if (data.type == "PERFORM_SEARCH_COMPLETED") {
            sub.unsubscribe();
            resolve();
            $ctrl.dealsheetDomain = data.data.dealsheetDomain;
            $ctrl.searchResultsForm = data.data.searchResultsForm;
          }

          if (data.type == "SEARCH_RESULTS_FAILED") {
            logger.error("searchInvalidFieldsMessageGeneric");
            sub.unsubscribe();
            resolve();
          }
        });
        delay(30000).then(function () {
          // no search should take longer than 30 seconds
          sub.unsubscribe();
          reject(new TimeoutError());
        });
      });
    } else {
      var errorMessage = $translate.instant("searchInvalidFieldsMessage") + " " + invalidFields;
      logger.error(errorMessage);
      setSectionsOpenCloseState(true);
    }
  }

  function getMakes(countryId) {
    vehicleService.getMakes(null, countryId).then(function (dealerMakes) {
      if (dealerMakes) {
        var searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.Makes;
        })[0];
        searchFilter.availableValues = dealerMakes.map(function (obj) {
          var rObj = {};
          rObj['value'] = obj.makeID;
          rObj['nameText'] = obj.makeName;
          return rObj;
        });
      }
    }).catch(function (error) {
      log.log(error);
    });
  }

  function getRecallNumbers() {
    vehicleService.getVinRecallNos($ctrl.selectedDealerId).then(function (vinRecallNos) {
      if (vinRecallNos) {
        // MfgrRecallNos list
        var searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.MfgrRecallNos;
        })[0];
        searchFilter.availableValues = vinRecallNos.mfgrRecallNos.filter(function (value) {
          return value != null;
        }).map(function (value) {
          var rObj = {};
          rObj['value'] = value;
          rObj['nameText'] = value;
          rObj['groupName'] = -1;
          return rObj;
        }); //searchFilter.availableValues.splice(0, 0, { value: "", nameText: "", groupName: -1 });
        // NatRecallNos list

        searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.NatRecallNos;
        })[0];
        searchFilter.availableValues = vinRecallNos.natRecallNos.filter(function (value) {
          return value != null;
        }).map(function (value) {
          var rObj = {};
          rObj['value'] = value;
          rObj['nameText'] = value;
          rObj['groupName'] = -1;
          return rObj;
        }); //searchFilter.availableValues.splice(0, 0, { value: "", nameText: "", groupName: -1 });
      }
    }).catch(function (error) {
      log.log(error);
    });
  }

  function getRecallTypes() {
    vehicleService.getVinRecallTypes().then(function (vinRecallTypes) {
      if (vinRecallTypes) {
        // RecallType list
        var searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.RecallTypes;
        })[0];
        searchFilter.availableValues = vinRecallTypes.map(function (rt) {
          var rObj = {};
          rObj['value'] = rt.vinRecallTypeId;
          rObj['nameText'] = rt.vinRecallTypeDescription;
          rObj['groupName'] = -1;
          return rObj;
        });
        searchFilter.availableValues.splice(0, 0, {
          value: "",
          nameText: "",
          groupName: -1
        });
      }
    }).catch(function (error) {
      log.log(error);
    });
  }

  function getTags() {
    searchService.getTagsForDealers($ctrl.selectedDealerId).then(function (tags) {
      if (tags) {
        var searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.Tags;
        })[0];
        searchFilter.availableValues = tags.map(function (obj) {
          var rObj = {};
          rObj['value'] = obj.tagText;
          rObj['nameText'] = obj.tagText;
          return rObj;
        }); // create distinct list of models

        searchFilter.availableValues = removeDuplicates(searchFilter.availableValues, "value");
      }
    });
  }

  function getTrims() {
    vehicleService.getTrimsByModels(getSelectedIds($ctrl.filterNames.ModelsList)).then(function (dealerTrims) {
      if (dealerTrims) {
        var searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.Trims;
        })[0];
        searchFilter.availableValues = dealerTrims.map(function (obj) {
          var rObj = {};
          rObj['value'] = obj.trimName;
          rObj['nameText'] = obj.trimName;
          return rObj;
        }); // create distinct list of models

        searchFilter.availableValues = removeDuplicates(searchFilter.availableValues, "value"); // sort models

        searchFilter.availableValues = searchFilter.availableValues.sort(compareValues("value"));
      }
    }).catch(function (error) {
      log.log(error);
    });
  }

  function getBanks() {
    searchService.getBankSelect($ctrl.selectedDealerId).then(function (banks) {
      if (banks) {
        var searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.Banks;
        })[0];
        searchFilter.availableValues = banks.map(function (obj) {
          var rObj = {};
          rObj["value"] = obj.bankSearchMask == null ? obj.bankId : obj.bankSearchMask; // we need to use the bankName (not the ID) so the name matching capability will work. at starts with these characters.

          rObj['nameText'] = obj.bankName;
          rObj["groupName"] = obj.type == 0 ? $translate.instant("bankSelectType0") : $translate.instant("bankSelectType1");
          return rObj;
        });
        searchFilter.groupNames = [];

        for (var value in searchFilter.availableValues) {
          if (!searchFilter.groupNames.some(function (s) {
            return s == searchFilter.availableValues[value].groupName;
          })) searchFilter.groupNames.push(searchFilter.availableValues[value].groupName);
        }

        searchFilter.availableValues.splice(0, 0, {
          value: 0,
          nameText: "",
          groupName: -1
        });
        $ctrl.values[$ctrl.filterNames.BankID] = "0";
      }
    }).catch(function (error) {
      log.log(error);
    });
  }

  function getSoldBy() {
    searchService.getSoldBySearchByDealers($ctrl.selectedDealerId).then(function (soldByUsers) {
      if (soldByUsers) {
        var searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.SoldBy;
        })[0];
        searchFilter.availableValues = soldByUsers.map(function (obj) {
          var rObj = {};
          rObj['value'] = obj.displayName;
          rObj['nameText'] = obj.displayName;
          return rObj;
        });
      }
    }).catch(function (error) {
      log.log(error);
    });
  }

  function getOwners() {
    userService.getUsers($ctrl.selectedDealerId).then(function (users) {
      if (users) {
        var searchFilter = $ctrl.searchFilters.filter(function (filter) {
          return filter.filterSource == $ctrl.filterSources.Owner;
        })[0];
        searchFilter.availableValues = users.map(function (obj) {
          var rObj = {};
          rObj['value'] = obj.id;
          rObj['nameText'] = obj.fullName;
          return rObj;
        }); // sort models

        searchFilter.availableValues = searchFilter.availableValues.sort(compareValues("nameText"));
        searchFilter.availableValues.splice(0, 0, {
          value: -1,
          nameText: "[Orphans]"
        }); // TODO:  add translation
      }
    }).catch(function (error) {
      log.log(error);
    });
  }

  function getModelsByMakesYears() {
    var makeIds = getSelectedValue($ctrl.filterNames.MakesList);
    var years = getSelectedValue($ctrl.filterNames.YearsList);
    var searchFilter = $ctrl.searchFilters.filter(function (filter) {
      return filter.filterSource == $ctrl.filterSources.Models;
    })[0];

    if (makeIds.length > 0 || years.length > 0) {
      vehicleService.getModelsByMakesYears(makeIds, years).then(function (dealerModels) {
        // we need to pull and check makeId and years again because these values may have changed on another thread (such as searchFilter.availableValues = [] below)   -Brad O. 2/4/2019
        makeIds = getSelectedValue($ctrl.filterNames.MakesList);
        years = getSelectedValue($ctrl.filterNames.YearsList);

        if (makeIds.length > 0 || years.length > 0) {
          if (dealerModels.length > 0) {
            searchFilter.availableValues = dealerModels.map(function (obj) {
              var rObj = {};
              rObj['value'] = obj.modelName;
              rObj['nameText'] = obj.modelName;
              rObj["id"] = obj.modelID;
              return rObj;
            }); // create distinct list of models

            searchFilter.availableValues = removeDuplicates(searchFilter.availableValues, "value"); // sort models

            searchFilter.availableValues = searchFilter.availableValues.sort(compareValues("value"));
          } else {
            $ctrl.values[searchFilter.fieldName] = [];
            searchFilter.availableValues = [];
          }
        }
      }).catch(function (error) {
        log.log(error);
      });
    } else {
      searchFilter.availableValues = [];
      $ctrl.values[searchFilter.fieldName] = [];
    }
  }

  function getYears() {
    var searchFilter = $ctrl.searchFilters.filter(function (filter) {
      return filter.filterSource == $ctrl.filterSources.Years;
    })[0];
    var currentYear = new Date().getFullYear();
    var yearValues = [];

    for (var i = currentYear + 1; i > currentYear - 10; i--) {
      var rObj = {};
      rObj['value'] = i;
      rObj["nameText"] = i;
      yearValues.push(rObj);
    }

    searchFilter.availableValues = yearValues;
  }

  function getSelectedValue(fieldName) {
    var selectedValues = $ctrl.values[fieldName];
    var values = [];

    for (var v in selectedValues) {
      values.push(selectedValues[v].value);
    }

    return values;
  }

  function getSelectedIds(fieldName) {
    var selectedValues = $ctrl.values[fieldName];
    var ids = [];

    for (var v in selectedValues) {
      ids.push(selectedValues[v].id);
    }

    return ids;
  }

  function onDealerSelect() {
    storageService.setItem("selectedDealerId", $ctrl.selectedDealerId);
    getBanks();
    getSoldBy();
    getOwners();
    getTags();
    getRecallNumbers();
    getRecallTypes();
    getDealerSettings($ctrl.selectedDealerId);
    onInitOrLoad();
  }

  function getDealerSettings(dealerId) {
    if (dealerId <= 0 || dealerId === undefined) {
      return;
    }

    var index = _.findIndex($ctrl.dealerSettings, function (d) {
      return d.dealerId === $ctrl.selectedDealerId;
    });

    if (index >= 0) {
      $ctrl.enableDirectAlertCampaigns = $ctrl.dealerSettings[index].enableDirectAlertCampaigns;
    } else {
      dealerService.getDealerSettings($ctrl.selectedDealerId).then(function (settings) {
        settings.dealerId = $ctrl.selectedDealerId;
        $ctrl.dealerSettings.push(settings);
        $ctrl.enableDirectAlertCampaigns = settings.enableDirectAlertCampaigns;
      });
    }
  }

  function openBulkTemplateWizard() {
    var dealerId = $ctrl.selectedDealerId;

    if (typeof dealerId != 'number') {
      throw new Error("Must select a single dealer");
    }

    var _onRendered = $q.defer();

    var modal = $uibModal.open({
      component: "bulkTemplateWizardModal",
      size: 'lg',
      resolve: {
        onRendered: function onRendered() {
          return {
            promise: _onRendered.promise
          };
        },
        src: function src() {
          return "".concat(bulkTemplateUrl, "?dealerID=").concat(dealerId);
        },
        title: function title() {
          return "selectCustomers";
        },
        searchGridMessagePort: function searchGridMessagePort() {
          return $ctrl.searchGridMessagePort;
        }
      }
    });
    modal.rendered.then(function () {
      return _onRendered.resolve();
    });
  }

  function convertLegacyObjectToPortalSearch(legacyObj) {
    var portalObj = {};

    for (var s in $ctrl.searchFilters) {
      var sf = $ctrl.searchFilters[s];
      var selected;
      var av;

      switch (sf.filterType) {
        case $ctrl.filterTypes.TextBox:
          portalObj[sf.fieldName] = legacyObj[sf.fieldName];
          break;

        case $ctrl.filterTypes.TextBoxRange:
          portalObj[sf.fieldNameMin] = legacyObj[sf.fieldNameMin] != null ? "" + legacyObj[sf.fieldNameMin] : null;
          portalObj[sf.fieldNameMax] = legacyObj[sf.fieldNameMax] != null ? "" + legacyObj[sf.fieldNameMax] : null;
          break;

        case $ctrl.filterTypes.DatepickerRange:
          portalObj[sf.fieldNameMin] = legacyObj[sf.fieldNameMin] ? new Date(legacyObj[sf.fieldNameMin]) : null;
          portalObj[sf.fieldNameMax] = legacyObj[sf.fieldNameMax] ? new Date(legacyObj[sf.fieldNameMax]) : null;
          break;

        case $ctrl.filterTypes.DropdownList:
          portalObj[sf.fieldName] = legacyObj[sf.fieldName];
          break;

        case $ctrl.filterTypes.MultiSelectList:
          selected = legacyObj[sf.fieldName];
          portalObj[sf.fieldName] = [];

          for (var sel in selected) {
            av = sf.availableValues.filter(function (filter) {
              var value = filter.valueOverride ? filter.valueOverride : filter.value;
              return value == selected[sel];
            })[0];
            if (av != null) portalObj[sf.fieldName].push(av);else portalObj[sf.fieldName].push({
              value: selected[sel]
            });
          }

          break;

        case $ctrl.filterTypes.CheckBoxList:
          if (!portalObj[sf.fieldName]) portalObj[sf.fieldName] = {};

          for (var v in sf.availableValues) {
            av = sf.availableValues[v];
            var fieldName = sf.availableValuesUsefieldName ? sf.fieldName : av.name;
            selected = legacyObj[fieldName];

            if (selected != null) {
              if (sf.availableValuesUsefieldName) {
                portalObj[sf.fieldName][av.name] = selected.some(function (s) {
                  return s == av.valueOverride;
                });
              } else {
                portalObj[sf.fieldName][av.name] = selected;
              }
            }
          }

          break;
      }
    }

    legacyObj[$ctrl.filterNames.DealerID] = $ctrl.selectedDealerId != null ? $ctrl.selectedDealerId.toString() : "0";
    return portalObj;
  }

  function convertPortalSearchToLegacyObject(portalObj) {
    var legacyObj = {};

    for (var s in $ctrl.searchFilters) {
      var sf = $ctrl.searchFilters[s];
      var selected;

      switch (sf.filterType) {
        case $ctrl.filterTypes.TextBox:
          legacyObj[sf.fieldName] = portalObj[sf.fieldName];
          break;

        case $ctrl.filterTypes.TextBoxRange:
          legacyObj[sf.fieldNameMin] = portalObj[sf.fieldNameMin];
          legacyObj[sf.fieldNameMax] = portalObj[sf.fieldNameMax];
          break;

        case $ctrl.filterTypes.DatepickerRange:
          var fieldNameMin = portalObj[sf.fieldNameMin] ? portalObj[sf.fieldNameMin] : null;
          var fieldNameMax = portalObj[sf.fieldNameMax] ? portalObj[sf.fieldNameMax] : null;
          legacyObj[sf.fieldNameMin] = fieldNameMin ? moment(fieldNameMin).format("YYYY-MM-DD[T]HH:mm:ss") : null;
          legacyObj[sf.fieldNameMax] = fieldNameMax ? moment(fieldNameMax).format("YYYY-MM-DD[T]HH:mm:ss") : null;
          break;

        case $ctrl.filterTypes.DropdownList:
          legacyObj[sf.fieldName] = portalObj[sf.fieldName];
          break;

        case $ctrl.filterTypes.MultiSelectList:
          selected = portalObj[sf.fieldName];
          legacyObj[sf.fieldName] = [];

          for (var sel in selected) {
            legacyObj[sf.fieldName].push(selected[sel].value);
          }

          break;

        case $ctrl.filterTypes.CheckBoxList:
          if (sf.availableValuesUsefieldName) {
            if (!legacyObj[sf.fieldName]) legacyObj[sf.fieldName] = [];
          }

          selected = portalObj[sf.fieldName];

          for (var v in sf.availableValues) {
            var av = sf.availableValues[v];

            if (selected != null && selected[av.name]) {
              var value = av.valueOverride ? av.valueOverride : selected[av.name];
              if (sf.availableValuesUsefieldName) legacyObj[sf.fieldName].push(value);else legacyObj[av.name] = value;
            }
          }

          break;
      }
    }

    legacyObj[$ctrl.filterNames.DealerID] = $ctrl.selectedDealerId != null ? $ctrl.selectedDealerId.toString() : "0";
    legacyObj["IsFordAlertDealer"] = $ctrl.isFordAlert;
    legacyObj[$ctrl.filterNames.ZipDistanceUnits] = portalObj[$ctrl.filterNames.ZipDistanceUnits];

    if (portalObj[$ctrl.filterNames.ZipDistance] == null) {
      legacyObj[$ctrl.filterNames.ZipDistance] = "0";
    }

    return legacyObj;
  }

  function updateTooltip(displayNameKey, filterType, tooltipText, selectedValues, availableValues) {
    var selectedSummary = "";

    switch (filterType) {
      case $ctrl.filterTypes.MultiSelectList:
        if (selectedValues != null && selectedValues.length > 0) {
          for (var i in availableValues) {
            var av = availableValues[i];
            if (selectedValues.some(function (s) {
              return s.nameText == av.nameText;
            })) selectedSummary += av.nameText + ", ";
          }

          selectedSummary = selectedSummary.slice(0, -2); // remove final comma
        }

        if (selectedSummary) tooltipText = selectedSummary;
        break;
    }

    $ctrl.popOverText[displayNameKey] = tooltipText;
  }

  function clearAll() {
    initializeValues();
  }

  function initializeValues() {
    for (var f in $ctrl.searchFilters) {
      var field = $ctrl.searchFilters[f]; // set tooltip

      updateTooltip(field.fieldName, field.filterType, field.tooltipText, null, null);
      field.triggerInit = true;
    }

    $ctrl.searchConditionName = "";
  }

  function removeDuplicates(myArr, prop) {
    return myArr.filter(function (obj, pos, arr) {
      return arr.map(function (mapObj) {
        return mapObj[prop];
      }).indexOf(obj[prop]) === pos;
    });
  }

  function compareValues(key) {
    var order = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'asc';
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      var varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      var varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
      var comparison = 0;

      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }

      return order == 'desc' ? comparison * -1 : comparison;
    };
  }

  function setDisabledUntilPerformSearch(value) {
    $ctrl.saveSearchBtnDisabled = $ctrl.isEmployeeUser && $ctrl.isMultipleDealers ? true : value;
    $ctrl.oneToOneBtnDisabled = $ctrl.selectedDealerId == 0 ? true : value;
    $ctrl.templateBtnDisabled = $ctrl.selectedDealerId == 0 ? true : value;
    $ctrl.moreBtnDisabled = value;
  }
}