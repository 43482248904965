import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.promise.finally";
DataEntryPricesController.$inject = ["$translate", "$q", "vehicleService", "dealerService", "userService", "dataEntryService", "storageService", "priceSetToOptions", "$uibModal", "$document", "logger", "dealerCountries", "cultures", "assetTypes", "$filter", "$logFactory"];

/* injects from baggage-loader */
require('./data-entry-prices.component.html');

import angular from 'angular';
import $ from 'jquery';
import './data-entry-prices.component.scss';
import BusyIndicator from '../../utilities/BusyIndicator';
angular.module('AaApp.Opp').component('dataEntryPrices', {
  templateUrl: '/app/components/data-entry/data-entry-prices.component.html',
  controller: DataEntryPricesController,
  bindings: {
    kendo: '<'
  }
});

function DataEntryPricesController($translate, $q, vehicleService, dealerService, userService, dataEntryService, storageService, priceSetToOptions, $uibModal, $document, logger, dealerCountries, cultures, assetTypes, $filter, $logFactory) {
  "ngInject";

  var $ctrl = this;
  var log = $logFactory.get();
  $ctrl.$onInit = init;
  $ctrl.onDealerSelect = onDealerSelect;
  $ctrl.trimSelections;
  $ctrl.setTrimSelections = setTrimSelections;
  $ctrl.showQuickAction = false;
  $ctrl.onShowQuickAction = onShowQuickAction;
  $ctrl.onHideQuickAction = onHideQuickAction;
  $ctrl.onPerformPrice = onPerformPrice;
  $ctrl.onClearPrice = onClearPrice;
  $ctrl.onPerformExpireDate = onPerformExpireDate;
  $ctrl.onClearExpireDate = onClearExpireDate;
  $ctrl.onSave = onSave;
  $ctrl.onCancel = onCancel;
  $ctrl.kendoGrid = null;
  $ctrl.busyIndicator = new BusyIndicator();
  $ctrl.minPrice = 10000;
  $ctrl.maxPrice = 500000;
  $ctrl.countryId;
  $ctrl.cultureName;
  $ctrl.pricePerformError = false;
  $ctrl.showOverwriteWarning = false;
  $ctrl.hasO2O = false;

  function init() {
    $ctrl.dealers = dealerService.getSelectedDealers();
    var selectedDealerId = storageService.getItem('selectedDealerId');
    $ctrl.selectedDealerId = selectedDealerId > 0 ? selectedDealerId : $ctrl.dealers[0].id;
    $ctrl.dateOptions = {
      minDate: new Date(),
      startingDay: 0,
      showWeeks: false
    };
    userService.getUserProfile().then(function (userProfile) {
      $ctrl.countryId = userProfile.countryId;
      $ctrl.cultureName = userProfile.cultureName;
      getTranslations();
      initGridOptions();
      onDealerSelect();
    });
  }

  function getTranslations() {
    $q.all({
      'saveConfirmMessage': $translate('saveConfirmMessage'),
      'saveWarningMessage': $translate('saveWarningMessage'),
      'currencySymbol': $translate('currencySymbol'),
      'validationErrorMessage': $translate('validationErrorMessage')
    }).then(function (stuff) {
      $ctrl.saveConfirmMessage = stuff.saveConfirmMessage;
      $ctrl.saveWarningMessage = stuff.saveWarningMessage;
      $ctrl.currencySymbol = stuff.currencySymbol;
      $ctrl.validationErrorMessage = stuff.validationErrorMessage;
    });
  }

  function busy(translationKey, promise) {
    $ctrl.busyMessage = null;
    $translate(translationKey).then(function (translation) {
      $ctrl.busyMessage = translation;
    }, function (err) {
      $ctrl.busyMessage = translationKey;
    });
    var done = $ctrl.busyIndicator.start();
    return promise.finally(done);
  }

  function onDealerSelect(previousValue) {
    storageService.setItem("selectedDealerId", $ctrl.selectedDealerId);

    if (isDataDirty()) {
      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "pleaseConfirm";
          },
          message: function message() {
            return $ctrl.saveWarningMessage;
          },
          submitButtonVisible: function submitButtonVisible() {
            return true;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          submitButtonText: function submitButtonText() {
            return "continue"; // translation key
          }
        }
      });
      modal.result.then(function () {
        return busy("Loading Prices", $q.all([vehicleService.getMakes($ctrl.selectedDealerId, null), vehicleService.getYearIDs($ctrl.selectedDealerId, false), dealerService.getIsAssetEnabledForDealer(assetTypes.oneToOne, $ctrl.selectedDealerId)]).then(function (data) {
          $ctrl.selectedMakeId = data[0][0].makeID; //$ctrl.countryId = data[0][0].countryId;

          var currentYear = new Date().getFullYear();
          $ctrl.selectedYearId = data[1][0];
          if ($ctrl.selectedYearId != currentYear) $ctrl.selectedYearId = currentYear;
          $ctrl.defaults = {
            vehicleTypes: 0,
            makeID: data[0][0].makeID,
            makeName: data[0][0].makeName,
            yearID: $ctrl.selectedYearId,
            modelID: null,
            modelName: null,
            trimID: null,
            trimName: null
          };
          $ctrl.trimSelections = $ctrl.defaults;
          $ctrl.hasO2O = data[2];
          return loadPrices();
        }));
      }).catch(function () {
        $ctrl.selectedDealerId = previousValue;
        return false;
      });
    } else return busy("Loading Prices", $q.all([vehicleService.getMakes($ctrl.selectedDealerId, null), vehicleService.getYearIDs($ctrl.selectedDealerId, false), dealerService.getIsAssetEnabledForDealer(assetTypes.oneToOne, $ctrl.selectedDealerId)]).then(function (data) {
      $ctrl.selectedMakeId = data[0][0].makeID; //$ctrl.countryId = data[0][0].countryId;

      var currentYear = new Date().getFullYear();
      $ctrl.selectedYearId = data[1][0];
      if ($ctrl.selectedYearId != currentYear) $ctrl.selectedYearId = currentYear;
      $ctrl.defaults = {
        vehicleTypes: 0,
        makeID: data[0][0].makeID,
        makeName: data[0][0].makeName,
        yearID: $ctrl.selectedYearId,
        modelID: null,
        modelName: null,
        trimID: null,
        trimName: null
      };
      $ctrl.trimSelections = $ctrl.defaults;
      $ctrl.hasO2O = data[2];
      return loadPrices();
    }));
  }

  function onShowQuickAction() {
    $ctrl.showQuickAction = true;
    if (!$ctrl.priceSetTo) $ctrl.priceSetTo = priceSetToOptions.empty;
    if (!$ctrl.priceAdjMode) $ctrl.priceAdjMode = "%";
  }

  function onHideQuickAction() {
    $ctrl.showQuickAction = false;
  }

  function clearQuickAction() {
    $ctrl.priceSetTo = priceSetToOptions.empty;
    $ctrl.priceAdjMode = "%";
    $ctrl.priceAdjBy = null;
    $ctrl.expireSetTo = null;
    $ctrl.expireAdjBy = null;
  }

  function onPerformPrice() {
    if ($ctrl.priceSetTo && $ctrl.priceSetTo !== priceSetToOptions.empty && ($ctrl.priceAdjBy || $ctrl.priceAdjBy == 0)) {
      $ctrl.pricePerformError = false;
      var rows = $ctrl.kendoGrid.dataSource.data();
      rows.forEach(function (row) {
        if ($ctrl.priceSetTo && $ctrl.priceSetTo !== priceSetToOptions.empty) {
          if ($ctrl.priceSetTo === priceSetToOptions.totalPrice) row.price = row.total;
          if ($ctrl.priceSetTo === priceSetToOptions.msrp) row.price = row.msrp;

          if ($ctrl.priceAdjBy) {
            if ($ctrl.priceAdjMode === "%") row.price = Math.round(row.price + row.price * $ctrl.priceAdjBy / 100);
            if ($ctrl.priceAdjMode === $ctrl.currencySymbol) row.price = row.price + $ctrl.priceAdjBy;
          }

          row.dirty = true;
          if (row.dirtyFields) row.dirtyFields.price = true;else row.dirtyFields = {
            "price": true
          };
        } else {
          if (row.price) {
            if ($ctrl.priceAdjMode === "%") row.price = row.price + row.price * $ctrl.priceAdjBy / 100;
            if ($ctrl.priceAdjMode === $ctrl.currencySymbol) row.price = row.price + $ctrl.priceAdjBy;
            row.dirty = true;
            if (row.dirtyFields) row.dirtyFields.price = true;else row.dirtyFields = {
              "price": true
            };
          }
        }
      });
      setOverwriteWarning(true);
      $ctrl.kendoGrid.refresh();
    } else {
      $ctrl.pricePerformError = true;
      var modal1 = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "validationError";
          },
          message: function message() {
            return $ctrl.validationErrorMessage;
          },
          submitButtonVisible: function submitButtonVisible() {
            return false;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          closeButtonVisible: function closeButtonVisible() {
            return true;
          },
          closeButtonDisabled: function closeButtonDisabled() {
            return true;
          },
          closeButtonText: function closeButtonText() {
            return "ok"; // translation key
          }
        }
      });
      return;
    }
  }

  function onClearPrice() {
    var rows = $ctrl.kendoGrid.dataSource.data();
    rows.forEach(function (row) {
      if (row.price) {
        row.price = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.price = true;else row.dirtyFields = {
          "price": true
        };
      }
    });
    $ctrl.kendoGrid.refresh();
  }

  function onPerformExpireDate() {
    if ($ctrl.expireSetTo || $ctrl.expireAdjBy) {
      var rows = $ctrl.kendoGrid.dataSource.data();
      rows.forEach(function (row) {
        if ($ctrl.expireSetTo) {
          row.expirationDate = new Date($ctrl.expireSetTo);
          if ($ctrl.expireAdjBy) row.expirationDate.setDate(row.expirationDate.getDate() + $ctrl.expireAdjBy);
          row.dirty = true;
          if (row.dirtyFields) row.dirtyFields.expirationDate = true;else row.dirtyFields = {
            "expirationDate": true
          };
        } else {
          if ($ctrl.expireAdjBy && row.expirationDate) {
            row.expirationDate.setDate(row.expirationDate.getDate() + $ctrl.expireAdjBy);
            row.dirty = true;
            if (row.dirtyFields) row.dirtyFields.expirationDate = true;else row.dirtyFields = {
              "expirationDate": true
            };
          }
        }
      });
      $ctrl.kendoGrid.refresh();
    }
  }

  function onClearExpireDate() {
    var rows = $ctrl.kendoGrid.dataSource.data();
    rows.forEach(function (row) {
      if (row.expirationDate) {
        row.expirationDate = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.expirationDate = true;else row.dirtyFields = {
          "expirationDate": true
        };
      }
    });
    $ctrl.kendoGrid.refresh();
  }

  function onSave() {
    if (!isDataValid()) {
      var modal1 = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "validationError";
          },
          message: function message() {
            return $ctrl.validationErrorMessage;
          },
          submitButtonVisible: function submitButtonVisible() {
            return false;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          closeButtonVisible: function closeButtonVisible() {
            return true;
          },
          closeButtonDisabled: function closeButtonDisabled() {
            return true;
          },
          closeButtonText: function closeButtonText() {
            return "ok"; // translation key
          }
        }
      });
      return;
    }

    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return "readyToProcess";
        },
        message: function message() {
          return $ctrl.saveConfirmMessage;
        },
        submitButtonVisible: function submitButtonVisible() {
          return true;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        submitButtonText: function submitButtonText() {
          return "continue"; // translation key
        }
      }
    });
    modal.result.then(function () {
      var rows = $ctrl.kendoGrid.dataSource._data;
      var dirtyRows = [];
      rows.forEach(function (row) {
        if (row.dirty) {
          dirtyRows.push({
            dealerPriceID: row.dealerPriceID,
            insertDate: row.insertDate,
            dealerID: row.dealerID,
            trimID: row.trimID,
            price: row.price,
            expirationDate: row.expirationDate,
            destinationFee: row.destinationFeeEditable
          });
          row.dirty = false;
        }
      });

      if (dirtyRows.length > 0) {
        return updateDealerPrices(dirtyRows);
      } else {
        logger.success("changesSaved");
        return $q.resolve();
      }
    }).catch(function () {
      return false;
    });
  }

  function onCancel() {
    $ctrl.kendoGrid.cancelChanges();
    setOverwriteWarning(anyOverrides($ctrl.kendoGrid.dataSource._data));
  }

  function updateDealerPrices(prices) {
    return busy("Saving Prices", dataEntryService.updateDealerPrices(prices).then(function (success) {
      logger.success("changesSaved");
      return loadPrices();
    }).then(function () {}, function (error) {
      log.log(error);
    }));
  }

  function setTrimSelections(selections) {
    if (isDataDirty()) {
      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "pleaseConfirm";
          },
          message: function message() {
            return $ctrl.saveWarningMessage;
          },
          submitButtonVisible: function submitButtonVisible() {
            return true;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          submitButtonText: function submitButtonText() {
            return "continue"; // translation key
          }
        }
      });
      modal.result.then(function () {
        $ctrl.trimSelections = selections;
        return loadPrices();
      }).catch(function () {
        if (selections.makeID != $ctrl.trimSelections.makeID || selections.yearID != $ctrl.trimSelections.yearID || selections.modelID != $ctrl.trimSelections.modelID || selections.trimID != $ctrl.trimSelections.trimID) {
          $ctrl.defaults = {
            vehicleTypes: 0,
            makeID: $ctrl.trimSelections.makeID,
            makeName: $ctrl.trimSelections.makeName,
            yearID: $ctrl.trimSelections.yearID,
            modelID: $ctrl.trimSelections.modelID,
            modelName: $ctrl.trimSelections.modelName,
            trimID: $ctrl.trimSelections.trimID,
            trimName: $ctrl.trimSelections.trimName
          };
        }

        return;
      });
    } else {
      $ctrl.trimSelections = selections;
      return loadPrices();
    }
  }

  function isDataValid() {
    if (!$ctrl.kendoGrid) return true;
    var valid = true;
    var rows = $ctrl.kendoGrid.dataSource._data;
    rows.forEach(function (row) {
      if (row.price && !row.expirationDate || !row.price && row.expirationDate || row.price && (row.price < $ctrl.minPrice || row.price > $ctrl.maxPrice)) valid = false;
    });
    return valid;
  }

  function isDataDirty() {
    if (!$ctrl.kendoGrid) return false;
    var dirty = false;
    var rows = $ctrl.kendoGrid.dataSource._data;
    rows.forEach(function (row) {
      if (row.dirty) dirty = true;
    });
    return dirty;
  }

  function initGridOptions() {
    $ctrl.gridOptions = {
      dataSource: $ctrl.ds,
      navigatable: true,
      pageable: true,
      height: 580,
      columns: [{
        field: "trimFullName",
        title: "{{ 'trim' | translate }}",
        width: 110,
        sortable: true
      }, {
        field: "msrp",
        title: "{{ 'msrp' | translate }}",
        format: "{0:c0}",
        width: 40,
        sortable: true,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "destination",
        title: "{{ 'destination' | translate }}",
        format: "{0:c0}",
        width: 40,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "options",
        title: "{{ 'options' | translate }}",
        format: "{0:c0}",
        width: 30,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "sunroof",
        title: "{{ 'sunroofPackage' | translate }}",
        format: "{0:c0}",
        width: 50,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "automaticTransmission",
        title: "{{ 'automaticTransmission' | translate }}",
        format: "{0:c0}",
        width: 50,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "metallicPaint",
        title: "{{ 'metallicPaint' | translate }}",
        format: "{0:c0}",
        width: 50,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "gasGuzzlerTax",
        title: "{{ 'gasGuzzlerTax' | translate }}",
        format: "{0:c0}",
        width: 50,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "total",
        title: "{{ 'total' | translate }}",
        template: totalTemplate,
        format: "{0:c0}",
        width: 40,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "price",
        title: "{{ 'Overwrite' | translate }}",
        template: priceTemplate,
        format: "{0:c0}",
        width: 60,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "expirationDate",
        title: "{{ 'expireOn' | translate }}",
        template: expirationDateTemplate,
        format: "{0:d}",
        width: 65,
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        }
      }, {
        field: "destinationFeeEditable",
        headerTemplate: "<span style='z-index=-1'>{{ 'destinationFeeEditable' | translate }}<icon icon=\"'info'\" class='page-info -small' popover-append-to-body='true' uib-popover='{{ \"destinationFeeEditableToolTip\" | translate }}' popover-placement='top' popover-trigger=\"'mouseenter'\"></icon></span>",
        template: destinationTemplate,
        format: "{0:c0}",
        attributes: {
          style: "text-align: right"
        },
        headerAttributes: {
          "class": "align-center"
        },
        width: 70
      }],
      editable: true,
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      resizable: true,
      reorderable: true,
      filterable: false
    };
  }

  function setOverwriteWarning(show) {
    $ctrl.showOverwriteWarning = $ctrl.hasO2O && show;
  }

  function onChange(e) {
    if (e.field === "price") {
      if (e.items[0].price != null) {
        setOverwriteWarning(true);
      }
    }
  }

  function anyOverrides(dataPrices) {
    var overridesFound = false;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = dataPrices[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var row = _step.value;

        if (row.price != null) {
          overridesFound = true;
          break;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return overridesFound;
  }

  function loadPrices() {
    var makeID = $ctrl.trimSelections.makeID;
    var yearID = $ctrl.trimSelections.yearID;
    var modelID = $ctrl.trimSelections.modelID ? $ctrl.trimSelections.modelID : 0;
    var trimID = $ctrl.trimSelections.trimID ? $ctrl.trimSelections.trimID : 0;
    return busy("Loading Prices", dataEntryService.getDealerPrices($ctrl.selectedDealerId, makeID, yearID, modelID, trimID).then(function (dataPrices) {
      setOverwriteWarning(anyOverrides(dataPrices)); //$("#gridDataEntryPrices").empty();

      $ctrl.ds = new $ctrl.kendo.data.DataSource({
        data: dataPrices,
        schema: {
          model: {
            fields: {
              trimFullName: {
                type: "string",
                editable: false
              },
              msrp: {
                type: "number",
                editable: false
              },
              destination: {
                type: "number",
                editable: false
              },
              options: {
                type: "number",
                editable: false
              },
              sunroof: {
                type: "number",
                editable: false
              },
              automaticTransmission: {
                type: "number",
                editable: false
              },
              metallicPaint: {
                type: "number",
                editable: false
              },
              gasGuzzlerTax: {
                type: "number",
                editable: false
              },
              total: {
                type: "number",
                editable: false
              },
              price: {
                type: "number",
                editable: true
              },
              expirationDate: {
                type: "date",
                editable: true
              },
              destinationFeeEditable: {
                type: "number",
                editable: true
              }
            }
          }
        },
        batch: true,
        pageSize: 50,
        change: onChange
      });

      switch ($ctrl.cultureName) {
        case cultures.enUS:
        case cultures.enGB:
        case cultures.enCA:
        case cultures.frCA:
        case cultures.frFR:
        case cultures.esUS:
          $ctrl.kendo.culture($ctrl.cultureName);
          break;

        default:
          $ctrl.kendo.culture(cultures.enUS);
          break;
      }

      $("#k-datepicker").kendoDatePicker.widget.fn.options.min = new Date();
      $ctrl.showQuickAction = false;
      $ctrl.kendoGrid = $("#gridDataEntryPrices").data("kendoGrid");
      clearQuickAction();
    }));
  }

  function totalTemplate(e) {
    return "<span class= 'bold'>" + $ctrl.kendo.toString(e.total, 'C0') + "</span>";
  }

  function destinationTemplate(e) {
    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.destinationFeeEditable, 'c0') + "</div>";
  }

  function priceTemplate(e) {
    updateExpirationValidationFlag(e);

    if (e.price) {
      if (e.price >= $ctrl.minPrice && e.price <= $ctrl.maxPrice) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.price, 'c0') + "</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.price, 'c0') + "</div>";
    } else if (e.expirationDate) {
      return "<div class='edit-box-error'></div>";
    } else {
      return "<div class='edit-box'></div>";
    }
  }

  function updateExpirationValidationFlag(e) {
    if (!e.expirationDate) {
      var grid = $("#gridDataEntryPrices").data("kendoGrid");
      var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
      var rowChildren = $(row.children('td[role="gridcell"]'));
      var cell;
      if ($ctrl.countryId == dealerCountries.canada) cell = rowChildren.eq(9);else cell = rowChildren.eq(10);

      if (e.price) {
        cell.html("<div class='edit-box-error'></div>");
      } else {
        cell.html("<div class='edit-box'></span></div>");
      }
    }
  }

  function expirationDateTemplate(e) {
    updatePriceValidationFlag(e);
    var expirationDate = e.expirationDate ? e.expirationDate : '';
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    if (expirationDate) {
      var localeDateFilter = $filter('localeDate');
      var localeExpirationDate = localeDateFilter(expirationDate);
      if (expirationDate < today) return "<div class= 'expire-date edit-box'>" + localeExpirationDate + "</div>";else return "<div class= 'edit-box'>" + localeExpirationDate + "</div>";
    } else if (e.price) {
      return "<div class='edit-box-error'></div>";
    } else {
      return "<div class= 'edit-box'>" + "</div>";
    }
  }

  function updatePriceValidationFlag(e) {
    if (!e.price) {
      var grid = $("#gridDataEntryPrices").data("kendoGrid");
      var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
      var rowChildren = $(row.children('td[role="gridcell"]'));
      var cell;
      if ($ctrl.countryId == dealerCountries.canada) cell = rowChildren.eq(8);else cell = rowChildren.eq(9);

      if (e.expirationDate) {
        cell.html("<div class='edit-box-error'></div>");
      } else {
        cell.html("<div class='edit-box'></span></div>");
      }
    }
  }
}