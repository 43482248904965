import { Component, OnInit, Input, OnChanges, Inject, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VehicleService } from '../../../../ajs-upgraded-providers';
import { ToasterLoggerService } from '../../../../core/toaster-logger.service';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { VautoModalComponent } from '../vauto-modal/vauto-modal.component';
import { NgxModalWrapperService } from '../../../../services/ngx-modal-wrapper.service';
import { DealSheetService } from '../../../../ajs-upgraded-providers';

@Component({
    selector: 'vauto',
    templateUrl: './vauto.component-ng.html',
    styleUrls: ['./vauto.component.scss']
})
export class VautoComponent {
    @Input() dealerId: number;
    @Input() customer: any;
    @Input() vehicle: any;
    @Input() owner: any;
    bsModalRef: BsModalRef;

    constructor(
        public modalService: NgxModalWrapperService,
        public tService: TranslateService,
        public logger: ToasterLoggerService,
        @Inject(VehicleService) private vehicleService,
        @Inject(DealSheetService) private dealSheetService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    public vautoModal() {

        const initialState = {
            dealerId: this.dealerId,
            customer: this.customer,
            vehicle: this.vehicle,
            owner: this.owner
        };

        this.bsModalRef = this.modalService.show(VautoModalComponent, { initialState, class: 'vauto-modal' });

        this.bsModalRef.content.onSaveChanges.subscribe((result: any) => {

            var salesperson = [];
            if (this.owner != null && this.owner.ownerUserFullName != null) {
                salesperson = this.owner.ownerUserFullName.split(" ", 2);
            }
            var request = {
                dealerId: this.dealerId,
                vin: this.vehicle.vin,
                make: this.vehicle.makeName,
                model: this.vehicle.modelName,
                year: this.vehicle.yearID,
                mileage: this.vehicle.estimatedCurrentMileage,
                firstName: this.customer.firstName,
                lastName: this.customer.lastName,
                postalCode: this.customer.address.zipCode,
                salespersonfirstname: salesperson.length > 0 ? salesperson[0] : null,
                salespersonlastname: salesperson.length > 1 ? salesperson[1] : null,
                interiorcolor: this.vehicle.interiorColor,
                exteriorcolor: this.vehicle.exteriorColor
            }

            this.dealSheetService.vAutoCreateAppraisal(request)
                .then(data => {
                    if (data && data.appraisalID) {
                        this.logger.success("vAuto appraisal successfully created with appraisal id: " + data.appraisalID);
                        this.showAppraisalPopup(data);
                    }
                    else {
                        this.logger.error(this.tService.instant("generalErrorMessageKey"));
                    }
                });
        });
    }

    public showAppraisalPopup(data) {
        console.log('Appraisial ID: ' + data.appraisalID + ' vAuto url: ' + data.deepLink);
        window.open(data.deepLink, '_blank');
    }
}
