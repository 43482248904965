
/* injects from baggage-loader */
require('./dealer-make-select.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('dealerMakeSelect', {
        templateUrl: '/app/components/reports/dealer-make-select.component.html',
        controller: DealerMakeSelectController,
        bindings: {
            defaultDealerId: '<',
            defaultMakeId: '<',
            showDealers: '<',
            showMakes: '<',
            onSelect: '&',
            dealers: '<'
        }
});

/* @ngInject */
function DealerMakeSelectController(dealerService, vehicleService, storageService) {
        var $ctrl = this;

        $ctrl.selectedDealerId;
        $ctrl.$onInit = init;
        $ctrl.$onChanges = onChanges;
        $ctrl.updateMakes = updateMakes;
        $ctrl.onDealerMakeSelect = onDealerMakeSelect;
        $ctrl.hasSetDefaultDealerId = false;

        function init() {
            $ctrl.dealers = $ctrl.dealers ? $ctrl.dealers : dealerService.getSelectedDealers();
            if (!$ctrl.selectedDealerId) {
                var selectedDealerId = storageService.getItem('selectedDealerId');
                $ctrl.selectedDealerId = selectedDealerId > 0 ? selectedDealerId : $ctrl.dealers[0].id;
            }
        }

    function onChanges(changes) {

        if (changes.defaultDealerId && typeof changes.defaultDealerId.currentValue == "number") { //&& $ctrl.hasSetDefaultDealerId == false
                $ctrl.hasSetDefaultDealerId = true;
                $ctrl.selectedDealerId = $ctrl.defaultDealerId;
                storageService.setItem("selectedDealerId", $ctrl.selectedDealerId);
                updateMakes($ctrl.defaultMakeId);
            }
        }

        function updateMakes(defaultMakeId) {
            vehicleService.getMakes($ctrl.selectedDealerId != null ? [$ctrl.selectedDealerId] : null, null)
                .then((makes) => {
                    $ctrl.makes = getSimpleMakes(makes);

                    if (defaultMakeId) {
                        $ctrl.selectedMakeId = defaultMakeId;
                    }
                    else {
                        $ctrl.selectedMakeId = $ctrl.makes[0].makeId;
                        onDealerMakeSelect();
                    }
                });
        }

        function getSimpleMakes(makes) {
            if (makes && makes.length > 0) {
                var simpleMakes = makes.map((make) => {
                    return { makeId: make.makeID, makeName: make.makeName };
                });

                if (simpleMakes.length > 0) {
                    simpleMakes.unshift({ makeId: -1, makeName: 'All Makes' });
                }
                return simpleMakes;
            } else {
                return [{ makeId: -1, makeName: 'All Makes' }];
            }
        }

        function onDealerMakeSelect() {
            $ctrl.onSelect({ dealerId: $ctrl.selectedDealerId, makeId: $ctrl.selectedMakeId });
        }
}
