import { Inject, Injectable } from '@angular/core';
import { UserClientService, UserProfileClientService, AnnouncementClientService, EulaClientService, EulaHistoryClientService, CacheManagementClientService } from '../generated/services';
import { UserDto, UserExtDto, TimeZoneDto, EulaDto, UserProfilePatch } from '../generated/models';
import { StorageService } from '../modules/storage/storage.service';
import { AnnouncementDto } from '../generated/models/announcement-dto';
import { WINDOW_REF, WindowWrapper } from './window-ref.service';
import { Settings } from '../constants/app-settings';
import { UserProfile } from '../interfaces/user-profile.interface';
import { DealerService } from './dealer.service';
import { ObjectCache } from '../utilities/object-cache';
import { PandoCommunicationService } from '../services/pando-communication.service';

interface CellPhoneNumberDetails {
    areaCode: string;
    part1: string;
    part2: string;
    wirelessCarrier: string;
}

@Injectable()
export class UserService {
    private userPromise: Promise<any> | null;
    private cachedUserProfile: UserProfile;

    private facebookUserIdCache = new ObjectCache<string>({cacheAgeLimitSeconds: Settings.environmentVariables.defaultInMemoryCacheTimeoutSeconds})

    constructor(
        @Inject(WINDOW_REF) private window: WindowWrapper,
        private announcementClientService: AnnouncementClientService,
        private cacheManagementClientService: CacheManagementClientService,
        private dealerService: DealerService,
        private eulaClientService: EulaClientService,
        private eulaHistoryClientService: EulaHistoryClientService,
        private storageService: StorageService,
        private userClientService: UserClientService,
        private userProfileService: UserProfileClientService,
        private pandoCommunicationService: PandoCommunicationService
    ) {}

    getAnnouncementForUser(): Promise<AnnouncementDto> {
        return this.announcementClientService
            .GetAnnouncementForUserGET()
            .toPromise();
    }

    getCellPhoneNumberDetails(): Promise<CellPhoneNumberDetails> {
        return this.getUserProfile().then(userProfile => {
            return {
                areaCode: userProfile.cellPhoneArea,
                part1: userProfile.cellPhonePart1,
                part2: userProfile.cellPhonePart2,
                wirelessCarrier: userProfile.cellPhoneCarrier
            };
        });
    }

    getCultureNameSync(): string | null {
        return this.storageService.getItem("cultureName");
    }

    getCultures(): Promise<string[]> {
        return this.userProfileService.GetCulturesGET().toPromise();
    }

    getEula(eulaId: number): Promise<EulaDto> {
        return this.eulaClientService.ByIdGET(eulaId).toPromise();
    }

    getEulaStatus(): Promise<number> {
        return this.eulaHistoryClientService.GetStatusGET().toPromise();
    }

    getFacebookUserId(emailAddress: string): Promise<string | null> {
        if (isValidEmailAddress(emailAddress)) {
            return this.facebookUserIdCache.getOrFetch(emailAddress, 
                () => this.userClientService
                    .FacebookUserIdByEmailaddressGET(emailAddress)
                    .toPromise());
        } else {
            return Promise.resolve(null);
        }

        function isValidEmailAddress(email) {
            const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailExpression.test(email);
        }
    }

    getTimeZones(): Promise<TimeZoneDto[]> {
        return this.userProfileService.GetTimeZonesGET().toPromise();
    }

    getUser(username: string): Promise<UserDto> {
        return this.userClientService
            .GetUserByUsernameGET(username)
            .toPromise();
    }

    getUserIsMetric(countryId: number): Promise<any> {
        return this.userClientService
            .GetUserIsMetricByCountryidGET(countryId)
            .toPromise();
    }

    /**
     * Gets the user profile. A cached value may be returned unless forceReload
     * is specified.
     * @param forceReload - Disregard any cached value and retrieve from the server.
     */
    async getUserProfile(forceReload: boolean = false): Promise<UserProfile> {
        if (
            forceReload ||
            // If these values are missing, assume this is the first load after
            // login and invalidate any server-side cache
            !this.storageService.getItem("accessRightIds") ||
            !this.storageService.getItem("moduleIds") ||
            !this.storageService.getItem("userProfile")
        ) {
            this.userPromise = null;
            this.cachedUserProfile = null;
            await this.cacheManagementClientService
                .FlushUserRelatedCacheGET()
                .toPromise();
        }

        if (this.cachedUserProfile) {
            return this.cachedUserProfile;
        }

        if (this.userPromise != null) {
            return this.userPromise;
        }

        this.userPromise = this.getUserProfileImpl();
        this.cachedUserProfile = await this.userPromise;
        return this.cachedUserProfile;
    }

    /**
     *  Gets the user's profile from the server. Never returns a cached value.
     */
    private async getUserProfileImpl(): Promise<UserProfile> {
        const [loggedInUser, userInfo, profile, motofuzeUser, gfConfig] = await Promise.all([
            this.userClientService.LoggedInUserGET().toPromise(),
            this.userClientService.CurrentUserGET().toPromise(),
            this.userProfileService.ExtendedGET().toPromise(),
            this.pandoCommunicationService.getMotofuzeCurrentUser(),
            this.pandoCommunicationService.getGlobalFrameConfig()
        ]);

        setGtmData(
            this.window,
            profile.isAutoAlertEmployee,
            loggedInUser.roleName,
            loggedInUser.dealerCodes
        );

        // Employee users can keep their previously-chosen selected dealer IDs,
        // All other users must be reset back to what LoggedInUser says
        const selectedDealerIds = await this.dealerService.getSelectedDealerIds();
        const cxmViewSelectedDealers = await this.dealerService.getCXMViewSelectedStores();
        const cxmDealers = cxmViewSelectedDealers && cxmViewSelectedDealers.viewSelectedStores ? cxmViewSelectedDealers.viewSelectedStores : [];
        let dealers = [];
        let dealerListsMatch = true;
        let dealerGroupId = loggedInUser.dealerGroupId;
        let dealerDisplayText = loggedInUser.dealerDisplayText;
        let dealerCodes = loggedInUser.dealerCodes;
        let dealerModules = loggedInUser.dealerModules;
        let countryId = loggedInUser.countryId;

        if (profile.isAutoAlertEmployee) {
            if (cxmViewSelectedDealers && cxmDealers) {
                for (let i = 0; i < cxmDealers.length; i++) {
                    let dealer = { id: cxmDealers[i].AutoAlertDealerID, name: cxmDealers[i].Name };
                    dealers.push(dealer);
                    if (selectedDealerIds && dealerListsMatch) {
                        dealerListsMatch = selectedDealerIds.includes(cxmDealers[i].AutoAlertDealerID);
                    }
                    else {
                        dealerListsMatch = false;
                    }
                }
                let dealerFordInfo = await this.dealerService.getDealerFordInfo(dealers[0].id);
                if (dealerFordInfo && dealerFordInfo.length > 0) {
                    countryId = dealerFordInfo[0].countryID;
                }
                if ((cxmViewSelectedDealers.viewDealerGroupID && loggedInUser.dealerGroupId && cxmViewSelectedDealers.viewDealerGroupID != loggedInUser.dealerGroupId) ||
                    (cxmViewSelectedDealers.viewAccount && loggedInUser.dealerDisplayText && cxmViewSelectedDealers.viewAccount != loggedInUser.dealerDisplayText)) {
                    //lists may match but user defaults are not current
                    dealerGroupId = cxmViewSelectedDealers.viewDealerGroupID ? cxmViewSelectedDealers.viewDealerGroupID : loggedInUser.dealerGroupId;
                    if (cxmViewSelectedDealers.viewSelectedStores.length == 1) {
                        dealerDisplayText = cxmViewSelectedDealers.viewSelectedStores[0].Name;
                    }
                    else {
                        dealerDisplayText = cxmViewSelectedDealers.viewAccount;
                    }
                }
                if (dealerListsMatch == false || (selectedDealerIds && cxmDealers.length != selectedDealerIds.length)) {
                    dealerGroupId = cxmViewSelectedDealers.viewDealerGroupID ? cxmViewSelectedDealers.viewDealerGroupID : loggedInUser.dealerGroupId;
                    if (cxmViewSelectedDealers.viewSelectedStores.length == 1) {
                        dealerDisplayText = cxmViewSelectedDealers.viewSelectedStores[0].Name;
                    }
                    else {
                        dealerDisplayText = cxmDealers.length == 1 ? cxmDealers[0].Name : cxmViewSelectedDealers.viewAccount ? cxmViewSelectedDealers.viewAccount : loggedInUser.dealerDisplayText;
                    }
                    this.dealerService.updateSelectedDealersToMatchCXM(dealers, dealerGroupId, dealerDisplayText);
                    let refreshedUser = await this.userClientService.LoggedInUserGET().toPromise();
                    if (refreshedUser) {
                        dealerCodes = refreshedUser.dealerCodes;
                        dealerModules = refreshedUser.dealerModules;
                    }
                }
            }
        }
        else if (cxmDealers && loggedInUser.dealerCodes) {
                for (let i = 0; i < loggedInUser.dealerCodes.length; i++) {
                    let dealer = { id: loggedInUser.dealerCodes[i].dealerID, name: loggedInUser.dealerCodes[i].dealerCode };
                    dealers.push(dealer);
                    if (dealerListsMatch) {
                        dealerListsMatch = cxmDealers.some(dealer => dealer.AutoAlertDealerID == loggedInUser.dealerCodes[i].dealerID);
                    }
                }
                if (cxmDealers.length != loggedInUser.dealerCodes.length || dealerListsMatch == false) {
                    this.dealerService.updateCXMSelectedDealersToMatchAlertMiner(dealers);
                }
        }

        const dealerIds = profile.isAutoAlertEmployee
            ? this.dealerService.getSelectedDealerIds() ||
              loggedInUser.dealerIds
            : loggedInUser.dealerIds;

        this.storageService.setItem("cultureName", profile.cultureName);
        this.storageService.setItem("timezoneId", profile.timeZoneID);

        const accountPlatformIds = [];
        if (dealerIds && dealerIds.length > 0
            && motofuzeUser && motofuzeUser.ViewAllowedStores && motofuzeUser.ViewAllowedStores.length > 0) {
            dealerIds.forEach((id) => {
                const store = motofuzeUser.ViewAllowedStores.find((x) => x.AutoAlertDealerID == id);
                if (store) {
                    accountPlatformIds.push(store.AccountPlatformID);
                }
            });
        }

        const userProfile: UserProfile = {
            userTypeId: userInfo.userTypeID,
            firstName: userInfo.firstName,
            middleName: userInfo.middleName,
            lastName: userInfo.lastName,
            userFullName: loggedInUser.name,
            dealerGroupId: dealerGroupId,
            dealerDisplayText: dealerDisplayText,
            dealerIds: dealerIds ? dealerIds : loggedInUser.dealerIds,
            roleId: loggedInUser.roleId,
            employeeUser: profile.isAutoAlertEmployee,
            cultureName: profile.cultureName,
            countryId: countryId,
            userId: profile.userID,
            roleName: loggedInUser.roleName,
            dealerCodes: dealerCodes,
            eulaAcceptedDate: profile.eulaAcceptDate,
            email: profile.email,
            phone: profile.phone,
            position: profile.position,
            timeZoneId: loggedInUser.timeZoneId
                ? loggedInUser.timeZoneId
                : profile.timeZoneID,
            lastPasswordResetDate: profile.lastPwdResetDate,
            isSltUser: loggedInUser.isSltUser,
            isLiteUser: loggedInUser.isLiteUser,
            cellPhoneArea: profile.cellPhoneArea,
            cellPhonePart1: profile.cellPhonePart1,
            cellPhonePart2: profile.cellPhonePart2,
            cellPhoneCarrier: profile.cellPhoneCarrier,

            //Per architect, we are keeping the logic to get dealerModules separate from other modules to replicate legacy logic
            dealerModules: dealerModules,
            permissions: motofuzeUser.Permissions,
            accountPlatformIds: accountPlatformIds,
            walkmeSegments: (gfConfig && gfConfig.WalkMeSegments) ? gfConfig.WalkMeSegments : null,
        };

        this.storageService.setItem("userProfile", userProfile);

        return userProfile;

        function setGtmData(window, employeeUser, role, dealerCodes) {
            if (!window.dataLayer) {
                var dealerCode;

                if (dealerCodes && dealerCodes.length > 0) {
                    dealerCode = dealerCodes[0].dealerCode;
                }

                window.dataLayer = [
                    {
                        product: "Opportunities",
                        isAutoAlertEmployee: employeeUser ? "Yes" : "No",
                        dealerCode: dealerCode,
                        role: role
                    }
                ];

                (function(w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({
                        "gtm.start": new Date().getTime(),
                        event: "gtm.js"
                    });
                    var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),
                        dl = l != "dataLayer" ? "&l=" + l : "";
                    (j as any).async = true;
                    (j as any).src =
                        "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                    f.parentNode.insertBefore(j, f);
                })(
                    window,
                    window.document,
                    "script",
                    "dataLayer",
                    Settings.tagManager.containerId
                );
            }
        }
    }

    async getUsers(dealerId: number): Promise<{ id: number; fullName: string }[]> {
        const enabledUsers = await this.dealerService.getEnabledUsersForDealer(dealerId);
        return enabledUsers.map(u => ({
            id: u.userID,
            fullName: u.fullName
        }));
    }

    //async getUsersExt(dealerId: number): Promise<{ id: number; fullName: string }[]> {
    //    const enabledUsers = await this.dealerService.getEnabledUsersForDealer(dealerId);
    //    return enabledUsers.map(u => ({
    //        id: u.userID,
    //        fullName: u.fullName
    //    }));
    //}

    getUsersExt(dealerId: number): Promise<UserExtDto[]> {
        return this.userClientService
            .GetEnabledClientUsersExtByDealerByDealeridGET(dealerId)
            .toPromise();
    }

    async isAutoAlertEmployee(): Promise<boolean> {
        const userProfile = await this.getUserProfile();
        return userProfile.employeeUser;
    }

    isDealerEnabledForModule(dealerId: number, moduleId: number): boolean {
        // This really should be calling this.getUserProfile(), but the previous
        // non-TypeScript implementation only ever checked the cached value, so
        // all the usages of this are expecting a boolean return.
        const cachedUserProfile = this.cachedUserProfile;
        if (!cachedUserProfile) {
            return false;
        }

        const dealerModules = cachedUserProfile.dealerModules;

        return dealerModules.some(mod =>
            mod.moduleID === moduleId &&
            (mod.dealerID === dealerId || mod.dealerID === 0)
        );
    }

    isModuleEnabledForAnyDealer(moduleId: number): boolean {
        const dealerIds = this.dealerService.getSelectedDealerIds();
        return dealerIds.some(dealerId =>
            this.isDealerEnabledForModule(dealerId, moduleId));
    }

    logUserLogin(): Promise<void> {
        return this.userClientService.LogUserLoginPOST().toPromise();
    }

    postResponse(eulaId: number, eulaResponseTypeId: 1 | 2 | 3): Promise<void> {
        const eulaResponse = { eulaId, eulaResponseTypeId };
        return this.eulaHistoryClientService
            .PostResponseByEulaidByEularesponsetypeidPOST(eulaResponse)
            .toPromise();
    }

    updateAnnouncementUserDisplay(
        announcementId: number,
        doNotDisplay
    ): Promise<void> {
        const dto = { announcementId, doNotDisplay };
        return this.announcementClientService
            .UpdateAnnouncementUserDisplayByAnnouncementidPUT(dto)
            .toPromise();
    }

    updateUserProfile(userProfile: UserProfilePatch): Promise<void> {
        return this.userProfileService.UpdatePATCH(userProfile).toPromise();
    }
}
