/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SearchableModelNameDto } from '../models/searchable-model-name-dto';
import { MakeDto } from '../models/make-dto';
@Injectable({
  providedIn: 'root',
})
class MakeClientService extends __BaseService {
  static readonly GetMakesGETPath = '/api/Make/GetMakes';
  static readonly GetSearchableModelNamesGETPath = '/api/Make/GetSearchableModelNames';
  static readonly ByIdGETPath = '/api/Make/{id}';
  static readonly ByIdDELETEPath = '/api/Make/{id}';
  static readonly GetManyGETPath = '/api/Make/GetMany';
  static readonly PUTPath = '/api/Make';
  static readonly POSTPath = '/api/Make';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param dealerIds undefined
   */
  GetMakesGETResponse(dealerIds?: Array<number>, countryId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (dealerIds || []).forEach(val => {if (val != null) __params = __params.append('dealerIds', val.toString())});
    if (countryId != null) __params = __params.set('countryId', countryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Make/GetMakes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param dealerIds undefined
   */
  GetMakesGET(dealerIds?: Array<number>, countryId?: number): __Observable<null> {
    return this.GetMakesGETResponse(dealerIds, countryId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param countryId undefined
   * @return Success
   */
  GetSearchableModelNamesGETResponse(countryId?: number): __Observable<__StrictHttpResponse<Array<SearchableModelNameDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (countryId != null) __params = __params.set('countryId', countryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Make/GetSearchableModelNames`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SearchableModelNameDto>>;
      })
    );
  }
  /**
   * @param countryId undefined
   * @return Success
   */
  GetSearchableModelNamesGET(countryId?: number): __Observable<Array<SearchableModelNameDto>> {
    return this.GetSearchableModelNamesGETResponse(countryId).pipe(
      __map(_r => _r.body as Array<SearchableModelNameDto>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<MakeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Make/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MakeDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<MakeDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as MakeDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Make/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<MakeDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Make/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MakeDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<MakeDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<MakeDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: MakeDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Make`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: MakeDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: MakeDto): __Observable<__StrictHttpResponse<MakeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Make`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MakeDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: MakeDto): __Observable<MakeDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as MakeDto)
    );
  }
}

module MakeClientService {
}

export { MakeClientService }
