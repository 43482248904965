
/* injects from baggage-loader */
require('./edit-wish-list-modal.component.html');

import angular from 'angular';
import './edit-wish-list-modal.component.scss';
//import $ from 'jquery';

angular.module('AaApp.Opp').component('editWishListModal', {
    templateUrl: '/app/components/wish-list/edit-wish-list-modal.component.html',
    controller: editWishListModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function editWishListModalController(_, $q, $timeout, $filter, vehicleService) {
    var $ctrl = this;
    $ctrl.$onInit = init;
    $ctrl.$onChanges = onChanges;

    //$ctrl.resolve is sent by the caller of this directive, and can be any JS object.
    //  I'm using it to enable DI and pass input parameters.
    $ctrl.row = $ctrl.resolve.message.row;
    $ctrl.selectedDealerId = $ctrl.resolve.message.selectedDealerId;
    $ctrl.translations = $ctrl.resolve.message.translations;
    $ctrl.wishListCriteria;
    $ctrl.save = $ctrl.resolve.message.save;
    $ctrl.closeWrapper = closeWrapper;

    $ctrl.selectedMake;
    $ctrl.selectedModel;
    $ctrl.selectedYearMin;
    $ctrl.selectedYearMax;
    $ctrl.selectedTrims = [];
    $ctrl.selectedOdometerMin;
    $ctrl.selectedOdometerMax;
    $ctrl.selectedUsers = [];
    $ctrl.selectedExpirationDate;
    $ctrl.makes;
    $ctrl.models;
    $ctrl.yearIDs;
    $ctrl.updateModels = updateModels;
    $ctrl.updateTrims = updateTrims;
    $ctrl.showBlankOptions = false;
    $ctrl.showBlankModelTrimOptions = false;
    $ctrl.defaultExpirationDate;
    $ctrl.datePickerOptions = {
        startingDay: 0,
        showWeeks: false,
        minDate: null,
        maxDate: null
    };
    $ctrl.users = [];
    $ctrl.isActive = true;
    $ctrl.trims = [];
    $ctrl.trimList = [];
    $ctrl.showAllMakes = true;
    $ctrl.wishListCriteria;
    $ctrl.isEditMode = false;
    $ctrl.wishListEditForm;
    $ctrl.selectedTrimCount;
    $ctrl.validateTrimSelections = validateTrimSelections;
    $ctrl.validateUserSelections = validateUserSelections;
    $ctrl.validateExpirationDate = validateExpirationDate;
    $ctrl.validateYearSelections = validateYearSelections;
    $ctrl.validateOdometerSelections = validateOdometerSelections;
    $ctrl.yearChanged = yearChanged;
    $ctrl.distributionListTouched = false;
    $ctrl.expirationDateTouched = false;

    // We're wrapping this in a Angular $timeout to insure the validation is performed in the next
    // event loop no matter what.  There were times when the model values and input values weren't
    // synchronized causing inconsistent validation results.
    $ctrl.checkValidation = function () {
        $timeout(function () {
            $ctrl.resolve.submitButtonDisabled = !($ctrl.wishListEditForm.$valid && $ctrl.wishListEditForm.$dirty);
        }, 20);
    }

    function init() {
        $ctrl.users = $ctrl.resolve.message.users.map(u => ({
            value: u.userID,
            nameText: u.fullName
        }));
    }
    
    function onChanges(changes) {
        // set default to 6 months from current date:
        $ctrl.selectedExpirationDate = $ctrl.defaultExpirationDate = new Date(new Date().setMonth(new Date().getMonth() + 6));

        $q.all([
            vehicleService.getMakes($ctrl.showAllMakes ? null : [$ctrl.selectedDealerId], null),
            vehicleService.getYearIDs($ctrl.selectedDealerId, false, $ctrl.yearLimit),
        ]).then((data) => {
            $ctrl.makes = data[0].sort(byMakeName);
            $ctrl.yearIDs = data[1].sort(descending);

            if ($ctrl.yearLimit && !($ctrl.yearLimit < 255)) {
                $ctrl.yearIDs.splice($ctrl.yearLimit, $ctrl.yearIDs.length - $ctrl.yearLimit);
            }

            if ($ctrl.row) {  // data coming from EXISTING ROW:
                $ctrl.isEditMode = true;
                $ctrl.resolve.submitButtonDisabled = false;
                $ctrl.wishListEditForm.$setDirty();

                var row = $ctrl.row;

                $ctrl.wishListCriteria = {
                    dealerWishListID: row.dealerWishListID,
                    makeID: row.searchObject.makeID,
                    makeName: row.searchObject.makeName,
                    yearMin: row.searchObject.yearMin,
                    yearMax: row.searchObject.yearMax,
                    modelName: row.searchObject.modelName,
                    modelIdList: row.searchObject.modelIdList,
                    trimList: row.searchObject.trimList,
                    odometerMin: row.searchObject.odometerMin,
                    odometerMax: row.searchObject.odometerMax,
                    distributionList: row.distributionList,
                    active: row.active
                };
                
                $ctrl.selectedExpirationDate = $ctrl.defaultExpirationDate = row.expirationDateUtc;
            }
            else {  // adding a NEW ROW:
                $ctrl.isEditMode = false;
                $ctrl.wishListEditForm.$setValidity('userReq', false);

                // Set defaults for adding a new wishlist record:
                $ctrl.wishListCriteria = {
                    dealerWishListID: -1,
                    makeID: null,
                    makeName: null,
                    yearMin: null,
                    yearMax: null,
                    modelName: null,
                    modelIdList: [],
                    trimList: [],
                    odometerMin: null,
                    odometerMax: null,
                    distributionList: [],
                    active: true
                };
            }

            if ($ctrl.selectedDealerId && $ctrl.wishListCriteria) {

                if ($ctrl.isEditMode) {
                    $ctrl.selectedMake = { makeID: $ctrl.wishListCriteria.makeID, makeName: $ctrl.wishListCriteria.makeName };
                    $ctrl.selectedModel = { modelName: $ctrl.wishListCriteria.modelName, modelIds: $ctrl.wishListCriteria.modelIdList };
                }
                $ctrl.distributionListTouched = false;
                $ctrl.expirationDateTouched = false;

                if ($ctrl.wishListCriteria.yearMin) {
                    $ctrl.selectedYearMin = $ctrl.wishListCriteria.yearMin;
                }
                else {
                    $ctrl.selectedYearMin = $ctrl.yearIDs[0];
                }
                if ($ctrl.wishListCriteria.yearMax) {
                    $ctrl.selectedYearMax = $ctrl.wishListCriteria.yearMax;
                }
                else {
                    $ctrl.selectedYearMax = $ctrl.yearIDs[0];
                }
                var years = _.range($ctrl.selectedYearMin, $ctrl.selectedYearMax + 1);


                if ($ctrl.wishListCriteria.odometerMin) {
                    $ctrl.selectedOdometerMin = $ctrl.wishListCriteria.odometerMin;
                }
                if ($ctrl.wishListCriteria.odometerMax) {
                    $ctrl.selectedOdometerMax = $ctrl.wishListCriteria.odometerMax;
                }

                if ($ctrl.wishListCriteria.distributionList && $ctrl.wishListCriteria.distributionList.length > 0) {
                    $ctrl.selectedUsers = [];
                    for (let user of $ctrl.users) {
                        for (let distUserId of $ctrl.wishListCriteria.distributionList) {
                            if (user.value === distUserId) {
                                $ctrl.selectedUsers.push({
                                    value: user.value,
                                    nameText: user.nameText
                                });
                            }
                        }
                    }
                }

                $ctrl.isActive = $ctrl.wishListCriteria.active;

                if ($ctrl.wishListCriteria.makeID) {
                    $q.all([
                        vehicleService.getModelsByMakesYears([$ctrl.wishListCriteria.makeID], years),
                    ]).then((data) => {
                        var models = data[0];
                        var uniqueModelsByName = $filter('unique')(models, 'modelName');

                        $ctrl.models = uniqueModelsByName.map(m => ({
                            modelName: m.modelName,
                            modelIds: getModelIds(models, m.modelName)
                        })).sort(byModelName);

                        if ($ctrl.wishListCriteria.dealerWishListID) {
                            updateTrims();
                        }

                        //Set blank for all
                        if ($ctrl.showBlankOptions) {
                            $ctrl.makes.unshift({
                                makeID: "",
                                makeName: ""
                            });
                            $ctrl.models.unshift({
                                modelIds: [],
                                modelName: ""
                            });
                        }

                        //Set blank for Model Trim only
                        if ($ctrl.showBlankModelTrimOptions) {
                            $ctrl.models.unshift({
                                modelIds: [],
                                modelName: ""
                            });
                            if (!$ctrl.selectedModel.modelName)
                                $ctrl.selectedModel = $ctrl.models[0];
                        }
                    });
                }
            }
        });
    }

    function validateExpirationDate(e) {
        $ctrl.selectedExpirationDate = e;
        $ctrl.expirationDateTouched = true;
        var currentDate = new Date();
        if ($ctrl.selectedExpirationDate) {
            $ctrl.wishListEditForm.$setValidity('expirationDateReq', true);
        }
        else {
            $ctrl.wishListEditForm.$setValidity('expirationDateReq', false);
        }

        if (!$ctrl.isEditMode && $ctrl.selectedExpirationDate && $ctrl.selectedExpirationDate <= currentDate) {
            $ctrl.wishListEditForm.$setValidity('expirationDatePast', false);
        }
        else {
            $ctrl.wishListEditForm.$setValidity('expirationDatePast', true);
        }

        $ctrl.checkValidation();
    }

    function validateTrimSelections() {
        if ($ctrl.selectedTrims && $ctrl.selectedTrims.length > 3) {
            $ctrl.wishListEditForm.$setValidity('trimCount', false);
        }
        else {
            $ctrl.wishListEditForm.$setValidity('trimCount', true);
        }

        $ctrl.checkValidation();
    }

    function validateOdometerSelections() {
        if (parseInt($ctrl.selectedOdometerMin) >= parseInt($ctrl.selectedOdometerMax)) {
            $ctrl.wishListEditForm.$setValidity('odometerRange', false);
        }
        else {
            $ctrl.wishListEditForm.$setValidity('odometerRange', true);
        }

        $ctrl.checkValidation();
    }

    function validateYearSelections() {
        if (parseInt($ctrl.selectedYearMin) > parseInt($ctrl.selectedYearMax)) {
            $ctrl.wishListEditForm.$setValidity('yearRange', false);
        }
        else {
            $ctrl.wishListEditForm.$setValidity('yearRange', true);
        }

        $ctrl.checkValidation();
    }

    function validateUserSelections() {
        $ctrl.distributionListTouched = true;
 
        if ($ctrl.selectedUsers && $ctrl.selectedUsers.length > 0) {
            $ctrl.wishListEditForm.$setValidity('userReq', true);
        }
        else {
            $ctrl.wishListEditForm.$setValidity('userReq', false);
        }

        if ($ctrl.selectedUsers && $ctrl.selectedUsers.length > 20) {
            $ctrl.wishListEditForm.$setValidity('userMaxExceeded', false);
        }
        else {
            $ctrl.wishListEditForm.$setValidity('userMaxExceeded', true);
        }

        $ctrl.checkValidation();
    }

    function yearChanged() {
        validateYearSelections();
        updateModels();
    }

    function descending(number1, number2) {
        return number2 - number1;
    }

    function byMakeName(object1, object2) {
        if (object1.makeName < object2.makeName)
            return -1;
        if (object1.makeName > object2.makeName)
            return 1;
        return 0;
    }

    function byModelName(object1, object2) {
        if (object1.modelName < object2.modelName)
            return -1;
        if (object1.modelName > object2.modelName)
            return 1;
        return 0;
    }

    function byTrimName(object1, object2) {
        if (object1.trimName < object2.trimName)
            return -1;
        if (object1.trimName > object2.trimName)
            return 1;
        return 0;
    }

    function updateModels() {
        // first clear out any selected models and trims
        $ctrl.models = [];
        $ctrl.selectedModel = null;
        $ctrl.trims = [];
        $ctrl.trimList = [];
        $ctrl.selectedTrims = [];

        $ctrl.checkValidation();

        if (!$ctrl.selectedMake)
            return;
        if ($ctrl.selectedMake.makeID && $ctrl.selectedYearMin && $ctrl.selectedYearMax) {
            var years = _.range($ctrl.selectedYearMin, $ctrl.selectedYearMax + 1);
            vehicleService.getModelsByMakesYears([$ctrl.selectedMake.makeID], years).then(setModels);
        }
        else {
            setModels(null);
        }
    }

    function updateTrims() {
        if ($ctrl.selectedModel && $ctrl.selectedModel.modelName) {
            vehicleService.getTrims($ctrl.selectedModel.modelIds).then(setTrims);
        }
        else {
            setTrims(null);
        }
        $ctrl.checkValidation();
    }

    function setModels(models) {
        if (models && models.length > 0) {

            var uniqueModelsByName = $filter('unique')(models, 'modelName');

            $ctrl.models = uniqueModelsByName.map(m => ({
                modelName: m.modelName,
                modelIds: getModelIds(models, m.modelName)
            })).sort(byModelName);

            if ($ctrl.showBlankOptions || $ctrl.showBlankModelTrimOptions) {
                $ctrl.models.unshift({
                    modelIds: [],
                    modelName: ""
                });
            }
        }
        else {
            $ctrl.models = [{
                modelIds: [],
                modelName: ""
            }];
        }

        $ctrl.checkValidation();
    }

    function setTrims(trims) {
        $ctrl.trims = [];
        $ctrl.trimList = [];
        $ctrl.selectedTrims = [];

        if (trims && trims.length > 0) {

            var uniqueTrimByName = $filter('unique')(trims, 'trimName');

            $ctrl.trims = uniqueTrimByName.map(t => ({
                trimName: t.trimName,
                trimIds: getTrimIds(trims, t.trimName)
            })).sort(byTrimName);

        }
 
        $ctrl.trimList = $ctrl.trims.map(t => ({
            value: t.trimIds.sort().join(','),
            nameText: t.trimName,
        }));

        if ($ctrl.wishListCriteria.trimList && $ctrl.wishListCriteria.trimList.length > 0) {

            $ctrl.selectedTrims = $ctrl.wishListCriteria.trimList.map(t => ({
                value: t.trimIdList.sort().join(','),
                nameText: t.trimName,
            }));
        }

        $ctrl.checkValidation();
    }

    function getModelIds(models, modelName) {
        var ids = [];

        if (modelName) {
            models = models.filter(filterByModelName(modelName));

            for (var i = 0; i < models.length; i++) {
                ids.push(models[i].modelID);
            }
        }

        return ids;
    }

    function getTrimIds(trims, trimName) {
        var ids = [];

        if (trimName) {
            trims = trims.filter(filterByTrimName(trimName));

            for (var i = 0; i < trims.length; i++) {
                ids.push(trims[i].trimID);
            }
        }

        return ids;
    }

    function filterByModelName(modelName) {
        return function (model) {
            return model.modelName == modelName;
        };
    }

    function filterByTrimName(trimName) {
        return function (trim) {
            return trim.trimName == trimName;
        };
    }

    function closeWrapper() {
        validateTrimSelections();
        validateUserSelections();
        $ctrl.checkValidation();
        if (!$ctrl.wishListEditForm.$valid)
            return;

        var rowState = {
            createUserFullName: null,
            modifyUserFullName: null,
            dealerWishListID: $ctrl.wishListCriteria.dealerWishListID,
            dealerID: $ctrl.selectedDealerId,
            expirationDateUtc: $ctrl.selectedExpirationDate,
            active: $ctrl.isActive,
            searchObject: {
                makeID: $ctrl.selectedMake.makeID,
                makeName: $ctrl.selectedMake.makeName,
                modelIdList: $ctrl.selectedModel.modelIds,
                modelName: $ctrl.selectedModel.modelName,
                yearMin: $ctrl.selectedYearMin,
                yearMax: $ctrl.selectedYearMax,
                odometerMin: $ctrl.selectedOdometerMin,
                odometerMax: $ctrl.selectedOdometerMax,
                trimList: $ctrl.selectedTrims.map(t => ({
                    trimName: t.nameText,
                    trimIdList: t.value.split(',')
                }))
            },
            distributionList: $ctrl.selectedUsers.map(u => u.value),
            createUserID: -1,
            createDateUtc: new Date(),
            modifyUserID: -1,
            modifyDateUtc: new Date()
        }

        $ctrl.save(rowState);

        ////The CLOSE() event should be called asyncronously, after the SAVE feature is successful.
        return $ctrl.close();
    }

    if ($ctrl.resolve.closeButtonVisible === undefined)
        $ctrl.resolve.closeButtonVisible = true;

    if ($ctrl.resolve.closeButtonText === undefined)
        $ctrl.resolve.closeButtonText = "cancel";

    if ($ctrl.resolve.submitButtonVisible === undefined)
        $ctrl.resolve.submitButtonVisible = true;

    if ($ctrl.resolve.submitButtonDisabled === undefined)
        $ctrl.resolve.submitButtonDisabled = false;

    if ($ctrl.resolve.submitButtonText === undefined)
        $ctrl.resolve.submitButtonText = "yes";
}

