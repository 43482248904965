import { Inject, Injectable } from '@angular/core';

import { VehicleService, ContractService, DealSheetService, MapUtilsService } from '../ajs-upgraded-providers';
import { LocaleCurrencyPipe } from '../filters/locale-currency.pipe';
import { DealerService } from './dealer.service';
import { CustomerService } from './customer.service';
import { UserService } from './user.service';
import { EntityClientService } from '../generated/services/entity-client.service';
import { UserClientService } from '../generated/services/user-client.service';
import { ActivityClientService } from '../generated/services/activity-client.service';


import { EntityOpportunityClientService } from '../generated/services/entity-opportunity-client.service';
import { TagClientService } from '../generated/services/tag-client.service';
import { CreditSoftPullResultEntityDelClientService } from '../generated/services/credit-soft-pull-result-entity-del-client.service';
import { FordCreditApprovalClientService } from '../generated/services/ford-credit-approval-client.service';
import { VehicleFinalTradeValueTempChangeClientService } from '../generated/services/vehicle-final-trade-value-temp-change-client.service';
import { DiscrepancyReportClientService } from '../generated/services/discrepancy-report-client.service';
import { ChangeLogClientService } from '../generated/services/change-log-client.service';
import { PandoClaimClientService } from '../generated/services/pando-claim-client.service';
import { SaleClientService } from '../generated/services/sale-client.service';
import { HttpInterceptorOptionsService } from './http/http-interceptor-options.service';
import { SearchClientService } from '../generated/services/search-client.service';

import {
    Modules
    , AlertTypes
    , VehicleTypes as vehicleTypes
    , DiscrepancyTypes as discrepancyTypes
    , ConquestTypes as conquestTypes
    , OpportunityTypes as opportunityTypes
    , OpportunityStatuses as opportunityStatuses
} from '../constants';

import { LogTradeValueChangeRequest } from '../generated/models';
import { EntityExternalDto } from '../generated/models/entity-external-dto';
import { DealSheetOpportunity } from '../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { LogFactory, NamedLog } from './log-factory.service';

@Injectable()
export class Opportunity2Service {

    private activityNotification = { triggered: false, notify: false };
    private log: NamedLog;

    constructor(
        @Inject(VehicleService) private vehicleService
        , @Inject(ContractService) private contractService
        , @Inject(DealSheetService) private dealSheetService
        , @Inject(MapUtilsService) private mapUtilsService
        , private entityClient: EntityClientService
        , private dealerService: DealerService
        , private customerService: CustomerService
        , private userService: UserService
        , private userClient: UserClientService
        , private activityClientService: ActivityClientService
        , private entityOpportunityClientService: EntityOpportunityClientService
        , private creditSoftPullResultEntityDelClientService: CreditSoftPullResultEntityDelClientService
        , private fordCreditApprovalClientService: FordCreditApprovalClientService
        , private vehicleFinalTradeValueTempChangeClientService: VehicleFinalTradeValueTempChangeClientService
        , private discrepancyReportClientService: DiscrepancyReportClientService
        , private changeLogClientService: ChangeLogClientService
        , private pandoClaimClientService: PandoClaimClientService
        , private saleClientService: SaleClientService
        , private tagClientService: TagClientService
        , private localeCurrency: LocaleCurrencyPipe
        , private httpInterceptorOptionsService: HttpInterceptorOptionsService
        , private searchClientService: SearchClientService
        , logFactory: LogFactory

    ) {
        this.log = logFactory.get();
    }

    // no reference in entire app so commented out.
    // public getAllSaleTypeLocalization() {
    //    //return this.coreApiService.api.Sale.GetAllSaleTypeLocalization.GET()
    //    return this.saleClientService.GetSaleExtByEntityIdByEntityidGET().toPromise<any>()
    //        .then(function (response) {
    //            return response ? response : [];
    //        });
    // }

    public getSaleExtByEntityId(entityId): any {
        // return coreApi.api.Sale.GetSaleExtByEntityId['{entityId}'].GET({ entityId: entityId })
        return this.saleClientService.GetSaleExtByEntityIdByEntityidGET(entityId).toPromise<any>()
            .then(function (response) {
                return response;
            });
    }

    public getVehicleTurnPredictor(entityId): any {
        return this.entityClient.GetVehicleTurnPredictorByEntityidGET(entityId).toPromise<any>()
            .then(function (response) {
                return response;
            });
    }

    public getActiveNLPNoLongerOwn(entityId): any {
        return this.entityClient.GetActiveNLPNoLongerOwnByEntityidGET(entityId).toPromise<any>()
            .then(function (response) {
                //response.hasData = true;
                //response.data = [
                //    {
                //        communicationSourceID: 1,
                //        communicationText: 'no longer owns the car',
                //        insertDate: '07-04-2021 09:45',
                //    },
                //    {
                //        communicationSourceID: 2,
                //        communicationText: 'she told me she is not in the market, does not own the lexus anymore, and to stop calling',
                //        insertDate: '2021-06-08 12:36:02.363',
                //    }
                //];
                return response;
            });
    }

    public updateActiveNLPNoLongerOwn(opportunityId, agreed) {
        return this.entityClient.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETResponse({ 'entityId': opportunityId, 'agreed': agreed }).toPromise<any>();
    }

    public getOpportunity(opportunityId, stockNo, calculationType, dsPermissions: DealSheetPermissions, moduleIds): Promise<DealSheetOpportunity> {
        // return this.coreApiService.api.Entity.GetExt['{entityId}'].GET({ entityId: opportunityId });
        return this.entityClient.GetExtByEntityidGET(opportunityId).toPromise<EntityExternalDto>()
            .then((entityResponse) => {
                return this.dealerService.getDealerSettings(entityResponse.dealerID)
                    .then((dealerSettings) => {
                        if (dealerSettings && dealerSettings.allowExpiration && this.isExpired(entityResponse.expirationDate)) {
                            throw new Error('Deal sheet has expired');
                        }

                        let status;
                        if (entityResponse.scheduled) {
                            status = opportunityStatuses.scheduled;
                        } else if (entityResponse.isMarkedAsSold) {
                            status = opportunityStatuses.sold;
                        } else if (entityResponse.closed) {
                            status = opportunityStatuses.closed;
                        } else {
                            status = opportunityStatuses.open;
                        }

                        const opportunity = {
                            id: entityResponse.entityID,
                            actionAccess: entityResponse.actionAccess,
                            keyId: entityResponse.entityKeyID,
                            dealerId: entityResponse.dealerID,
                            vehicleId: entityResponse.vehicleID,
                            customerId: entityResponse.customerID,
                            type: entityResponse.entityTypeID,
                            hasExtendedWarranty: entityResponse.hasWarranty,
                            status: status,
                            countryID: entityResponse.countryID,
                            stillOwned: (entityResponse.stillOwn == null) ? true : entityResponse.stillOwn,
                            paidOff: entityResponse.paidOff,
                            watched: entityResponse.watched,
                            assignedUserId: entityResponse.ownerUserID,
                            isMarkedAsSold: entityResponse.isMarkedAsSold,
                            expirationDate: entityResponse.expirationDate,
                            entityScore: entityResponse.entityScore,
                            autoAssignAccessUserName: entityResponse.autoAssignAccessUserName,
                            autoAssignOwner: entityResponse.autoAssignOwner,
                            autoAssignAccessUser: entityResponse.autoAssignAccessUser,
                            autoAssignAccessSearch: entityResponse.autoAssignAccessSearch,
                            autoAssignAccessDate: entityResponse.autoAssignAccessDate,
                            autoAssignOwnerDate: entityResponse.autoAssignOwnerDate,
                            autoAssignOwnerSearch: entityResponse.autoAssignOwnerSearch,
                            dealerSettings: dealerSettings,
                            actionChecked: entityResponse.actionChecked,
                            onOrder: entityResponse.onOrder == null ? false : entityResponse.onOrder
                        } as DealSheetOpportunity;

                        if (this.activityNotification.triggered === false) {
                            this.activityNotification.triggered = true;
                            // this.coreApiService.api.Activity.GetNewActivityNotifications['{entityId}'].GET({ entityId: opportunity.id })
                            this.activityClientService.GetNewActivityNotificationsByEntityidGET(opportunity.id).toPromise<any>()
                                .then((notificationsResponse) => {
                                    if (notificationsResponse && this.activityNotification) {
                                        this.activityNotification.notify = notificationsResponse.notification;
                                    }
                                });
                        }

                        this.getNonEssentialOpportunityData(opportunity, dsPermissions, moduleIds);

                        const customerServicePromise = dsPermissions && dsPermissions.viewCustomer ?
                            this.customerService.getCustomer(opportunity.id) : this.customerService.getCustomerName(opportunity.id);

                        return customerServicePromise
                            .then((customer) => {
                                return Promise.all([
                                    this.vehicleService.getCurrentVehicle(opportunity.vehicleId), // results[0]
                                    this.contractService.getCurrentContract(opportunity.id, opportunity.type), // results[1]
                                    this.vehicleService.getReplacementVehicles(opportunity.id, stockNo, customer.pricingPlanId), // results[2]
                                    this.getTags(opportunity.id),
                                    // this.coreApiService.api.User.FullNameByEntity['{entityId}'].GET({ entityId: opportunity.id }),
                                    this.userClient.GetUserFullNameByEntityByEntityidGET(opportunity.id).toPromise() // results[3]
                                ])
                                    .then((results) => {
                                        const data = {
                                            customer: customer
                                            , currentVehicle: results[0]
                                            , currentContract: results[1]
                                            , replacementVehicles: results[2]
                                            , tags: results[3]
                                            , fullName: results[4]

                                        };
                                        return this.resolveOpportunityDetails(data, opportunity, dsPermissions);
                                    });
                            });
                    });
            });

    }

    private getNonEssentialOpportunityData(opportunity, dsPermissions, moduleIds) {
        // WEBUI-7833 Non-essential api calls are made here (moved out of $q.all):

        // this.coreApiService.api.User.GetEntityUserInfo['{entityId}'].GET({ entityId: opportunity.id })
        try {
            this.userClient.GetEntityUserInfoByEntityidGET(opportunity.id).toPromise()
                .then(function (data) {
                    opportunity.ownerUserInfo = data;
                })
                .catch(err => {
                    this.log.warn(err);
                });
        } catch (err) {
            this.log.warn(err);
        }

        try {
            this.getCreditSoftPullResultAsync(dsPermissions, opportunity.id)
                .then((data) => { opportunity.creditSoftPull = data; })
                .catch(err => {
                    this.log.warn(err);
                });
        } catch (err) {
            this.log.warn(err);
        }

        try {
            this.getCreditPrescreenStatus(opportunity.id, moduleIds)
                .then((data) => { opportunity.creditPrescreenStatus = data; })
                .catch(err => {
                    this.log.warn(err);
                });
        } catch (err) {
            this.log.warn(err);
        }

        try {
            const isFordAlertEnabled = this.userService.isDealerEnabledForModule(opportunity.dealerId, Modules.fordAlert);
            if (isFordAlertEnabled) {
                this.getCreditPreApprovalAsync(opportunity.id)
                    .then((data) => { opportunity.creditPreApproval = data; })
                    .catch(err => {
                        this.log.warn(err);
                    });
            } else {
                opportunity.creditPreApproval = null;
            }
        } catch (err) {
            this.log.warn(err);
        }

        try {
            this.getPandoClaim(opportunity.id)
                .then((data) => {
                    opportunity.pandoClaim = data;
                })
                .catch(err => {
                    this.log.warn(err);
                });
        } catch (err) {
            this.log.warn(err);
        }
    }

    private resolveOpportunityDetails(data: any, opportunity: DealSheetOpportunity, permissions: DealSheetPermissions): Promise<DealSheetOpportunity> {

        opportunity.currentContract = data.currentContract;
        opportunity.currentVehicle = data.currentVehicle;
        opportunity.currentVehicle.type = opportunity.currentContract.vehicleType;
        opportunity.currentContract.hasExtendedWarranty = opportunity.hasExtendedWarranty;
        opportunity.currentContract.paidOff = opportunity.paidOff;
        if (opportunity.currentContract.paidOff) {
            opportunity.currentContract.paymentsRemaining = 0;
            opportunity.currentContract.payoffAmount = 0;
        }

        opportunity.replacementVehicles = data.replacementVehicles;
        opportunity.replacementVehicle = opportunity.replacementVehicles.standardVehicle;
        opportunity.customer = data.customer;
        opportunity.tags = data.tags;
        opportunity.assignedUserFullName = data.fullName;

        // this.coreApiService.api.Entity.MarkAsRead.POST("'" + opportunity.id + "'");
        this.entityClient.MarkAsReadPOST('\'' + opportunity.id + '\'').toPromise<any>();

        if (opportunity.type == opportunityTypes.conquest) {
            opportunity.currentContract.conquestType =
                this.isCreditConvertOpportunity(opportunity) ? conquestTypes.creditConvert : conquestTypes.notConverted;
        } else if (opportunity.type == opportunityTypes.converted) {
            opportunity.currentContract.conquestType = conquestTypes.converted;
        }

        const tradeValue = (opportunity.stillOwned === null || opportunity.stillOwned === true) ? opportunity.currentVehicle.tradeValue : 0;
        const equityValue = opportunity.currentContract.payoffAmount ? opportunity.currentContract.payoffAmount : 0;
        opportunity.currentContract.equity = { tradeValue: tradeValue, equityValue: tradeValue - equityValue };

        // return this.coreApiService.api.EntityOpportunity.GetOpportunitiesForEntity['{entityId}'].GET({ entityId: opportunity.id })
        return this.entityOpportunityClientService.GetOpportunitiesForEntityByEntityidGET(opportunity.id).toPromise<any>()
            .then((response) => {
                const alerts = [];
                for (let i = 0; i < response.length; i++) {
                    const alert = response[i];

                    alerts.push({
                        type: alert.alertType,
                        typeName: this.getAlertTypeName(alert.alertType)
                    });
                }
                return alerts.sort(this.byType);
            })
            .then((alerts) => {
                opportunity.alerts = alerts;
                return opportunity;
            })
            .then(() => {
                return this.contractService.getReplacementContracts(opportunity, permissions)
                    .then((data) => {
                        opportunity.replacementContracts = data.contracts;
                        opportunity.replacementContract = data.defaultContract;
                        if (opportunity.replacementContract) {
                            opportunity.replacementContract.vehicleType = opportunity.replacementVehicle.type;
                        }

                        this.setAlertDetails(opportunity.alerts, opportunity.currentVehicle,
                            opportunity.currentContract, opportunity.replacementVehicle, opportunity.replacementContract);
                        return opportunity;
                    }

                    );
            });
    }

    private isExpired(dealSheetExpirationDate) {
        const todayDate = (new Date()).setHours(0, 0, 0, 0);
        const expirationDate = (new Date(dealSheetExpirationDate)).setHours(0, 0, 0, 0);
        return (todayDate > expirationDate);
    }

    public getOpportunityEntityOnly(opportunityId) {
        return this.entityClient.GetExtByEntityidGET(opportunityId).toPromise<EntityExternalDto>()
            // return this.coreApiService.api.Entity.GetExt['{entityId}'].GET({ entityId: opportunityId })
            .then((response) => {
                let status;
                if (response.scheduled) {
                    status = opportunityStatuses.scheduled;
                } else if (response.isMarkedAsSold) {
                    status = opportunityStatuses.sold;
                } else if (response.closed) {
                    status = opportunityStatuses.closed;
                } else {
                    status = opportunityStatuses.open;
                }
                const opportunity = {
                    id: response.entityID,
                    actionAccess: response.actionAccess,
                    keyId: response.entityKeyID,
                    dealerId: response.dealerID,
                    vehicleId: response.vehicleID,
                    customerId: response.customerID,
                    type: response.entityTypeID,
                    hasExtendedWarranty: response.hasWarranty,
                    status: status,
                    stillOwned: (response.stillOwn == null) ? true : response.stillOwn,
                    paidOff: response.paidOff,
                    watched: response.watched,
                    assignedUserId: response.ownerUserID,
                    isMarkedAsSold: response.isMarkedAsSold,
                    expirationDate: response.expirationDate,
                    entityScore: response.entityScore,
                    actionChecked: response.actionChecked
                };
                return opportunity;
            });
    }

    public getTags(opportunityId): any {
        // return this.coreApiService.api.Tag.GetTagsForEntity['{entityId}'].GET({ entityId: opportunityId })
        return this.tagClientService.GetTagsForEntityByEntityidGET(opportunityId).toPromise<any>()
            .then((response) => {
                const tags = [];
                for (let i = 0; i < response.length; i++) {
                    const tag = response[i];
                    tag.text = tag.tagText,
                        tag.expirationDate = tag.tagExpireDate;
                    tags.push(tag);
                }
                return tags;
            });
    }

    public getTagsPivotForDealers(selectedDealerId, flushCache): any {
        // return this.coreApiService.api.Tag.GetTagsPivotForDealers.GET({ flushCache: flushCache }, { overrideDealerIds: [selectedDealerId] })
        if (selectedDealerId !== null && selectedDealerId !== undefined) {
            this.httpInterceptorOptionsService.setOverrideDealerIds([selectedDealerId]);
        }
        return this.tagClientService.GetTagsPivotForDealersGET(flushCache).toPromise<any>()
            .then((data) => {
                return data;
            });
    }

    public resetActivityNotification() {
        if (this.activityNotification) {
            this.activityNotification.triggered = false;
            this.activityNotification.notify = false;
        }
    }

    public getOpportunitiesByCustomerId(customerId) {
        return this.entityClient.GetEntityReportByCustomerIdByCustomeridGET(customerId).toPromise<any>()
            // return this.coreApiService.api.Entity.GetEntityReportByCustomerId['{customerId}'].GET({ customerId: customerId })
            .then((response) => {
               return this.buildOpportunitiesFromEntityReport(response);
            });
    }

    public buildOpportunitiesFromEntityReport(entityReport) {
        const opportunities = [];
        if (entityReport == null || entityReport.length < 1) {
            return opportunities;
        }

        for (let i = 0; i < entityReport.length; i++) {
            const opportunity = entityReport[i];
            if (opportunity) {
                opportunities.push({
                    id: opportunity.entityID,
                    type: opportunity.entityTypeID,
                    alerts: opportunity.alerts ? opportunity.alerts : [],
                    stillOwned: (opportunity.stillOwn == null) ? true : opportunity.stillOwn,
                    assignedUserFullName: opportunity.ownerFullName,
                    status: opportunity.closed,
                    opportunityRank: opportunity.opportunityRank,
                    entityScore: opportunity.entityScore,
                    currentVehicle: {
                        type: opportunity.soldAsNew ? vehicleTypes.new : vehicleTypes.used,
                        makeName: opportunity.makeName,
                        yearID: opportunity.yearID == 0 ? '' : opportunity.yearID,
                        modelName: opportunity.modelName,
                        trimName: opportunity.trimName,
                        trimFullName: this.mapUtilsService.getVehicleTrimFullName(opportunity),
                        lastServiceDate: opportunity.lastServiceDate,
                        vin: opportunity.vin
                    },
                    currentContract: {
                        saleType: opportunity.saleTypeID,
                        startDate: opportunity.dealDate,
                        soldByFullName: opportunity.soldByFullName
                    },
                    dealerName: opportunity.dealerName
                });
            }
        }

        return opportunities;
    }

    public getOpportunitiesByVin(dealerId, vin) {
        return this.entityClient.GetEntityReportByDealerIdAndVinByDealeridByVinGET({ dealerId: dealerId, vin: vin }).toPromise<any>()
            // return this.coreApiService.api.Entity.GetEntityReportByDealerIdAndVin['{dealerId}']['{vin}'].GET({ dealerId: dealerId, vin: vin })
            .then((response) => {
                const data = response;
                const opportunities = [];

                for (let i = 0; i < data.length; i++) {
                    opportunities.push({
                        id: data[i].entityID,
                        customerFullName: data[i].customerFullName,
                        alerts: data[i].alerts
                    });
                }

                return opportunities;
            });
    }

    public getOwnerUserInfo(opportunityId) {
        // return this.coreApiService.api.User.GetEntityUserInfo['{entityId}'].GET({ entityId: opportunityId });
        return this.userClient.GetEntityUserInfoByEntityidGET(opportunityId).toPromise();
    }

    public addAlertsAsProperties(target, alerts) {
        this.clearAlerts(target);
        for (let i = 0; i < alerts.length; i++) {
            const alert = alerts[i];
            for (const alertType in AlertTypes) {
                if (AlertTypes.hasOwnProperty(alertType)) {
                    if (alert.type == AlertTypes[alertType]) {
                        if (alert.typeName.toLowerCase().indexOf('alert') !== -1) {
                            target[alert.typeName + 'Exists'] = true;
                        } else {
                            target[alert.typeName + 'AlertExists'] = true;
                        }
                    }
                }
            }
        }
    }

    public updateAssignedUser(opportunityId, userId) {
        return this.entityClient.ReassignToUserIdPOSTResponse({ 'entityId': opportunityId, 'assignToUserId': userId }).toPromise<any>();
        // return this.coreApiService.api.Entity.ReassignToUserId.POST({ "entityId": opportunityId, "assignToUserId": userId });
    }

    public markOpportunityWatched(opportunityId) {
        return this.entityClient.WatchByEntityidPOSTResponse(opportunityId).toPromise<any>();
        // return this.coreApiService.api.Entity.Watch['{entityId}'].POST({}, { entityId: opportunityId });
    }

    public markOpportunityUnwatched(opportunityId) {
        return this.entityClient.UnwatchByEntityidPOSTResponse(opportunityId).toPromise<any>();
        // return this.coreApiService.api.Entity.Unwatch['{entityId}'].POST({}, { entityId: opportunityId });
    }

    public reopenOpportunity(opportunityId): Promise<any> {
        return this.entityClient.ReopenByEntityidPOSTResponse(opportunityId).toPromise<any>();
        // return this.coreApiService.api.Entity.Reopen['{entityId}'].POST({}, { entityId: opportunityId });
    }

    public submitCreditPrescreenRequest(opportunityId, permissions, moduleIds, dealerSettings) {
        if (permissions.requestCreditPrescreen
            && moduleIds.indexOf(Modules.creditConvert) > -1
            && dealerSettings.useCreditSoftPull) {
            // return this.coreApiService.api.Entity.CreditPrescreenRequest['{entityId}'].POST({}, { entityId: opportunityId });
            return this.entityClient.CreditPrescreenRequestByEntityidPOSTResponse(opportunityId).toPromise<any>();

        } else {
            return Promise.resolve(false);
        }
    }

    public getCreditPrescreenStatus(opportunityId, moduleIds): Promise<any> {
        if (moduleIds.indexOf(Modules.creditConvert) > -1) {
            // return this.coreApiService.api.Entity.CreditPrescreenStatus['{entityId}'].GET({ entityId: opportunityId })
            return this.entityClient.CreditPrescreenStatusByEntityidGET(opportunityId).toPromise<any>()
                .then((response) => {
                    return response != '' ? response : null;
                },
                    () => { });
        } else {
            return Promise.resolve(null);
        }
    }

    public getCreditSoftPullResultAsync(permissions, opportunityId): Promise<any> {
        if (permissions && permissions.requestCreditPrescreen) {
            // return this.coreApiService.api.CreditSoftPullResultEntityDel['{entityId}'].GET({ entityId: opportunityId })
            return this.creditSoftPullResultEntityDelClientService.GetCreditSoftPullResultEntityDelByEntityidGET(opportunityId).toPromise<any>()
                .then((result) => result,
                    () => { });
        } else {
            return Promise.resolve(undefined);
        }
    }

    private getCreditPreApprovalAsync(opportunityId) {
        // return this.coreApiService.api.FordCreditApproval.FordCreditApprovalByEntityId['{entityId}'].GET({ entityId: opportunityId })
        return this.fordCreditApprovalClientService.FordCreditApprovalByEntityIdByEntityidGET(opportunityId).toPromise<any>()
            .then((result) => result,
                function () { });
    }

    public setAlertDetails(alerts, currentVehicle, currentContract, replacementVehicle, replacementContract) {
        for (let i = 0; i < alerts.length; i++) {
            const alert = alerts[i];

            switch (alert.type) {
                case AlertTypes.alert:
                case AlertTypes.flexAlert:
                    this.setFlexAlertDetails(alert, replacementVehicle, replacementContract);
                    break;
                case AlertTypes.service:
                    this.setServiceAlertDetails(alert, currentVehicle);
                    break;
                case AlertTypes.appointment:
                    this.setAppointmentAlertDetails(alert, currentVehicle);
                    break;
                case AlertTypes.contractEnd:
                    this.setContractEndAlertDetails(alert, currentContract);
                    break;
                case AlertTypes.mileage:
                    this.setMileageAlertDetails(alert, currentContract);
                    break;
                case AlertTypes.warranty:
                    this.setWarrantyAlertDetails(alert, currentVehicle, currentContract);
                    break;
                default:
                    break;
            }
            alert.translateKey = this.getAlertTranslateKey(alert);
        }
    }

    private getAlertTranslateKey(alert) {
        if (alert.details && !alert.details.payment
            && (alert.type == AlertTypes.alert || alert.type == AlertTypes.flexAlert)) {
            return alert.typeName + 'RelevantInfoNoPayment';
        }

        return alert.typeName + 'RelevantInfo';
    }

    private isCreditConvertOpportunity(opportunity) {
        return this.containsTag(opportunity, 'CreditConvert');
    }

    private containsTag(opportunity, tagText) {
        if (opportunity && opportunity.tags) {
            return opportunity.tags.some((tag) => {
                return tag.text === tagText;
            });
        }
    }

    private byType(alert1, alert2) {
        if (alert1.type > alert2.type) {
            return 1;
        } else if (alert1.type < alert2.type) {
            return -1;
        } else {
            return 0;
        }
    }

    private setFlexAlertDetails(alert, vehicle, contract) {
        alert.details = {
            yearID: vehicle.yearID,
            makeName: vehicle.makeName,
            modelName: vehicle.modelName,
            trimName: vehicle.trimName,
            contractTerm: contract ? contract.term : null,
            payment: contract ? contract.payment : null,
            paymentDifferenceAmount: contract ? contract.paymentDifference : null
        };
    }

    private setServiceAlertDetails(alert, vehicle) {
        alert.details = {
            openDate: vehicle.openDate,
            eighteenMonthServiceCount: vehicle.eighteenMonthServiceCount
        };
    }

    private setAppointmentAlertDetails(alert, vehicle) {
        alert.details = {
            appointmentDate: vehicle.appointmentDate
        };
    }

    private setContractEndAlertDetails(alert, contract) {
        alert.details = {
            paymentsMade: contract.paymentsMade,
            paymentsRemaining: contract.paymentsRemaining
        };
    }

    private setMileageAlertDetails(alert, contract) {
        alert.details = {
            estimatedMaturityMileage: contract.estimatedMaturityMileage,
            currentMileagePenalty: contract.currentMileagePenalty,
            estimatedMileagePenalty: contract.estimatedMileagePenalty
        };
    }

    private setWarrantyAlertDetails(alert, vehicle, contract) {
        alert.details = {
            warrantyMonthsRemaining: vehicle.warrantyMonthsRemaining ? vehicle.warrantyMonthsRemaining : contract.warrantyMonthsRemaining,
            warrantyMilesRemaining: vehicle.warrantyMilesRemaining ? vehicle.warrantyMilesRemaining : contract.warrantyMilesRemaining
        };
    }

    private getAlertTypeName(alertType) {
        switch (alertType) {
            case AlertTypes.alert:
                return 'alert';
            case AlertTypes.flexAlert:
                return 'flexAlert';
            case AlertTypes.inMarket:
                return 'inMarket';
            case AlertTypes.inMarketEngaged:
                return 'inMarketEngaged';
            case AlertTypes.contractEnd:
                return 'contractEnd';
            case AlertTypes.service:
                return 'service';
            case AlertTypes.appointment:
                return 'appointment';
            case AlertTypes.mileage:
                return 'mileage';
            case AlertTypes.warranty:
                return 'warranty';
            default:
                return '';
        }
    }

    private clearAlerts(target) {
        target.alertExists = null;
        target.flexAlertExists = null;
        target.serviceAlertExists = null;
        target.appointmentAlertExists = null;
        target.mileageAlertExists = null;
        target.contractEndAlertExists = null;
        target.warrantyAlertExists = null;
    }

    public logTradeValueChange(vehicleId, tradeValue) {
        // return this.coreApiService.api.VehicleFinalTradeValueTempChange.LogTradeValueChange.POST({ VehicleID: vehicleId, TradeValue: tradeValue });
        const params: LogTradeValueChangeRequest = { vehicleID: vehicleId, tradeValue: tradeValue };
        return this.vehicleFinalTradeValueTempChangeClientService.LogTradeValueChangePOSTResponse(params).toPromise<any>();
    }

    public getChangeLogs(opportunityId) {
        // return this.coreApiService.api.ChangeLog['{entityId}'].GET({ entityId: opportunityId })
        return this.changeLogClientService.ByEntityidGET(opportunityId).toPromise<any>()
            .then((response) => {
                if (!response) { return null; }
                return response;
            });
    }

    public submitDiscrepancy(opportunity, discrepancy) {
        const request = {
            entityId: opportunity.id,
            reportableFieldId: discrepancy.type,
            reportableExpectedValue: discrepancy.expectedValue,
            dealerId: opportunity.dealerId,
            dealerName: opportunity.dealer.name,
            customerFullName: opportunity.customer.fullName,
            currentVehicleVin: opportunity.currentVehicle.vin,
            reportDiscrepancyVehicle: {
                yearID: opportunity.replacementVehicle.yearID,
                fullTrimName: opportunity.replacementVehicle.makeName + ' ' + opportunity.replacementVehicle.trimFullName,
                localePrice: this.localeCurrency.transform(opportunity.replacementVehicle.price)
            },
            dealSheetUrl: this.dealSheetService.getDealSheetUrl(opportunity.id),
            ReportableCurrentValues: null,
            reportableCurrentValue: null
        };

        if (discrepancy.multiple) {
            request.ReportableCurrentValues = discrepancy.currentValue;
        } else {
            if (discrepancy.type == discrepancyTypes.tradeValue || discrepancy.type == discrepancyTypes.payoffAmount) {
                request.reportableCurrentValue = this.localeCurrency.transform(discrepancy.currentValue);
            } else {
                request.reportableCurrentValue = discrepancy.currentValue;
            }
        }

        // return this.coreApiService.api.DiscrepancyReport.ReportDiscrepancy.POST(request);
        return this.discrepancyReportClientService.ReportDiscrepancyPOSTResponse(request).toPromise<any>();
    }

    public addTermOverrideChangeLog(vehicleId, term) {
        // return this.coreApiService.api.ChangeLog.Term['{vehicleId}']['{term}'].POST({}, { vehicleId: vehicleId, term: term });
        return this.changeLogClientService.AddTermChangeLogByVehicleidByTermPOSTResponse({ vehicleId: vehicleId, term: term }).toPromise<any>();
    }

    public addPriceOverrideChangeLog(vehicleId, price) {
        // return this.coreApiService.api.ChangeLog.Price['{vehicleId}']['{price}'].POST({}, { vehicleId: vehicleId, price: price });
        return this.changeLogClientService.AddPriceChangeLogByVehicleidByPricePOSTResponse({ vehicleId: vehicleId, price: price }).toPromise<any>();
    }

    public getPandoClaim(opportunityId): any {
        // return (this.coreApiService.api.PandoClaim.ByEntity['{entityId}'].GET({ entityId: opportunityId }))
        return this.pandoClaimClientService.ByEntityByEntityidGET(opportunityId).toPromise<any>()
            .then((results) => {
                return results;
            });
    }

    public hasReviewProDealer(entityId: string): Promise<boolean> {
        return this.entityClient.HasReviewProDealerByEntityidGET(entityId).toPromise<any>()
            // return (this.coreApiService.api.Entity.HasReviewProDealer['{entityId}'].GET({ entityId: entityId }))
            .then((results) => {
                return results;
            },
                () => {
                    return false;
                });
    }

    public hasGeoAlertDealer(entityId: string): Promise<boolean> {
        return this.entityClient.HasGeoAlertDealerByEntityidGET(entityId).toPromise<any>()
            // return (this.coreApiService.api.Entity.HasGeoAlertDealer['{entityId}'].GET({ entityId: entityId }))
            .then((results) => {
                return results;
            },
                () => {
                    return false;
                });
    }


    public getEntityScoreFeature(entityId): Promise<any> {
        return this.entityClient.GetEntityScoreFeatureByEntityidGET(entityId).toPromise<any>()
            .then(function (response) {
                return response;
            });
    }



    /* BEGIN --- Unused redundent functions that were created for dealsheet lite in service but never used */

    public showIfNotNullOrEmpty(defaultValue, item, obj) {

        if (item != null && item != '') {
            if (obj != null) { return obj; }
            return item;
        }
        return defaultValue;
    }

    public toYesNoTypeID(yesNoTypeName) {
        if (yesNoTypeName == null) { return 0; }
        yesNoTypeName = yesNoTypeName.toString().toLowerCase();
        if (yesNoTypeName == 'yes') { return 1; }
        if (yesNoTypeName == 'no') { return 0; }
        return 0;
    }

    public toBoolean(yesNoTypeName) {
        if (yesNoTypeName == null) { return false; }
        yesNoTypeName = yesNoTypeName.toString().toLowerCase();
        if (yesNoTypeName == 'yes') { return true; }
        if (yesNoTypeName == 'no') { return false; }
        return false;
    }

    public nullIf(valueIfNull, defaultValue) {
        if (valueIfNull) { return null; }
        return defaultValue;
    }

    public defaultValue(value, defaultValue) {
        if (value == null) { return defaultValue; }
        return value;
    }

    public toSaleTypeID(saleType) {
        if (saleType == null) { return ''; }
        saleType = saleType.toString().toLowerCase();
        if (saleType == 'lease') { return 1; }
        if (saleType == 'retail') { return 2; }
        if (saleType == 'balloon') { return 3; }
        if (saleType == 'cash') { return 4; }
        if (saleType == 'onepay lease') { return 5; }
        if (saleType == 'wholesale') { return 6; }
        if (saleType == 'incomplete') { return 7; }
        return '';
    }

    public NewUsedCpoToID(vehicleType) {
        if (vehicleType == null) { return ''; }
        vehicleType = vehicleType.toString().toLowerCase();
        if (vehicleType == 'new') { return 0; }
        if (vehicleType == 'used') { return 1; }
        if (vehicleType == 'cpo') { return 2; }
        return '';
    }

    public toAlertTypeList(vw_search, replacementVehicle) {
        replacementVehicle.payment = vw_search.replacement_Payment;
        const alerts = [];
        if (vw_search.is_Alert == 'Yes') { this.pushAlert(alerts, AlertTypes.alert, 'alert', replacementVehicle); }
        if (vw_search.is_Appointment == 'Yes') { this.pushAlert(alerts, AlertTypes.appointment, 'appointment', replacementVehicle); }
        if (vw_search.is_Contract_End == 'Yes') { this.pushAlert(alerts, AlertTypes.contractEnd, 'contractEnd', replacementVehicle); }
        // if (vw_search.is_Conversion_Alert == "Yes") this.pushAlert(alerts, alertTypes.alert, 'alert');
        if (vw_search.is_Flex_Alert == 'Yes') { this.pushAlert(alerts, AlertTypes.flexAlert, 'flexAlert', replacementVehicle); }
        if (vw_search.is_In_Market == 'Yes') { this.pushAlert(alerts, AlertTypes.inMarket, 'inMarket', replacementVehicle); }
        if (vw_search.is_In_Market_Engaged == 'Yes') { this.pushAlert(alerts, AlertTypes.inMarketEngaged, 'inMarketEngaged', replacementVehicle); }
        if (vw_search.is_Mileage_Alert == 'Yes') { this.pushAlert(alerts, AlertTypes.mileage, 'mileage', replacementVehicle); }
        if (vw_search.is_Warranty_Alert == 'Yes') { this.pushAlert(alerts, AlertTypes.warranty, 'warranty', replacementVehicle); }

        return alerts;
    }

    public pushAlert(arr, type, name, replacementVehicle) {
        arr.push({
            type: type,
            typeName: name,
            translateKey: name + 'RelevantInfo',
            details: replacementVehicle, // terrible.  needs refactor.

        });
    }

    /* END ---- Unused redundent functions that were created for dealsheet lite in service but never used */

    public getOpportunityFromViewEntityId(entityId, vw_SearchRow) {
        if (vw_SearchRow == null || vw_SearchRow['_EntityID'] == null) {
            entityId = (vw_SearchRow == null) ? entityId : vw_SearchRow.opportunityId;
            // return this.coreApiService.api.Search.GetSearchByEntityId['{entityId}'].GET({ entityId: entityId });
            return this.searchClientService.GetSearchByEntityIdByEntityidGET(entityId).toPromise<any>()
                .then((results) => results);
        } else {
            return Promise.resolve(vw_SearchRow);
        }
    }

    public getOpportunityFromView(vw_SearchRow) {
        if (vw_SearchRow == null) {
            return;
        }
        const opportunity = {
            id: vw_SearchRow._EntityID,
            vehicleId: vw_SearchRow._VehicleID,
            type: vw_SearchRow._EntityTypeID,
            stillOwned: this.toBoolean(vw_SearchRow.still_Own),
            paidOff: this.toBoolean(vw_SearchRow.paid_Off),
            dealerId: vw_SearchRow._DealerID,
            customer: {
                fullName: vw_SearchRow.full_Name,
                address: {
                    streetAddress: vw_SearchRow.address,
                    streetAddress2: vw_SearchRow.address_2,
                    stateId: vw_SearchRow._StateID,
                    normalizedZipCode: vw_SearchRow.zip_Code,
                    zipCode: vw_SearchRow.zip_Code,
                    city: vw_SearchRow.city,
                    stateName: vw_SearchRow.state
                },
                pricingPlanId: null, // disables UseEmployeePricing
                alternateContacts: [],
                cellPhoneNumber: this.showIfNotNullOrEmpty(null, vw_SearchRow.cell_Phone, {
                    'contactType': 4,
                    'value': vw_SearchRow.cell_Phone,
                    'isBad': false,
                    'isPreferred': true,
                    'doNotCallStatusTypeId': 4
                }),
                'companyName': '',
                'doNotCall': this.toBoolean(vw_SearchRow.do_Not_Call),
                'doNotEmail': this.toBoolean(vw_SearchRow.do_Not_Email),
                'doNotMail': this.toBoolean(vw_SearchRow.do_Not_Mail),
                'emailAddress': this.showIfNotNullOrEmpty(null, vw_SearchRow.email, {
                    'contactType': 5,
                    'value': vw_SearchRow.email,
                    'isBad': vw_SearchRow.email != '',
                    'isPreferred': vw_SearchRow.email != ''
                }),
                'firstName': vw_SearchRow.first_Name,
                'homePhoneNumber': this.showIfNotNullOrEmpty(null, vw_SearchRow.home_Phone, {
                    'contactType': 2,
                    'value': vw_SearchRow.home_Phone,
                    'isBad': false,
                    'isPreferred': true,
                    'doNotCallStatusTypeId': 4
                }),
                'id': vw_SearchRow._CustomerID,
                'invalidContacts': [],
                'isAddressNotValid': false,
                'isFullCustomer': true,
                'lastName': vw_SearchRow.last_Name,
                'middleName': vw_SearchRow.middle_Name,
                'workPhoneNumber': this.showIfNotNullOrEmpty(null, vw_SearchRow.work_Phone, {
                    'contactType': 3,
                    'value': vw_SearchRow.work_Phone,
                    'isBad': false,
                    'isPreferred': true,
                    'doNotCallStatusTypeId': 4
                })
            },
            // "dealer": {
            //    "name": "MB Demo",
            //    "displayText": "MB Demo (MBDEMO)"
            // },
            entityScore: vw_SearchRow._Opportunity_Rank,
            assignedUserId: vw_SearchRow._OwnerUserID,
            assignedUserFullName: vw_SearchRow._Customer_Owner,
            // alerts: toAlertTypeList(vw_SearchRow),
            permissions: {
                viewContract: true,
            },
            currentContract: {
                adjustedBalance: vw_SearchRow.estimated_Payoff - vw_SearchRow.totalPenalty,
                saleType: vw_SearchRow._SaleTypeID,
                moneyFactor: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.rate : null,
                payment: vw_SearchRow.current_Payment,  // 1790,
                soldByName: vw_SearchRow.sold_By_Name, // "THOMAS CARNEVALE"
                vehicleType: this.NewUsedCpoToID(vw_SearchRow.new_Used),
                paymentsMade: vw_SearchRow.payments_Made, // 41,
                paymentsRemaining: vw_SearchRow.payment_Remaining,  // 7,
                // "cashPaid": null,
                allowedMileage: vw_SearchRow.lease_Total_Allowed_Miles, // 48000, //N/A
                // "estimatedMaturityMileage": 51700,
                // "currentMileagePenalty": 0,
                // "estimatedMileagePenalty": 900,
                // "preferredEquity": 5350,
                // "balanceToMaturity": 13125,
                // "dispositionFee": 595,
                entityID: vw_SearchRow._EntityID,
                // "hasWarranty": false,
                bankName: vw_SearchRow.bank_Name,
                // "bankUrl": "https://new.mb-advantage.com",
                // "isCreditConvert": true,
                // "paymentMinimum": 1700,
                // "paymentMaximum": 1750,
                payoffAmount: vw_SearchRow.estimated_Payoff, // 57550,
                // "payoffGoodThroughDate": null,
                lastTouchedDate: vw_SearchRow.last_Payment_Date, // How Necessary is this?  Required by <customer-contact>
                saleID: vw_SearchRow._SaleID, // 60924823,
                // "insertDate": "2015-04-25T01:25:57.84Z",
                // "dealerID": 1167,
                // "customerID": 38827071,
                // "vehicleID": 89569084,
                // "soldAsNew": true,
                // "mileageAtDelivery": 8,
                // "bankID": 48,
                dmsDealId: vw_SearchRow.dmS_Deal_ID,
                // "soldByID": 314698,
                term: vw_SearchRow.deal_Term,
                dealDate: vw_SearchRow.deal_Date,
                // "firstPaymentDate": "2015-04-18T00:00:00",
                lastPaymentDate: vw_SearchRow.last_Payment_Date, // "2019-04-18T00:00:00",
                // "leaseBasePayment": 1646,
                // "leaseTotalPayment": 1790,
                capitalCost: vw_SearchRow.lease_Net_Cap_Cost,
                residualAmount: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.lease_Residual : null, // 45807,
                // "leaseAnnualMiles": null,
                // "retailPayment": 0,
                financedAmount: vw_SearchRow.purchase_Amount_Financed,
                // "balloonPayment": null,
                cashDown: vw_SearchRow.cash_Down, // 1495,
                apr: vw_SearchRow._SaleTypeID == 1 ? null : vw_SearchRow.rate, //  null,
                // "isNative": true,
                // "boughtExtWarranty": false,
                // "hasExtendedWarranty": false,
                paidOff: this.toYesNoTypeID(vw_SearchRow.paid_Off), // false
                // "equity": {
                //  "tradeValue": 10, // 62900,
                //  "equityValue": 5350
                // }
                financedOrCapCost: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.lease_Net_Cap_Cost : vw_SearchRow.purchase_Amount_Financed
            },
            currentVehicle: {
                openDate: vw_SearchRow.openDate,
                tradeValue: vw_SearchRow.trade_Value,
                trimID: vw_SearchRow._TrimID,
                yearID: vw_SearchRow._YearID,
                makeID: vw_SearchRow._MakeID,
                // imagePath: "https://media.chromedata.com/MediaGallery/media/Mjk4MTk1Xk1lZGlhIEdhbGxlcnk/Sn49u8QXXzwYZTjrZnCR0T8Wpzp-bEBtPwoDyPdoT4Zo-WVTyU0mgsvV07a55ZhB/2018MBCBN0001_320_07.png",
                // "imagePath": "../../../images/Capture1.png",
                // imagePath: oneImage,
                type: this.NewUsedCpoToID(vw_SearchRow.new_Used),
                // pricingPlanMsrp: vw_SearchRow.
                // "addModelToTrim": false,
                // "appointmentDate": "2018-05-04T12:00:00",
                // "dealerID": 1167,
                // "displayCodes": false,
                // "eighteenMonthServiceCount": 3,
                // "estimatedCurrentMileage": 44200,
                // "estimatedMilesPerMonth": 1140,
                // "extColorID": null,
                // "exteriorColor": "DESIGNO GRAPHITE",
                // "intColorID": null,
                // "interiorColor": null,
                // "lastOdometerReadingDate": "2018-05-04T15:24:00",
                'makeName': vw_SearchRow.current_Make_Name,
                // "mfcModelCode": null,
                'modelName': vw_SearchRow.current_Model_Name,
                // "modelNo": "E63SP",
                // "odometer": 38750,
                // "replacementTrimID": 422662,
                // "tradeMileageAdjustment": -2400,
                // "tradeSourceID": 1,
                'trimFullName': this.defaultValue(vw_SearchRow.current_Model_Name, '') + ' ' + this.defaultValue(vw_SearchRow.current_Trim_Name, ''),
                'trimName': this.defaultValue(vw_SearchRow.current_Trim_Name, ''),
                // "vehicleID": 89569084,
                // "viN8Key": "WDDHH7GB_F",
                'vin': vw_SearchRow.vin,
                // "vrn": null,
                // "vrnLastUpdateDate": "2016-06-27T14:45:00",
                // "warrantyMilesRemaining": 5800,
                // "warrantyMonthsRemaining": 7
            },
            replacementVehicle: {
                paymentDifferenceAmount: vw_SearchRow.replacement_Payment - vw_SearchRow.current_Payment,
                type: this.NewUsedCpoToID(vw_SearchRow.replacement_New_Used),  // 0,
                stockNo: vw_SearchRow.replacement_Stock_No, // null,
                // "odometer": 0,
                // "vin": null,
                msrp: vw_SearchRow.replacement_MSRP,
                price: vw_SearchRow.replacement_MSRP, // is not part of vw_search, MSRP is part of vw_search.
                trimID: vw_SearchRow.replacementTrimID,
                // "daysInStock": null,
                // "interiorColor": null,
                // "exteriorColor": null,
                // "countryID": 4,
                // "countryName": "France",
                makeID: vw_SearchRow.replacementMakeID,
                makeName: vw_SearchRow.replacement_Make_Name, // "Mercedes-Benz",
                // modelID: vw_SearchRow.replacementModelID, //replacementModelID needs to be added to vw_search
                modelName: vw_SearchRow.replacement_Model_Name,
                yearID: vw_SearchRow.replacement_Model_Year, // 2018,
                trimName: vw_SearchRow.replacement_Trim_Name,
                // "rawTrimName": null,
                // "mfcModelCode": "E43W4",
                // "extID": "7778055",
                // "extOptionId": "0",
                // "extFuelTypeId": 1059,
                // "cityMPG": 19,
                // "cityMPGe": null,
                // "highwayMPG": 25,
                // "highwayMPGe": null,
                // "combinedMPG": 21,
                // "batteryRange": null,
                trimFullName: vw_SearchRow.replacement_Full_Trim_Name, // "E 43 AMG",
                // "imagePath": vw_SearchRow.imagePath, //"https://media.chromedata.com/MediaGallery/media/Mjk4MTk1Xk1lZGlhIEdhbGxlcnk/Sn49u8QXXzwYZTjrZnCR0T8Wpzp-bEBtPwoDyPdoT4Zo-WVTyU0mgsvV07a55ZhB/2018MBCBN0001_320_07.png",
                // "imagePath": "https://media.chromedata.com/MediaGallery/media/Mjk4MTk1Xk1lZGlhIEdhbGxlcnk/Sn49u8QXXzwYZTjrZnCR0T8Wpzp-bEBtPwoDyPdoT4Zo-WVTyU0mgsvV07a55ZhB/2018MBCBN0001_320_07.png",
                // imagePath: twoImage,
                // "vehicleID": 89569084,
                // "oldMakeID": 51,
                // "oldTrimID": 389466,
                // "newMakeID": 51,
                // "newTrimID": 422648,
                // "isValid": 1,
                // "isConquest": 0,
                // "newVehiclePrice": 72595
            },
            replacementContract: {
                keyID: vw_SearchRow._EntityKeyID,
                saleType: this.toSaleTypeID(vw_SearchRow.replacement_Sale_Type), // 1,
                // "saleTypeName": "Lease",
                // "bankID": 48,
                // "bankName": "Mercedes-Benz Financial",
                term: vw_SearchRow.replacement_Term,
                // "termBeforeImprovement": null,
                apr: this.toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 2 ? vw_SearchRow.replacement_Rate : null, // null,
                moneyFactor: this.toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 1 ? vw_SearchRow.replacement_Rate : null, // 0.00386,
                // "standardRebate": 0,
                // "loyaltyRebate": 0,
                // "conquestRebate": 0,
                // "cashOrAprRebate": 1000,
                // "cashOrApr": true,
                // "isValueAdded": false,
                // "pullForwardAmount": 5370,
                // "specialLoyaltyValue": 0,
                isOverAllowance: this.toYesNoTypeID(vw_SearchRow.is_Over_Allowance), // false,
                financedAmount: this.nullIf(this.toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 1, vw_SearchRow.replacement_Loan_Amount), // null,
                // "residualAmount": 35571.55,
                preferredEquity: vw_SearchRow.preferred_Equity, // 5350,
                payment: vw_SearchRow.replacement_Payment, // 1157,
                paymentDifference: vw_SearchRow.replacement_Payment - vw_SearchRow.current_Payment, // -633,
                // "capitalCost": 67512,
                // "paymentBeforeImprovement": null,
                // "salesTax": 8.75,
                // "isClosestTerm": true,
                isAlert: this.toYesNoTypeID(vw_SearchRow.is_Alert), // 1,
                isFlexAlert: this.toYesNoTypeID(vw_SearchRow.is_Flex_Alert), // 1,
                isConversionAlert: this.toYesNoTypeID(vw_SearchRow.is_Conversion_Alert), // 0,
                // "factoryRebate": 1000,
                // "dealerRebate": 0,
                // "cashBack": 0,
                // "adjustedCashDown": 0,
                // "isCapDownReduced": false,
                // "maxCapDown": null,
                // "isReplacementPayment": true,
                // "programSourceID": 2,
            },
            alerts: []
        };
        opportunity.alerts = this.toAlertTypeList(vw_SearchRow, opportunity.replacementVehicle);
        return opportunity;
    }
}
